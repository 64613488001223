import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Merchants 图标组件
 */
export const Merchants = defineComponent({
	name: "Merchants",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Merchants icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M517.632 552.15c80.64 0 152.277-46.678 182.613-118.955a21.333 21.333 0 0 0-39.338-16.512c-23.595 56.362-79.915 92.8-143.275 92.8-85.163 0-154.453-66.432-154.453-148.054 0-29.568 9.045-58.026 26.026-82.474 28.843-41.088 76.8-65.622 128.427-65.622 51.541 0 99.499 24.491 128.299 65.579a139.742 139.742 0 0 1 14.933 27.136 21.333 21.333 0 1 0 39.339-16.597 188.416 188.416 0 0 0-19.371-35.072c-36.736-52.438-97.792-83.712-163.2-83.712-65.493 0-126.55 31.317-163.285 83.84a185.43 185.43 0 0 0-33.835 106.88c0 105.216 88.405 190.762 197.12 190.762M720.17 810.667H303.873c-49.92 0-90.539-41.088-90.539-91.606 0-50.517 40.619-91.605 90.539-91.605h416.299c49.92 0 90.453 41.088 90.453 91.605.043 50.518-40.49 91.606-90.41 91.606m0-225.878H303.871c-73.472 0-133.205 60.246-133.205 134.272 0 74.027 59.733 134.272 133.205 134.272h416.299c73.386 0 133.12-60.245 133.12-134.272.042-74.026-59.691-134.272-133.078-134.272" fill="#3C405D"/><path d="M475.136 739.413l38.827 36.139 38.016-37.675-37.974-75.264z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Merchants;
