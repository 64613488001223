import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * NotData 图标组件
 */
export const NotData = defineComponent({
	name: "NotData",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-NotData icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1569 1024"><path d="M0 855.859184a784.670613 168.140816 0 1 0 1569.341227 0 784.670613 168.140816 0 1 0-1569.341227 0Z" fill="#F3F3F8" p-id="4760"></path><path d="M1233.059594 897.909543h-896.777962a98.205067 98.205067 0 0 1-98.06362-98.104033v-434.446287h257.535718l22.732654 45.465309a126.272318 126.272318 0 0 0 126.050044 122.796748h308.275001a98.205067 98.205067 0 0 0 97.982793-93.69895l49.627911-74.421659h230.721288v434.446286a98.205067 98.205067 0 0 1-98.083827 97.962586z" fill="#FBFBFD" p-id="4761"></path><path d="M1233.059594 883.906228a84.181546 84.181546 0 0 0 84.060305-84.100718v-420.301524H1107.918857l-43.363801 65.065909a112.248796 112.248796 0 0 1-111.743627 103.0547H644.536428a140.215013 140.215013 0 0 1-139.851291-133.223458l-17.519299-34.998185H252.221328v420.301523a84.181546 84.181546 0 0 0 84.060304 84.100719h896.777962m0 28.00663h-896.777962a112.228589 112.228589 0 0 1-112.087142-112.107349V351.456942h280.207752l28.047044 56.053674a112.208383 112.208383 0 0 0 112.087142 112.087142h308.275001a84.141132 84.141132 0 0 0 84.060305-84.060304l56.053674-84.060305h252.221328v448.328361a112.228589 112.228589 0 0 1-112.087142 112.107348z" fill="#E2E2EE" p-id="4762"></path><path d="M249.210514 371.360644l-24.389613-17.984055L485.286605 0H1060.655142l283.804563 351.275081-23.58134 19.034809L1046.187071 30.310206h-545.583708L249.210514 371.360644z" fill="#E2E2EE" p-id="4763"></path></svg>
			</ElIcon>
		);
	},
});

export default NotData;
