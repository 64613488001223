import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Ability 图标组件
 */
export const Ability = defineComponent({
	name: "Ability",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Ability icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M723.712 427.819a21.248 21.248 0 0 0-27.05 13.312 204.8 204.8 0 0 1-88.96 109.653 20.907 20.907 0 0 0-5.803 5.29 20.907 20.907 0 0 0-8.107 16.385v54.058l-184.917 23.04v-77.056a21.29 21.29 0 0 0-2.39-9.429 21.376 21.376 0 0 0-9.642-13.227 203.179 203.179 0 0 1-98.134-174.677 204.672 204.672 0 0 1 204.374-204.459 204.715 204.715 0 0 1 197.546 151.296 21.29 21.29 0 1 0 41.131-11.093 247.339 247.339 0 0 0-238.677-182.87 247.381 247.381 0 0 0-247.04 247.126c0 83.413 41.13 159.147 110.165 205.227v93.44a21.376 21.376 0 0 0 23.979 21.162l227.541-28.458a21.333 21.333 0 0 0 18.688-21.163v-62.848a247.04 247.04 0 0 0 100.608-127.616 21.333 21.333 0 0 0-13.312-27.05M609.621 723.627l-215.125 19.541a21.376 21.376 0 0 0 1.92 42.624l1.963-.085 215.125-19.584a21.333 21.333 0 0 0 19.285-23.168 20.992 20.992 0 0 0-23.168-19.328m-61.312 104.96l-94.208 12.501a21.333 21.333 0 1 0 5.59 42.24l94.208-12.416a21.333 21.333 0 1 0-5.547-42.283" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Ability;
