import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * PurchaseOrderManagement 图标组件
 */
export const PurchaseOrderManagement = defineComponent({
	name: "PurchaseOrderManagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-PurchaseOrderManagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.333 789.333a32 32 0 1 0 0 64 32 32 0 0 0 0-64m268.459-426.666H423.125a21.333 21.333 0 0 0 0 42.666h218.667a21.333 21.333 0 0 0 0-42.666m0 85.333H423.125a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667M704 640a64 64 0 1 0 .043 128.043A64 64 0 0 0 704 640" fill="#3C405D"/><path d="M704 810.667A106.795 106.795 0 0 1 597.333 704c0-58.795 47.83-106.667 106.667-106.667S810.667 645.205 810.667 704 762.837 810.667 704 810.667m0-256a147.627 147.627 0 0 0-41.856 6.698c.384-1.621.981-3.157.981-4.906a21.333 21.333 0 0 0-21.333-21.334H423.125a21.333 21.333 0 1 0 0 42.667h201.942A148.907 148.907 0 0 0 554.667 704c0 82.347 66.986 149.333 149.333 149.333S853.333 786.347 853.333 704 786.347 554.667 704 554.667" fill="#3C405D"/><path d="M491.52 684.416a21.333 21.333 0 0 0 0-42.667H290.688l-26.453-343.082h374.912A171.733 171.733 0 0 1 806.4 508.16a21.333 21.333 0 1 0 41.6 9.557 209.963 209.963 0 0 0 5.333-47.53A214.443 214.443 0 0 0 639.147 256h-378.24l-4.95-65.621c-.128-.939-.64-1.707-.81-2.56a21.376 21.376 0 0 0-1.536-5.206c-.299-.64-.726-1.066-1.067-1.621a21.333 21.333 0 0 0-4.224-4.95c-.384-.469-.981-.81-1.493-1.109a22.101 22.101 0 0 0-4.992-2.858c-1.408-.555-2.902-.598-4.395-.811-.896-.128-1.75-.597-2.773-.597H192a21.333 21.333 0 0 0 0 42.666h22.955l41.13 534.998c.128 1.28.683 2.389.982 3.584.341 1.237.426 2.474.938 3.626.683 1.451 1.707 2.646 2.688 3.883.683.853 1.11 1.835 1.92 2.56 1.28 1.28 2.859 2.133 4.438 2.987.768.426 1.365 1.024 2.218 1.365a21.077 21.077 0 0 0 8.022 1.621h213.333a21.29 21.29 0 1 0 0-42.666H297.088l-3.157-40.875h197.674z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default PurchaseOrderManagement;
