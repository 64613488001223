import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Coupons 图标组件
 */
export const Coupons = defineComponent({
	name: "Coupons",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Coupons icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M853.333 627.03v64.426c0 33.45-27.221 60.757-60.629 60.757H364.459v-77.44a21.333 21.333 0 0 0-42.667 0v77.44h-90.496a60.8 60.8 0 0 1-60.63-60.757v-63.872c40.833-15.573 67.542-54.699 67.542-101.888 0-46.933-26.795-86.187-67.541-101.845v-64.427c0-33.493 27.221-60.8 60.629-60.8h90.496v77.483a21.333 21.333 0 0 0 42.667 0v-77.483h428.245c33.408 0 60.63 27.307 60.63 60.8v65.067c-39.766 16.085-65.793 55.04-65.793 101.205 0 46.507 25.899 85.333 65.792 101.333m23.595-165.76c.384-.085.64-.426.981-.469A21.077 21.077 0 0 0 896 440.107v-80.683a103.51 103.51 0 0 0-103.296-103.467H231.296A103.51 103.51 0 0 0 128 359.424v78.89c0 9.473 6.272 17.238 14.848 20.011a21.333 21.333 0 0 0 6.016 2.944c27.947 7.211 46.677 33.11 46.677 64.427 0 32-19.37 58.027-48.256 64.768-1.706.427-3.072 1.28-4.565 2.09A21.077 21.077 0 0 0 128 612.566v78.891A103.51 103.51 0 0 0 231.296 794.88h561.408A103.51 103.51 0 0 0 896 691.456v-80.213a20.992 20.992 0 0 0-16.683-20.352c-.298-.086-.554-.342-.853-.427-28.885-6.699-48.256-32.725-48.256-64.768 0-31.317 18.773-57.216 46.72-64.427" fill="#3C405D"/><path d="M343.125 440.15a21.333 21.333 0 0 0-21.333 21.333v128a21.333 21.333 0 0 0 42.667 0v-128a21.333 21.333 0 0 0-21.334-21.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Coupons;
