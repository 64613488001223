import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Minigames 图标组件
 */
export const Minigames = defineComponent({
	name: "Minigames",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Minigames icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M639.4 128.3H384c-211.2 0-383 171.8-383 383s171.8 383 383 383h255.4c211.2 0 383-171.8 383-383s-171.8-383-383-383zm0 681H384c-164.3 0-297.9-133.6-297.9-297.9s133.7-298 297.9-298h255.4c164.3 0 297.9 133.6 297.9 297.9s-133.7 298-297.9 298z" fill="#333"/><path d="M469.1 468.8H384v-85.1c0-23.4-19.1-42.6-42.6-42.6-23.4 0-42.6 19.2-42.6 42.6v85.1h-85.1c-23.4 0-42.6 19.2-42.6 42.6 0 23.4 19.1 42.6 42.6 42.6h85.1v85c0 23.4 19.1 42.6 42.6 42.6 23.4 0 42.6-19.2 42.6-42.6v-85.1h85.1c23.4 0 42.6-19.2 42.6-42.6 0-23.4-19.1-42.5-42.6-42.5zM681.9 383.7a42.6 42.6 0 1 0 85.2 0 42.6 42.6 0 1 0-85.2 0zM681.9 639a42.6 42.6 0 1 0 85.2 0 42.6 42.6 0 1 0-85.2 0zM596.8 511.4a42.6 42.6 0 1 0 85.2 0 42.6 42.6 0 1 0-85.2 0zM767 511.4a42.6 42.6 0 1 0 85.2 0 42.6 42.6 0 1 0-85.2 0z" fill="#333"/></svg>
			</ElIcon>
		);
	},
});

export default Minigames;
