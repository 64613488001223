import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Allocationvariance 图标组件
 */
export const Allocationvariance = defineComponent({
	name: "Allocationvariance",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Allocationvariance icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M838.059 596.267a21.376 21.376 0 0 0-26.155 15.018 309.973 309.973 0 0 1-298.837 228.096c-137.686 0-260.096-92.202-297.814-224.213a20.821 20.821 0 0 0-20.522-15.147c-.939-.128-1.75-.554-2.731-.554a21.333 21.333 0 0 0-21.333 21.333v239.915a21.333 21.333 0 1 0 42.666 0V714.496c63.062 101.76 175.872 167.552 299.734 167.552 158.72 0 298.581-106.752 340.01-259.627a21.333 21.333 0 0 0-15.018-26.154M190.72 452.31a21.333 21.333 0 0 0 26.71-14.038 307.968 307.968 0 0 1 295.637-217.045 308.181 308.181 0 0 1 295.466 216.661 21.077 21.077 0 0 0 12.502 13.184c1.237.555 2.517.81 3.84 1.152.853.17 1.706.427 2.645.512.853.128 1.664.512 2.56.512a21.26 21.26 0 0 0 21.333-21.333V192a21.333 21.333 0 1 0-42.666 0v148.608A349.91 349.91 0 0 0 513.109 178.56 350.55 350.55 0 0 0 176.768 425.6a21.333 21.333 0 0 0 14.037 26.71" fill="#3C405D"/><path d="M553.941 464.597a21.333 21.333 0 0 0-21.333-21.333H361.941a21.333 21.333 0 0 0 0 42.667h170.667a21.333 21.333 0 0 0 21.333-21.334m106.667-21.333h-42.667a21.333 21.333 0 0 0 0 42.667h42.667a21.333 21.333 0 0 0 0-42.667M489.941 613.845h170.667a21.333 21.333 0 0 0 0-42.666H489.941a21.333 21.333 0 0 0 0 42.666m-128-42.666a21.333 21.333 0 0 0 0 42.666h42.667a21.333 21.333 0 0 0 0-42.666h-42.667z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Allocationvariance;
