import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * AItools 图标组件
 */
export const AItools = defineComponent({
	name: "AItools",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-AItools icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M362.8 553.9c23.5 0 42.6-19.1 42.6-42.6V213.4c0-46.9 38.2-85.1 85.1-85.1s85.1 38.2 85.1 85.1V245c0 23.5 19 42.6 42.6 42.6 23.5 0 42.6-19.1 42.6-42.6v-31.5c0-93.9-76.3-170.2-170.2-170.2s-170.2 76.4-170.2 170.2v297.9c-.2 23.5 18.8 42.5 42.4 42.5zm297.9-85.1c-23.5 0-42.6 19.1-42.6 42.6v297.9c0 46.9-38.2 85.1-85.1 85.1s-85.1-38.2-85.1-85.1v-31.5c0-23.5-19-42.6-42.6-42.6-23.5 0-42.6 19.1-42.6 42.6v31.5c0 93.9 76.3 170.2 170.2 170.2s170.2-76.4 170.2-170.2V511.4c.1-23.5-18.9-42.6-42.4-42.6z" fill="#1A1A1A"/><path d="M809.6 319.8H511.7c-23.5 0-42.6 19.1-42.6 42.6s19 42.6 42.6 42.6h297.9c46.9 0 85.1 38.2 85.1 85.1s-38.2 85.1-85.1 85.1h-31.5c-23.5 0-42.6 19.1-42.6 42.6s19 42.6 42.6 42.6h31.5c93.9 0 170.2-76.4 170.2-170.2s-76.3-170.4-170.2-170.4zM554.3 660.3c0-23.5-19-42.6-42.6-42.6H213.8c-46.9 0-85.1-38.2-85.1-85.1s38.2-85.1 85.1-85.1h31.5c23.5 0 42.6-19.1 42.6-42.6s-19-42.6-42.6-42.6h-31.5c-93.9 0-170.2 76.4-170.2 170.2s76.3 170.2 170.2 170.2h297.9c23.5.2 42.6-18.9 42.6-42.4z" fill="#1A1A1A"/></svg>
			</ElIcon>
		);
	},
});

export default AItools;
