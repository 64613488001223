import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Plateofgoods 图标组件
 */
export const Plateofgoods = defineComponent({
	name: "Plateofgoods",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Plateofgoods icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 469.76a21.333 21.333 0 0 1-21.333-21.333V263.168a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h497.621a49.92 49.92 0 0 0 49.878-49.878V576.427a21.333 21.333 0 1 1 42.666 0v184.362a92.63 92.63 0 0 1-92.544 92.544H263.168a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621c51.03 0 92.544 41.514 92.544 92.501v185.259A21.333 21.333 0 0 1 832 469.76zM383.53 255.53h256v-42.197h-256v42.198z" fill="#3C405D"/><path d="M383.573 532.01a107.648 107.648 0 0 1 107.52-107.562c59.307 0 107.563 48.213 107.563 107.52a107.69 107.69 0 0 1-107.52 107.605 107.69 107.69 0 0 1-107.605-107.562m107.562-150.23a150.4 150.4 0 0 0-150.186 150.187A150.4 150.4 0 0 0 491.093 682.24c35.67 0 68.054-13.056 93.867-33.92l61.952 67.797a21.077 21.077 0 0 0 30.123 1.408 21.29 21.29 0 0 0 1.365-30.122l-63.957-70.059a148.907 148.907 0 0 0 26.88-85.333 150.443 150.443 0 0 0-150.187-150.23" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Plateofgoods;
