import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Resourceordering 图标组件
 */
export const Resourceordering = defineComponent({
	name: "Resourceordering",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Resourceordering icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.333 789.333a32 32 0 1 0 0 64 32 32 0 0 0 0-64m277.334 0a32 32 0 1 0 0 64 32 32 0 0 0 0-64M828.245 513.237a21.333 21.333 0 0 0-27.178 13.056A169.259 169.259 0 0 1 641.92 640H290.475l-26.283-341.333h377.643A169.216 169.216 0 0 1 800.98 412.33a21.419 21.419 0 0 0 27.179 13.056 21.333 21.333 0 0 0 13.099-27.179A212.01 212.01 0 0 0 641.877 256H260.95l-5.034-65.621c-.086-.939-.598-1.707-.768-2.646-.384-1.749-.726-3.456-1.536-5.12-.342-.597-.811-1.109-1.238-1.792a20.693 20.693 0 0 0-3.84-4.693c-.64-.555-1.28-.853-1.962-1.323a21.12 21.12 0 0 0-4.694-2.645c-1.45-.64-2.944-.725-4.437-.939-.939-.128-1.792-.554-2.773-.554H192a21.333 21.333 0 0 0 0 42.666h22.912l41.173 534.998c.086 1.28.64 2.346.982 3.584.298 1.194.426 2.474.938 3.584.683 1.493 1.707 2.688 2.688 3.968.64.853 1.11 1.792 1.878 2.517 1.322 1.237 2.858 2.133 4.437 2.987.768.426 1.408 1.066 2.219 1.408a21.248 21.248 0 0 0 8.106 1.621h469.334a21.333 21.333 0 1 0 0-42.667H297.045l-3.242-42.666h348.074a212.053 212.053 0 0 0 199.467-142.251 21.333 21.333 0 0 0-13.099-27.179" fill="#3C405D"/><path d="M483.413 391.808h91.051a29.867 29.867 0 0 1 29.867 29.867 21.333 21.333 0 1 0 42.666 0c0-39.979-32.554-72.534-72.533-72.534h-23.893v-4.266a21.333 21.333 0 1 0-42.667 0v4.266h-24.533a72.533 72.533 0 0 0 0 145.067h91.093a29.867 29.867 0 0 1 0 59.733h-91.05a29.867 29.867 0 0 1-29.825-29.866 21.333 21.333 0 0 0-42.666 0 72.533 72.533 0 0 0 72.533 72.533h24.448v4.267a21.333 21.333 0 1 0 42.667 0v-4.267h23.936c39.978 0 72.533-32.512 72.533-72.533 0-39.979-32.555-72.534-72.533-72.534h-91.094a29.867 29.867 0 0 1 0-59.733" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Resourceordering;
