import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Announcement 图标组件
 */
export const Announcement = defineComponent({
	name: "Announcement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Announcement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M512 181.461l138.667 157.867H373.376L512 181.461zm321.792 349.867a21.333 21.333 0 0 0 21.333-21.333v-64c0-58.795-47.829-106.667-106.666-106.667h-40.96L528.043 135.04c-.342-.427-.811-.512-1.152-.853-.299-.342-.47-.768-.811-1.11-.768-.682-1.707-.896-2.56-1.45a19.925 19.925 0 0 0-3.584-2.048 19.2 19.2 0 0 0-4.01-1.067c-1.323-.256-2.603-.512-3.926-.512-1.365 0-2.645.256-4.01.512a21.29 21.29 0 0 0-3.926 1.024 21.419 21.419 0 0 0-3.712 2.133c-.81.555-1.707.768-2.432 1.408-.341.342-.47.768-.81 1.067-.342.341-.811.512-1.153.853L316.544 339.328h-39.21a106.795 106.795 0 0 0-106.667 106.667v256c0 58.837 47.829 106.666 106.666 106.666H748.46c58.837 0 106.666-47.829 106.666-106.666v-64a21.333 21.333 0 0 0-42.666 0v64c0 35.285-28.715 64-64 64H277.333c-35.285 0-64-28.715-64-64v-256c0-35.286 28.715-64 64-64H748.46c35.285 0 64 28.714 64 64v64a21.333 21.333 0 0 0 21.333 21.333z" fill="#3C405D"/><path d="M704 467.328H320a21.333 21.333 0 0 0 0 42.667h384a21.333 21.333 0 0 0 0-42.667m21.333 106.667A21.333 21.333 0 0 0 704 552.66H320a21.333 21.333 0 0 0 0 42.667h384a21.333 21.333 0 0 0 21.333-21.333m-405.333 64a21.333 21.333 0 0 0 0 42.666h170.667a21.333 21.333 0 0 0 0-42.666H320z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Announcement;
