import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Datamanagement 图标组件
 */
export const Datamanagement = defineComponent({
	name: "Datamanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Datamanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M850.52 491.947H552.365V211.925c153.13 9.643 279.04 127.702 298.155 279.936m-318.336 40.32h338.005c7.083 0 13.014-3.84 16.598-9.386a19.883 19.883 0 0 0 5.973-15.488c-12.715-188.758-171.136-336.64-360.576-336.64a20.181 20.181 0 0 0-20.181 20.096c0 .298.17.554.17.853 0 .299-.17.555-.17.853v317.91c0 .256.17.512.17.853 0 .256-.17.512-.17.853 0 11.094 9.045 20.096 20.18 20.096" fill="#3C405D"/><path d="M588.93 848.427a328.832 328.832 0 0 1-56.746 4.906c-177.067 0-321.152-144.085-321.152-321.152A319.445 319.445 0 0 1 437.72 225.11a20.181 20.181 0 0 0-11.819-38.528 359.68 359.68 0 0 0-255.232 345.6c0 199.339 162.134 361.515 361.515 361.515 21.29 0 42.752-1.92 63.744-5.632a20.096 20.096 0 1 0-6.997-39.68M765.101 704.597a64 64 0 1 0 .043 128.043 64 64 0 0 0-.043-128" fill="#3C405D"/><path d="M765.101 875.307A106.752 106.752 0 0 1 658.435 768.64c0-58.88 47.786-106.667 106.666-106.667 58.838 0 106.667 47.787 106.667 106.667 0 58.837-47.83 106.667-106.667 106.667m0-256c-82.346 0-149.333 66.986-149.333 149.333S682.755 917.973 765.1 917.973s149.334-66.986 149.334-149.333S847.448 619.307 765.1 619.307" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Datamanagement;
