import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Collaborativeplanning 图标组件
 */
export const Collaborativeplanning = defineComponent({
	name: "Collaborativeplanning",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Collaborativeplanning icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M511.38 635.904a21.333 21.333 0 0 0-21.333 21.333v66.774A257.024 257.024 0 0 1 301.63 617.899a254.293 254.293 0 0 1-44.118-190.891c3.712-22.272 11.18-43.093 20.224-63.104l46.806 34.133a21.333 21.333 0 0 0 25.173-34.517l-51.2-37.376a255.19 255.19 0 0 1 62.805-65.28c115.03-81.92 275.2-55.21 357.12 59.648 1.536 2.219 2.688 4.608 4.182 6.827L673 363.52a21.376 21.376 0 0 0 25.174 34.56l45.098-32.939a257.707 257.707 0 0 1 1.366 206.635 21.29 21.29 0 1 0 39.082 17.067 300.16 300.16 0 0 0-5.333-249.302l64.853-47.317a21.376 21.376 0 0 0 4.694-29.824 21.504 21.504 0 0 0-29.867-4.65l-60.928 44.458c-1.408-2.09-2.517-4.267-3.968-6.4C657.6 161.835 470.72 130.475 336.575 226.133a298.581 298.581 0 0 0-72.96 74.667l-58.966-43.05a21.461 21.461 0 0 0-29.866 4.693 21.376 21.376 0 0 0 4.693 29.781l62.336 45.483a300.373 300.373 0 0 0-26.368 82.218 296.704 296.704 0 0 0 51.456 222.72A299.733 299.733 0 0 0 490.047 766.72v70.059a21.333 21.333 0 0 0 42.666 0V657.237a21.333 21.333 0 0 0-21.333-21.333" fill="#3C405D"/><path d="M829.588 643.413H616.255a21.333 21.333 0 0 0 0 42.667h213.333a21.333 21.333 0 0 0 0-42.667m0 85.334H616.255a21.333 21.333 0 0 0 0 42.666h213.333a21.333 21.333 0 0 0 0-42.666m0 87.168H610.921a21.333 21.333 0 0 0 0 42.666h218.667a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Collaborativeplanning;
