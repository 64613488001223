import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Rightsmanagement 图标组件
 */
export const Rightsmanagement = defineComponent({
	name: "Rightsmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Rightsmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M514.071 231.21l135.083 210.262-135.083 95.573-135.083-95.573 135.083-210.261zm291.115 152.107L788.375 485.12a21.248 21.248 0 0 0 21.077 24.747 21.333 21.333 0 0 0 20.992-17.835l24.662-149.333a21.333 21.333 0 0 0-17.579-24.491c-1.152-.213-2.219.128-3.328.085a21.547 21.547 0 0 0-16.043 3.542l-134.144 94.933-151.978-236.587-.214-.213c-.853-1.28-2.048-2.176-3.114-3.243-.982-.938-1.792-2.09-2.859-2.773-.128-.043-.128-.17-.213-.213-.896-.555-1.878-.64-2.774-1.067-1.621-.725-3.072-1.536-4.778-1.877-1.152-.171-2.262 0-3.456-.043-1.579-.043-3.2-.213-4.736.128-1.408.256-2.646.981-3.926 1.536-1.152.427-2.346.64-3.456 1.28l-.213.299c-1.11.725-1.963 1.834-2.901 2.773-1.067 1.024-2.262 1.92-3.072 3.157l-.256.256-151.979 236.63-134.144-94.976a21.547 21.547 0 0 0-16.128-3.499c-1.707-.085-3.285-.427-4.992-.128a21.333 21.333 0 0 0-17.579 24.49l60.459 366.251c.128.982.64 1.707.939 2.646.341 1.152.64 2.218 1.152 3.285.682 1.408 1.621 2.56 2.56 3.797.682.811 1.237 1.664 2.005 2.39a19.968 19.968 0 0 0 4.181 2.816c.811.426 1.494 1.024 2.347 1.365a21.077 21.077 0 0 0 7.808 1.579h240.043a21.333 21.333 0 1 0 0-42.667H270.786l-49.92-302.25 280.832 198.74c.384.3.853.171 1.28.427 3.413 2.091 7.125 3.499 11.05 3.499a21.248 21.248 0 0 0 11.051-3.499c.384-.256.853-.128 1.28-.426l278.784-197.334zM494.487 810.368h-257.75a21.333 21.333 0 0 0 0 42.667h257.707a21.333 21.333 0 0 0 0-42.667m211.627-170.667a64 64 0 1 0 0 128.043 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M706.071 810.368a106.752 106.752 0 0 1-106.667-106.667c0-58.837 47.787-106.666 106.667-106.666 58.837 0 106.667 47.829 106.667 106.666 0 58.88-47.83 106.667-106.667 106.667m0-256c-82.347 0-149.333 66.987-149.333 149.333s66.986 149.334 149.333 149.334S855.404 786.048 855.404 703.7s-66.986-149.333-149.333-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Rightsmanagement;
