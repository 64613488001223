import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Safety 图标组件
 */
export const Safety = defineComponent({
	name: "Safety",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Safety icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 470.912a21.333 21.333 0 0 0 21.333-21.333V220.16a21.035 21.035 0 0 0-17.365-20.48c-1.536-.81-2.944-1.707-4.736-2.133l-314.667-69.248c-2.048-.427-4.01-.256-5.973-.128h-.043c-.896.085-1.834-.128-2.688.042-1.92-.128-3.84-.341-5.845.086l-309.333 69.29c-1.792.427-3.2 1.323-4.736 2.134a21.035 21.035 0 0 0-17.28 20.48v325.333c0 1.408.554 2.645.81 3.968 8.32 198.101 173.44 324.907 337.067 348.288a24.107 24.107 0 0 0 3.03.213l.426-.085.427.085a24.107 24.107 0 0 0 3.029-.213c144.512-20.693 310.912-128.853 334.976-317.568a21.333 21.333 0 0 0-42.368-5.376c-21.035 165.461-167.68 260.907-296.021 280.235-148.864-22.315-298.71-139.264-298.71-321.11V236.715l296.064-66.39 301.27 66.39v212.864A21.333 21.333 0 0 0 832 470.912" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Safety;
