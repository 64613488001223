import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Lock 图标组件
 */
export const Lock = defineComponent({
	name: "Lock",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Lock icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M507.05 170.667c85.547 0 155.18 61.696 155.18 137.514v92.544H351.871v-92.544c0-75.818 69.675-137.514 155.179-137.514M783.36 578.645a21.333 21.333 0 0 0 21.333-21.333v-58.155c0-52.01-44.245-94.293-99.797-97.792v-93.184c0-99.37-88.747-180.181-197.845-180.181-109.099 0-197.846 80.81-197.846 180.181v93.014c-56.576 2.474-101.845 45.269-101.845 97.962V754.86c0 54.314 47.957 98.474 106.88 98.474h383.488c58.965 0 106.965-44.16 106.965-98.474v-69.547a21.333 21.333 0 0 0-42.666 0v69.547c0 30.805-28.843 55.808-64.299 55.808H314.24c-35.413 0-64.213-25.003-64.213-55.808V499.157c0-30.762 28.8-55.765 64.213-55.765h383.488c35.456 0 64.299 25.003 64.299 55.765v58.155a21.333 21.333 0 0 0 21.333 21.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Lock;
