import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Contract 图标组件
 */
export const Contract = defineComponent({
	name: "Contract",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Contract icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M789.333 425.813a21.333 21.333 0 0 0 21.334-21.333V191.317A106.24 106.24 0 0 0 704.555 85.42h-385.11A106.24 106.24 0 0 0 213.333 191.36v555.947a106.24 106.24 0 0 0 106.112 106.026h385.11a106.24 106.24 0 0 0 106.112-106.069V532.48a21.333 21.333 0 0 0-42.667 0v214.784c0 34.987-28.459 63.403-63.445 63.403h-385.11c-34.986 0-63.445-28.416-63.445-63.403V191.403C256 156.416 284.459 128 319.445 128h385.11C739.54 128 768 156.459 768 191.403v213.034a21.333 21.333 0 0 0 21.333 21.334" fill="#3C405D"/><path d="M576.47 493.568a21.461 21.461 0 0 0-6.145 18.901l11.264 65.451-58.794-30.89a21.461 21.461 0 0 0-19.84 0l-58.838 30.89 11.264-65.45a21.419 21.419 0 0 0-6.144-18.902l-47.573-46.336 65.75-9.557a21.333 21.333 0 0 0 16.042-11.648l29.44-59.563 29.355 59.52a21.333 21.333 0 0 0 16.042 11.69l65.75 9.558-47.574 46.336zm113.706-67.84a21.504 21.504 0 0 0-17.237-14.507l-97.408-14.165-43.52-88.235a21.333 21.333 0 0 0-38.272 0l-43.52 88.235-97.451 14.123a21.419 21.419 0 0 0-11.861 36.437l70.528 68.693-16.64 96.982a21.333 21.333 0 0 0 30.933 22.485l87.125-45.781 87.126 45.781a21.035 21.035 0 0 0 9.941 2.475h.427c10.666-.427 21.76-9.387 21.76-21.334a20.907 20.907 0 0 0-2.134-9.344l-15.701-91.264 70.485-68.693a21.461 21.461 0 0 0 5.419-21.888z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Contract;
