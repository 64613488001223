import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Productreleasemanagement 图标组件
 */
export const Productreleasemanagement = defineComponent({
	name: "Productreleasemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Productreleasemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M782.763 261.376l-327.68 372.907-249.942-81.494 577.622-291.413zM504.405 646.997l-2.901-.938 334.165-380.16-54.741 261.888a21.29 21.29 0 1 0 41.728 8.746l71.125-340.138a21.29 21.29 0 0 0-30.506-23.424L139.733 538.027a21.333 21.333 0 0 0 2.987 39.296l300.032 97.792-.043 157.482a21.333 21.333 0 1 0 42.667 0l.085-146.901 5.718 1.835a21.29 21.29 0 1 0 13.226-40.534z" fill="#3C405D"/><path d="M704 640.853a64 64 0 1 0 .043 128 64 64 0 0 0-.043-128" fill="#3C405D"/><path d="M704 811.52a106.795 106.795 0 0 1-106.667-106.667c0-58.837 47.83-106.666 106.667-106.666 58.88 0 106.667 47.829 106.667 106.666 0 58.752-47.787 106.667-106.667 106.667m0-256c-82.347 0-149.333 66.987-149.333 149.333S621.653 854.187 704 854.187 853.333 787.2 853.333 704.853 786.347 555.52 704 555.52" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Productreleasemanagement;
