import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * ChinaMerchants 图标组件
 */
export const ChinaMerchants = defineComponent({
	name: "ChinaMerchants",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-ChinaMerchants icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M821.204 547.67l-32 32.042-241.792-241.707 32.043-32a171.093 171.093 0 0 1 241.75 0 171.093 171.093 0 0 1 0 241.664M220.798 313.43c64.47-64.554 177.11-64.554 241.706 0L759.04 609.877l-36.182 36.139L594.9 518.101a21.333 21.333 0 0 0-30.165 30.166l128 127.914-36.694 36.608L528.127 584.96a21.333 21.333 0 0 0-30.166 30.165L625.876 743.04l-39.339 39.296-127.914-127.915a21.333 21.333 0 0 0-30.166 30.166L556.372 812.5l-39.125 39.126-296.448-296.448a171.136 171.136 0 0 1 0-241.75m630.613-37.546a213.845 213.845 0 0 0-302.08 0l-32.085 32-24.576-24.619a212.352 212.352 0 0 0-151.04-62.592 212.181 212.181 0 0 0-150.998 62.55 213.845 213.845 0 0 0 0 302.08l308.054 308.053.426.298c.854 1.28 1.408 2.646 2.56 3.755a21.248 21.248 0 0 0 30.123 0L851.37 577.835a213.888 213.888 0 0 0 0-302.038" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default ChinaMerchants;
