import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Searchbox 图标组件
 */
export const Searchbox = defineComponent({
	name: "Searchbox",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Searchbox icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M929.1 883.9L779.9 734.8c58-68.5 93.2-157 93.2-253.7 0-216.8-176.4-393.2-393.2-393.2S86.7 264.3 86.7 481.1s176.4 393.2 393.2 393.2c96.6 0 185.1-35.2 253.7-93.2l149.2 149.2c6.4 6.4 14.8 9.6 23.2 9.6s16.8-3.2 23.2-9.6c12.7-12.8 12.7-33.6-.1-46.4zM152.2 481.1c0-180.7 147-327.7 327.7-327.7s327.7 147 327.7 327.7-147 327.7-327.7 327.7-327.7-147-327.7-327.7z" fill="#231815"/></svg>
			</ElIcon>
		);
	},
});

export default Searchbox;
