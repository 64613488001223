import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inventorymanagement 图标组件
 */
export const Inventorymanagement = defineComponent({
	name: "Inventorymanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inventorymanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M595.554 406.955H382.22a21.333 21.333 0 0 0 0 42.666h213.334a21.333 21.333 0 0 0 0-42.666m0 128H382.22a21.333 21.333 0 0 0 0 42.666h213.334a21.333 21.333 0 0 0 0-42.666M704.012 641.62a64 64 0 1 0 .043 128.043 64 64 0 0 0 0-128.043" fill="#3C405D"/><path d="M704.012 812.288a106.795 106.795 0 0 1-106.666-106.667c0-58.794 47.829-106.666 106.666-106.666 58.88 0 106.667 47.872 106.667 106.666s-47.787 106.667-106.667 106.667m0-256c-68.693 0-126.208 46.933-143.488 110.293a20.736 20.736 0 0 0-11.178-3.626H384.012a21.333 21.333 0 0 0 0 42.666h165.334c1.92 0 3.669-.597 5.461-1.109 0 .384-.128.725-.128 1.11 0 82.346 66.987 149.333 149.333 149.333s149.334-66.987 149.334-149.334-66.987-149.333-149.334-149.333" fill="#3C405D"/><path d="M490.679 811.307H273.762c-31.744 0-57.6-25.686-57.6-57.174V434.261l274.517-257.664L817.42 483.371a21.29 21.29 0 0 0 30.123-1.024 21.29 21.29 0 0 0-.939-30.166L507.191 133.59a21.248 21.248 0 0 0-16.512-5.504 21.163 21.163 0 0 0-16.512 5.547l-339.413 318.55a21.29 21.29 0 0 0 14.592 36.906 21.29 21.29 0 0 0 14.592-5.76l9.557-8.96v279.723a100.181 100.181 0 0 0 100.267 99.882h216.917a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inventorymanagement;
