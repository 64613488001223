import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Announcementmanagement 图标组件
 */
export const Announcementmanagement = defineComponent({
	name: "Announcementmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Announcementmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M512 224.128l138.667 157.867H373.333L512 224.128zm236.459 157.867h-41.003L528.043 177.707c-.342-.384-.768-.512-1.11-.854s-.469-.81-.853-1.109c-.768-.683-1.75-.939-2.56-1.493-1.195-.768-2.304-1.536-3.584-2.048a20.864 20.864 0 0 0-3.968-.982c-1.323-.298-2.603-.554-3.968-.554s-2.645.256-3.968.512a20.864 20.864 0 0 0-3.968 1.024c-1.28.512-2.39 1.28-3.584 2.048-.81.554-1.792.853-2.56 1.493-.384.299-.512.768-.853 1.11s-.768.469-1.11.853L316.544 381.995h-39.21A106.752 106.752 0 0 0 170.666 488.66v256c0 58.88 47.829 106.667 106.666 106.667H492.46a21.333 21.333 0 0 0 0-42.667H277.333c-35.285 0-64-28.714-64-64v-256c0-35.285 28.715-64 64-64H748.46c35.285 0 64 28.715 64 64v64a21.333 21.333 0 0 0 42.666 0v-64c0-58.837-47.829-106.666-106.666-106.666z" fill="#3C405D"/><path d="M320 552.661h384a21.333 21.333 0 0 0 0-42.666H320a21.333 21.333 0 0 0 0 42.666m0 128a21.333 21.333 0 0 0 0 42.667h170.667a21.333 21.333 0 0 0 0-42.667H320zm385.792-42.666a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M705.792 808.661a106.752 106.752 0 0 1-106.667-106.666c0-58.88 47.83-106.667 106.667-106.667s106.667 47.787 106.667 106.667c0 58.837-47.83 106.666-106.667 106.666m0-256c-40.533 0-77.227 16.342-104.192 42.667H320a21.333 21.333 0 0 0 0 42.667h251.435a147.797 147.797 0 0 0-14.976 64c0 82.346 66.986 149.333 149.333 149.333s149.333-66.987 149.333-149.333S788.14 552.66 705.792 552.66" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Announcementmanagement;
