import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Icon 图标组件
 */
export const Icon = defineComponent({
	name: "Icon",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Icon icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M108.1 742.5c0 97.2 78.8 175.9 175.9 175.9 97.1 0 175.9-78.8 175.9-175.9V566.6H284c-97.2 0-175.9 78.8-175.9 175.9zm286.3 0c0 60.9-49.5 110.4-110.4 110.4s-110.4-49.5-110.4-110.4c0-60.9 49.5-110.4 110.4-110.4h110.4v110.4zm346.9-175.9H565.4v175.9c0 97.2 78.8 175.9 175.9 175.9 97.2 0 175.9-78.8 175.9-175.9.1-97.1-78.7-175.9-175.9-175.9zm0 286.3c-60.9 0-110.4-49.5-110.4-110.4V632.2h110.4c60.9 0 110.4 49.5 110.4 110.4 0 60.8-49.5 110.3-110.4 110.3zM284 109.3c-97.2 0-175.9 78.8-175.9 175.9 0 97.2 78.8 175.9 175.9 175.9h175.9V285.2c0-97.2-78.8-175.9-175.9-175.9zm110.4 286.3H284c-60.9 0-110.4-49.5-110.4-110.4 0-60.9 49.5-110.4 110.4-110.4s110.4 49.5 110.4 110.4v110.4zm522.9-110.4c0-97.1-78.8-175.9-175.9-175.9-97.1 0-175.9 78.8-175.9 175.9v175.9h175.9c97.1 0 175.9-78.8 175.9-175.9zm-286.3 0c0-60.9 49.5-110.4 110.4-110.4 60.9 0 110.4 49.5 110.4 110.4 0 60.9-49.5 110.4-110.4 110.4H631V285.2z"/></svg>
			</ElIcon>
		);
	},
});

export default Icon;
