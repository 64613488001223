import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Distributionofgoodsthroughchannels 图标组件
 */
export const Distributionofgoodsthroughchannels = defineComponent({
	name: "Distributionofgoodsthroughchannels",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Distributionofgoodsthroughchannels icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M771.755 305.408H201.6l55.68-55.68a21.333 21.333 0 0 0-30.165-30.165l-88.022 88.064c-.426.426-.597.981-.981 1.45A20.95 20.95 0 0 0 128 326.741a21.333 21.333 0 0 0 21.333 21.334h622.422c45.44 0 82.389 36.949 82.389 82.389a21.333 21.333 0 1 0 42.667 0 125.227 125.227 0 0 0-125.014-125.013M253.781 555.563h269.312a21.333 21.333 0 0 0 0-42.667H253.781a125.227 125.227 0 0 0-125.098 125.099A125.227 125.227 0 0 0 253.78 763.05h219.222l-53.462 53.418a21.333 21.333 0 0 0 30.166 30.166l88.021-87.979a20.992 20.992 0 0 0 4.992-8.619 21.333 21.333 0 0 0-19.627-29.653H253.781a82.517 82.517 0 0 1-82.432-82.347c0-45.482 36.992-82.474 82.432-82.474M834.176 711.979a7.125 7.125 0 0 1-7.125 7.125H627.968a7.125 7.125 0 0 1-7.125-7.125v-199.04c0-3.926 3.2-7.168 7.125-7.168h199.083c3.925 0 7.125 3.242 7.125 7.168v199.04zm-7.125-248.875H627.968a49.92 49.92 0 0 0-49.792 49.835v199.04c0 27.477 22.357 49.792 49.792 49.792h199.083c27.434 0 49.792-22.315 49.792-49.792v-199.04a49.92 49.92 0 0 0-49.792-49.835z" fill="#3C405D"/><path d="M770.176 527.104h-85.333a21.333 21.333 0 0 0 0 42.667h85.333a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Distributionofgoodsthroughchannels;
