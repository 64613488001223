import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Replenishmentplan 图标组件
 */
export const Replenishmentplan = defineComponent({
	name: "Replenishmentplan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Replenishmentplan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.79 170.667c51.029 0 92.543 41.514 92.543 92.501v314.155a21.333 21.333 0 1 1-42.666 0V263.168a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h269.696a21.333 21.333 0 1 1 0 42.666H263.168a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621zM383.53 255.53h256v-42.198h-256v42.198z" fill="#3C405D"/><path d="M831.573 637.739H612.864a21.333 21.333 0 0 0 0 42.666h218.667a21.333 21.333 0 0 0 0-42.666m.042 85.333H612.864a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667m.042 87.168H612.864a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667M467.072 446.677a21.333 21.333 0 0 0-30.165 0L348.885 534.7a21.205 21.205 0 0 0-4.608 23.168 20.992 20.992 0 0 0 20.566 17.706h295.125a21.333 21.333 0 1 0 0-42.666H411.051l56.021-56.064a21.333 21.333 0 0 0 0-30.166" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Replenishmentplan;
