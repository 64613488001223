import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Smartsales 图标组件
 */
export const Smartsales = defineComponent({
	name: "Smartsales",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Smartsales icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M886.87 134.699a21.376 21.376 0 0 0-30.166-.939L706.816 275.285a339.328 339.328 0 0 0-396.032-1.706L164.011 135.04a21.333 21.333 0 1 0-29.312 31.061L277.419 300.8a338.261 338.261 0 0 0-109.867 250.07c0 187.52 152.619 340.223 340.181 340.223a339.797 339.797 0 0 0 329.814-256.341 20.139 20.139 0 1 0-39.04-9.941 299.605 299.605 0 0 1-290.774 225.962 300.288 300.288 0 0 1-299.946-299.946c0-60.032 17.706-118.016 51.2-167.552a299.904 299.904 0 0 1 542.421 106.197 20.267 20.267 0 0 0 23.85 15.659 20.224 20.224 0 0 0 15.617-23.851 337.536 337.536 0 0 0-51.03-120.747 340.693 340.693 0 0 0-49.92-57.898L885.931 164.82a21.333 21.333 0 0 0 .853-30.122" fill="#3C405D"/><path d="M632.747 554.155a21.333 21.333 0 0 0 0-42.667h-96.128v-13.227l82.048-77.44a21.333 21.333 0 0 0-29.27-31.061l-75.989 71.723-75.99-71.68a21.376 21.376 0 0 0-29.311 31.018l85.845 80.982v9.685H392.789a21.333 21.333 0 1 0 0 42.667h101.12v39.85H392.79a21.29 21.29 0 1 0 0 42.667h101.12v71.168a21.333 21.333 0 1 0 42.667 0v-71.168h96.213a21.333 21.333 0 0 0 0-42.667h-96.17v-39.85h96.17z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Smartsales;
