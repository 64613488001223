import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Newproductlaunch 图标组件
 */
export const Newproductlaunch = defineComponent({
	name: "Newproductlaunch",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Newproductlaunch icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 512.47a21.333 21.333 0 0 1-21.333-21.334V305.877A49.92 49.92 0 0 0 760.789 256h-78.592v63.53a21.287 21.287 0 0 1-21.333 21.334H362.197a21.333 21.333 0 0 1-21.333-21.333V256h-77.696a49.92 49.92 0 0 0-49.835 49.877v497.579a49.92 49.92 0 0 0 49.835 49.877h497.621a49.92 49.92 0 0 0 49.878-49.877v-184.32a21.333 21.333 0 1 1 42.666 0v184.32A92.63 92.63 0 0 1 760.79 896H263.168a92.63 92.63 0 0 1-92.501-92.544V305.877c0-51.029 41.514-92.544 92.501-92.544h497.621c51.03 0 92.544 41.515 92.544 92.544v185.259A21.333 21.333 0 0 1 832 512.469zM383.53 298.196h256V256h-256v42.197z" fill="#3C405D"/><path d="M328.704 499.968h-27.435v195.157h30.763V579.157l45.995 115.968h27.178V499.968h-30.72v115.968zm191.317 27.69v-27.69h-82.005v195.157h82.048v-27.69h-51.243v-56.747h44.075V583.04h-44.075v-55.381zm124.928-27.69h-33.962l-26.88 148.053-19.158-148.053h-33.706l36.992 195.157h32.042l27.734-148.266 27.946 148.266h32.086l36.693-195.157h-33.707l-18.901 148.053z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Newproductlaunch;
