import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Platform 图标组件
 */
export const Platform = defineComponent({
	name: "Platform",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Platform icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 403.499a21.333 21.333 0 0 0 21.333-21.334V276.736a106.24 106.24 0 0 0-106.197-106.07H276.864a106.24 106.24 0 0 0-106.197 106.07v342.528a106.24 106.24 0 0 0 106.197 106.07h470.272a106.24 106.24 0 0 0 106.197-106.07V512a21.333 21.333 0 0 0-42.666 0v107.264a63.573 63.573 0 0 1-63.531 63.403H276.864a63.573 63.573 0 0 1-63.53-63.403V276.736a63.573 63.573 0 0 1 63.53-63.403h470.272a63.573 63.573 0 0 1 63.53 63.403v105.43A21.333 21.333 0 0 0 832 403.498m-39.125 407.168h-560a21.333 21.333 0 0 0 0 42.666h560a21.333 21.333 0 0 0 0-42.666" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Platform;
