import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * SuperService 图标组件
 */
export const SuperService = defineComponent({
	name: "SuperService",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-SuperService icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M515.84 668.501h-.085a21.333 21.333 0 0 1-15.062-6.272L287.957 448.47a137.941 137.941 0 0 1 0-194.858 137.941 137.941 0 0 1 194.859 0l33.493 33.536 33.494-33.536a138.07 138.07 0 0 1 220.586 35.584 21.333 21.333 0 0 1-38.101 19.2 95.19 95.19 0 0 0-152.32-24.619l-48.597 48.64a21.333 21.333 0 0 1-30.166 0l-48.512-48.683a95.275 95.275 0 0 0-134.528 0 95.275 95.275 0 0 0 0 134.528l197.76 198.656 198.571-198.656a21.29 21.29 0 0 1 30.165 0 21.29 21.29 0 0 1 0 30.166L530.944 662.229a21.504 21.504 0 0 1-15.104 6.23m-110.336 79.786a21.76 21.76 0 0 1-6.912-1.152 353.621 353.621 0 0 1-227.2-243.2 21.333 21.333 0 0 1 15.061-26.154 21.59 21.59 0 0 1 26.155 15.104A310.997 310.997 0 0 0 412.416 706.73a21.333 21.333 0 0 1-6.912 41.514m217.003-.298c-8.875 0-17.067-5.547-20.224-14.336a21.419 21.419 0 0 1 13.226-27.179 311.296 311.296 0 0 0 198.998-213.547 21.333 21.333 0 0 1 41.173 11.094 353.877 353.877 0 0 1-226.219 242.773 22.272 22.272 0 0 1-6.997 1.195" fill="#3C405D"/><path d="M407.339 894.123a21.333 21.333 0 0 1-21.334-21.334v-144a21.333 21.333 0 0 1 42.667 0v144a21.333 21.333 0 0 1-21.333 21.334m213.333 0a21.333 21.333 0 0 1-21.333-21.334V727.04a21.333 21.333 0 0 1 42.666 0v145.792a21.333 21.333 0 0 1-21.333 21.333M512.299 490.581a21.419 21.419 0 0 1-15.104-6.186l-90.71-90.838a21.29 21.29 0 0 1 0-30.122 21.29 21.29 0 0 1 30.166 0l90.752 90.666a21.419 21.419 0 0 1-15.104 36.438" fill="#3C405D"/><path d="M512.299 490.581a21.419 21.419 0 0 1-15.104-36.437l91.477-91.477a21.333 21.333 0 0 1 30.165 30.165l-91.434 91.52a21.419 21.419 0 0 1-15.104 6.23" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default SuperService;
