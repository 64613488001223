import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Lifecyclemanagement 图标组件
 */
export const Lifecyclemanagement = defineComponent({
	name: "Lifecyclemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Lifecyclemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M863.445 235.307a20.181 20.181 0 0 0-40.32 0v54.698a382.55 382.55 0 0 0-312.32-162.048A382.72 382.72 0 0 0 128 510.805c0 211.072 171.733 382.848 382.805 382.848a20.181 20.181 0 0 0 0-40.362c-174.677 0-318.976-131.499-339.626-300.715H348.16c9.301 0 17.493-5.973 20.31-14.805l30.122-94.294 48.725 221.227a21.333 21.333 0 0 0 20.182 16.768h.64a21.333 21.333 0 0 0 20.522-15.445L534.528 505.6l18.773 35.627a21.333 21.333 0 0 0 18.859 11.349h85.333a21.333 21.333 0 1 0 0-42.667h-72.448L547.5 438.656a21.035 21.035 0 0 0-21.078-11.264 21.205 21.205 0 0 0-18.304 15.36l-37.29 130.347-47.574-216.192a21.333 21.333 0 0 0-19.84-16.726c-10.069-1.28-18.432 5.59-21.333 14.806l-49.493 154.922H168.405a342.23 342.23 0 0 1 342.4-341.589 342.443 342.443 0 0 1 313.046 203.136c4.522 10.24 16.426 14.805 26.581 10.24a19.797 19.797 0 0 0 9.941-10.155 19.541 19.541 0 0 0 3.072-10.069V235.307z" fill="#3C405D"/><path d="M701.056 638.848a64 64 0 1 0 .043 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M701.099 809.515a106.752 106.752 0 0 1-106.667-106.667c0-58.88 47.787-106.667 106.667-106.667 58.837 0 106.666 47.787 106.666 106.667 0 58.837-47.829 106.667-106.666 106.667m0-256c-82.347 0-149.334 66.986-149.334 149.333S618.752 852.181 701.1 852.181s149.333-66.986 149.333-149.333-66.987-149.333-149.333-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Lifecyclemanagement;
