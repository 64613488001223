import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Dialogue 图标组件
 */
export const Dialogue = defineComponent({
	name: "Dialogue",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Dialogue icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M919.083 443.563a21.333 21.333 0 0 0 21.333-21.334V319.957c0-58.794-47.83-106.666-106.667-106.666H277.333A106.795 106.795 0 0 0 170.667 320v298.667c0 58.88 47.829 106.666 106.666 106.666h154.368l108.843 120.96c.256.342.683.427.939.726.298.213.384.597.682.853 1.152 1.024 2.518 1.579 3.798 2.304.81.512 1.578 1.152 2.432 1.493a21.077 21.077 0 0 0 8.021 1.707 21.077 21.077 0 0 0 8.021-1.707c.854-.341 1.622-.981 2.432-1.493 1.28-.725 2.646-1.28 3.798-2.304.298-.213.384-.597.682-.853.256-.342.683-.427.939-.726l104.832-116.48h156.63c58.837 0 106.666-47.829 106.666-106.666V548.48a21.333 21.333 0 0 0-42.667 0v74.667c0 35.328-28.714 64-64 64H673.621a21.333 21.333 0 0 0-21.077 6.186l-96.128 106.795-96.128-106.837c-.81-.854-1.877-1.28-2.773-2.006a20.907 20.907 0 0 0-16.64-8.618H277.333c-35.285 0-64-28.63-64-64V320c0-35.328 28.715-64 64-64H833.75c35.286 0 64 28.757 64 64v102.272a21.333 21.333 0 0 0 21.334 21.333" fill="#3C405D"/><path d="M321.75 463.104a21.333 21.333 0 0 0 0 42.667h88.917a21.333 21.333 0 0 0 0-42.667h-88.918zm193.791 0a21.333 21.333 0 0 0 0 42.667h87.126a21.333 21.333 0 0 0 0-42.667H515.54zm190.208 0a21.333 21.333 0 0 0 0 42.667h87.126a21.333 21.333 0 0 0 0-42.667h-87.126z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Dialogue;
