import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Calendar 图标组件
 */
export const Calendar = defineComponent({
	name: "Calendar",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Calendar icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M213.333 341.333v-19.541c0-35.328 28.715-64 64-64h62.208v23.083a21.333 21.333 0 1 0 42.667 0v-23.083h259.584v23.083a21.333 21.333 0 1 0 42.667 0v-23.083h65.749a64 64 0 0 1 64 64v19.541H213.333zm622.208 128A21.333 21.333 0 0 0 856.875 448V321.792c0-58.837-47.83-106.667-106.667-106.667h-65.75V192a21.333 21.333 0 0 0-42.666 0v23.125H382.208V192a21.333 21.333 0 1 0-42.667 0v23.125h-62.208a106.795 106.795 0 0 0-106.666 106.667v424.875c0 58.794 47.872 106.666 106.666 106.666h472.875c58.837 0 106.667-47.872 106.667-106.666V576a21.333 21.333 0 1 0-42.667 0v170.667c0 35.285-28.672 64-64 64H277.333c-35.285 0-64-28.715-64-64V384h600.875v64a21.333 21.333 0 0 0 21.333 21.333z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Calendar;
