import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Target 图标组件
 */
export const Target = defineComponent({
	name: "Target",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Target icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M876.672 488.661H683.264a21.333 21.333 0 1 0 0 42.667h72.363a244.907 244.907 0 0 1-220.288 224.128v-74.197a21.333 21.333 0 1 0-42.667 0v74.368a244.693 244.693 0 0 1-224.299-224.299h74.411a21.333 21.333 0 1 0 0-42.667h-74.24a244.864 244.864 0 0 1 224.128-220.288v74.411a21.333 21.333 0 0 0 42.667 0v-73.941a243.712 243.712 0 0 1 181.76 109.781 21.376 21.376 0 0 0 35.754-23.339A286.208 286.208 0 0 0 535.34 226.176v-76.8a21.333 21.333 0 0 0-42.667 0v76.288a287.403 287.403 0 0 0-266.795 262.997h-76.544a21.333 21.333 0 1 0 0 42.667h76.374a287.275 287.275 0 0 0 266.965 266.965v76.374a21.333 21.333 0 0 0 42.667 0v-76.544a287.403 287.403 0 0 0 262.954-266.795h78.379a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M553.003 512a39.168 39.168 0 1 1-78.294-.043 39.168 39.168 0 0 1 78.294.043m-120.875 0c0 45.099 36.693 81.792 81.75 81.792A81.92 81.92 0 0 0 595.668 512a81.92 81.92 0 0 0-81.792-81.792c-45.098 0-81.749 36.693-81.749 81.792" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Target;
