import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Supplier 图标组件
 */
export const Supplier = defineComponent({
	name: "Supplier",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Supplier icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M517.632 552.15c80.64 0 152.32-46.678 182.699-118.955a21.163 21.163 0 0 0 1.408-6.784 21.29 21.29 0 0 0-19.542-29.867H493.995a21.333 21.333 0 1 0 0 42.667H648.96c-27.819 43.264-76.885 70.272-131.328 70.272-85.163 0-154.453-66.432-154.453-148.054 0-29.568 9.045-58.026 26.026-82.432a156.416 156.416 0 0 1 128.47-65.664c51.541 0 99.498 24.491 128.298 65.579 5.974 8.533 11.051 17.621 15.019 27.136a21.333 21.333 0 0 0 39.339-16.597 189.141 189.141 0 0 0-19.456-35.072c-36.779-52.438-97.835-83.712-163.243-83.712-65.45 0-126.507 31.317-163.285 83.84a185.43 185.43 0 0 0-33.835 106.88c0 105.216 88.405 190.762 197.12 190.762M720.17 810.667H303.873c-49.92 0-90.539-41.088-90.539-91.606 0-50.517 40.619-91.605 90.539-91.605h416.299c49.92 0 90.453 41.088 90.453 91.605.043 50.518-40.49 91.606-90.41 91.606m0-225.878H303.871c-73.472 0-133.205 60.246-133.205 134.272 0 74.027 59.733 134.272 133.205 134.272h416.299c73.386 0 133.12-60.245 133.12-134.272.042-74.026-59.691-134.272-133.078-134.272" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Supplier;
