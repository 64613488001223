import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Purchasingstaff 图标组件
 */
export const Purchasingstaff = defineComponent({
	name: "Purchasingstaff",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Purchasingstaff icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.832 170.667c50.987 0 92.501 41.514 92.501 92.501v312.363a21.333 21.333 0 1 1-42.666 0V263.168a49.92 49.92 0 0 0-49.835-49.835H682.24v63.531a21.287 21.287 0 0 1-21.333 21.333H362.24a21.333 21.333 0 0 1-21.333-21.333v-63.53H263.21a49.92 49.92 0 0 0-49.878 49.834v497.621a49.92 49.92 0 0 0 49.878 49.878h141.696a21.333 21.333 0 1 1 0 42.666H263.21a92.63 92.63 0 0 1-92.544-92.544V263.168c0-50.987 41.514-92.501 92.544-92.501h497.621zM383.573 255.53h256v-42.198h-256v42.198z" fill="#3C405D"/><path d="M470.656 425.344a21.333 21.333 0 0 0-30.165 0l-88.022 88.021a21.205 21.205 0 0 0-4.608 23.168 20.992 20.992 0 0 0 20.566 17.707h295.125a21.333 21.333 0 1 0 0-42.667H414.635l56.021-56.064a21.333 21.333 0 0 0 0-30.165m-106.667 214.23h298.667a21.333 21.333 0 0 0 0-42.667H363.989a21.333 21.333 0 0 0 0 42.666M844.46 656c-1.536-1.323-3.371-2.048-5.12-2.816a20.608 20.608 0 0 0-10.582-3.243h-65.109a21.333 21.333 0 0 0 0 42.667h21.632l-70.656 83.072-100.821-80.47a21.333 21.333 0 0 0-29.014 2.22L476.288 815.274a21.419 21.419 0 0 0 15.701 35.797c5.76 0 11.52-2.304 15.702-6.87l95.061-103.253 101.419 80.982a21.248 21.248 0 0 0 29.525-2.859l76.544-89.941V739.2a21.333 21.333 0 1 0 42.667 0v-65.067a20.565 20.565 0 0 0-2.816-10.026 20.992 20.992 0 0 0-5.632-8.107" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Purchasingstaff;
