import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * MultiDimensionalKanban 图标组件
 */
export const MultiDimensionalKanban = defineComponent({
	name: "MultiDimensionalKanban",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-MultiDimensionalKanban icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 508.416a21.333 21.333 0 0 0 21.333-21.333v-128a106.24 106.24 0 0 0-106.197-106.07H276.821a106.24 106.24 0 0 0-106.154 106.07v386.389a106.24 106.24 0 0 0 106.154 106.07h470.315a106.24 106.24 0 0 0 106.197-106.07V616.875a21.333 21.333 0 0 0-42.666 0v128.597a63.573 63.573 0 0 1-63.531 63.403H276.821a63.573 63.573 0 0 1-63.488-63.403v-386.39a63.573 63.573 0 0 1 63.488-63.402h470.315a63.573 63.573 0 0 1 63.53 63.403v128A21.333 21.333 0 0 0 832 508.416M318.208 213.333h389.333a21.333 21.333 0 0 0 0-42.666H318.208a21.333 21.333 0 0 0 0 42.666" fill="#3D405D"/><path d="M510.208 382.208a21.333 21.333 0 0 0-21.333 21.333v173.483L327.168 669.44a21.419 21.419 0 0 0-7.979 29.141 21.461 21.461 0 0 0 29.142 7.979l161.834-92.544 160.043 92.459a21.333 21.333 0 0 0 21.333-36.95l-160-92.416V403.541a21.333 21.333 0 0 0-21.333-21.333" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default MultiDimensionalKanban;
