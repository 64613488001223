import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * SalesrealTimeKanban 图标组件
 */
export const SalesrealTimeKanban = defineComponent({
	name: "SalesrealTimeKanban",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-SalesrealTimeKanban icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M747.093 253.013H276.907a106.24 106.24 0 0 0-106.24 106.07v386.389a106.24 106.24 0 0 0 106.24 106.07h213.76a21.29 21.29 0 1 0 0-42.667h-213.76a63.573 63.573 0 0 1-63.574-63.403v-386.39a63.573 63.573 0 0 1 63.574-63.402h470.186a63.573 63.573 0 0 1 63.574 63.403v128a21.333 21.333 0 1 0 42.666 0v-128a106.24 106.24 0 0 0-106.24-106.07" fill="#3C405D"/><path d="M318.208 213.333h389.333a21.333 21.333 0 0 0 0-42.666H318.208a21.333 21.333 0 0 0 0 42.666m387.541 360.875a21.333 21.333 0 0 0 0-42.667H533.333v-128a21.333 21.333 0 0 0-42.666 0v149.334A21.333 21.333 0 0 0 512 574.208c.299 0 .555-.17.853-.17.342 0 .598.17.896.17h192zM832 586.453a21.333 21.333 0 0 0-21.333 21.334v147.2a21.333 21.333 0 1 0 42.666 0v-147.2A21.333 21.333 0 0 0 832 586.453M747.093 776.32a21.333 21.333 0 0 0 21.334-21.333V641.92a21.333 21.333 0 1 0-42.667 0v113.067a21.333 21.333 0 0 0 21.333 21.333M640.085 675.03v79.487a21.333 21.333 0 1 0 42.667 0V675.03a21.333 21.333 0 0 0-42.667 0m-85.418 34.518v45.482a21.333 21.333 0 1 0 42.666 0v-45.482a21.333 21.333 0 0 0-42.666 0M832 809.77H576a21.333 21.333 0 0 0 0 42.666h256a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default SalesrealTimeKanban;
