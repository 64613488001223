import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Backupandrecovery 图标组件
 */
export const Backupandrecovery = defineComponent({
	name: "Backupandrecovery",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Backupandrecovery icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M840.4 186.2c0-18.1-14.7-32.8-32.8-32.8H217.8c-18.1 0-32.8 14.7-32.8 32.8s14.7 32.8 32.8 32.8h589.8c18.1 0 32.8-14.7 32.8-32.8zm23.1 370L535.8 228.6c-6.4-6.4-14.8-9.6-23.2-9.6s-16.8 3.2-23.2 9.6L161.8 556.2c-12.8 12.8-12.8 33.5 0 46.3 12.8 12.8 33.5 12.8 46.3 0l271.8-271.8v510.7c0 18.1 14.7 32.8 32.8 32.8s32.8-14.7 32.8-32.8V330.8l271.8 271.8c6.4 6.4 14.8 9.6 23.2 9.6 8.4 0 16.8-3.2 23.2-9.6 12.6-12.8 12.6-33.6-.2-46.4z"/></svg>
			</ElIcon>
		);
	},
});

export default Backupandrecovery;
