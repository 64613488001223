import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Salesandinventoryreport 图标组件
 */
export const Salesandinventoryreport = defineComponent({
	name: "Salesandinventoryreport",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Salesandinventoryreport icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M789.333 512.896a21.333 21.333 0 0 0 21.334-21.333V213.675c0-47.232-37.248-85.675-83.03-85.675H382.208a21.163 21.163 0 0 0-15.147 6.4c-.298.256-.682.341-.938.597l-146.56 146.56a21.077 21.077 0 0 0-6.016 16.086c-.043.341-.214.64-.214 1.024V811.22c0 47.232 37.248 85.675 83.03 85.675h431.274c45.782 0 83.03-38.443 83.03-85.675V619.563a21.333 21.333 0 0 0-42.667 0V811.22c0 23.723-18.09 43.008-40.363 43.008H296.363c-22.272 0-40.363-19.285-40.363-43.008v-505.77l134.784-134.784h336.853c22.272 0 40.363 19.285 40.363 43.008v277.888a21.333 21.333 0 0 0 21.333 21.333" fill="#3C405D"/><path d="M469.333 425.813a21.333 21.333 0 0 0-30.165 0l-88.021 88.022a21.333 21.333 0 0 0-4.651 23.21 21.077 21.077 0 0 0 20.65 17.622H662.23a21.333 21.333 0 1 0 0-42.667H413.312l56.021-56.021a21.333 21.333 0 0 0 0-30.166" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Salesandinventoryreport;
