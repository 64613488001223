import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Categoryplanning 图标组件
 */
export const Categoryplanning = defineComponent({
	name: "Categoryplanning",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Categoryplanning icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M439.125 319.232h220.8v-32.213h-220.8v32.213zm393.046 189.184a21.333 21.333 0 0 1-21.334-21.333V328.49a41.472 41.472 0 0 0-41.472-41.43h-66.773v53.504a21.333 21.333 0 0 1-21.333 21.334H417.792a21.333 21.333 0 0 1-21.333-21.334v-53.504h-66.006a41.472 41.472 0 0 0-41.472 41.43v438.912c0 22.869 18.603 41.472 41.472 41.472h438.912a41.515 41.515 0 0 0 41.472-41.472V616.875a21.333 21.333 0 0 1 42.667 0v150.528a84.224 84.224 0 0 1-84.139 84.138H330.453a83.968 83.968 0 0 1-83.157-74.453 83.968 83.968 0 0 1-76.63-83.37V254.804c0-46.378 37.718-84.138 84.14-84.138h438.911c43.094 0 78.294 32.682 83.158 74.453a83.968 83.968 0 0 1 76.629 83.37v158.593a21.333 21.333 0 0 1-21.333 21.333zM363.477 244.395h220.758v-31.062H363.477v31.062zM246.315 328.49c0-43.051 32.682-78.294 74.496-83.158v-32h-66.006a41.515 41.515 0 0 0-41.472 41.472v438.912c0 19.84 14.336 35.712 32.982 39.723V328.49zm486.784-84.096a40.96 40.96 0 0 0-39.382-31.062h-66.816v31.062H733.1z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Categoryplanning;
