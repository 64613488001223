import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Routingwarehousemanagement 图标组件
 */
export const Routingwarehousemanagement = defineComponent({
	name: "Routingwarehousemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Routingwarehousemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M626.572 406.272l-86.016 86.016H343.138a21.333 21.333 0 0 0 0 42.667h202.666a20.779 20.779 0 0 0 9.728-2.603 20.907 20.907 0 0 0 10.667-5.419l90.539-90.496a21.333 21.333 0 0 0-30.166-30.165m79.232 235.35a64 64 0 1 0 .043 128.042 64 64 0 0 0 0-128.043" fill="#3D405D"/><path d="M705.804 812.288a106.795 106.795 0 0 1-106.666-106.667c0-58.794 47.829-106.666 106.666-106.666 58.88 0 106.667 47.872 106.667 106.666s-47.787 106.667-106.667 106.667m0-256a148.31 148.31 0 0 0-113.877 53.93L565.9 585.43a20.736 20.736 0 0 0-10.41-5.034 20.779 20.779 0 0 0-9.686-2.603H343.138a21.333 21.333 0 0 0 0 42.667h197.589l27.861 26.453a148.053 148.053 0 0 0-12.117 58.71c0 82.346 66.987 149.333 149.333 149.333s149.334-66.987 149.334-149.334-66.987-149.333-149.334-149.333" fill="#3D405D"/><path d="M492.471 811.307h-218.71a57.472 57.472 0 0 1-57.557-57.174V434.176L490.68 176.64 817.42 483.328a21.29 21.29 0 0 0 30.123-.981 21.29 21.29 0 0 0-.939-30.166L507.191 133.59a21.077 21.077 0 0 0-16.47-5.504 21.205 21.205 0 0 0-16.511 5.547l-339.456 318.55a21.29 21.29 0 0 0 14.592 36.906 21.29 21.29 0 0 0 14.592-5.76l9.6-9.045V754.09a100.181 100.181 0 0 0 100.266 99.882h218.667a21.333 21.333 0 0 0 0-42.666" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Routingwarehousemanagement;
