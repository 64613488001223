import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Selectionsystem 图标组件
 */
export const Selectionsystem = defineComponent({
	name: "Selectionsystem",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Selectionsystem icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M516.864 810.667h-253.61a49.92 49.92 0 0 1-49.92-49.878V263.168a49.92 49.92 0 0 1 49.92-49.835h497.493a49.92 49.92 0 0 1 49.92 49.835v228.821a21.29 21.29 0 1 0 42.666 0V263.168a92.63 92.63 0 0 0-92.586-92.501H263.253a92.587 92.587 0 0 0-92.586 92.501v497.621a92.587 92.587 0 0 0 92.586 92.544h253.611a21.333 21.333 0 0 0 0-42.666" fill="#3D405D"/><path d="M383.573 299.093a21.333 21.333 0 0 0-21.333 21.334v87.466c0 81.664 66.987 148.054 149.333 148.054s149.334-66.39 149.334-148.054v-87.466a21.333 21.333 0 0 0-42.667 0v87.466c0 58.155-47.872 105.387-106.667 105.387-58.88 0-106.666-47.232-106.666-105.387v-87.466a21.333 21.333 0 0 0-21.334-21.334M811.99 744.661a12.63 12.63 0 0 1-12.63 12.587H611.285a12.63 12.63 0 0 1-12.629-12.587v-136.96c0-6.997 5.675-12.629 12.63-12.629H799.36c6.955 0 12.63 5.632 12.63 12.587V744.66zm-12.63-192.256H611.285c-30.506 0-55.296 24.79-55.296 55.254V744.66c0 30.464 24.79 55.254 55.296 55.254H799.36c30.507 0 55.296-24.747 55.296-55.254v-136.96c0-30.506-24.747-55.296-55.296-55.296zM817.707 808.405h-224a21.333 21.333 0 0 0 0 42.667h224a21.333 21.333 0 0 0 0-42.667" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Selectionsystem;
