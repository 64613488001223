import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Acquisitionprogrammeadministration 图标组件
 */
export const Acquisitionprogrammeadministration = defineComponent({
	name: "Acquisitionprogrammeadministration",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Acquisitionprogrammeadministration icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.345 789.353a31.997 31.997 0 1 0 0 63.995 31.997 31.997 0 0 0 0-63.995m-10.666-341.305a21.332 21.332 0 0 0 0 42.663h42.663a21.332 21.332 0 0 0 0-42.663H362.68zm172.444 0H492.46a21.332 21.332 0 0 0 0 42.663h42.663a21.332 21.332 0 0 0 0-42.663m85.327 0a21.332 21.332 0 0 0 0 42.663h42.663a21.332 21.332 0 0 0 0-42.663H620.45zm85.326 191.984a63.995 63.995 0 1 0 .043 128.032 63.995 63.995 0 0 0-.043-128.032" fill="#3C405D"/><path d="M705.776 810.684a106.786 106.786 0 0 1-106.658-106.657c0-58.79 47.825-106.658 106.658-106.658s106.658 47.868 106.658 106.658-47.826 106.657-106.658 106.657m0-255.978c-82.34 0-149.321 66.98-149.321 149.32s66.981 149.322 149.32 149.322 149.322-66.982 149.322-149.321-66.981-149.321-149.321-149.321" fill="#3C405D"/><path d="M493.783 684.444a21.332 21.332 0 0 0 0-42.663H290.706l-26.493-343.054h377.056a171.719 171.719 0 0 1 167.325 209.604 21.332 21.332 0 1 0 41.64 9.3 211.524 211.524 0 0 0 5.247-47.356 214.467 214.467 0 0 0-214.126-214.211H260.928l-4.992-65.616c-.085-.938-.597-1.706-.768-2.56a21.374 21.374 0 0 0-1.578-5.205c-.3-.597-.726-1.066-1.067-1.62a19.796 19.796 0 0 0-4.138-4.992c-.427-.427-.982-.64-1.45-.982a21.716 21.716 0 0 0-5.12-2.9c-1.451-.598-2.859-.683-4.267-.854-1.066-.17-1.92-.683-2.858-.683h-42.663a21.332 21.332 0 1 0 0 42.664h22.91l41.17 534.995c.085 1.28.64 2.39.938 3.584.342 1.237.47 2.474.981 3.626.683 1.45 1.707 2.645 2.688 3.882.64.854 1.11 1.835 1.877 2.56 1.28 1.28 2.859 2.133 4.437 2.987.768.426 1.408 1.024 2.219 1.365a21.076 21.076 0 0 0 8.106 1.706H492.46a21.332 21.332 0 1 0 0-42.663H297.063l-3.114-40.871h199.834z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Acquisitionprogrammeadministration;
