import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * InputSupplement 图标组件
 */
export const InputSupplement = defineComponent({
	name: "InputSupplement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-InputSupplement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M831.918 541.227H233.816L397.23 377.77a21.333 21.333 0 0 0-30.166-30.166L176.771 537.856a21.205 21.205 0 0 0-4.096 23.893c-.085.299-.17.512-.17.811a21.333 21.333 0 0 0 21.333 21.333h638.037a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default InputSupplement;
