import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Message 图标组件
 */
export const Message = defineComponent({
	name: "Message",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Message icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M834.39 739.541h-56.918V568.875a18.944 18.944 0 1 0-37.93 0V739.54H284.458V512A227.797 227.797 0 0 1 512 284.459a226.56 226.56 0 0 1 221.44 175.018 18.987 18.987 0 0 0 36.95-8.746c-26.966-113.92-124.075-194.432-239.446-202.624v-58.454a18.987 18.987 0 0 0-37.888 0v57.814C355.499 257.28 246.528 372.053 246.528 512v227.541h-56.917a18.987 18.987 0 0 0 0 37.974h644.778a18.987 18.987 0 0 0 0-37.974m-227.584 75.862H415.573a18.987 18.987 0 0 0 0 37.93h191.232a18.987 18.987 0 0 0 0-37.93" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Message;
