import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Operationhub 图标组件
 */
export const Operationhub = defineComponent({
	name: "Operationhub",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Operationhub icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M833.792 407.083a21.333 21.333 0 0 0 21.333-21.334v-63.957c0-58.837-47.872-106.667-106.666-106.667H533.333V192a21.333 21.333 0 0 0-42.666 0v23.125H277.333a106.795 106.795 0 0 0-106.666 106.667v256c0 58.837 47.872 106.667 106.666 106.667h213.334v49.792L352.085 813.44a21.376 21.376 0 0 0 21.163 37.12L512 771.243l138.752 79.317a21.333 21.333 0 0 0 21.163-37.12l-138.582-79.19V684.46H748.46c58.794 0 106.666-47.83 106.666-106.667v-64a21.333 21.333 0 0 0-42.666 0v64c0 35.285-28.715 64-64 64H277.333c-35.285 0-64-28.715-64-64v-256c0-35.285 28.715-64 64-64H748.46c35.285 0 64 28.715 64 64v63.957a21.333 21.333 0 0 0 21.333 21.334" fill="#3C405D"/><path d="M517.333 410.667a42.71 42.71 0 0 1 0 85.333 42.71 42.71 0 0 1 0-85.333m0 128a85.333 85.333 0 0 0 82.816-65.707h18.091a32.256 32.256 0 0 0 24.619 11.733 32.683 32.683 0 0 0 0-65.408 32.299 32.299 0 0 0-24.107 11.008h-19.627A85.248 85.248 0 0 0 517.333 368c-15.488 0-29.781 4.437-42.325 11.69l-32.213-32.213c.512-2.432 1.45-4.65 1.45-7.168a37.248 37.248 0 1 0-37.248 37.206c1.664 0 3.03-.726 4.608-.896l32.896 32.896C436.736 422.357 432 437.248 432 453.333c0 16.384 4.864 31.616 12.928 44.63l-30.08 30.08c-2.603-.555-5.077-1.579-7.85-1.579a37.205 37.205 0 1 0 37.247 37.248c0-1.408-.64-2.603-.768-3.925l32.342-32.384c12.33 6.954 26.368 11.264 41.514 11.264" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Operationhub;
