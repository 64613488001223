import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Performance 图标组件
 */
export const Performance = defineComponent({
	name: "Performance",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Performance icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M789.333 468.48a21.333 21.333 0 0 0 21.334-21.333V233.984a106.24 106.24 0 0 0-106.112-105.899h-385.11a106.24 106.24 0 0 0-106.112 105.942v555.946A106.24 106.24 0 0 0 319.445 896h385.11a106.24 106.24 0 0 0 106.112-106.07V575.148a21.333 21.333 0 0 0-42.667 0V789.93c0 34.986-28.459 63.402-63.445 63.402h-385.11c-34.986 0-63.445-28.416-63.445-63.402V234.069c0-34.986 28.459-63.402 63.445-63.402h385.11c34.986 0 63.445 28.458 63.445 63.402v213.035a21.333 21.333 0 0 0 21.333 21.333" fill="#3D405D"/><path d="M451.413 547.413a11491.84 11491.84 0 0 1 51.499-132.949l9.941-29.867h1.707c0 2.176 2.73 9.942 8.32 23.254a28.8 28.8 0 0 1 1.707 6.613c1.024 2.261 18.218 46.55 51.456 132.95h-124.63zm31.574-207.744L346.709 680.405H403.2l34.901-91.392h151.254l34.901 91.392h56.533L544.47 339.67h-61.482zm172.117-27.648a34.645 34.645 0 1 0-69.248 0 34.645 34.645 0 0 0 69.248 0" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Performance;
