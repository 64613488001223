import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Productcopymanagement 图标组件
 */
export const Productcopymanagement = defineComponent({
	name: "Productcopymanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Productcopymanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M213.333 696.021v-440.96c0-23.04 18.688-41.728 41.771-41.728h440.917c19.798 0 35.627 14.08 39.894 32.555H327.936a84.48 84.48 0 0 0-84.437 84.395v405.162a41.259 41.259 0 0 1-30.166-39.424m322.475 116.992H327.936a41.813 41.813 0 0 1-41.77-41.77v-440.96a41.813 41.813 0 0 1 41.77-41.728h440.96a41.813 41.813 0 0 1 41.77 41.728v203.733a21.333 21.333 0 0 0 42.667 0V330.283a84.31 84.31 0 0 0-73.685-83.328 84.224 84.224 0 0 0-83.627-76.288H255.104a84.48 84.48 0 0 0-84.437 84.394v440.96c0 42.88 32.213 77.995 73.642 83.328a84.31 84.31 0 0 0 83.627 76.331h207.872a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M434.603 359.68a21.333 21.333 0 0 0-21.334 21.333v77.568c0 73.686 60.459 133.632 134.742 133.632 74.325 0 134.784-59.946 134.784-133.632v-77.568a21.333 21.333 0 1 0-42.667 0v77.526c0 50.176-41.301 90.965-92.16 90.965-50.773 0-92.032-40.79-92.032-90.965v-77.526a21.333 21.333 0 0 0-21.333-21.333m270.08 283.008a64 64 0 1 0 .042 128.043 64 64 0 0 0-.042-128" fill="#3C405D"/><path d="M704.683 813.397a106.795 106.795 0 0 1-106.667-106.666c0-58.838 47.787-106.667 106.667-106.667 58.837 0 106.666 47.83 106.666 106.667 0 58.752-47.829 106.666-106.666 106.666m0-256c-82.347 0-149.334 66.987-149.334 149.334s66.987 149.333 149.334 149.333 149.333-66.987 149.333-149.333-66.987-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Productcopymanagement;
