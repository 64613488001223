import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Sales 图标组件
 */
export const Sales = defineComponent({
	name: "Sales",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Sales icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M850.219 614.87a21.333 21.333 0 1 0-41.984 7.594 192.256 192.256 0 0 1-53.59 169.728c-74.496 74.539-194.986 75.435-268.458 1.963L213.333 521.344V256.085h275.542l248.149 248.15a21.12 21.12 0 0 0 30.165 0 21.333 21.333 0 0 0 0-30.166L514.944 221.696a21.76 21.76 0 0 0-8.79-5.035 21.035 21.035 0 0 0-10.794-3.328H192a21.333 21.333 0 0 0-21.333 21.334v292.266c0 1.152.512 2.176.682 3.371a21.29 21.29 0 0 0 6.144 15.573L456.021 824.32a228.992 228.992 0 0 0 162.56 66.987c60.032 0 120.278-23.04 166.23-68.992a234.965 234.965 0 0 0 65.408-207.403" fill="#3D405D"/><path d="M405.333 408.875a42.667 42.667 0 1 1-85.333 0 42.667 42.667 0 0 1 85.333 0m-128 0c0 47.061 38.272 85.333 85.334 85.333S448 455.936 448 408.875s-38.272-85.334-85.333-85.334-85.334 38.272-85.334 85.334" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Sales;
