import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Singlewarehouseissuednationwide 图标组件
 */
export const Singlewarehouseissuednationwide = defineComponent({
	name: "Singlewarehouseissuednationwide",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Singlewarehouseissuednationwide icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M417.24 673.195h225.963c34.432 0 62.421-27.904 62.421-62.251v-30.635a21.333 21.333 0 0 0-42.666 0v30.635a19.712 19.712 0 0 1-19.755 19.584H417.24a19.712 19.712 0 0 1-19.754-19.584V448.768l132.736-124.544 163.2 153.13a21.29 21.29 0 0 0 29.141-31.06L545.795 280.362a21.248 21.248 0 0 0-15.573-5.718 21.12 21.12 0 0 0-15.574 5.718L337.88 446.293a21.248 21.248 0 0 0 14.55 36.864c.853 0 1.578-.341 2.389-.426v128.213c0 34.347 27.99 62.25 62.421 62.25" fill="#3C405D"/><path d="M623.79 139.819c-192.896-50.475-390.699 65.28-441.216 258.09a359.424 359.424 0 0 0 8.533 210.987A361.216 361.216 0 0 0 532.27 850.859a361.43 361.43 0 0 0 229.504-82.646l-12.118 46.166a20.224 20.224 0 0 0 19.499 25.258c8.96 0 17.152-5.973 19.499-15.018l30.08-114.902a19.627 19.627 0 0 0-.299-10.197 20.01 20.01 0 0 0-35.456-9.43 321.408 321.408 0 0 1-332.117 109.995A320.896 320.896 0 0 1 221.57 408.107C266.456 236.8 442.286 133.845 613.55 178.816a320.299 320.299 0 0 1 239.36 320.939H508.93a21.29 21.29 0 1 0 0 42.666H868.91a21.077 21.077 0 0 0 20.01-14.848 20.565 20.565 0 0 0 3.627-9.685 360.79 360.79 0 0 0-268.8-378.07" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Singlewarehouseissuednationwide;
