import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Marketingtrafficconfiguration 图标组件
 */
export const Marketingtrafficconfiguration = defineComponent({
	name: "Marketingtrafficconfiguration",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Marketingtrafficconfiguration icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M444.632 810.624A206.848 206.848 0 0 1 256.046 603.52c0-39.083 11.264-76.416 31.701-109.312l176.256-264.021L625.07 471.509c.256.384.298.811.597 1.152.853 1.024 1.493 2.176 2.347 3.243l13.525 20.267c.17.298.512.384.725.682 11.264 18.774 19.755 39.168 24.576 60.459a21.333 21.333 0 0 0 41.643-9.43 251.477 251.477 0 0 0-44.203-94.506L485.208 185.173c-.682-1.066-1.706-1.706-2.56-2.602a20.565 20.565 0 0 0-6.954-8.32 21.333 21.333 0 0 0-29.611 5.93L262.659 454.955c-.555.64-1.28.981-1.792 1.664-2.816 3.882-4.864 8.149-7.424 12.16l-2.517 3.712c-.214.341-.214.725-.427 1.066a248.32 248.32 0 0 0-37.12 129.963 249.387 249.387 0 0 0 227.328 249.6l2.005.085a21.333 21.333 0 0 0 1.92-42.581" fill="#3C405D"/><path d="M314.072 530.773v133.419a20.139 20.139 0 1 0 40.278 0V530.773a20.139 20.139 0 1 0-40.278 0m107.819-42.026v175.402a20.139 20.139 0 1 0 40.277 0V488.79a20.139 20.139 0 1 0-40.277 0m151.765 65.366V443.093a20.139 20.139 0 0 0-40.277 0v111.062a20.139 20.139 0 1 0 40.277 0M783.79 698.88h-37.846v-5.035a21.333 21.333 0 1 0-42.666 0v5.035H527.79a21.333 21.333 0 0 0 0 42.667h175.488v3.626a21.333 21.333 0 1 0 42.666 0v-3.626h37.846a21.333 21.333 0 0 0 0-42.667M609.027 609.707a21.333 21.333 0 0 0-42.667 0v4.352h-38.57a21.333 21.333 0 1 0 0 42.666h38.57v4.31a21.333 21.333 0 0 0 42.667 0v-4.267H783.79a21.333 21.333 0 1 0 0-42.667H609.027v-4.437zM783.79 784.47H609.027v-4.353a21.333 21.333 0 0 0-42.667 0v4.352h-38.57a21.333 21.333 0 0 0 0 42.667h38.57v4.352a21.333 21.333 0 1 0 42.667 0v-4.352H783.79a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Marketingtrafficconfiguration;
