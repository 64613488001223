import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * SmartMarketingManagement 图标组件
 */
export const SmartMarketingManagement = defineComponent({
	name: "SmartMarketingManagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-SmartMarketingManagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M881.5 134.699a21.376 21.376 0 0 0-30.123-.896L703.964 272.896a340.437 340.437 0 0 0-122.41-54.656 339.413 339.413 0 0 0-270.252 54.699L163.974 133.76a21.376 21.376 0 0 0-29.269 31.061l143.061 135.126c-1.365 1.237-2.9 2.261-4.266 3.584a338.475 338.475 0 0 0-98.006 172.97c-40.704 183.126 75.094 365.227 258.304 406.016a342.187 342.187 0 0 0 69.248 8.107h.3a20.139 20.139 0 0 0 .298-40.235 302.08 302.08 0 0 1-61.099-7.253c-161.45-35.84-263.68-196.437-227.712-357.888a299.861 299.861 0 0 1 357.973-227.67 299.733 299.733 0 0 1 214.016 183.04c8.704 22.06 14.806 45.185 18.048 68.694a20.181 20.181 0 0 0 39.936-5.547 338.859 338.859 0 0 0-20.48-77.909 339.797 339.797 0 0 0-86.954-125.867l143.232-135.082a21.333 21.333 0 0 0 .853-30.166" fill="#3C405D"/><path d="M368.006 530.261v146.347a20.139 20.139 0 0 0 40.32 0V530.261a20.139 20.139 0 0 0-40.32 0M506.46 464a20.139 20.139 0 0 0-20.182 20.139v192.469a20.181 20.181 0 0 0 40.363 0v-192.47A20.139 20.139 0 0 0 506.46 464m192.426 172.288a64 64 0 1 0 .043 128.043 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M698.886 806.955A106.795 106.795 0 0 1 592.22 700.288c0-58.795 47.872-106.667 106.666-106.667 58.88 0 106.667 47.872 106.667 106.667s-47.787 106.667-106.667 106.667m0-256c-17.578 0-34.26 3.626-49.962 9.258V434.005a20.181 20.181 0 0 0-40.32 0v148.182a148.608 148.608 0 0 0-59.008 118.101c0 82.347 66.986 149.333 149.333 149.333s149.333-66.986 149.333-149.333-66.986-149.333-149.333-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default SmartMarketingManagement;
