import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Categorymanagement 图标组件
 */
export const Categorymanagement = defineComponent({
	name: "Categorymanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Categorymanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M405.333 213.333H832a21.333 21.333 0 0 0 0-42.666H405.333a21.333 21.333 0 0 0 0 42.666m-128-42.666H192a21.333 21.333 0 0 0 0 42.666h85.333a21.333 21.333 0 0 0 0-42.666m554.667 320H405.333a21.333 21.333 0 0 0 0 42.666H832a21.333 21.333 0 0 0 0-42.666m-554.667 0H192a21.333 21.333 0 0 0 0 42.666h85.333a21.333 21.333 0 0 0 0-42.666m211.542 320h-83.542a21.333 21.333 0 0 0 0 42.666h83.542a21.333 21.333 0 0 0 0-42.666m-211.542 0H192a21.333 21.333 0 0 0 0 42.666h85.333a21.333 21.333 0 0 0 0-42.666M704 640a64 64 0 1 0 0 128.043A64 64 0 0 0 704 640" fill="#3C405D"/><path d="M704 810.667A106.752 106.752 0 0 1 597.333 704c0-58.837 47.83-106.667 106.667-106.667S810.667 645.163 810.667 704 762.837 810.667 704 810.667m0-256c-82.347 0-149.333 66.986-149.333 149.333S621.653 853.333 704 853.333 853.333 786.347 853.333 704 786.347 554.667 704 554.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Categorymanagement;
