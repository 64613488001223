import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inandout 图标组件
 */
export const Inandout = defineComponent({
	name: "Inandout",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inandout icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M150.016 499.072c.427.085.853.299 1.365.299h672.512a21.333 21.333 0 1 0 0-42.667H200.704l164.267-164.267a21.333 21.333 0 0 0-30.166-30.165L134.272 462.805a21.29 21.29 0 0 0 15.061 36.438c.256 0 .427-.128.683-.128M847.104 634.155a21.333 21.333 0 0 0-15.488-6.187c-.597-.043-1.11-.299-1.621-.299H157.44a21.333 21.333 0 1 0 0 42.667h623.275l-164.31 164.395a21.29 21.29 0 0 0 15.062 36.394 21.419 21.419 0 0 0 15.061-6.229L847.061 664.32a21.333 21.333 0 0 0 0-30.165" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inandout;
