import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Data 图标组件
 */
export const Data = defineComponent({
	name: "Data",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Data icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M807.68 490.667H509.611V211.968c152.79 9.643 278.357 127.019 298.07 278.699zm-338.346 18.346c0 .342.17.598.213.939 0 .299-.213.555-.213.853 0 11.094 9.045 20.182 20.181 20.182h336a19.413 19.413 0 0 0 10.581-3.456 19.84 19.84 0 0 0 14.08-20.139c-12.928-188.8-171.306-336.725-360.704-336.725a20.139 20.139 0 0 0-20.138 20.266v318.123z" fill="#3C405D"/><path d="M829.056 572.544a19.925 19.925 0 0 0-23.38 16.299 320.597 320.597 0 0 1-316.16 264.49c-177.153 0-321.238-144.085-321.238-321.152a319.573 319.573 0 0 1 226.73-307.114 20.053 20.053 0 0 0 13.312-25.174 20.01 20.01 0 0 0-25.173-13.312A359.637 359.637 0 0 0 128 532.181c0 199.339 162.176 361.472 361.472 361.472a360.917 360.917 0 0 0 355.883-297.728 20.181 20.181 0 0 0-16.299-23.381" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Data;
