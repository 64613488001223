import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Qualitycontrolmanagement 图标组件
 */
export const Qualitycontrolmanagement = defineComponent({
	name: "Qualitycontrolmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Qualitycontrolmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M405.333 213.888h128a21.333 21.333 0 0 0 0-42.667h-128a21.333 21.333 0 0 0 0 42.667m-128-42.667H192a21.333 21.333 0 0 0 0 42.667h85.333a21.333 21.333 0 0 0 0-42.667m128 362.667H832a21.333 21.333 0 0 0 0-42.667H405.333a21.333 21.333 0 0 0 0 42.667m-128-42.667H192a21.333 21.333 0 0 0 0 42.667h85.333a21.333 21.333 0 0 0 0-42.667m213.334 320h-85.334a21.333 21.333 0 0 0 0 42.667h85.334a21.333 21.333 0 0 0 0-42.667m-213.334 0H192a21.333 21.333 0 0 0 0 42.667h85.333a21.333 21.333 0 0 0 0-42.667m447.702-399.488C689.792 405.632 640 376.917 640 319.616v-87.424l84.181-18.859 85.846 18.902v96.341l.085.341c-.213.854-.725 1.579-.81 2.432-5.974 46.763-47.275 74.027-84.267 80.384m-3.2 42.88a24.747 24.747 0 0 0 3.072.214l.128-.043.085.043a25.344 25.344 0 0 0 3.072-.214c53.163-7.637 114.475-47.744 123.435-117.845.085-.725-.171-1.365-.171-2.09.597-1.963 1.28-3.926 1.28-6.102v-113.28a21.077 21.077 0 0 0-15.659-20.181c-.981-.384-1.792-1.024-2.858-1.28l-104.662-23.04c-.768-.128-1.493.085-2.261 0-.853-.171-1.707 0-2.645-.043-1.28 0-2.56-.213-3.84.043-.768.085-1.451-.128-2.219 0l-102.827 23.04c-1.109.256-1.92.938-2.944 1.322a21.035 21.035 0 0 0-15.488 20.139v108.16c0 1.365.555 2.603.768 3.925 4.267 72.278 64.043 118.656 123.734 127.232M704 641.451a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M704 812.117a106.752 106.752 0 0 1-106.667-106.666c0-58.88 47.83-106.667 106.667-106.667s106.667 47.787 106.667 106.667c0 58.837-47.83 106.666-106.667 106.666m0-256c-82.347 0-149.333 66.987-149.333 149.334S621.653 854.784 704 854.784s149.333-66.987 149.333-149.333S786.347 556.117 704 556.117" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Qualitycontrolmanagement;
