import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Audioandvideo 图标组件
 */
export const Audioandvideo = defineComponent({
	name: "Audioandvideo",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Audioandvideo icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M767.1 149.6H256.4c-117.3 0-212.8 95.5-212.8 212.8v297.9c0 117.3 95.5 212.8 212.8 212.8h510.7c117.3 0 212.8-95.5 212.8-212.8V362.4c0-117.3-95.5-212.8-212.8-212.8zm127.6 510.7c0 70.4-57.3 127.7-127.7 127.7H256.4c-70.4 0-127.7-57.3-127.7-127.7V362.4c0-70.4 57.3-127.7 127.7-127.7h510.7c70.4 0 127.7 57.3 127.7 127.7v297.9z" fill="#333"/><path d="M639.1 437.5l-140.2-81c-26.7-15.5-58.7-15.4-85.3 0-26.7 15.4-42.7 43.1-42.7 73.9v161.9c0 30.8 16 58.5 42.7 73.9 13.3 7.7 28 11.6 42.7 11.6 14.6 0 29.3-3.8 42.6-11.6l140.2-81c26.7-15.4 42.7-43.1 42.7-73.9s-16-58.4-42.7-73.8zm-42.6 74l-140.6 80.8.3-162.1 140.2 81 21.3-36.9-21.2 37.2z" fill="#333"/></svg>
			</ElIcon>
		);
	},
});

export default Audioandvideo;
