import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Shopping 图标组件
 */
export const Shopping = defineComponent({
	name: "Shopping",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Shopping icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M959.8 711.6l-63.6-450.2c-10.2-75.4-76.8-132-154.7-132H282.2c-78.2 0-144.7 56.6-154.9 131.7L63.7 711.6c-6.4 45.7 6.4 91.6 35.1 126.1 29.4 35.3 72.8 55.6 119.1 55.6h587.7c46.2 0 89.6-20.2 119.1-55.5 28.7-34.6 41.5-80.6 35.1-126.2zm-100.5 71.6c-13.3 15.9-32.8 25-53.7 25H217.8c-20.9 0-40.4-9.1-53.7-25-13.4-16.1-19.3-37.8-16.2-59.7l63.7-450.7c4.4-32.7 35.4-58.3 70.6-58.3h458.9c35.2 0 66.2 25.5 70.7 58.5l63.6 450.4c3.2 22-2.8 43.7-16.1 59.8z" fill="#333"/><path d="M661.4 357.2c-21.1-10.1-46.6-1-56.6 20.2-17 35.8-53.5 58.9-93.1 58.9-39.6 0-76.1-23.1-93.1-58.9-10.1-21.2-35.5-30.3-56.6-20.2-21.2 10.1-30.3 35.4-20.2 56.7 30.9 65.3 97.6 107.6 169.9 107.6s139.1-42.2 170-107.6c10.1-21.3 1-46.7-20.3-56.7z" fill="#333"/></svg>
			</ElIcon>
		);
	},
});

export default Shopping;
