import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Goodsallocationmanagement 图标组件
 */
export const Goodsallocationmanagement = defineComponent({
	name: "Goodsallocationmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Goodsallocationmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.832 170.667c50.987 0 92.501 41.514 92.501 92.501v228.821a21.333 21.333 0 1 1-42.666 0V263.168a49.92 49.92 0 0 0-49.835-49.835H682.24v63.531a21.287 21.287 0 0 1-21.333 21.333H362.24a21.333 21.333 0 0 1-21.333-21.333v-63.53H263.21a49.92 49.92 0 0 0-49.878 49.834v497.621a49.92 49.92 0 0 0 49.878 49.878H490.24a21.333 21.333 0 1 1 0 42.666H263.21a92.63 92.63 0 0 1-92.543-92.544V263.168c0-50.987 41.514-92.501 92.544-92.501h497.621zM383.573 255.53h256v-42.198h-256v42.198z" fill="#3C405D"/><path d="M358.23 533.717a21.333 21.333 0 0 0 26.751-13.994 132.352 132.352 0 0 1 126.976-93.44c58.454 0 109.44 37.504 126.891 93.269a20.907 20.907 0 0 0 5.76 8.747l.725.597c1.707 1.45 3.414 2.73 5.419 3.627 1.152.554 2.39.768 3.584 1.109 1.28.299 2.475.683 3.84.725.512.086 1.024.342 1.621.342 2.048 0 3.926-.598 5.803-1.152a20.992 20.992 0 0 0 15.53-20.182V401.323a21.333 21.333 0 1 0-42.666 0v36.181A174.89 174.89 0 0 0 512 383.659a175.019 175.019 0 0 0-167.765 123.306 21.376 21.376 0 0 0 13.994 26.752m345.344 105.856a64 64 0 1 0 .043 128 64 64 0 0 0-.043-128" fill="#3C405D"/><path d="M703.573 810.24a106.752 106.752 0 0 1-106.666-106.667c0-58.88 47.829-106.666 106.666-106.666 58.88 0 106.667 47.786 106.667 106.666 0 58.795-47.787 106.667-106.667 106.667m0-256c-75.69 0-137.728 56.79-147.37 129.877-14.166 5.035-28.928 8.107-44.246 8.107a133.547 133.547 0 0 1-127.914-96.384 21.205 21.205 0 0 0-13.483-13.952h-.085a19.243 19.243 0 0 0-6.23-1.28c-.682-.085-1.28-.427-2.005-.427a21.333 21.333 0 0 0-21.333 21.334v112.042a21.333 21.333 0 1 0 42.666 0V678.7A176.043 176.043 0 0 0 512 734.89c15.36 0 30.208-2.646 44.8-6.528a149.248 149.248 0 0 0 146.773 124.501c82.347 0 149.334-66.987 149.334-149.333S785.92 554.197 703.573 554.197" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Goodsallocationmanagement;
