import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inquiriesmanagement 图标组件
 */
export const Inquiriesmanagement = defineComponent({
	name: "Inquiriesmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inquiriesmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.79 170.667c51.029 0 92.543 41.514 92.543 92.501v230.57a21.333 21.333 0 1 1-42.666 0v-230.57a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h227.03a21.333 21.333 0 1 1 0 42.666h-227.03a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621zM383.53 255.53h256v-42.198h-256v42.198z" fill="#3C405D"/><path d="M628.608 477.397a115.584 115.584 0 0 0-115.413-115.413 115.584 115.584 0 0 0-115.456 115.413 21.333 21.333 0 0 0 42.666 0c0-40.106 32.64-72.746 72.79-72.746 40.106 0 72.746 32.64 72.746 72.746 0 40.107-32.64 72.79-72.746 72.79a21.333 21.333 0 0 0-21.334 21.333c0 .683.342 1.28.427 1.92-.085.683-.427 1.28-.427 1.963v77.269a21.333 21.333 0 1 0 42.667 0v-61.995a115.541 115.541 0 0 0 94.08-113.28M513.195 679.04a21.333 21.333 0 0 0-21.334 21.333v24.491a21.333 21.333 0 1 0 42.667 0v-24.49a21.333 21.333 0 0 0-21.333-21.334m190.378-39.467a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M703.573 810.24a106.752 106.752 0 0 1-106.666-106.667c0-58.88 47.786-106.666 106.666-106.666 58.795 0 106.667 47.786 106.667 106.666 0 58.795-47.872 106.667-106.667 106.667m0-256c-82.346 0-149.333 66.987-149.333 149.333s66.987 149.334 149.333 149.334 149.334-66.987 149.334-149.334S785.92 554.24 703.573 554.24" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inquiriesmanagement;
