import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Datariskcontrolandearlywarningplatform 图标组件
 */
export const Datariskcontrolandearlywarningplatform = defineComponent({
	name: "Datariskcontrolandearlywarningplatform",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Datariskcontrolandearlywarningplatform icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 403.499a21.333 21.333 0 0 0 21.333-21.334V276.736a106.24 106.24 0 0 0-106.197-106.07H276.864a106.24 106.24 0 0 0-106.197 106.07v342.528a106.24 106.24 0 0 0 106.197 106.07h470.272a106.24 106.24 0 0 0 106.197-106.07V512a21.333 21.333 0 0 0-42.666 0v107.264a63.573 63.573 0 0 1-63.531 63.403H276.864a63.573 63.573 0 0 1-63.53-63.403V276.736a63.573 63.573 0 0 1 63.53-63.403h470.272a63.573 63.573 0 0 1 63.53 63.403v105.43A21.333 21.333 0 0 0 832 403.498m-39.083 407.168h-560a21.333 21.333 0 0 0 0 42.666h560a21.333 21.333 0 0 0 0-42.666" fill="#3D405D"/><path d="M438.87 502.741c0-40.832 33.237-74.069 74.069-74.069 40.874 0 74.112 33.28 74.112 74.07v71.722H438.869v-71.68zm-57.6 114.39h263.38a21.333 21.333 0 0 0 0-42.667h-14.933v-71.68A116.907 116.907 0 0 0 512.94 386.005a116.907 116.907 0 0 0-116.736 116.736v71.723h-14.934a21.333 21.333 0 1 0 0 42.667zm29.951-228.694A21.419 21.419 0 0 0 426.325 352l-36.352-36.267a21.333 21.333 0 0 0-30.165 30.208l36.267 36.267a21.419 21.419 0 0 0 15.104 6.272m-108.886 57.472a21.333 21.333 0 0 0 21.334 21.333h51.285a21.333 21.333 0 1 0 0-42.666h-51.285a21.333 21.333 0 0 0-21.334 21.333m422.016 0a21.333 21.333 0 0 0-21.333-21.333h-51.328a21.333 21.333 0 0 0 0 42.666h51.328a21.333 21.333 0 0 0 21.333-21.333M613.12 388.437a21.419 21.419 0 0 0 15.061-6.186l36.267-36.31a21.333 21.333 0 0 0-30.165-30.165l-36.267 36.267a21.419 21.419 0 0 0 15.061 36.437m-78.037-40.32v-51.285a21.333 21.333 0 1 0-42.667 0v51.285a21.333 21.333 0 0 0 42.667 0" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Datariskcontrolandearlywarningplatform;
