import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Schedulingmanagement 图标组件
 */
export const Schedulingmanagement = defineComponent({
	name: "Schedulingmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Schedulingmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M213.333 341.333v-19.541c0-35.285 28.715-64 64-64h62.208v23.083a21.333 21.333 0 0 0 42.667 0v-23.083h259.541v23.083a21.333 21.333 0 0 0 42.667 0v-23.083h65.792c35.285 0 64 28.715 64 64v19.541H213.333zm536.875-126.208h-65.792V192a21.333 21.333 0 0 0-42.667 0v23.125H382.208V192a21.333 21.333 0 0 0-42.667 0v23.125h-62.208a106.752 106.752 0 0 0-106.666 106.667v424.875c0 58.837 47.829 106.666 106.666 106.666h213.334a21.333 21.333 0 0 0 0-42.666H277.333c-35.285 0-64-28.715-64-64V384h600.875v104.875a21.333 21.333 0 0 0 42.667 0V321.792c0-58.837-47.83-106.667-106.667-106.667z" fill="#3C405D"/><path d="M320 512h170.667a21.333 21.333 0 0 0 0-42.667H320A21.333 21.333 0 0 0 320 512m385.75 128a64 64 0 1 0 .042 128.043 64 64 0 0 0 0-128.043" fill="#3C405D"/><path d="M705.75 810.667A106.752 106.752 0 0 1 599.082 704c0-58.837 47.829-106.667 106.666-106.667S812.416 645.163 812.416 704s-47.83 106.667-106.667 106.667m0-256c-27.733 0-53.418 8.106-75.69 21.333H320a21.333 21.333 0 0 0 0 42.667h263.51a147.712 147.712 0 0 0-24.918 64H320a21.333 21.333 0 0 0 0 42.666h238.592c10.453 72.192 72.107 128 147.157 128 82.347 0 149.334-66.986 149.334-149.333s-66.987-149.333-149.334-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Schedulingmanagement;
