import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Dataanalysis 图标组件
 */
export const Dataanalysis = defineComponent({
	name: "Dataanalysis",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Dataanalysis icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M410.03 183.381a20.053 20.053 0 0 0 13.355-25.173 19.925 19.925 0 0 0-25.174-13.312 380.8 380.8 0 0 0-270.208 365.952 381.61 381.61 0 0 0 266.454 364.8 20.224 20.224 0 0 0 12.245-38.443 341.333 341.333 0 0 1-238.421-326.4 340.821 340.821 0 0 1 241.75-327.381M850.435 469.333H530.947V169.301c164.395 9.728 299.648 136.534 319.488 300.032m42.326 15.232C879.193 284.672 711.427 128 510.809 128a20.139 20.139 0 0 0-20.139 20.181c0 .299.17.555.17.854 0 .384-.17.64-.17.981V487.68c0 .299.17.555.17.853 0 .342-.17.598-.17.939 0 11.093 9.003 20.139 20.139 20.139h359.168a19.84 19.84 0 0 0 16.682-9.515 19.883 19.883 0 0 0 6.144-15.573M843.694 682.965c-1.536-1.322-3.328-2.005-5.077-2.816a20.864 20.864 0 0 0-10.624-3.242h-65.11a21.333 21.333 0 0 0 0 42.666h21.632l-70.698 83.072-100.779-80.469a21.29 21.29 0 0 0-29.013 2.219L475.566 842.283a21.333 21.333 0 0 0 31.403 28.928l94.976-103.296 101.418 80.981a21.333 21.333 0 0 0 29.568-2.859l76.544-89.941v10.07a21.29 21.29 0 1 0 42.667 0v-65.067a20.65 20.65 0 0 0-2.773-9.942 20.736 20.736 0 0 0-5.675-8.192" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Dataanalysis;
