import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Operationmanagement 图标组件
 */
export const Operationmanagement = defineComponent({
	name: "Operationmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Operationmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M704 597.333c58.837 0 106.667 47.83 106.667 106.667S762.837 810.667 704 810.667A106.752 106.752 0 0 1 597.333 704c0-58.837 47.83-106.667 106.667-106.667m129.75-83.541a21.333 21.333 0 0 0 21.333-21.333V321.792c0-58.837-47.83-106.667-106.667-106.667H533.333V192a21.333 21.333 0 0 0-42.666 0v23.125H277.333a106.752 106.752 0 0 0-106.666 106.667v256c0 58.837 47.829 106.667 106.666 106.667h213.334v49.792L352.085 813.44a21.419 21.419 0 0 0-7.978 29.141 21.461 21.461 0 0 0 29.141 7.979l139.093-79.488 112.256 60.16c1.366.768 2.902.725 4.352 1.11A147.499 147.499 0 0 0 704 853.332c82.347 0 149.333-66.986 149.333-149.333S786.347 554.667 704 554.667 554.667 621.653 554.667 704c0 16 3.2 31.104 7.85 45.568l-29.184-15.659v-69.034c0-9.344-6.101-17.067-14.421-19.926a20.736 20.736 0 0 0-10.496-3.157H277.333c-35.285 0-64-28.715-64-64v-256c0-35.285 28.715-64 64-64h471.083c35.285 0 64 28.715 64 64v170.667a21.333 21.333 0 0 0 21.333 21.333" fill="#3C405D"/><path d="M643.115 330.24L535.083 507.307l-66.304-131.158c-.342-.725-.982-1.152-1.451-1.792a19.2 19.2 0 0 0-5.93-6.016c-.726-.512-1.238-1.194-2.049-1.621-.512-.256-1.109-.213-1.621-.427a20.907 20.907 0 0 0-3.968-1.066c-1.365-.256-2.688-.512-4.01-.512-1.366 0-2.646.256-4.011.512a21.76 21.76 0 0 0-4.054 1.066c-.512.214-1.066.171-1.578.427-.768.427-1.238 1.067-1.92 1.536a24.832 24.832 0 0 0-3.371 2.645 19.456 19.456 0 0 0-2.56 3.328c-.512.726-1.195 1.152-1.579 1.92l-87.04 172.416a21.333 21.333 0 1 0 38.059 19.243l68.053-134.741 64.512 127.658c.214.427.64.555.854.896 1.706 3.03 3.925 5.76 7.082 7.68l.043.043a21.547 21.547 0 0 0 20.693.853l.342-.298c.554-.299.896-.811 1.45-1.152a20.907 20.907 0 0 0 5.846-5.547c.256-.427.725-.555.981-.981l128-209.792a21.333 21.333 0 0 0-36.437-22.187M704 768a64 64 0 1 0-.043-128.043 64 64 0 0 0 0 128.043" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Operationmanagement;
