import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Synergy 图标组件
 */
export const Synergy = defineComponent({
	name: "Synergy",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Synergy icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M668.377 436.139a21.333 21.333 0 0 0 29.866 4.65L843.31 335.061a21.333 21.333 0 0 0-25.13-34.56l-61.185 44.672c-55.21-81.493-148.053-131.84-246.912-131.84a298.667 298.667 0 0 0-246.442 130.262l-59.008-43.051a21.376 21.376 0 0 0-25.13 34.517l62.72 45.654A296.192 296.192 0 0 0 211.416 512c0 157.867 123.306 287.275 278.656 297.685v69.888a21.333 21.333 0 1 0 42.666 0v-70.058c152.192-11.606 274.006-138.752 275.968-293.718a21.376 21.376 0 0 0-21.077-21.589h-.256a21.333 21.333 0 0 0-21.333 21.077c-1.664 131.712-104.363 240.086-233.302 251.563v-66.816a21.333 21.333 0 1 0-42.666 0v66.987C358.233 756.736 254.126 646.443 254.126 512c0-37.717 8.363-73.472 23.083-105.728l47.402 34.517a21.461 21.461 0 0 0 29.824-4.65 21.376 21.376 0 0 0-4.693-29.867l-51.627-37.632A256 256 0 0 1 510.041 256c86.613 0 164.864 42.667 212.565 114.176l-49.579 36.139a21.333 21.333 0 0 0-4.693 29.824" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Synergy;
