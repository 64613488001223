import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * GoalSetting 图标组件
 */
export const GoalSetting = defineComponent({
	name: "GoalSetting",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-GoalSetting icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M513.877 551.125a39.168 39.168 0 1 1 .043-78.293 39.168 39.168 0 0 1-.043 78.293M595.627 512a81.92 81.92 0 0 0-81.792-81.792A81.92 81.92 0 0 0 432.085 512a81.92 81.92 0 0 0 81.792 81.792A81.92 81.92 0 0 0 595.627 512" fill="#3C405D"/><path d="M876.672 488.661h-74.453a287.275 287.275 0 0 0-266.88-264.96v-74.368a21.333 21.333 0 0 0-42.667 0v74.539a287.403 287.403 0 0 0-262.87 264.79h-80.469a21.333 21.333 0 0 0 0 42.666h80.427a285.184 285.184 0 0 0 262.912 264.661v78.678a21.333 21.333 0 0 0 42.667 0V681.259a21.333 21.333 0 1 0-42.667 0v71.978a242.816 242.816 0 0 1-220.245-221.866h70.314a21.333 21.333 0 1 0 0-42.667H272.47a244.907 244.907 0 0 1 220.16-222.165v76.202a21.333 21.333 0 0 0 42.667 0v-76.373a244.779 244.779 0 0 1 224.256 222.293h-76.288a21.333 21.333 0 1 0 0 42.667h193.408a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M807.552 636.8l-13.824 13.824-32.683-32.683 13.824-13.866a23.808 23.808 0 0 1 32.683 0 22.997 22.997 0 0 1 0 32.725zM651.691 792.661l-32.683-32.64L730.88 648.107l32.683 32.682L651.69 792.661zm93.013-218.752l-28.885 28.928h-.043l-141.995 142.08a20.95 20.95 0 0 0-6.186 15.488l-8.747 65.92a21.333 21.333 0 0 0 4.181 15.744 21.333 21.333 0 0 0 21.675 11.35l66.645-8.79c.939-.128 1.707-.64 2.646-.896a20.95 20.95 0 0 0 12.757-5.802l170.965-170.966c12.374-12.416 19.286-29.013 19.286-46.506 0-17.579-6.87-34.134-19.286-46.55-24.832-24.832-68.181-24.832-93.013 0z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default GoalSetting;
