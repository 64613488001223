import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Channelsales 图标组件
 */
export const Channelsales = defineComponent({
	name: "Channelsales",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Channelsales icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M771.755 298.667H149.333a21.333 21.333 0 0 0 0 42.666h622.422c45.44 0 82.432 36.95 82.432 82.347a21.29 21.29 0 1 0 42.666 0 125.227 125.227 0 0 0-125.098-125.013M599.467 506.155H253.824a125.227 125.227 0 0 0-125.056 125.098 125.227 125.227 0 0 0 125.013 125.014h269.355a21.333 21.333 0 0 0 0-42.667H253.781a82.475 82.475 0 0 1-82.389-82.347c0-45.44 36.95-82.432 82.347-82.432h345.77a21.333 21.333 0 0 0 0-42.666M859.477 615.85a21.333 21.333 0 0 0 0-42.666h-78.08v-6.57l68.395-64.555a21.333 21.333 0 1 0-29.27-31.019l-62.037 58.624-62.08-58.624a21.333 21.333 0 1 0-29.269 31.019l71.595 67.541v3.541h-82.347a21.333 21.333 0 0 0 0 42.667h82.347v27.136h-82.347a21.333 21.333 0 0 0 0 42.667h82.347v56.96a21.333 21.333 0 0 0 42.666 0v-56.96h78.08a21.333 21.333 0 1 0 0-42.667h-78.08v-27.093h78.08z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Channelsales;
