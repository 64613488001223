import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Scheduling 图标组件
 */
export const Scheduling = defineComponent({
	name: "Scheduling",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Scheduling icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M213.333 341.333v-19.541c0-35.285 28.715-64 64-64h62.208v23.083a21.333 21.333 0 0 0 42.667 0v-23.083h259.584v23.083a21.333 21.333 0 0 0 42.667 0v-23.083h65.749c35.285 0 64 28.715 64 64v19.541H213.333zm622.208 128A21.333 21.333 0 0 0 856.875 448V321.792c0-58.837-47.83-106.667-106.667-106.667h-65.75V192a21.333 21.333 0 0 0-42.666 0v23.125H382.208V192a21.333 21.333 0 0 0-42.667 0v23.125h-62.208a106.752 106.752 0 0 0-106.666 106.667v424.875c0 58.837 47.829 106.666 106.666 106.666h472.875c58.837 0 106.667-47.829 106.667-106.666V576a21.333 21.333 0 0 0-42.667 0v170.667c0 35.285-28.715 64-64 64H277.333c-35.285 0-64-28.715-64-64V384h600.875v64a21.333 21.333 0 0 0 21.333 21.333z" fill="#3C405D"/><path d="M320 512h170.667a21.333 21.333 0 0 0 0-42.667H320A21.333 21.333 0 0 0 320 512m384 64H320a21.333 21.333 0 0 0 0 42.667h384A21.333 21.333 0 0 0 704 576m0 106.667H320a21.333 21.333 0 0 0 0 42.666h384a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Scheduling;
