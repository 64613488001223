import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Crowd 图标组件
 */
export const Crowd = defineComponent({
	name: "Crowd",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Crowd icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M735.445 810.667h-372.48a79.36 79.36 0 0 1-78.762-79.7c0-43.947 35.328-79.745 78.763-79.745h372.479c43.434 0 78.72 35.798 78.72 79.744s-35.286 79.701-78.72 79.701m-388.053-467.07c0-25.985 7.936-51.03 22.955-72.491a137.557 137.557 0 0 1 112.981-57.77c28.885 0 55.893 9.514 78.677 25.173-2.602-.128-5.162-.47-7.808-.47-59.306 0-114.602 28.416-147.925 75.99a168.02 168.02 0 0 0-30.72 96.938c0 4.736 1.11 9.173 1.494 13.824a126.421 126.421 0 0 1-29.654-81.195m388.053 264.96h-372.48c-64.852 0-117.503 51.626-120.788 116.18a80.042 80.042 0 0 1-28.843-61.14c0-43.99 35.328-79.702 78.72-79.702H548.48c.683 0 1.237-.34 1.877-.384 1.28 0 2.518.384 3.84.384 73.045 0 138.027-42.325 165.546-107.818a21.333 21.333 0 1 0-39.338-16.512c-20.821 49.579-70.4 81.664-126.208 81.664-74.922 0-135.935-58.453-135.935-130.261 0-25.941 7.936-50.987 22.954-72.49a137.557 137.557 0 0 1 112.981-57.771c45.397 0 87.637 21.59 112.981 57.728a126.728 126.728 0 0 1 13.184 23.893 21.333 21.333 0 0 0 39.339-16.597 170.24 170.24 0 0 0-17.621-31.787 176.597 176.597 0 0 0-67.414-57.173 20.395 20.395 0 0 0-3.456-10.197 180.309 180.309 0 0 0-147.882-75.904c-59.306 0-114.602 28.416-147.882 75.946a168.106 168.106 0 0 0-30.72 96.981c0 64.342 36.437 122.624 95.104 152.747 10.112 17.024 22.954 32.085 38.186 44.885H292.054c-66.901 0-121.386 54.87-121.386 122.368 0 51.455 32.298 97.749 80.298 115.157l.299.085a121.6 121.6 0 0 0 111.7 74.496h372.48c66.944 0 121.386-54.912 121.386-122.368 0-67.498-54.442-122.41-121.386-122.41" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Crowd;
