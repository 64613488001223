import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * ManagementofthecargoinOut 图标组件
 */
export const ManagementofthecargoinOut = defineComponent({
	name: "ManagementofthecargoinOut",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-ManagementofthecargoinOut icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M445.32 405.675a21.333 21.333 0 0 0-30.165 0l-88.021 87.978a21.333 21.333 0 0 0-4.565 23.254 20.992 20.992 0 0 0 20.565 17.578h295.083a21.333 21.333 0 1 0 0-42.666H389.299l56.022-56.064a21.333 21.333 0 0 0 0-30.166m93.397 300.886a21.248 21.248 0 0 0 30.165 0l88.022-88.022a21.333 21.333 0 0 0 4.565-23.253 21.035 21.035 0 0 0-20.565-17.621H345.822a21.333 21.333 0 1 0 0 42.666h248.917l-56.021 56.064a21.333 21.333 0 0 0 0 30.166" fill="#3D405D"/><path d="M846.6 452.181L507.188 133.59a21.205 21.205 0 0 0-16.512-5.504 21.248 21.248 0 0 0-16.512 5.504L134.75 452.181a21.29 21.29 0 1 0 29.184 31.104l9.6-8.96v279.766a100.139 100.139 0 0 0 100.224 99.882h433.835c55.296 0 100.266-44.8 100.266-99.882v-71.552a21.333 21.333 0 0 0-42.666 0v71.552c0 31.53-25.856 57.216-57.6 57.216H273.758a57.472 57.472 0 0 1-57.6-57.216V434.26l274.517-257.664 274.518 257.664V554.54a21.333 21.333 0 1 0 42.666 0v-80.214l9.558 8.96a21.12 21.12 0 0 0 30.122-.938 21.29 21.29 0 0 0-.938-30.166" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default ManagementofthecargoinOut;
