import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Suppliermanagement 图标组件
 */
export const Suppliermanagement = defineComponent({
	name: "Suppliermanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Suppliermanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M517.632 552.15c80.64 0 152.277-46.678 182.613-118.955a20.395 20.395 0 0 0 1.408-6.699 21.29 21.29 0 0 0-19.456-29.952H493.952a21.333 21.333 0 1 0 0 42.667h154.965c-27.818 43.264-76.842 70.272-131.285 70.272-85.163 0-154.453-66.432-154.453-148.054 0-29.568 9.045-58.026 26.026-82.474 28.843-41.088 76.8-65.622 128.427-65.622 51.541 0 99.499 24.491 128.299 65.579a139.742 139.742 0 0 1 14.933 27.136 21.333 21.333 0 1 0 39.339-16.597 188.416 188.416 0 0 0-19.371-35.072c-36.736-52.438-97.792-83.712-163.2-83.712-65.493 0-126.55 31.317-163.285 83.84a185.43 185.43 0 0 0-33.835 106.88c0 105.216 88.405 190.762 197.12 190.762m23.723 258.518H303.872c-49.877 0-90.539-41.088-90.539-91.606 0-50.517 40.619-91.605 90.582-91.605h235.648a21.333 21.333 0 0 0 0-42.667H303.872c-73.387 0-133.205 60.246-133.205 134.272 0 74.027 59.733 134.272 133.248 134.272h237.482a21.333 21.333 0 0 0 0-42.666m170.624-151.979a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M712.021 829.355a106.795 106.795 0 0 1-106.666-106.667c0-58.795 47.786-106.667 106.666-106.667 58.838 0 106.667 47.83 106.667 106.667 0 58.795-47.83 106.667-106.667 106.667m0-256c-82.346 0-149.333 66.986-149.333 149.333s66.987 149.333 149.333 149.333 149.334-66.986 149.334-149.333-66.987-149.333-149.334-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Suppliermanagement;
