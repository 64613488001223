import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Procurementplan 图标组件
 */
export const Procurementplan = defineComponent({
	name: "Procurementplan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Procurementplan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.333 789.333a32 32 0 1 0 0 64 32 32 0 0 0 0-64m277.334 0a32 32 0 1 0 0 64 32 32 0 0 0 0-64M828.245 513.237a21.333 21.333 0 0 0-27.178 13.056A169.259 169.259 0 0 1 641.92 640H290.475l-26.283-341.333h377.643A169.216 169.216 0 0 1 800.98 412.33a21.419 21.419 0 0 0 27.179 13.056 21.333 21.333 0 0 0 13.099-27.179A212.01 212.01 0 0 0 641.877 256H260.95l-5.034-65.621c-.086-.939-.555-1.707-.768-2.56a21.376 21.376 0 0 0-1.579-5.206c-.299-.554-.683-1.024-.981-1.578a21.077 21.077 0 0 0-4.224-5.12c-.47-.299-.896-.47-1.323-.811a21.205 21.205 0 0 0-5.29-2.987c-1.409-.512-2.817-.597-4.267-.853-.939-.17-1.835-.597-2.816-.597H192a21.333 21.333 0 0 0 0 42.666h22.912l41.173 534.998c.086 1.28.64 2.346.982 3.584.298 1.194.426 2.474.938 3.584.683 1.493 1.707 2.688 2.688 3.968.64.853 1.11 1.792 1.878 2.517 1.322 1.237 2.858 2.133 4.437 2.987.768.426 1.408 1.066 2.219 1.408a21.248 21.248 0 0 0 8.106 1.621h469.334a21.333 21.333 0 1 0 0-42.667H297.045l-3.242-42.666h348.074a212.053 212.053 0 0 0 199.467-142.251 21.333 21.333 0 0 0-13.099-27.179" fill="#3C405D"/><path d="M362.667 448a21.333 21.333 0 0 0 0 42.667h42.666a21.333 21.333 0 0 0 0-42.667h-42.666zm129.792 0a21.333 21.333 0 0 0 0 42.667h42.666a21.333 21.333 0 0 0 0-42.667H492.46zm128 0a21.333 21.333 0 0 0 0 42.667h42.666a21.333 21.333 0 0 0 0-42.667H620.46z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Procurementplan;
