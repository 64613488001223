import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Add 图标组件
 */
export const Add = defineComponent({
	name: "Add",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Add icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M871.723 572.501A19.968 19.968 0 0 0 848.34 588.8a320.64 320.64 0 0 1-316.16 264.533c-177.194 0-321.28-144.042-321.28-321.194A321.152 321.152 0 0 1 532.14 210.944 321.067 321.067 0 0 1 848.34 475.392a20.181 20.181 0 0 0 39.68-7.083 357.504 357.504 0 0 0-56.192-138.368 361.301 361.301 0 0 0-299.69-159.274 361.387 361.387 0 0 0-361.472 361.472c0 199.338 162.176 361.472 361.472 361.472A360.917 360.917 0 0 0 888.02 595.883a20.181 20.181 0 0 0-16.298-23.382" fill="#3C405D"/><path d="M531.712 784.341a21.333 21.333 0 0 0 21.333-21.333V553.515h209.536a21.333 21.333 0 0 0 0-42.667H553.088V301.227a21.333 21.333 0 0 0-42.667 0v209.578H300.8a21.333 21.333 0 0 0 0 42.667h209.579v209.536a21.333 21.333 0 0 0 21.333 21.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Add;
