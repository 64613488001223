import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Putitaway 图标组件
 */
export const Putitaway = defineComponent({
	name: "Putitaway",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Putitaway icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M676.441 625.792L526.17 475.562a21.419 21.419 0 0 0-30.208 0L347.567 624a21.419 21.419 0 0 0 15.06 36.437 21.419 21.419 0 0 0 15.148-6.229l133.333-133.376 135.125 135.125a21.248 21.248 0 0 0 30.208 0 21.333 21.333 0 0 0 0-30.165" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Putitaway;
