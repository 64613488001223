import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Usermanagement 图标组件
 */
export const Usermanagement = defineComponent({
	name: "Usermanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Usermanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M534.187 810.667H303.915c-49.963 0-90.582-41.088-90.582-91.606 0-50.517 40.619-91.605 90.539-91.605h228.565a21.333 21.333 0 0 0 0-42.667H303.872c-73.43 0-133.248 60.246-133.248 134.272.043 74.027 59.776 134.272 133.29 134.272h230.358a21.333 21.333 0 0 0 0-42.666m-16.64-258.518c80.64 0 152.277-46.677 182.613-118.954a21.333 21.333 0 0 0-39.338-16.512c-23.595 56.362-79.915 92.8-143.275 92.8-85.205 0-154.496-66.432-154.496-148.054 0-29.525 9.045-58.026 26.112-82.474 28.8-41.088 76.8-65.622 128.384-65.622 51.541 0 99.499 24.491 128.256 65.536 5.973 8.576 11.05 17.707 15.019 27.179a21.333 21.333 0 0 0 39.338-16.597 189.44 189.44 0 0 0-19.413-35.072c-36.736-52.438-97.792-83.712-163.2-83.712-65.493 0-126.55 31.317-163.285 83.84a185.259 185.259 0 0 0-33.92 106.88c0 105.216 88.448 190.762 197.12 190.762" fill="#3C405D"/><path d="M704.853 830.25a106.795 106.795 0 0 1-106.666-106.666c0-58.795 47.829-106.667 106.666-106.667 58.88 0 106.667 47.872 106.667 106.667 0 58.752-47.787 106.667-106.667 106.667m0-256c-82.346 0-149.333 66.986-149.333 149.333s66.987 149.333 149.333 149.333 149.334-66.986 149.334-149.333S787.2 574.251 704.853 574.251" fill="#3C405D"/><path d="M704.853 659.541a64 64 0 1 0 .086 128.043 64 64 0 0 0-.043-128" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Usermanagement;
