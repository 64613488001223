import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Activitymanagement 图标组件
 */
export const Activitymanagement = defineComponent({
	name: "Activitymanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Activitymanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M490.785 597.035H295.8l-68.95-340.566h488.79l-53.163 163.67c-.213.682-.085 1.365-.213 2.048a21.29 21.29 0 0 0 .938 12.16 20.053 20.053 0 0 0 1.963 3.84c.427.554.512 1.28.939 1.834l49.749 62.208a21.461 21.461 0 0 0 30.037 3.328 21.376 21.376 0 0 0 3.328-29.994l-42.581-53.206 58.667-180.65c.34-1.11.17-2.134.34-3.286.214-1.109.684-2.133.684-3.285 0-.341-.171-.597-.171-.939-.085-1.493-.512-2.901-.853-4.352-.342-1.237-.555-2.56-1.11-3.712-.554-1.194-1.408-2.218-2.218-3.285-.811-1.152-1.536-2.304-2.518-3.243-.938-.853-2.048-1.408-3.114-2.09-1.28-.854-2.518-1.707-3.968-2.262-.3-.085-.512-.341-.811-.426-1.067-.342-2.133-.214-3.285-.384-1.11-.171-2.134-.64-3.286-.64h-526.72l-5.248-25.942a21.29 21.29 0 1 0-41.813 8.406l129.75 641.792a21.333 21.333 0 1 0 41.813-8.406l-38.4-189.866h186.453a21.333 21.333 0 0 0 0-42.667M704.119 640a64 64 0 1 0 .042 128.085A64 64 0 0 0 704.12 640" fill="#3C405D"/><path d="M704.119 810.71a106.752 106.752 0 0 1-106.667-106.667c0-58.838 47.83-106.667 106.667-106.667 58.88 0 106.666 47.83 106.666 106.667S763 810.709 704.12 810.709m0-256c-82.347 0-149.334 66.987-149.334 149.334s66.987 149.333 149.334 149.333 149.333-66.987 149.333-149.333-66.987-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Activitymanagement;
