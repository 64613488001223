import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inventoryguarantee 图标组件
 */
export const Inventoryguarantee = defineComponent({
	name: "Inventoryguarantee",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inventoryguarantee icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M491.33 668.544c-45.824-7.68-91.307-43.52-91.307-98.816V476.16l90.325-20.224 92.16 20.31v103.807c-.17.811-.682 1.494-.768 2.304-6.4 50.219-50.773 79.488-90.41 86.187m115.242-230.699l-110.72-24.405c-.768-.17-1.536 0-2.304 0-1.237-.256-2.56-.043-3.84-.043-.81 0-1.706-.128-2.517 0-.853.128-1.621-.085-2.475.086l-108.8 24.405c-1.024.213-1.792.853-2.73 1.195a21.077 21.077 0 0 0-15.787 20.224V573.78c0 1.494.512 2.774.853 4.182 4.395 75.861 67.158 124.501 129.878 133.461a23.552 23.552 0 0 0 3.029.213l.128-.042h.128a24.107 24.107 0 0 0 3.03-.171c53.93-7.723 115.285-47.488 128-116.395a20.95 20.95 0 0 0 2.73-9.984V459.307a21.035 21.035 0 0 0-15.787-20.224c-.981-.427-1.792-1.024-2.816-1.28" fill="#3D405D"/><path d="M846.914 452.181L507.458 133.59a20.864 20.864 0 0 0-16.384-5.461 20.992 20.992 0 0 0-16.64 5.461L135.106 452.181a21.29 21.29 0 1 0 29.184 31.104l9.6-8.96v279.766a100.139 100.139 0 0 0 100.138 99.882h433.878a100.181 100.181 0 0 0 100.224-99.882v-71.552a21.333 21.333 0 0 0-42.667 0v71.552c0 31.53-25.856 57.216-57.6 57.216H274.071a57.43 57.43 0 0 1-57.515-57.216V434.26L490.988 176.64l274.475 257.579v120.32a21.333 21.333 0 1 0 42.667 0v-80.256l9.6 9.002a21.12 21.12 0 0 0 30.122-.938 21.29 21.29 0 0 0-.938-30.166" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inventoryguarantee;
