import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Complete 图标组件
 */
export const Complete = defineComponent({
	name: "Complete",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Complete icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M837.461 535.467a19.072 19.072 0 0 0-22.314 15.573 326.315 326.315 0 0 1-321.792 269.227c-180.267 0-326.912-146.646-326.912-326.912a326.827 326.827 0 0 1 597.93-182.784 323.157 323.157 0 0 1 50.774 125.056 19.243 19.243 0 0 0 37.888-6.699 360.704 360.704 0 0 0-56.79-139.947A365.099 365.099 0 0 0 493.312 128 365.227 365.227 0 0 0 128 493.355c0 201.472 163.883 365.354 365.355 365.354a364.672 364.672 0 0 0 359.68-300.885 19.2 19.2 0 0 0-15.574-22.315" fill="#3C405D"/><path d="M650.539 389.504L450.56 589.525 330.795 469.76a18.816 18.816 0 0 0-26.624 26.581l132.224 132.182c.128.17.341.213.469.341.17.17.213.427.427.597a18.773 18.773 0 0 0 26.581 0l213.29-213.333a18.816 18.816 0 0 0-26.623-26.624" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Complete;
