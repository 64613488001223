import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Assembly 图标组件
 */
export const Assembly = defineComponent({
	name: "Assembly",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Assembly icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M930.2 510.2l-419 228.3L92 510.2c-15.3-8.2-34.4-2.6-42.8 12.6-8.3 15.3-2.6 34.4 12.6 42.8l434.3 236.5c4.7 2.5 9.9 3.8 15.1 3.8 5.2 0 10.4-1.3 15.1-3.8l434.1-236.5c15.3-8.4 20.9-27.5 12.6-42.8-8.4-15.2-27.5-20.8-42.8-12.6z"/><path d="M930.2 723.7l-419 228.2L92 723.7c-15.3-8.2-34.4-2.6-42.8 12.6-8.3 15.3-2.6 34.4 12.6 42.8l434.3 236.4c4.7 2.5 9.9 3.8 15.1 3.8 5.2 0 10.4-1.3 15.1-3.8l434.1-236.4c15.3-8.4 20.9-27.5 12.6-42.8-8.4-15.3-27.5-20.8-42.8-12.6zM86.7 383.1l389.4 212.2 4.5 2.5c.7.4 1.4.7 2.2 1.1l5.4 2.3c7.3 2.8 15 4.2 22.8 4.2 10.3 0 20.4-2.5 31-8.1l393.3-214.2c25.4-14 41.3-43.5 41.5-77 0-33.5-15.9-63-41.6-77.3l-392-213.6-1.4-.8c-.5-.2-.9-.5-1.4-.7l-6.5-3c-17.3-6.8-36.9-5.5-53.8 3.6L86.9 228.8c-25.2 13.7-41.6 44.1-41.6 77.3 0 33.4 15.9 62.9 41.4 77zm30.4-98.9L506.6 72l12.4 1.8 385.9 210.4c3.7 2 8.9 10.2 8.9 21.8-.1 11.8-5.3 20-8.8 21.9L515.2 539.4l-12.1-1.2-385.9-210.3c-3.7-2-8.8-10.2-8.8-21.8 0-11.8 5-19.9 8.7-21.9z"/></svg>
			</ElIcon>
		);
	},
});

export default Assembly;
