import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Warehousingmanagement 图标组件
 */
export const Warehousingmanagement = defineComponent({
	name: "Warehousingmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Warehousingmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M445.32 448.299a21.333 21.333 0 0 0-30.165 0l-88.021 88.021a21.333 21.333 0 0 0-4.565 23.253 20.992 20.992 0 0 0 20.565 17.579h295.083a21.333 21.333 0 1 0 0-42.667H389.299l56.022-56.021a21.333 21.333 0 0 0 0-30.165m235.605 214.656a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3D405D"/><path d="M680.883 833.621a106.795 106.795 0 0 1-106.666-106.666c0-58.795 47.829-106.667 106.666-106.667 58.88 0 106.667 47.872 106.667 106.667S739.763 833.62 680.883 833.62m0-256c-40.533 0-77.226 16.342-104.192 42.667H345.78a21.333 21.333 0 0 0 0 42.667h200.747a147.797 147.797 0 0 0-14.933 64c0 82.346 66.986 149.333 149.333 149.333s149.333-66.987 149.333-149.333S763.273 577.62 680.926 577.62" fill="#3D405D"/><path d="M846.6 494.848L507.188 176.256a21.205 21.205 0 0 0-16.512-5.504 21.248 21.248 0 0 0-16.512 5.504L134.75 494.848a21.29 21.29 0 1 0 29.184 31.104l9.6-8.96v279.765a100.139 100.139 0 0 0 100.224 99.883H467.55a21.333 21.333 0 1 0 0-42.667H273.758a57.472 57.472 0 0 1-57.6-57.216V476.928l274.517-257.664 326.742 306.688a21.12 21.12 0 0 0 30.122-.939 21.29 21.29 0 0 0-.938-30.165" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Warehousingmanagement;
