import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Reportmanagement 图标组件
 */
export const Reportmanagement = defineComponent({
	name: "Reportmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Reportmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M448 854.23H253.653c-22.229 0-40.32-19.286-40.32-43.009v-505.77l134.784-134.784h336.854c22.229 0 40.362 19.285 40.362 43.008v319.658a21.333 21.333 0 0 0 42.667 0V213.675C768 166.443 730.752 128 684.97 128H339.542a21.205 21.205 0 0 0-15.189 6.4c-.299.256-.64.341-.896.597l-146.56 146.56a21.077 21.077 0 0 0-6.016 16.086c-.043.341-.213.64-.213 1.024V811.22c0 47.232 37.248 85.675 82.986 85.675H448a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M576 362.667H362.667a21.333 21.333 0 0 0 0 42.666H576a21.333 21.333 0 0 0 0-42.666m0 128H362.667a21.333 21.333 0 0 0 0 42.666H576a21.333 21.333 0 0 0 0-42.666m85.333 192a64 64 0 1 0 0 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M661.333 853.333a106.795 106.795 0 0 1-106.666-106.666c0-58.795 47.829-106.667 106.666-106.667S768 687.872 768 746.667s-47.83 106.666-106.667 106.666m0-256c-28.586 0-55.082 8.491-77.824 22.486-1.877-.512-3.712-1.152-5.76-1.152H364.416a21.333 21.333 0 0 0 0 42.666h174.677A148.48 148.48 0 0 0 512 746.667C512 829.013 578.987 896 661.333 896s149.334-66.987 149.334-149.333-66.987-149.334-149.334-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Reportmanagement;
