import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Clearanceplan 图标组件
 */
export const Clearanceplan = defineComponent({
	name: "Clearanceplan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Clearanceplan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M533.333 811.264H308.608c-28.97 0-52.608-23.467-52.608-52.352V459.648l256-240.256 256 240.256v116.181a21.333 21.333 0 1 0 42.667 0v-76.16l6.741 6.358a21.077 21.077 0 0 0 14.592 5.76 20.95 20.95 0 0 0 15.53-6.742 21.248 21.248 0 0 0-.938-30.122L528.384 176.256a20.821 20.821 0 0 0-16.341-5.504 20.907 20.907 0 0 0-16.384 5.504L177.408 474.923a21.248 21.248 0 0 0-.939 30.122A20.95 20.95 0 0 0 192 511.787a21.077 21.077 0 0 0 14.592-5.76l6.741-6.358v259.243a95.275 95.275 0 0 0 95.275 95.019h224.725a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M471.125 405.163a21.333 21.333 0 0 0 0 42.666h87.126a21.333 21.333 0 0 0 0-42.666h-87.126zm128 256c0 11.733-9.6 21.333-21.333 21.333H448a21.376 21.376 0 0 1-21.333-21.333V511.829h172.458v149.334zm231.126-23.083H641.792V511.787h19.541a21.333 21.333 0 0 0 0-42.667H364.46a21.333 21.333 0 0 0 0 42.667H384V661.12c0 35.328 28.715 64 64 64h129.792c28.416 0 52.267-18.688 60.63-44.373H830.25a21.333 21.333 0 0 0 0-42.667zm.042 85.333h-212.48a21.333 21.333 0 1 0 0 42.667h212.48a21.333 21.333 0 0 0 0-42.667m0 87.083H611.584a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Clearanceplan;
