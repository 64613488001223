import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Upload 图标组件
 */
export const Upload = defineComponent({
	name: "Upload",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Upload icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M257.5 358.1l216.4-216.4v575.6c0 22.1 17.9 39.9 39.9 39.9 22.1 0 39.9-17.9 39.9-39.9V141.7l216.4 216.4c7.8 7.8 18 11.7 28.2 11.7s20.4-3.9 28.2-11.7c15.6-15.6 15.6-40.9 0-56.5L542.1 17c-15.6-15.6-40.9-15.6-56.5 0L201.1 301.6c-15.6 15.6-15.6 40.9 0 56.5s40.8 15.6 56.4 0z"/><path d="M979.4 642.6c-22.1 0-39.9 17.9-39.9 39.9v219.8c0 20.8-16.9 37.6-37.6 37.6H126c-20.8 0-37.7-16.9-37.7-37.6V682.5c0-22.1-17.9-39.9-39.9-39.9s-40 17.9-40 39.9v219.8c0 64.8 52.7 117.5 117.6 117.5h775.8c64.8 0 117.5-52.7 117.5-117.5V682.5c0-22-17.8-39.9-39.9-39.9z"/></svg>
			</ElIcon>
		);
	},
});

export default Upload;
