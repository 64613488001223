import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Channels 图标组件
 */
export const Channels = defineComponent({
	name: "Channels",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Channels icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M827.997 713.643H247.176c-43.051 0-78.08-36.95-78.08-82.39s35.029-82.432 78.08-82.432h490.752c65.322 0 118.485-56.106 118.485-125.056 0-68.992-53.163-125.098-118.528-125.098H148.232A20.779 20.779 0 0 0 128.008 320c0 11.819 9.045 21.333 20.224 21.333h589.653c43.093 0 78.08 36.992 78.08 82.432s-34.987 82.347-78.08 82.347h-490.71c-65.28 0-118.485 56.15-118.485 125.141 0 68.95 53.163 125.014 118.486 125.014h580.821a20.821 20.821 0 0 0 20.224-21.334c0-11.733-9.088-21.333-20.224-21.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Channels;
