import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inventory 图标组件
 */
export const Inventory = defineComponent({
	name: "Inventory",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inventory icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M380.503 447.83h213.333a21.333 21.333 0 0 0 0-42.667H380.503a21.333 21.333 0 0 0 0 42.666m0 128h213.333a21.333 21.333 0 0 0 0-42.666H380.503a21.333 21.333 0 0 0 0 42.666m1.792 128h213.333a21.333 21.333 0 0 0 0-42.666H382.295a21.333 21.333 0 0 0 0 42.666" fill="#3D405D"/><path d="M846.636 452.181L507.18 133.59a21.205 21.205 0 0 0-16.555-5.504 21.29 21.29 0 0 0-16.426 5.504L134.743 452.181a21.333 21.333 0 0 0 29.226 31.104l9.6-8.96v279.766a100.139 100.139 0 0 0 100.182 99.882h433.92a100.139 100.139 0 0 0 100.18-99.882v-69.803a21.333 21.333 0 1 0-42.666 0v69.803c0 31.53-25.813 57.216-57.557 57.216H273.75a57.43 57.43 0 0 1-57.515-57.216V434.26L490.71 176.64l274.474 257.664v124.459a21.333 21.333 0 0 0 42.667 0v-84.438l9.557 8.96a21.333 21.333 0 1 0 29.227-31.104" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inventory;
