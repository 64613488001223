import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Monthlyclosingmanagement 图标组件
 */
export const Monthlyclosingmanagement = defineComponent({
	name: "Monthlyclosingmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Monthlyclosingmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M213.333 341.333v-19.541c0-35.328 28.715-64 64-64h62.251v23.083a21.333 21.333 0 1 0 42.667 0v-23.083h259.541v23.083a21.333 21.333 0 1 0 42.667 0v-23.083h65.792c35.285 0 64 28.672 64 64v19.541H213.333zm536.918-126.208h-65.792V192a21.333 21.333 0 0 0-42.667 0v23.125H382.251V192a21.333 21.333 0 0 0-42.667 0v23.125h-62.25a106.752 106.752 0 0 0-106.667 106.667v424.875c0 58.794 47.829 106.666 106.666 106.666H492.46a21.333 21.333 0 0 0 0-42.666H277.333c-35.285 0-64-28.715-64-64V384h600.918v108.459a21.333 21.333 0 0 0 42.666 0V321.792c0-58.837-47.829-106.667-106.666-106.667z" fill="#3C405D"/><path d="M707.584 640a64 64 0 1 0 0 128.043 64 64 0 0 0 0-128.043" fill="#3C405D"/><path d="M707.584 810.667A106.795 106.795 0 0 1 600.917 704c0-58.795 47.83-106.667 106.667-106.667S814.251 645.205 814.251 704s-47.83 106.667-106.667 106.667zm-172.032-181.76v-30.678h66.73a150.528 150.528 0 0 0-22.997 30.678h-43.733zm172.032-74.24c-24.832 0-47.915 6.656-68.565 17.45a21.035 21.035 0 0 0-20.352-16.554h-83.115V547.2l72.15-68.096a21.333 21.333 0 0 0-29.227-31.061l-65.92 62.208-65.92-62.208a21.333 21.333 0 0 0-29.27 31.061l75.52 71.253v5.206h-87.552a21.333 21.333 0 0 0 0 42.666h87.552v30.678h-87.552a21.333 21.333 0 1 0 0 42.666h87.552v60.886a21.333 21.333 0 1 0 42.667 0v-60.886h26.496A147.541 147.541 0 0 0 558.251 704c0 82.347 66.986 149.333 149.333 149.333S856.917 786.347 856.917 704s-66.986-149.333-149.333-149.333z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Monthlyclosingmanagement;
