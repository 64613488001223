import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Returnorder 图标组件
 */
export const Returnorder = defineComponent({
	name: "Returnorder",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Returnorder icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M789.333 512a21.333 21.333 0 0 0 21.334-21.333V299.008c0-47.232-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.208 128a128.683 128.683 0 0 0-120.277 85.333H296.32c-45.739 0-82.987 38.443-82.987 85.675v511.317c0 47.232 37.248 85.675 82.987 85.675h431.317c45.782 0 83.03-38.443 83.03-85.675V618.667a21.333 21.333 0 0 0-42.667 0v191.658c0 23.723-18.133 43.008-40.363 43.008H296.32c-22.23 0-40.32-19.285-40.32-43.008V299.008C256 275.285 274.09 256 296.32 256h107.179a20.736 20.736 0 0 0 12.373-4.437 20.95 20.95 0 0 0 11.008-14.08 85.845 85.845 0 0 1 83.328-66.816c40.448 0 75.605 28.714 83.627 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256h108.97c22.23 0 40.363 19.285 40.363 43.008v191.659A21.333 21.333 0 0 0 789.333 512" fill="#3C405D"/><path d="M577.75 624H405.332a21.333 21.333 0 0 0 0 42.667H577.75c58.838 0 106.667-47.872 106.667-106.667s-47.83-106.667-106.667-106.667H414.165l52.48-52.48a21.333 21.333 0 0 0-30.208-30.165l-87.978 87.979c-.171.17-.214.384-.342.554a21.12 21.12 0 0 0-6.784 15.446A21.333 21.333 0 0 0 362.667 496h215.082c35.286 0 64 28.715 64 64s-28.714 64-64 64" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Returnorder;
