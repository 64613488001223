import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Valueassessment 图标组件
 */
export const Valueassessment = defineComponent({
	name: "Valueassessment",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Valueassessment icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M870.272 351.317l-92.459-167.466c-3.797-6.614-13.909-13.142-21.546-13.142H245.803c-7.68 0-18.006 6.656-21.76 13.44L131.712 351.36a30.293 30.293 0 0 0 2.176 32.512l347.52 461.355a24.32 24.32 0 0 0 19.67 10.197 28.373 28.373 0 0 0 19.626-10.155l158.037-209.92a21.333 21.333 0 1 0-34.09-25.6L501.077 800.427 172.971 364.8l83.712-151.467H745.3l83.755 151.595-87.936 116.779a21.333 21.333 0 1 0 34.133 25.6l93.014-123.478a29.995 29.995 0 0 0 2.048-32.554" fill="#3C405D"/><path d="M755.968 364.8a21.333 21.333 0 0 0-21.333-21.333H267.008a21.333 21.333 0 0 0 0 42.666h467.627a21.333 21.333 0 0 0 21.333-21.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Valueassessment;
