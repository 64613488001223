import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Resources 图标组件
 */
export const Resources = defineComponent({
	name: "Resources",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Resources icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M675.67 490.667H448A106.795 106.795 0 0 1 341.333 384c0-58.795 47.787-106.667 106.667-106.667h227.67c58.837 0 106.666 47.872 106.666 106.667a21.333 21.333 0 0 0 42.667 0c0-82.347-66.987-149.333-149.334-149.333h-92.586c.256-1.195.768-2.304.768-3.542V192a21.333 21.333 0 0 0-42.667 0v39.125c0 1.238.512 2.347.725 3.542H448c-82.347 0-149.333 66.986-149.333 149.333S365.653 533.333 448 533.333h227.67c58.837 0 106.666 47.872 106.666 106.667s-47.83 106.667-106.667 106.667H448A106.795 106.795 0 0 1 341.333 640a21.333 21.333 0 0 0-42.666 0c0 82.347 66.986 149.333 149.333 149.333h93.867c-.171 1.195-.683 2.304-.683 3.542V832a21.333 21.333 0 1 0 42.667 0v-39.125c0-1.238-.512-2.347-.726-3.542h92.544c82.347 0 149.334-66.986 149.334-149.333s-66.987-149.333-149.334-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Resources;
