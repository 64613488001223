import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Retirementplan 图标组件
 */
export const Retirementplan = defineComponent({
	name: "Retirementplan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Retirementplan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M553.77 760.405H288.94a21.333 21.333 0 0 0 0 42.667H553.77a21.333 21.333 0 0 0 0-42.667M890.88 541.27c-21.547-97.707-109.91-168.62-210.133-168.62H243.499l165.546-165.546a21.333 21.333 0 0 0-30.165-30.165L178.901 376.832c-.341.384-.426.81-.768 1.195a20.992 20.992 0 0 0-7.466 15.957A21.333 21.333 0 0 0 192 415.317h488.747a173.483 173.483 0 0 1 168.448 135.126 21.333 21.333 0 0 0 41.685-9.174M852.437 608H633.771a21.333 21.333 0 0 0 0 42.667h218.666a21.333 21.333 0 0 0 0-42.667m0 85.333H633.771a21.333 21.333 0 0 0 0 42.667h218.666a21.333 21.333 0 0 0 0-42.667m0 87.126H633.771a21.333 21.333 0 0 0 0 42.666h218.666a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Retirementplan;
