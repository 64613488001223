import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Optimizationplan 图标组件
 */
export const Optimizationplan = defineComponent({
	name: "Optimizationplan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Optimizationplan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.32 566.144a21.12 21.12 0 0 0-29.739-4.821 194.347 194.347 0 0 1-192 18.602 21.29 21.29 0 0 0-28.245 10.496c-.341.768-.256 1.494-.512 2.134l-203.99 203.989a54.23 54.23 0 0 1-76.671-76.63L438.4 510.593a21.035 21.035 0 0 0 5.76-25.003 191.872 191.872 0 0 1-17.536-80.298c0-105.814 86.101-192 192-192 16.981 0 33.365 2.986 49.45 7.296l-97.706 97.706a96.939 96.939 0 0 0 68.48 165.462c25.899 0 50.219-10.07 68.523-28.416l96.768-96.768c3.84 15.232 6.485 30.762 6.485 46.72 0 11.776-1.11 23.637-3.243 35.37a21.333 21.333 0 1 0 41.984 7.638c2.56-14.208 3.926-28.672 3.926-43.008 0-31.062-6.016-61.355-17.92-89.856a19.968 19.968 0 0 0-6.187-8.022c-.597-.81-.896-1.706-1.621-2.432a21.333 21.333 0 0 0-30.166 0L677.205 425.173c-20.522 20.48-56.192 20.48-76.672 0a54.315 54.315 0 0 1 0-76.714L720.64 228.352a21.29 21.29 0 0 0 0-30.165c-1.195-1.238-2.73-1.878-4.139-2.774a20.565 20.565 0 0 0-8.661-7.04 231.936 231.936 0 0 0-89.259-17.706 234.923 234.923 0 0 0-230.016 281.557c2.56 12.587 6.486 24.79 11.094 36.779L198.997 689.707a97.024 97.024 0 0 0 0 136.96 96.427 96.427 0 0 0 68.48 28.458c24.747 0 49.622-9.429 68.523-28.373l201.387-201.387a233.77 233.77 0 0 0 218.112-29.44 21.248 21.248 0 0 0 4.821-29.738" fill="#3C405D"/><path d="M830.123 640H609.707a21.333 21.333 0 0 0 0 42.667h220.458a21.333 21.333 0 0 0 0-42.667m-.042 85.333H611.456a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667m0 87.126H611.456a21.333 21.333 0 0 0 0 42.666h218.667a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Optimizationplan;
