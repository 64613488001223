import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * List 图标组件
 */
export const List = defineComponent({
	name: "List",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-List icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 213.333H405.333a21.333 21.333 0 0 1 0-42.666H832a21.333 21.333 0 0 1 0 42.666m-554.667 0H192a21.333 21.333 0 0 1 0-42.666h85.333a21.333 21.333 0 0 1 0 42.666m554.667 320H405.333a21.333 21.333 0 0 1 0-42.666H832a21.333 21.333 0 0 1 0 42.666m-554.667 0H192a21.333 21.333 0 0 1 0-42.666h85.333a21.333 21.333 0 0 1 0 42.666m554.667 320H405.333a21.333 21.333 0 0 1 0-42.666H832a21.333 21.333 0 0 1 0 42.666m-554.667 0H192a21.333 21.333 0 0 1 0-42.666h85.333a21.333 21.333 0 0 1 0 42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default List;
