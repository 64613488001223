import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Shortcutbar 图标组件
 */
export const Shortcutbar = defineComponent({
	name: "Shortcutbar",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Shortcutbar icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M250.5 350c42.7 0 78.8-27.4 92.3-65.5h497.6c18.1 0 32.8-14.7 32.8-32.8S858.5 219 840.4 219H342.8c-13.5-38.1-49.5-65.5-92.3-65.5-54.3 0-98.3 44-98.3 98.3s44 98.2 98.3 98.2zm589.9 393.3H342.8c-13.5-38.1-49.5-65.5-92.3-65.5-54.3 0-98.3 44-98.3 98.3s44 98.3 98.3 98.3c42.7 0 78.8-27.4 92.3-65.5h497.6c18.1 0 32.8-14.7 32.8-32.8s-14.7-32.8-32.8-32.8zm-65.6-327.7c-42.7 0-78.8 27.4-92.3 65.5H185c-18.1 0-32.8 14.7-32.8 32.8s14.7 32.8 32.8 32.8h497.6c13.5 38.1 49.5 65.5 92.3 65.5 54.3 0 98.3-44 98.3-98.3s-44.1-98.3-98.4-98.3z"/></svg>
			</ElIcon>
		);
	},
});

export default Shortcutbar;
