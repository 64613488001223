import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Settlementdoc 图标组件
 */
export const Settlementdoc = defineComponent({
	name: "Settlementdoc",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Settlementdoc icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M789.333 512a21.333 21.333 0 0 0 21.334-21.333V299.008c0-47.232-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.251 128c-54.315 0-102.4 35.157-120.278 85.333h-93.61c-45.782 0-83.03 38.443-83.03 85.675v511.317c0 47.232 37.248 85.675 83.03 85.675h431.274c45.782 0 83.03-38.443 83.03-85.675V618.667a21.333 21.333 0 0 0-42.667 0v191.658c0 23.723-18.133 43.008-40.363 43.008H296.363c-22.23 0-40.363-19.285-40.363-43.008V299.008C256 275.285 274.133 256 296.363 256H403.54A20.736 20.736 0 0 0 416 251.52a20.992 20.992 0 0 0 10.923-14.037 85.76 85.76 0 0 1 83.328-66.816c40.405 0 75.562 28.714 83.584 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256h108.97c22.23 0 40.363 19.285 40.363 43.008v191.659A21.333 21.333 0 0 0 789.333 512" fill="#3C405D"/><path d="M659.584 544.853a21.333 21.333 0 0 0 0-42.666H534.656v-23.894l103.765-97.92a21.333 21.333 0 0 0-29.269-31.018l-98.133 92.586-98.134-92.586a21.376 21.376 0 0 0-29.269 31.061l108.373 102.315v19.498H360.917a21.333 21.333 0 0 0 0 42.667H491.99v59.99H360.917a21.333 21.333 0 0 0 0 42.666H491.99v93.781a21.333 21.333 0 1 0 42.667 0v-93.781h124.928a21.333 21.333 0 1 0 0-42.667H534.656v-60.032h124.928z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Settlementdoc;
