import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Positioning 图标组件
 */
export const Positioning = defineComponent({
	name: "Positioning",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Positioning icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M752.981 609.792a298.837 298.837 0 0 0 57.387-131.157 23.168 23.168 0 0 0-18.987-26.624 22.87 22.87 0 0 0-26.666 18.944 253.44 253.44 0 0 1-52.139 116.053L513.92 809.941 320.128 592.427a253.696 253.696 0 0 1-60.587-163.84A254.635 254.635 0 0 1 513.92 174.208a255.147 255.147 0 0 1 239.275 167.808 23.04 23.04 0 1 0 43.392-15.787A301.27 301.27 0 0 0 513.92 127.957c-165.76 0-300.587 134.912-300.587 300.587 0 64.256 21.419 125.525 58.966 176.81a23.637 23.637 0 0 0 4.693 8.193l8.917 9.984c1.664 1.962 2.859 4.266 4.608 6.229.726.768 1.707 1.11 2.518 1.792L496.64 860.117c.299.342.725.47 1.024.768.299.342.427.768.81 1.11 1.238 1.109 2.731 1.706 4.097 2.474.853.512 1.706 1.195 2.56 1.579a24.107 24.107 0 0 0 8.746 1.835 23.893 23.893 0 0 0 8.704-1.835c.854-.384 1.707-1.067 2.56-1.579 1.408-.81 2.902-1.365 4.139-2.474.384-.342.512-.768.81-1.11.342-.298.769-.426 1.067-.768L743.168 622.08c.597-.597 1.408-.81 1.963-1.493 1.024-1.195 1.792-2.56 2.773-3.84l2.859-3.2c1.024-1.11 1.45-2.518 2.133-3.755" fill="#3D405D"/><path d="M583.253 430.507c0 38.272-31.104 69.418-69.333 69.418s-69.376-31.146-69.376-69.376c0-38.229 31.147-69.376 69.376-69.376 38.23 0 69.333 31.147 69.333 69.376m-184.96 0A115.712 115.712 0 0 0 513.92 546.133a115.712 115.712 0 0 0 115.627-115.626A115.712 115.712 0 0 0 513.92 314.88a115.712 115.712 0 0 0-115.627 115.627" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Positioning;
