import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Riskcontrol 图标组件
 */
export const Riskcontrol = defineComponent({
	name: "Riskcontrol",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Riskcontrol icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 471.125a21.333 21.333 0 0 0 21.333-21.333V220.373a21.035 21.035 0 0 0-17.322-20.48c-1.579-.81-2.987-1.706-4.779-2.133l-314.667-69.248a20.48 20.48 0 0 0-8.661-.085c-1.92-.171-3.883-.342-5.888.085l-309.333 69.29c-1.792.427-3.2 1.323-4.736 2.134a21.035 21.035 0 0 0-17.28 20.48v325.333c0 1.408.554 2.646.81 3.968 8.32 198.102 173.44 324.907 337.067 348.288a24.107 24.107 0 0 0 3.03.214l.426-.086.427.086a24.107 24.107 0 0 0 3.029-.214c144.512-20.693 310.912-128.853 334.976-317.568a21.333 21.333 0 0 0-42.368-5.376c-21.035 165.462-167.68 260.907-296.021 280.235-148.864-22.315-298.71-139.264-298.71-321.11V236.929l296.064-66.39 301.27 66.39v212.864A21.333 21.333 0 0 0 832 471.125" fill="#3C405D"/><path d="M533.333 312.917a21.333 21.333 0 0 0-42.666 0v254.208a21.333 21.333 0 0 0 42.666 0V312.917zM512 631.125a21.333 21.333 0 0 0-21.333 21.334v42.666a21.333 21.333 0 0 0 42.666 0V652.46A21.333 21.333 0 0 0 512 631.125" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Riskcontrol;
