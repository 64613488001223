import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Investmentpromotionmanagement 图标组件
 */
export const Investmentpromotionmanagement = defineComponent({
	name: "Investmentpromotionmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Investmentpromotionmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M220.758 469.803a169.643 169.643 0 0 1-50.09-120.875 169.813 169.813 0 0 1 50.09-120.875c64.597-64.597 177.194-64.597 241.706 0l283.094 283.094a21.333 21.333 0 0 0 30.165-30.166l-228.31-228.266 32-32.086a171.093 171.093 0 0 1 241.75 0c66.688 66.688 66.688 175.104.64 241.195l-.555.427 30.08 30.293a213.845 213.845 0 0 0 0-302.08 213.888 213.888 0 0 0-302.08 0l-32 32.085-24.618-24.618a212.224 212.224 0 0 0-151.04-62.592 212.31 212.31 0 0 0-151.04 62.592A212.31 212.31 0 0 0 128 348.928c0 57.088 22.272 110.72 62.592 151.04l308.054 308.053a21.248 21.248 0 0 0 30.165 0 21.333 21.333 0 0 0 0-30.122L220.758 469.803z" fill="#3C405D"/><path d="M458.624 569.045a21.333 21.333 0 0 0-30.165 30.166l90.496 90.496a21.333 21.333 0 1 0 30.165-30.166l-90.496-90.453zm252.63 33.323a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M711.254 773.035a106.752 106.752 0 0 1-106.667-106.667c0-58.88 47.787-106.667 106.667-106.667 58.837 0 106.666 47.787 106.666 106.667 0 58.837-47.829 106.667-106.666 106.667m0-256c-9.984 0-19.712 1.066-29.142 2.944l-87.21-87.211a21.333 21.333 0 0 0-30.166 30.165l73.814 73.814a149.547 149.547 0 0 0-40.235 33.024l-70.187-70.187a21.333 21.333 0 0 0-30.165 30.165l76.715 76.715c-8.107 18.347-12.8 38.57-12.8 59.904 0 82.347 66.986 149.333 149.333 149.333s149.333-66.986 149.333-149.333-66.986-149.333-149.333-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Investmentpromotionmanagement;
