import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Minus 图标组件
 */
export const Minus = defineComponent({
	name: "Minus",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Minus icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M830.08 554.795a18.901 18.901 0 0 0-22.059 15.402 322.901 322.901 0 0 1-318.464 266.411c-178.389 0-323.498-145.11-323.498-323.499A323.413 323.413 0 0 1 757.76 332.203a319.83 319.83 0 0 1 50.261 123.818 19.072 19.072 0 0 0 37.462-6.656 356.95 356.95 0 0 0-56.15-138.453A361.301 361.301 0 0 0 489.6 151.552 361.43 361.43 0 0 0 128 513.109c0 199.382 162.219 361.558 361.557 361.558a360.875 360.875 0 0 0 355.926-297.771 19.03 19.03 0 0 0-15.36-22.101" fill="#3C405D"/><path d="M284.16 512a20.139 20.139 0 0 0 0 40.277h436.053a20.139 20.139 0 0 0 0-40.277H284.16z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Minus;
