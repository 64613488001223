import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Salesforecast 图标组件
 */
export const Salesforecast = defineComponent({
	name: "Salesforecast",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Salesforecast icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M833.792 426.667A21.333 21.333 0 0 0 812.459 448v298.667a21.333 21.333 0 0 0 42.666 0V448a21.333 21.333 0 0 0-21.333-21.333m-192.597 320V576a21.333 21.333 0 0 0-42.667 0v170.667a21.333 21.333 0 0 0 42.667 0m-214.23-1.792V449.792a21.333 21.333 0 0 0-42.666 0v295.083a21.333 21.333 0 0 0 42.666 0M192 768.043a21.333 21.333 0 0 0 21.333-21.334V620.46a21.333 21.333 0 0 0-42.666 0v126.25A21.333 21.333 0 0 0 192 768.043m640 42.624H192a21.333 21.333 0 0 0 0 42.666h640a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/><path d="M851.67 186.41a21.248 21.248 0 0 0-5.846-8.874 22.144 22.144 0 0 0-7.467-4.096 20.48 20.48 0 0 0-9.898-2.773H705.792a21.333 21.333 0 1 0 0 42.666h81.536l-171.52 201.558L411.52 251.819a21.29 21.29 0 0 0-28.97 2.133L178.09 476.245A21.419 21.419 0 0 0 193.793 512a21.333 21.333 0 0 0 15.701-6.87l191.019-207.573 204.843 163.584a21.333 21.333 0 0 0 29.568-2.816l177.536-208.64V320a21.29 21.29 0 1 0 42.666 0V197.333a20.565 20.565 0 0 0-3.413-10.922" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Salesforecast;
