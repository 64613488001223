import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Catalogue 图标组件
 */
export const Catalogue = defineComponent({
	name: "Catalogue",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Catalogue icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M192 341.333h638.25a21.333 21.333 0 0 0 0-42.666H192a21.333 21.333 0 0 0 0 42.666M830.25 510.25H192a21.333 21.333 0 0 0 0 42.667h638.25a21.333 21.333 0 0 0 0-42.666m0 213.333H192a21.333 21.333 0 0 0 0 42.667h638.25a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Catalogue;
