import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Goodsallocation 图标组件
 */
export const Goodsallocation = defineComponent({
	name: "Goodsallocation",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Goodsallocation icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 469.76a21.333 21.333 0 0 1-21.333-21.333V263.168a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h497.621a49.92 49.92 0 0 0 49.878-49.878V576.427a21.333 21.333 0 1 1 42.666 0v184.362a92.63 92.63 0 0 1-92.544 92.544H263.168a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621c51.03 0 92.544 41.514 92.544 92.501v185.259A21.333 21.333 0 0 1 832 469.76zM383.53 255.53h256v-42.197h-256v42.198z" fill="#3C405D"/><path d="M358.23 533.717a21.333 21.333 0 0 0 26.709-14.037 132.267 132.267 0 0 1 126.976-93.355c58.453 0 109.525 37.462 126.976 93.227a20.864 20.864 0 0 0 5.674 8.747l.811.597a19.968 19.968 0 0 0 5.248 3.584c1.28.597 2.688.853 4.053 1.195 1.11.256 2.134.597 3.2.682.683 0 1.28.342 1.878.342 2.09 0 3.968-.598 5.802-1.152h.086a21.035 21.035 0 0 0 15.445-20.182V401.323a21.333 21.333 0 1 0-42.667 0v36.181A174.805 174.805 0 0 0 512 383.659a174.805 174.805 0 0 0-167.765 123.306 21.333 21.333 0 0 0 14.08 26.752M340.907 713.6a21.333 21.333 0 0 0 42.666 0v-34.859a176.043 176.043 0 0 0 128.342 56.192 175.787 175.787 0 0 0 169.514-129.621 21.248 21.248 0 1 0-41.13-11.179 133.163 133.163 0 0 1-128.384 98.134A133.547 133.547 0 0 1 384 595.84a21.077 21.077 0 0 0-13.483-13.952 21.035 21.035 0 0 0-6.357-1.28c-.683-.043-1.28-.427-1.963-.427a21.333 21.333 0 0 0-21.333 21.334V713.6z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Goodsallocation;
