import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Salesandinventoryreportmanagement 图标组件
 */
export const Salesandinventoryreportmanagement = defineComponent({
	name: "Salesandinventoryreportmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Salesandinventoryreportmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M490.667 854.23H296.32c-22.23 0-40.32-19.286-40.32-43.009v-505.77l134.784-134.784h336.853c22.23 0 40.363 19.285 40.363 43.008v321.45a21.333 21.333 0 0 0 42.667 0v-321.45c0-47.232-37.248-85.675-83.03-85.675H382.208a21.205 21.205 0 0 0-15.19 6.4c-.298.256-.64.341-.895.597l-146.56 146.56a21.077 21.077 0 0 0-6.016 16.086c-.043.341-.214.64-.214 1.024V811.22c0 47.232 37.248 85.675 82.987 85.675h194.347a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M469.29 425.813a21.333 21.333 0 0 0-30.165 0l-88.021 88.022a21.333 21.333 0 0 0-4.608 23.168 21.035 21.035 0 0 0 20.565 17.664h295.126a21.333 21.333 0 1 0 0-42.667H413.269l56.022-56.021a21.333 21.333 0 0 0 0-30.166M704 682.667a64 64 0 1 0 0 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M704 853.333a106.795 106.795 0 0 1-106.667-106.666C597.333 687.872 645.163 640 704 640s106.667 47.872 106.667 106.667S762.837 853.333 704 853.333m0-256c-82.347 0-149.333 66.987-149.333 149.334S621.653 896 704 896s149.333-66.987 149.333-149.333S786.347 597.333 704 597.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Salesandinventoryreportmanagement;
