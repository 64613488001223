import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Warehousehealthindexmanagement 图标组件
 */
export const Warehousehealthindexmanagement = defineComponent({
	name: "Warehousehealthindexmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Warehousehealthindexmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M702.263 641.621a64 64 0 1 0 0 128.043 64 64 0 0 0 0-128.043" fill="#3C405D"/><path d="M702.22 812.288a106.795 106.795 0 0 1-106.666-106.667c0-58.794 47.829-106.666 106.666-106.666 58.88 0 106.667 47.872 106.667 106.666S761.1 812.288 702.22 812.288m0-256c-82.346 0-149.333 66.987-149.333 149.333s66.987 149.334 149.333 149.334 149.334-66.987 149.334-149.334-66.987-149.333-149.334-149.333" fill="#3C405D"/><path d="M492.471 811.307h-218.71c-31.743 0-57.6-25.686-57.6-57.174V434.261L490.68 176.597 817.42 483.371a21.29 21.29 0 0 0 30.123-1.024 21.29 21.29 0 0 0-.939-30.166L507.191 133.59a21.163 21.163 0 0 0-16.512-5.504 21.248 21.248 0 0 0-16.512 5.547l-339.413 318.55a21.29 21.29 0 0 0 14.592 36.906 21.29 21.29 0 0 0 14.592-5.76l9.557-8.96v279.723a100.181 100.181 0 0 0 100.267 99.882H492.47a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/><path d="M635.959 501.93l10.581 35.585a21.333 21.333 0 0 0 40.96-12.203l-25.386-85.205a21.333 21.333 0 0 0-26.582-14.336l-85.162 25.344a21.333 21.333 0 1 0 12.202 40.874l36.054-10.709-83.072 136.064-66.304-131.115c-.384-.768-1.024-1.28-1.494-1.92a19.456 19.456 0 0 0-6.144-6.101c-.64-.427-1.066-1.067-1.706-1.408-.512-.213-1.024-.213-1.494-.427a22.87 22.87 0 0 0-4.352-1.194c-1.28-.214-2.56-.47-3.84-.47s-2.56.256-3.968.512a20.992 20.992 0 0 0-4.181 1.152c-.427.214-.981.171-1.493.427-.726.384-1.152 1.024-1.835 1.45a23.083 23.083 0 0 0-3.413 2.689 20.565 20.565 0 0 0-2.688 3.413c-.427.683-1.11 1.152-1.451 1.877l-87.04 172.374a21.333 21.333 0 1 0 38.016 19.328l68.053-134.827 64.555 127.701c.17.384.597.512.853.854a20.65 20.65 0 0 0 7.083 7.765 21.504 21.504 0 0 0 20.736.81c.128-.085.17-.213.299-.255.554-.299.896-.768 1.408-1.11a20.48 20.48 0 0 0 5.973-5.717c.213-.341.64-.47.896-.853L635.959 501.93z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Warehousehealthindexmanagement;
