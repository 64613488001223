import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Homepage 图标组件
 */
export const Homepage = defineComponent({
	name: "Homepage",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Homepage icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M511.7 170.5c13.1 0 24.9 5.1 34.1 14.6l295.4 307.3c8.6 8.9 11 15.5 11.4 18.5-.9.3-2.7.8-5.5.8h-6.7c-47 0-85.1 38.1-85.1 85.1v199.6c0 30.3-20.5 55.9-44.8 55.9h-77.1V646.9c.4-22.8-8.4-44.8-24.4-61.1-16-16.3-37.9-25.4-60.7-25.4h-73c-22.7 0-44.5 9.9-60.4 26-16 16.1-24.9 38.8-24.7 61.5v204.5h-77.1c-24.3 0-44.8-25.6-44.8-55.8V596.8c0-47-38.1-85.1-85.1-85.1h-6.7c-2.7 0-4.4-.4-5.4-1 .5-3.1 3-9.6 11.4-18.3l295.3-307.2c9-9.6 20.8-14.7 33.9-14.7m0-85.1c-36.2 0-70.1 14.5-95.4 40.8L120.9 433.4c-39.2 40.8-40.5 84.1-28.6 111.1 14.5 32.8 45.9 52.3 84 52.3h6.7v199.6c0 77.7 58.3 141 129.9 141h125.7c20.2 0 36.5-16.5 36.5-36.8V645.5h73v255.1c0 20.3 16.4 36.8 36.5 36.8h125.7c71.6 0 129.9-63.2 129.9-141V596.8h6.7c38.2 0 69.6-19.6 84-52.2 12-26.9 10.8-70.3-28.6-111.2L607.1 126.1c-25.3-26.3-59.2-40.7-95.4-40.7z" fill="#272636"/></svg>
			</ElIcon>
		);
	},
});

export default Homepage;
