import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Design 图标组件
 */
export const Design = defineComponent({
	name: "Design",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Design icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M936.8 678.5L763.4 505.1l149.5-149.5c8-8 12.5-18.8 12.5-30.1s-4.5-22.1-12.5-30.1L727.3 109.9c-16.6-16.6-43.6-16.6-60.2 0L517.7 259.4 344.3 86c-16-16-44.2-16-60.2 0L86.6 283.5c-16.6 16.6-16.6 43.6 0 60.2L260 517.1 110.5 666.6c-8 8-12.5 18.8-12.5 30.1v185.5c0 23.5 19 42.6 42.6 42.6h185.5c11.3 0 22.1-4.5 30.1-12.5l149.5-149.5 173.4 173.4c8.3 8.3 19.2 12.5 30.1 12.5s21.8-4.2 30.1-12.5l197.5-197.5c16.6-16.6 16.6-43.6 0-60.2zM277.5 414.2l70.5-70.5c16.6-16.6 16.6-43.6 0-60.2s-43.6-16.6-60.2 0L217.3 354l-40.4-40.4 137.3-137.3 143.3 143.3-137.3 137.3-42.7-42.7zm31 425.4H183.2V714.3l514-514.1 125.4 125.3-514.1 514.1zm400.7 6.3l-48.6-48.6 70.6-70.5c16.6-16.6 16.6-43.6 0-60.2s-43.6-16.6-60.2 0l-70.6 70.5-34.5-34.5 137.3-137.3 143.3 143.3-137.3 137.3z" fill="#333"/></svg>
			</ElIcon>
		);
	},
});

export default Design;
