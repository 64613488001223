import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Goods 图标组件
 */
export const Goods = defineComponent({
	name: "Goods",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Goods icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 469.76a21.333 21.333 0 0 1-21.333-21.333V263.168a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h497.621a49.92 49.92 0 0 0 49.878-49.878V576.427a21.333 21.333 0 1 1 42.666 0v184.362a92.63 92.63 0 0 1-92.544 92.544H263.168a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621c51.03 0 92.544 41.514 92.544 92.501v185.259A21.333 21.333 0 0 1 832 469.76zM383.53 255.53h256v-42.197h-256v42.198z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Goods;
