import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Purchase 图标组件
 */
export const Purchase = defineComponent({
	name: "Purchase",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Purchase icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 469.76a21.333 21.333 0 0 1-21.333-21.333V263.168a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h497.621a49.92 49.92 0 0 0 49.878-49.878V576.427a21.333 21.333 0 1 1 42.666 0v184.362a92.63 92.63 0 0 1-92.544 92.544H263.168a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621c51.03 0 92.544 41.514 92.544 92.501v185.259A21.333 21.333 0 0 1 832 469.76zM383.53 255.53h256v-42.197h-256v42.198z" fill="#3C405D"/><path d="M663.552 511.573h-248.96l56.064-56.064a21.419 21.419 0 0 0-30.208-30.208l-88.021 88.022a21.333 21.333 0 0 0-4.694 23.168 21.077 21.077 0 0 0 20.651 17.706h295.125a21.333 21.333 0 0 0 0-42.666m-.853 85.376H363.989a21.333 21.333 0 0 0 0 42.666h298.667a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Purchase;
