import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Operation 图标组件
 */
export const Operation = defineComponent({
	name: "Operation",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Operation icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M833.792 407.083a21.333 21.333 0 0 0 21.333-21.334v-64c0-58.794-47.829-106.666-106.666-106.666H533.333V192a21.333 21.333 0 0 0-42.666 0v23.083H277.333a106.795 106.795 0 0 0-106.666 106.666v256c0 58.838 47.829 106.667 106.666 106.667h213.334v49.877l-138.582 79.19a21.333 21.333 0 1 0 21.163 37.034L512 771.243l138.752 79.274a21.376 21.376 0 0 0 21.163-37.034l-138.582-79.19v-49.877H748.46c58.837 0 106.666-47.83 106.666-106.667v-64a21.333 21.333 0 0 0-42.666 0v64c0 35.328-28.715 64-64 64H277.333c-35.285 0-64-28.672-64-64v-256c0-35.285 28.715-64 64-64H748.46c35.285 0 64 28.715 64 64v64a21.333 21.333 0 0 0 21.333 21.334" fill="#3C405D"/><path d="M643.115 330.24L535.083 507.307 468.82 376.149c-.384-.725-1.024-1.194-1.493-1.877a20.01 20.01 0 0 0-6.101-6.101c-.64-.427-1.11-1.067-1.835-1.451-.427-.213-.981-.17-1.493-.427a20.395 20.395 0 0 0-4.267-1.152 19.243 19.243 0 0 0-3.797-.469c-1.366 0-2.688.256-4.054.512a19.03 19.03 0 0 0-4.053 1.11c-.512.213-1.067.213-1.579.426-.725.384-1.194 1.067-1.877 1.493a18.645 18.645 0 0 0-5.973 5.974c-.512.725-1.195 1.194-1.579 1.962l-87.125 172.459a21.333 21.333 0 0 0 38.101 19.2l68.096-134.699 64.47 127.574c.213.426.64.597.853.938 1.706 2.987 3.925 5.76 7.082 7.68h.043a21.547 21.547 0 0 0 20.693.854l.342-.256c.512-.299.896-.768 1.408-1.11a20.352 20.352 0 0 0 5.973-5.674c.213-.427.64-.512.896-.896l128-209.75a21.419 21.419 0 0 0-7.125-29.354 21.248 21.248 0 0 0-29.312 7.125" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Operation;
