import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Selection 图标组件
 */
export const Selection = defineComponent({
	name: "Selection",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Selection icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.832 170.667H263.211a92.63 92.63 0 0 0-92.544 92.501v497.579c0 51.029 41.514 92.544 92.544 92.544h225.237a21.333 21.333 0 1 0 0-42.667H263.211a49.92 49.92 0 0 1-49.878-49.877V263.21a49.92 49.92 0 0 1 49.878-49.878h497.621a49.92 49.92 0 0 1 49.835 49.835v313.259a21.333 21.333 0 1 0 42.666 0V263.168a92.63 92.63 0 0 0-92.501-92.501" fill="#3C405D"/><path d="M383.573 299.093a21.333 21.333 0 0 0-21.333 21.334v87.466c0 81.664 66.987 148.096 149.333 148.096s149.334-66.432 149.334-148.053v-87.51a21.333 21.333 0 0 0-42.667 0v87.467c0 58.112-47.787 105.387-106.667 105.387-58.837 0-106.666-47.275-106.666-105.387v-87.466a21.333 21.333 0 0 0-21.334-21.334M814.933 644.907L651.947 802.26l-62.806-65.066a21.333 21.333 0 0 0-30.72 29.653l77.654 80.427a21.248 21.248 0 0 0 30.208.469l178.346-172.16a21.333 21.333 0 1 0-29.653-30.72" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Selection;
