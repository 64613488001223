import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Financialanalysis 图标组件
 */
export const Financialanalysis = defineComponent({
	name: "Financialanalysis",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Financialanalysis icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M405.376 810.667H261.035a47.787 47.787 0 0 1-47.702-47.702V487.168a47.787 47.787 0 0 1 47.702-47.701h501.973c26.283 0 47.659 21.418 47.659 47.701v82.859a21.333 21.333 0 1 0 42.666 0v-82.859a90.453 90.453 0 0 0-90.325-90.368H261.035a90.453 90.453 0 0 0-90.368 90.368v275.797a90.453 90.453 0 0 0 90.368 90.368h144.341a21.333 21.333 0 0 0 0-42.666m-138.027-446.72h489.387a21.333 21.333 0 0 0 0-42.667H267.392a21.333 21.333 0 0 0 0 42.667m75.221-75.307h340.395a21.333 21.333 0 0 0 0-42.667H342.613a21.333 21.333 0 0 0 0 42.667m75.307-75.307h188.16a21.333 21.333 0 0 0 0-42.666H417.92a21.333 21.333 0 0 0 0 42.666" fill="#3C405D"/><path d="M508.245 699.008a21.333 21.333 0 0 0 0-42.667h-70.784v-22.058h70.827a21.333 21.333 0 1 0 0-42.667h-70.827v-3.755l62.891-59.392a21.333 21.333 0 0 0-29.312-31.018l-56.405 53.29-56.491-53.333a21.248 21.248 0 1 0-29.227 31.061l65.878 62.166v.981h-74.752a21.333 21.333 0 0 0 0 42.667h74.752v22.058h-74.752a21.333 21.333 0 0 0 0 42.667h74.752v51.2a21.29 21.29 0 1 0 42.666 0v-51.2h70.827zM852.352 668.459a20.736 20.736 0 0 0-5.675-8.235c-1.578-1.408-3.498-2.219-5.418-2.987a20.65 20.65 0 0 0-10.326-2.986h-64.981a21.29 21.29 0 1 0 0 42.666h21.59l-70.7 82.944-100.863-80.426a21.333 21.333 0 0 0-29.014 2.218L478.55 819.541a21.333 21.333 0 0 0 31.488 28.928l94.976-103.253 101.419 80.939a21.333 21.333 0 0 0 29.525-2.816l76.502-89.942v10.07a21.333 21.333 0 0 0 42.666 0v-65.11a20.608 20.608 0 0 0-2.773-9.898" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Financialanalysis;
