import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * SalePlan 图标组件
 */
export const SalePlan = defineComponent({
	name: "SalePlan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-SalePlan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M405.333 366.208a42.667 42.667 0 1 1-85.333 0 42.667 42.667 0 0 1 85.333 0m-128 0c0 47.061 38.272 85.333 85.334 85.333S448 413.27 448 366.208s-38.272-85.333-85.333-85.333-85.334 38.272-85.334 85.333M833.792 640H615.125a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667m0 85.333H615.125a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667m0 87.126H615.125a21.333 21.333 0 0 0 0 42.666h218.667a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/><path d="M543.872 793.813a190.72 190.72 0 0 1-55.808-38.784l-274.73-274.773V213.333h274.218l271.104 271.104a190.592 190.592 0 0 1 51.67 94.336 21.333 21.333 0 1 0 41.685-9.088 233.259 233.259 0 0 0-63.147-115.413L514.816 180.267c-1.11-1.152-2.56-1.707-3.84-2.56a21.163 21.163 0 0 0-15.616-7.04H192A21.333 21.333 0 0 0 170.667 192v292.267c0 1.706.597 3.157.981 4.736a20.992 20.992 0 0 0 6.059 15.957l280.192 280.235a232.96 232.96 0 0 0 68.266 47.445 21.333 21.333 0 0 0 17.75-38.827" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default SalePlan;
