import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Purchasingmanagement 图标组件
 */
export const Purchasingmanagement = defineComponent({
	name: "Purchasingmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Purchasingmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.333 832a32 32 0 1 0 0 64 32 32 0 0 0 0-64M704 682.667a64 64 0 1 0 .043 128.042A64 64 0 0 0 704 682.667" fill="#3C405D"/><path d="M704 853.333a106.795 106.795 0 0 1-106.667-106.666C597.333 687.872 645.163 640 704 640s106.667 47.872 106.667 106.667S762.837 853.333 704 853.333m0-256c-82.347 0-149.333 66.987-149.333 149.334S621.653 896 704 896s149.333-66.987 149.333-149.333S786.347 597.333 704 597.333" fill="#3C405D"/><path d="M490.667 726.23a21.333 21.333 0 0 0 0-42.667H290.56l-26.368-343.126h374.016a171.733 171.733 0 0 1 167.339 209.536 21.333 21.333 0 1 0 41.642 9.344A213.077 213.077 0 0 0 852.437 512a214.443 214.443 0 0 0-214.229-214.23H260.907l-4.992-64.767c-.086-.896-.555-1.622-.768-2.475a20.48 20.48 0 0 0-1.579-5.29c-.256-.555-.683-.982-1.024-1.537a21.76 21.76 0 0 0-4.181-5.077c-.47-.384-.982-.597-1.451-.981a21.547 21.547 0 0 0-5.12-2.859c-1.408-.555-2.859-.683-4.31-.896-.98-.128-1.834-.555-2.815-.555H192A21.333 21.333 0 0 0 192 256h22.912l41.173 534.997c.086 1.28.64 2.347.982 3.584.298 1.195.469 2.475.981 3.584.64 1.494 1.664 2.688 2.645 3.968.64.811 1.11 1.792 1.878 2.518 1.322 1.237 2.901 2.09 4.437 2.986.768.427 1.408 1.067 2.219 1.408a21.248 21.248 0 0 0 8.064 1.622h213.376a21.333 21.333 0 0 0 0-42.667H297.088l-3.243-41.77h196.822z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Purchasingmanagement;
