import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Channelmanagement 图标组件
 */
export const Channelmanagement = defineComponent({
	name: "Channelmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Channelmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M149.333 341.333h622.422c45.44 0 82.432 36.95 82.432 82.39a21.29 21.29 0 1 0 42.666 0 125.227 125.227 0 0 0-125.098-125.056H149.333a21.333 21.333 0 0 0 0 42.666m104.448 207.488H533.76a21.333 21.333 0 0 0 0-42.666H253.781a125.27 125.27 0 0 0-125.098 125.098A125.227 125.227 0 0 0 253.78 756.267H533.76a21.333 21.333 0 0 0 0-42.667H253.781a82.517 82.517 0 0 1-82.432-82.347c0-45.44 36.992-82.432 82.432-82.432m491.52 10.667a64 64 0 1 0 .043 128 64 64 0 0 0-.043-128" fill="#3C405D"/><path d="M745.301 730.155a106.795 106.795 0 0 1-106.666-106.667c0-58.795 47.786-106.667 106.666-106.667 58.838 0 106.667 47.872 106.667 106.667s-47.83 106.667-106.667 106.667m0-256c-82.346 0-149.333 66.986-149.333 149.333s66.987 149.333 149.333 149.333 149.334-66.986 149.334-149.333S827.648 474.155 745.3 474.155" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Channelmanagement;
