import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * GMVtarget 图标组件
 */
export const GMVtarget = defineComponent({
	name: "GMVtarget",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-GMVtarget icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M876.715 488.661H731.648a21.333 21.333 0 1 0 0 42.667h24.021a244.907 244.907 0 0 1-220.288 224.128v-74.197a21.333 21.333 0 1 0-42.666 0v74.368a244.693 244.693 0 0 1-224.342-224.299h28.032a21.333 21.333 0 1 0 0-42.667h-27.861a244.907 244.907 0 0 1 224.17-220.288v74.411a21.333 21.333 0 0 0 42.667 0v-73.941A243.627 243.627 0 0 1 717.1 378.58a21.333 21.333 0 0 0 35.754-23.253 285.952 285.952 0 0 0-217.472-129.152v-76.8a21.333 21.333 0 0 0-42.666 0v76.288a287.403 287.403 0 0 0-266.838 262.997h-76.544a21.333 21.333 0 0 0 0 42.667h76.374a287.19 287.19 0 0 0 267.008 266.965v76.374a21.333 21.333 0 0 0 42.666 0v-76.544a287.403 287.403 0 0 0 262.955-266.795h78.379a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M386.73 439.85c16.726 0 20.054 15.147 20.054 27.307h21.76c-.256-14.634-4.267-27.05-12.672-35.669a39.808 39.808 0 0 0-29.141-11.136c-14.422 0-24.022 5.29-30.934 12.928-12.373 13.653-13.141 34.133-13.141 79.232s.768 65.579 13.141 79.317c6.912 7.552 16.512 12.886 30.934 12.886 23.552 0 41.813-15.446 41.813-45.312V505.94h-41.813v18.262h20.053v35.925c0 14.677-7.381 25.088-20.053 25.088a17.152 17.152 0 0 1-14.72-6.87c-6.23-8.575-7.51-23.04-7.51-65.834s1.28-57.216 7.552-65.835a17.237 17.237 0 0 1 14.678-6.826m183.765 162.816V422.315h-20.48l-36.224 100.309-36.48-100.31h-20.48v180.353h21.76V476.288l28.16 82.304h13.355l28.629-84.352v128.427zm67.541-35.2l-26.112-145.11h-24.064l38.998 180.31h22.314l38.998-180.31h-23.808z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default GMVtarget;
