import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Pricingmanagement 图标组件
 */
export const Pricingmanagement = defineComponent({
	name: "Pricingmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Pricingmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M681.515 615.68a64 64 0 1 0 0 128.085 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M681.515 786.347A106.795 106.795 0 0 1 574.848 679.68c0-58.752 47.787-106.667 106.667-106.667 58.837 0 106.666 47.915 106.666 106.667 0 58.837-47.829 106.667-106.666 106.667m0-256c-32.854 0-62.891 11.008-87.552 29.013h-80.555v-60.032h124.843a21.29 21.29 0 1 0 0-42.667H513.408v-23.893l103.68-97.92a21.333 21.333 0 0 0-29.184-31.019l-98.176 92.587-98.176-92.587a21.333 21.333 0 1 0-29.227 31.019L470.7 437.163v19.498H339.627a21.333 21.333 0 0 0 0 42.667h131.157v60.032h-131.2a21.333 21.333 0 1 0 0 42.667h131.157v93.738a21.29 21.29 0 1 0 42.667 0v-93.738h41.216a147.499 147.499 0 0 0-22.443 77.653c0 82.347 66.987 149.333 149.334 149.333s149.333-66.986 149.333-149.333-66.987-149.333-149.333-149.333" fill="#3C405D"/><path d="M489.515 128C290.133 128 128 290.133 128 489.472c0 189.61 148.053 348.032 337.067 360.661h1.28a20.139 20.139 0 0 0 1.365-40.192c-167.85-11.221-299.35-151.978-299.35-320.469 0-177.11 144.086-321.195 321.153-321.195 169.344 0 310.186 132.267 320.554 301.099.683 11.093 10.667 20.352 21.334 18.901a20.181 20.181 0 0 0 18.901-21.333C838.614 276.864 680.064 128 489.514 128" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Pricingmanagement;
