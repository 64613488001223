import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Reverse 图标组件
 */
export const Reverse = defineComponent({
	name: "Reverse",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Reverse icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M869.333 330.155a360.875 360.875 0 0 0-592.554-9.216V272.81a20.139 20.139 0 0 0-40.32 0v118.784c0 3.626 1.194 6.826 2.858 9.77a20.053 20.053 0 0 0 10.027 10.326c10.283 4.437 22.059 0 26.624-10.198a321.11 321.11 0 0 1 293.547-190.506 321.11 321.11 0 0 1 321.152 321.152c0 177.109-144.086 321.194-321.152 321.194a320.597 320.597 0 0 1-316.16-264.49 20.139 20.139 0 1 0-39.68 7.082 360.875 360.875 0 0 0 355.84 297.728c199.338 0 361.472-162.176 361.472-361.514 0-72.363-21.334-142.208-61.654-201.984" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Reverse;
