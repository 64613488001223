import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * ChannelReturns 图标组件
 */
export const ChannelReturns = defineComponent({
	name: "ChannelReturns",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-ChannelReturns icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M771.883 348.075H201.728l55.637-55.638a21.333 21.333 0 0 0-30.165-30.165l-88.021 87.979c-.427.426-.555.981-.939 1.408A20.992 20.992 0 0 0 128 369.408a21.333 21.333 0 0 0 21.333 21.333H771.84c45.397 0 82.347 36.95 82.347 82.39a21.29 21.29 0 1 0 42.666 0A125.227 125.227 0 0 0 771.84 348.117M253.867 598.23h269.312a21.333 21.333 0 0 0 0-42.667H253.867a125.27 125.27 0 0 0-125.099 125.141 125.227 125.227 0 0 0 125.099 125.013h269.312a21.333 21.333 0 0 0 0-42.666H253.867a82.517 82.517 0 0 1-82.432-82.347c0-45.483 36.992-82.475 82.432-82.475M834.304 754.645a7.168 7.168 0 0 1-7.168 7.126h-199.04a7.125 7.125 0 0 1-7.125-7.126v-199.04c0-3.925 3.2-7.168 7.125-7.168h199.04c3.968 0 7.168 3.243 7.168 7.168v199.04zm-7.168-248.874h-199.04c-27.477 0-49.792 22.357-49.792 49.834v199.04c0 27.478 22.315 49.792 49.792 49.792h199.04c27.477 0 49.835-22.314 49.835-49.792v-199.04a49.92 49.92 0 0 0-49.835-49.834z" fill="#3C405D"/><path d="M770.304 569.77h-85.333a21.333 21.333 0 0 0 0 42.667h85.333a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default ChannelReturns;
