import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Goodsallocationmonitoring 图标组件
 */
export const Goodsallocationmonitoring = defineComponent({
	name: "Goodsallocationmonitoring",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Goodsallocationmonitoring icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M579.115 810.667a21.333 21.333 0 1 1 0 42.666H263.21a92.63 92.63 0 0 1-92.544-92.501V263.211c0-51.03 41.514-92.544 92.544-92.544h497.578c51.03 0 92.544 41.514 92.544 92.544v269.696a21.333 21.333 0 1 1-42.666 0V263.21a49.92 49.92 0 0 0-49.878-49.878h-78.592v63.574a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.574h-77.653a49.92 49.92 0 0 0-49.878 49.878v497.621a49.92 49.92 0 0 0 49.878 49.835h315.904zM383.53 255.573h256v-42.24h-256v42.24z" fill="#3C405D"/><path d="M659.797 379.99a21.333 21.333 0 0 0-21.333 21.333v36.138a174.933 174.933 0 0 0-126.55-53.76 174.763 174.763 0 0 0-167.68 123.35 21.333 21.333 0 1 0 40.747 12.629 132.267 132.267 0 0 1 126.934-93.312c58.453 0 109.525 37.461 126.933 93.184a21.035 21.035 0 0 0 5.803 8.875l.341.256c1.707 1.621 3.67 2.986 5.845 3.925.982.47 2.091.64 3.158.939 1.365.426 2.688.768 4.096.853.597.043 1.109.341 1.706.341 2.048 0 3.926-.64 5.803-1.152a21.077 21.077 0 0 0 15.53-20.181V401.323a21.333 21.333 0 0 0-21.333-21.334M792.448 810.24H672.981a21.333 21.333 0 0 0 0 42.667h119.467a21.333 21.333 0 0 0 0-42.667m-61.525-183.467a34.133 34.133 0 1 0 0 68.267 34.133 34.133 0 0 0 0-68.267" fill="#3C405D"/><path d="M730.88 759.04a81.152 81.152 0 0 1-80.981-81.067c0-44.672 36.352-81.066 81.024-81.066a80.981 80.981 0 0 1 81.066 81.066c0 44.672-36.352 81.067-81.066 81.067m119.85-112.043A123.733 123.733 0 0 0 730.88 554.24c-58.027 0-106.496 40.32-119.765 94.336-.598.512-1.323.768-1.878 1.323a131.456 131.456 0 0 1-97.322 42.41A133.547 133.547 0 0 1 384 595.84a21.035 21.035 0 0 0-20.053-15.232c-.555-.043-1.11-.341-1.75-.341a21.333 21.333 0 0 0-21.333 21.333v112.043a21.333 21.333 0 0 0 42.667 0v-34.944a175.957 175.957 0 0 0 128.384 56.277c35.242 0 69.461-11.05 98.304-30.507a123.861 123.861 0 0 0 120.704 97.238 123.861 123.861 0 0 0 119.85-154.71" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Goodsallocationmonitoring;
