import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Settlementmanagement 图标组件
 */
export const Settlementmanagement = defineComponent({
	name: "Settlementmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Settlementmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M721.75 658.347a64 64 0 1 0 0 128.085 64 64 0 0 0 0-128.043" fill="#3C405D"/><path d="M721.75 829.013a106.795 106.795 0 0 1-106.667-106.666c0-58.752 47.829-106.667 106.666-106.667s106.667 47.915 106.667 106.667c0 58.837-47.83 106.666-106.667 106.666m0-256c-32.81 0-62.89 11.008-87.552 29.014h-80.554v-60.032h124.842a21.29 21.29 0 1 0 0-42.667H553.643v-23.936l103.68-97.877a21.333 21.333 0 0 0-29.227-31.019l-98.133 92.587-98.176-92.587a21.333 21.333 0 1 0-29.227 31.019l108.416 102.314v19.499H379.819a21.333 21.333 0 0 0 0 42.667h131.157v60.032H379.819a21.333 21.333 0 1 0 0 42.666h131.157v93.739a21.29 21.29 0 1 0 42.667 0v-93.739h41.216a147.499 147.499 0 0 0-22.443 77.654c0 82.346 66.987 149.333 149.333 149.333s149.334-66.987 149.334-149.333-66.987-149.334-149.334-149.334" fill="#3C405D"/><path d="M510.379 852.608c-167.894-11.221-299.392-151.979-299.392-320.47 0-177.109 144.085-321.194 321.194-321.194a319.104 319.104 0 0 1 288.854 180.693c.341.64.853 1.024 1.237 1.622a19.925 19.925 0 0 0 39.637-3.414V271.02a20.139 20.139 0 0 0-40.32 0v45.525a358.23 358.23 0 0 0-289.408-145.92c-199.381.043-361.514 162.261-361.514 361.557 0 189.611 148.053 348.032 337.066 360.662h1.28a20.096 20.096 0 1 0 1.323-40.235" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Settlementmanagement;
