import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Settlementdocmanagement 图标组件
 */
export const Settlementdocmanagement = defineComponent({
	name: "Settlementdocmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Settlementdocmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M488.875 853.333H296.363c-22.23 0-40.363-19.285-40.363-43.008V299.008C256 275.285 274.133 256 296.363 256H403.54A20.65 20.65 0 0 0 416 251.52a21.077 21.077 0 0 0 10.923-14.037 85.76 85.76 0 0 1 83.285-66.816c40.448 0 75.605 28.714 83.627 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256h108.97c22.23 0 40.363 19.285 40.363 43.008V528a21.333 21.333 0 0 0 42.667 0V299.008c0-47.232-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.208 128a128.512 128.512 0 0 0-120.235 85.333h-93.61c-45.782 0-83.03 38.443-83.03 85.675v511.317c0 47.232 37.248 85.675 83.03 85.675h192.512a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M704 682.667a64 64 0 1 0 .043 128 64 64 0 0 0-.043-128" fill="#3C405D"/><path d="M704 853.333a106.795 106.795 0 0 1-106.667-106.666C597.333 687.872 645.163 640 704 640s106.667 47.872 106.667 106.667S762.837 853.333 704 853.333m0-256c-15.616 0-30.379 3.072-44.544 7.552h-124.8v-59.989h124.885a21.333 21.333 0 0 0 0-42.667H534.656v-23.936l103.765-97.877a21.333 21.333 0 0 0-29.269-31.061l-98.133 92.629-98.176-92.63a21.376 21.376 0 0 0-29.27 31.062L491.99 482.688v19.541H360.875a21.333 21.333 0 1 0 0 42.667h131.114v59.99H360.875a21.333 21.333 0 1 0 0 42.666h131.114v93.781a21.333 21.333 0 0 0 42.667 0v-93.781h58.496a148.181 148.181 0 0 0-38.485 99.115C554.667 829.013 621.653 896 704 896s149.333-66.987 149.333-149.333S786.347 597.333 704 597.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Settlementdocmanagement;
