import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Venuemanagement 图标组件
 */
export const Venuemanagement = defineComponent({
	name: "Venuemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Venuemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M253.312 512l.81-4.779L512 345.216l257.621 161.835.726 4.949H253.312zm549.035 38.997a20.95 20.95 0 0 0 13.44-22.442l-5.334-37.334c-.042-.298-.213-.554-.298-.853-.256-1.536-.939-2.944-1.579-4.395-.47-1.109-.768-2.261-1.408-3.242-.853-1.28-1.963-2.262-3.072-3.371-.853-.853-1.579-1.75-2.56-2.432-.341-.256-.512-.597-.853-.81L533.333 308.18V256h87.126a21.333 21.333 0 0 0 0-42.667h-87.126V192a21.333 21.333 0 1 0-42.666 0v116.181l-267.35 167.936c-.17.086-.213.256-.426.384-1.323.896-2.347 2.134-3.456 3.328-.854.854-1.792 1.622-2.475 2.603-.597.939-.896 2.048-1.365 3.115-.683 1.493-1.408 2.944-1.75 4.565l-.213.555-42.965 256.512a106.539 106.539 0 0 0 106.666 106.154h144c5.206 0 9.814-2.09 13.526-5.205A21.29 21.29 0 0 0 448 828.458v-64a64.085 64.085 0 0 1 64-64 21.333 21.333 0 1 0 0-42.666 106.965 106.965 0 0 0-106.667 106.667v46.208h-128c-35.285 0-64-28.502-64.298-59.947L246.1 554.667h545.024a20.48 20.48 0 0 0 11.222-3.67z" fill="#3C405D"/><path d="M704 640a64 64 0 1 0 .043 128.043A64 64 0 0 0 704 640" fill="#3C405D"/><path d="M704 810.667A106.795 106.795 0 0 1 597.333 704c0-58.837 47.872-106.667 106.667-106.667S810.667 645.163 810.667 704 762.795 810.667 704 810.667m0-256c-82.347 0-149.333 66.986-149.333 149.333S621.653 853.333 704 853.333 853.333 786.347 853.333 704 786.347 554.667 704 554.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Venuemanagement;
