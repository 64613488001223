import { AItools } from "@gejia-element-plus/icons-vue/icons/AItools";
export * from "@gejia-element-plus/icons-vue/icons/AItools";
import { Ability } from "@gejia-element-plus/icons-vue/icons/Ability";
export * from "@gejia-element-plus/icons-vue/icons/Ability";
import { About } from "@gejia-element-plus/icons-vue/icons/About";
export * from "@gejia-element-plus/icons-vue/icons/About";
import { Accuracyanalysis } from "@gejia-element-plus/icons-vue/icons/Accuracyanalysis";
export * from "@gejia-element-plus/icons-vue/icons/Accuracyanalysis";
import { Acquisitionprogrammeadministration } from "@gejia-element-plus/icons-vue/icons/Acquisitionprogrammeadministration";
export * from "@gejia-element-plus/icons-vue/icons/Acquisitionprogrammeadministration";
import { Activereview } from "@gejia-element-plus/icons-vue/icons/Activereview";
export * from "@gejia-element-plus/icons-vue/icons/Activereview";
import { Activity } from "@gejia-element-plus/icons-vue/icons/Activity";
export * from "@gejia-element-plus/icons-vue/icons/Activity";
import { Activitycollaboration } from "@gejia-element-plus/icons-vue/icons/Activitycollaboration";
export * from "@gejia-element-plus/icons-vue/icons/Activitycollaboration";
import { Activitymanagement } from "@gejia-element-plus/icons-vue/icons/Activitymanagement";
export * from "@gejia-element-plus/icons-vue/icons/Activitymanagement";
import { Activitymonitoring } from "@gejia-element-plus/icons-vue/icons/Activitymonitoring";
export * from "@gejia-element-plus/icons-vue/icons/Activitymonitoring";
import { Add } from "@gejia-element-plus/icons-vue/icons/Add";
export * from "@gejia-element-plus/icons-vue/icons/Add";
import { Allocated } from "@gejia-element-plus/icons-vue/icons/Allocated";
export * from "@gejia-element-plus/icons-vue/icons/Allocated";
import { Allocationvariance } from "@gejia-element-plus/icons-vue/icons/Allocationvariance";
export * from "@gejia-element-plus/icons-vue/icons/Allocationvariance";
import { Announcement } from "@gejia-element-plus/icons-vue/icons/Announcement";
export * from "@gejia-element-plus/icons-vue/icons/Announcement";
import { Announcementmanagement } from "@gejia-element-plus/icons-vue/icons/Announcementmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Announcementmanagement";
import { Appointment } from "@gejia-element-plus/icons-vue/icons/Appointment";
export * from "@gejia-element-plus/icons-vue/icons/Appointment";
import { Appointmentpurchasemanagement } from "@gejia-element-plus/icons-vue/icons/Appointmentpurchasemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Appointmentpurchasemanagement";
import { Assembly } from "@gejia-element-plus/icons-vue/icons/Assembly";
export * from "@gejia-element-plus/icons-vue/icons/Assembly";
import { Audioandvideo } from "@gejia-element-plus/icons-vue/icons/Audioandvideo";
export * from "@gejia-element-plus/icons-vue/icons/Audioandvideo";
import { Backup } from "@gejia-element-plus/icons-vue/icons/Backup";
export * from "@gejia-element-plus/icons-vue/icons/Backup";
import { Backupandrecovery } from "@gejia-element-plus/icons-vue/icons/Backupandrecovery";
export * from "@gejia-element-plus/icons-vue/icons/Backupandrecovery";
import { Brandauthorization } from "@gejia-element-plus/icons-vue/icons/Brandauthorization";
export * from "@gejia-element-plus/icons-vue/icons/Brandauthorization";
import { Brandauthorizationmanagement } from "@gejia-element-plus/icons-vue/icons/Brandauthorizationmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Brandauthorizationmanagement";
import { Businessanalysis } from "@gejia-element-plus/icons-vue/icons/Businessanalysis";
export * from "@gejia-element-plus/icons-vue/icons/Businessanalysis";
import { Calendar } from "@gejia-element-plus/icons-vue/icons/Calendar";
export * from "@gejia-element-plus/icons-vue/icons/Calendar";
import { Catalogue } from "@gejia-element-plus/icons-vue/icons/Catalogue";
export * from "@gejia-element-plus/icons-vue/icons/Catalogue";
import { Categorymanagement } from "@gejia-element-plus/icons-vue/icons/Categorymanagement";
export * from "@gejia-element-plus/icons-vue/icons/Categorymanagement";
import { Categoryplanning } from "@gejia-element-plus/icons-vue/icons/Categoryplanning";
export * from "@gejia-element-plus/icons-vue/icons/Categoryplanning";
import { Central } from "@gejia-element-plus/icons-vue/icons/Central";
export * from "@gejia-element-plus/icons-vue/icons/Central";
import { ChannelReturns } from "@gejia-element-plus/icons-vue/icons/ChannelReturns";
export * from "@gejia-element-plus/icons-vue/icons/ChannelReturns";
import { Channelinventory } from "@gejia-element-plus/icons-vue/icons/Channelinventory";
export * from "@gejia-element-plus/icons-vue/icons/Channelinventory";
import { Channelinventorymanagement } from "@gejia-element-plus/icons-vue/icons/Channelinventorymanagement";
export * from "@gejia-element-plus/icons-vue/icons/Channelinventorymanagement";
import { Channelmanagement } from "@gejia-element-plus/icons-vue/icons/Channelmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Channelmanagement";
import { Channels } from "@gejia-element-plus/icons-vue/icons/Channels";
export * from "@gejia-element-plus/icons-vue/icons/Channels";
import { Channelsales } from "@gejia-element-plus/icons-vue/icons/Channelsales";
export * from "@gejia-element-plus/icons-vue/icons/Channelsales";
import { ChinaMerchants } from "@gejia-element-plus/icons-vue/icons/ChinaMerchants";
export * from "@gejia-element-plus/icons-vue/icons/ChinaMerchants";
import { Clearance } from "@gejia-element-plus/icons-vue/icons/Clearance";
export * from "@gejia-element-plus/icons-vue/icons/Clearance";
import { Clearancemanagement } from "@gejia-element-plus/icons-vue/icons/Clearancemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Clearancemanagement";
import { Clearanceplan } from "@gejia-element-plus/icons-vue/icons/Clearanceplan";
export * from "@gejia-element-plus/icons-vue/icons/Clearanceplan";
import { Close } from "@gejia-element-plus/icons-vue/icons/Close";
export * from "@gejia-element-plus/icons-vue/icons/Close";
import { Collaborativeplanning } from "@gejia-element-plus/icons-vue/icons/Collaborativeplanning";
export * from "@gejia-element-plus/icons-vue/icons/Collaborativeplanning";
import { Commodityfiling } from "@gejia-element-plus/icons-vue/icons/Commodityfiling";
export * from "@gejia-element-plus/icons-vue/icons/Commodityfiling";
import { Commodityfilingmanagement } from "@gejia-element-plus/icons-vue/icons/Commodityfilingmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Commodityfilingmanagement";
import { Commoditymanagement } from "@gejia-element-plus/icons-vue/icons/Commoditymanagement";
export * from "@gejia-element-plus/icons-vue/icons/Commoditymanagement";
import { Complete } from "@gejia-element-plus/icons-vue/icons/Complete";
export * from "@gejia-element-plus/icons-vue/icons/Complete";
import { Completionstatement } from "@gejia-element-plus/icons-vue/icons/Completionstatement";
export * from "@gejia-element-plus/icons-vue/icons/Completionstatement";
import { Configuration } from "@gejia-element-plus/icons-vue/icons/Configuration";
export * from "@gejia-element-plus/icons-vue/icons/Configuration";
import { Configurationmanagement } from "@gejia-element-plus/icons-vue/icons/Configurationmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Configurationmanagement";
import { Contract } from "@gejia-element-plus/icons-vue/icons/Contract";
export * from "@gejia-element-plus/icons-vue/icons/Contract";
import { Contractmanagement } from "@gejia-element-plus/icons-vue/icons/Contractmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Contractmanagement";
import { Costquery } from "@gejia-element-plus/icons-vue/icons/Costquery";
export * from "@gejia-element-plus/icons-vue/icons/Costquery";
import { Coupons } from "@gejia-element-plus/icons-vue/icons/Coupons";
export * from "@gejia-element-plus/icons-vue/icons/Coupons";
import { Crowd } from "@gejia-element-plus/icons-vue/icons/Crowd";
export * from "@gejia-element-plus/icons-vue/icons/Crowd";
import { Crowdmanagement } from "@gejia-element-plus/icons-vue/icons/Crowdmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Crowdmanagement";
import { Customicon } from "@gejia-element-plus/icons-vue/icons/Customicon";
export * from "@gejia-element-plus/icons-vue/icons/Customicon";
import { Dark } from "@gejia-element-plus/icons-vue/icons/Dark";
export * from "@gejia-element-plus/icons-vue/icons/Dark";
import { Data } from "@gejia-element-plus/icons-vue/icons/Data";
export * from "@gejia-element-plus/icons-vue/icons/Data";
import { Dataanalysis } from "@gejia-element-plus/icons-vue/icons/Dataanalysis";
export * from "@gejia-element-plus/icons-vue/icons/Dataanalysis";
import { Datamanagement } from "@gejia-element-plus/icons-vue/icons/Datamanagement";
export * from "@gejia-element-plus/icons-vue/icons/Datamanagement";
import { Datariskcontrolandearlywarningplatform } from "@gejia-element-plus/icons-vue/icons/Datariskcontrolandearlywarningplatform";
export * from "@gejia-element-plus/icons-vue/icons/Datariskcontrolandearlywarningplatform";
import { Design } from "@gejia-element-plus/icons-vue/icons/Design";
export * from "@gejia-element-plus/icons-vue/icons/Design";
import { Dialogue } from "@gejia-element-plus/icons-vue/icons/Dialogue";
export * from "@gejia-element-plus/icons-vue/icons/Dialogue";
import { Diskormanagement } from "@gejia-element-plus/icons-vue/icons/Diskormanagement";
export * from "@gejia-element-plus/icons-vue/icons/Diskormanagement";
import { Distribution } from "@gejia-element-plus/icons-vue/icons/Distribution";
export * from "@gejia-element-plus/icons-vue/icons/Distribution";
import { Distributionofgoodsthroughchannels } from "@gejia-element-plus/icons-vue/icons/Distributionofgoodsthroughchannels";
export * from "@gejia-element-plus/icons-vue/icons/Distributionofgoodsthroughchannels";
import { Distributor } from "@gejia-element-plus/icons-vue/icons/Distributor";
export * from "@gejia-element-plus/icons-vue/icons/Distributor";
import { Distributorsettlement } from "@gejia-element-plus/icons-vue/icons/Distributorsettlement";
export * from "@gejia-element-plus/icons-vue/icons/Distributorsettlement";
import { Docmanagement } from "@gejia-element-plus/icons-vue/icons/Docmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Docmanagement";
import { Doubt } from "@gejia-element-plus/icons-vue/icons/Doubt";
export * from "@gejia-element-plus/icons-vue/icons/Doubt";
import { DropDown } from "@gejia-element-plus/icons-vue/icons/DropDown";
export * from "@gejia-element-plus/icons-vue/icons/DropDown";
import { Earnfun } from "@gejia-element-plus/icons-vue/icons/Earnfun";
export * from "@gejia-element-plus/icons-vue/icons/Earnfun";
import { Equityissuancetool } from "@gejia-element-plus/icons-vue/icons/Equityissuancetool";
export * from "@gejia-element-plus/icons-vue/icons/Equityissuancetool";
import { Exitmanagement } from "@gejia-element-plus/icons-vue/icons/Exitmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Exitmanagement";
import { Exitplan } from "@gejia-element-plus/icons-vue/icons/Exitplan";
export * from "@gejia-element-plus/icons-vue/icons/Exitplan";
import { Explosiveproducts } from "@gejia-element-plus/icons-vue/icons/Explosiveproducts";
export * from "@gejia-element-plus/icons-vue/icons/Explosiveproducts";
import { Financialanalysis } from "@gejia-element-plus/icons-vue/icons/Financialanalysis";
export * from "@gejia-element-plus/icons-vue/icons/Financialanalysis";
import { FirstScheduleManagement } from "@gejia-element-plus/icons-vue/icons/FirstScheduleManagement";
export * from "@gejia-element-plus/icons-vue/icons/FirstScheduleManagement";
import { Flow } from "@gejia-element-plus/icons-vue/icons/Flow";
export * from "@gejia-element-plus/icons-vue/icons/Flow";
import { Forward } from "@gejia-element-plus/icons-vue/icons/Forward";
export * from "@gejia-element-plus/icons-vue/icons/Forward";
import { FullScreen } from "@gejia-element-plus/icons-vue/icons/FullScreen";
export * from "@gejia-element-plus/icons-vue/icons/FullScreen";
import { FullScreenExit } from "@gejia-element-plus/icons-vue/icons/FullScreenExit";
export * from "@gejia-element-plus/icons-vue/icons/FullScreenExit";
import { Fulllinkavailableforsale } from "@gejia-element-plus/icons-vue/icons/Fulllinkavailableforsale";
export * from "@gejia-element-plus/icons-vue/icons/Fulllinkavailableforsale";
import { GMVtarget } from "@gejia-element-plus/icons-vue/icons/GMVtarget";
export * from "@gejia-element-plus/icons-vue/icons/GMVtarget";
import { GoalSetting } from "@gejia-element-plus/icons-vue/icons/GoalSetting";
export * from "@gejia-element-plus/icons-vue/icons/GoalSetting";
import { Goods } from "@gejia-element-plus/icons-vue/icons/Goods";
export * from "@gejia-element-plus/icons-vue/icons/Goods";
import { Goodsallocation } from "@gejia-element-plus/icons-vue/icons/Goodsallocation";
export * from "@gejia-element-plus/icons-vue/icons/Goodsallocation";
import { Goodsallocationmanagement } from "@gejia-element-plus/icons-vue/icons/Goodsallocationmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Goodsallocationmanagement";
import { Goodsallocationmonitoring } from "@gejia-element-plus/icons-vue/icons/Goodsallocationmonitoring";
export * from "@gejia-element-plus/icons-vue/icons/Goodsallocationmonitoring";
import { Goodsselection } from "@gejia-element-plus/icons-vue/icons/Goodsselection";
export * from "@gejia-element-plus/icons-vue/icons/Goodsselection";
import { GrossprofitofbackEndcommodities } from "@gejia-element-plus/icons-vue/icons/GrossprofitofbackEndcommodities";
export * from "@gejia-element-plus/icons-vue/icons/GrossprofitofbackEndcommodities";
import { GrossprofitoffrontEndproducts } from "@gejia-element-plus/icons-vue/icons/GrossprofitoffrontEndproducts";
export * from "@gejia-element-plus/icons-vue/icons/GrossprofitoffrontEndproducts";
import { Health } from "@gejia-element-plus/icons-vue/icons/Health";
export * from "@gejia-element-plus/icons-vue/icons/Health";
import { Homepage } from "@gejia-element-plus/icons-vue/icons/Homepage";
export * from "@gejia-element-plus/icons-vue/icons/Homepage";
import { Icon } from "@gejia-element-plus/icons-vue/icons/Icon";
export * from "@gejia-element-plus/icons-vue/icons/Icon";
import { InTransitreport } from "@gejia-element-plus/icons-vue/icons/InTransitreport";
export * from "@gejia-element-plus/icons-vue/icons/InTransitreport";
import { InTransitreportmanagement } from "@gejia-element-plus/icons-vue/icons/InTransitreportmanagement";
export * from "@gejia-element-plus/icons-vue/icons/InTransitreportmanagement";
import { Inandout } from "@gejia-element-plus/icons-vue/icons/Inandout";
export * from "@gejia-element-plus/icons-vue/icons/Inandout";
import { Index } from "@gejia-element-plus/icons-vue/icons/Index";
export * from "@gejia-element-plus/icons-vue/icons/Index";
import { InputSupplement } from "@gejia-element-plus/icons-vue/icons/InputSupplement";
export * from "@gejia-element-plus/icons-vue/icons/InputSupplement";
import { Inquiriesmanagement } from "@gejia-element-plus/icons-vue/icons/Inquiriesmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Inquiriesmanagement";
import { Inquiry } from "@gejia-element-plus/icons-vue/icons/Inquiry";
export * from "@gejia-element-plus/icons-vue/icons/Inquiry";
import { Intelligentselection } from "@gejia-element-plus/icons-vue/icons/Intelligentselection";
export * from "@gejia-element-plus/icons-vue/icons/Intelligentselection";
import { Inventory } from "@gejia-element-plus/icons-vue/icons/Inventory";
export * from "@gejia-element-plus/icons-vue/icons/Inventory";
import { Inventoryassurancemanagement } from "@gejia-element-plus/icons-vue/icons/Inventoryassurancemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Inventoryassurancemanagement";
import { Inventoryguarantee } from "@gejia-element-plus/icons-vue/icons/Inventoryguarantee";
export * from "@gejia-element-plus/icons-vue/icons/Inventoryguarantee";
import { Inventoryhealth } from "@gejia-element-plus/icons-vue/icons/Inventoryhealth";
export * from "@gejia-element-plus/icons-vue/icons/Inventoryhealth";
import { Inventoryhealthindex } from "@gejia-element-plus/icons-vue/icons/Inventoryhealthindex";
export * from "@gejia-element-plus/icons-vue/icons/Inventoryhealthindex";
import { Inventoryhealthmanagement } from "@gejia-element-plus/icons-vue/icons/Inventoryhealthmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Inventoryhealthmanagement";
import { Inventorymanagement } from "@gejia-element-plus/icons-vue/icons/Inventorymanagement";
export * from "@gejia-element-plus/icons-vue/icons/Inventorymanagement";
import { Inventorysystem } from "@gejia-element-plus/icons-vue/icons/Inventorysystem";
export * from "@gejia-element-plus/icons-vue/icons/Inventorysystem";
import { Investmentpromotionmanagement } from "@gejia-element-plus/icons-vue/icons/Investmentpromotionmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Investmentpromotionmanagement";
import { Jobauthorizationmanagement } from "@gejia-element-plus/icons-vue/icons/Jobauthorizationmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Jobauthorizationmanagement";
import { Jumpdirectly } from "@gejia-element-plus/icons-vue/icons/Jumpdirectly";
export * from "@gejia-element-plus/icons-vue/icons/Jumpdirectly";
import { Less } from "@gejia-element-plus/icons-vue/icons/Less";
export * from "@gejia-element-plus/icons-vue/icons/Less";
import { Liangjipin } from "@gejia-element-plus/icons-vue/icons/Liangjipin";
export * from "@gejia-element-plus/icons-vue/icons/Liangjipin";
import { Lifecycle } from "@gejia-element-plus/icons-vue/icons/Lifecycle";
export * from "@gejia-element-plus/icons-vue/icons/Lifecycle";
import { Lifecyclemanagement } from "@gejia-element-plus/icons-vue/icons/Lifecyclemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Lifecyclemanagement";
import { Light } from "@gejia-element-plus/icons-vue/icons/Light";
export * from "@gejia-element-plus/icons-vue/icons/Light";
import { Link } from "@gejia-element-plus/icons-vue/icons/Link";
export * from "@gejia-element-plus/icons-vue/icons/Link";
import { List } from "@gejia-element-plus/icons-vue/icons/List";
export * from "@gejia-element-plus/icons-vue/icons/List";
import { Lock } from "@gejia-element-plus/icons-vue/icons/Lock";
export * from "@gejia-element-plus/icons-vue/icons/Lock";
import { Logistics } from "@gejia-element-plus/icons-vue/icons/Logistics";
export * from "@gejia-element-plus/icons-vue/icons/Logistics";
import { ManagementofthecargoinOut } from "@gejia-element-plus/icons-vue/icons/ManagementofthecargoinOut";
export * from "@gejia-element-plus/icons-vue/icons/ManagementofthecargoinOut";
import { Managementplan } from "@gejia-element-plus/icons-vue/icons/Managementplan";
export * from "@gejia-element-plus/icons-vue/icons/Managementplan";
import { Marketingtrafficconfiguration } from "@gejia-element-plus/icons-vue/icons/Marketingtrafficconfiguration";
export * from "@gejia-element-plus/icons-vue/icons/Marketingtrafficconfiguration";
import { MaterialCenter } from "@gejia-element-plus/icons-vue/icons/MaterialCenter";
export * from "@gejia-element-plus/icons-vue/icons/MaterialCenter";
import { Materiallibrary } from "@gejia-element-plus/icons-vue/icons/Materiallibrary";
export * from "@gejia-element-plus/icons-vue/icons/Materiallibrary";
import { Menu } from "@gejia-element-plus/icons-vue/icons/Menu";
export * from "@gejia-element-plus/icons-vue/icons/Menu";
import { Merchandise } from "@gejia-element-plus/icons-vue/icons/Merchandise";
export * from "@gejia-element-plus/icons-vue/icons/Merchandise";
import { Merchandiseplanning } from "@gejia-element-plus/icons-vue/icons/Merchandiseplanning";
export * from "@gejia-element-plus/icons-vue/icons/Merchandiseplanning";
import { Merchantdata } from "@gejia-element-plus/icons-vue/icons/Merchantdata";
export * from "@gejia-element-plus/icons-vue/icons/Merchantdata";
import { Merchantmanagement } from "@gejia-element-plus/icons-vue/icons/Merchantmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Merchantmanagement";
import { Merchants } from "@gejia-element-plus/icons-vue/icons/Merchants";
export * from "@gejia-element-plus/icons-vue/icons/Merchants";
import { Message } from "@gejia-element-plus/icons-vue/icons/Message";
export * from "@gejia-element-plus/icons-vue/icons/Message";
import { Minigames } from "@gejia-element-plus/icons-vue/icons/Minigames";
export * from "@gejia-element-plus/icons-vue/icons/Minigames";
import { Minus } from "@gejia-element-plus/icons-vue/icons/Minus";
export * from "@gejia-element-plus/icons-vue/icons/Minus";
import { Monitorandcontrol } from "@gejia-element-plus/icons-vue/icons/Monitorandcontrol";
export * from "@gejia-element-plus/icons-vue/icons/Monitorandcontrol";
import { Monthlyclosing } from "@gejia-element-plus/icons-vue/icons/Monthlyclosing";
export * from "@gejia-element-plus/icons-vue/icons/Monthlyclosing";
import { Monthlyclosingmanagement } from "@gejia-element-plus/icons-vue/icons/Monthlyclosingmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Monthlyclosingmanagement";
import { MultiDimensionalKanban } from "@gejia-element-plus/icons-vue/icons/MultiDimensionalKanban";
export * from "@gejia-element-plus/icons-vue/icons/MultiDimensionalKanban";
import { Newproductlaunch } from "@gejia-element-plus/icons-vue/icons/Newproductlaunch";
export * from "@gejia-element-plus/icons-vue/icons/Newproductlaunch";
import { NotData } from "@gejia-element-plus/icons-vue/icons/NotData";
export * from "@gejia-element-plus/icons-vue/icons/NotData";
import { Operation } from "@gejia-element-plus/icons-vue/icons/Operation";
export * from "@gejia-element-plus/icons-vue/icons/Operation";
import { Operationhub } from "@gejia-element-plus/icons-vue/icons/Operationhub";
export * from "@gejia-element-plus/icons-vue/icons/Operationhub";
import { Operationmanagement } from "@gejia-element-plus/icons-vue/icons/Operationmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Operationmanagement";
import { Operationtools } from "@gejia-element-plus/icons-vue/icons/Operationtools";
export * from "@gejia-element-plus/icons-vue/icons/Operationtools";
import { Optimizationplan } from "@gejia-element-plus/icons-vue/icons/Optimizationplan";
export * from "@gejia-element-plus/icons-vue/icons/Optimizationplan";
import { Orderfulfillmentsignage } from "@gejia-element-plus/icons-vue/icons/Orderfulfillmentsignage";
export * from "@gejia-element-plus/icons-vue/icons/Orderfulfillmentsignage";
import { Page403 } from "@gejia-element-plus/icons-vue/icons/Page403";
export * from "@gejia-element-plus/icons-vue/icons/Page403";
import { Page404 } from "@gejia-element-plus/icons-vue/icons/Page404";
export * from "@gejia-element-plus/icons-vue/icons/Page404";
import { Panoramaoftheevent } from "@gejia-element-plus/icons-vue/icons/Panoramaoftheevent";
export * from "@gejia-element-plus/icons-vue/icons/Panoramaoftheevent";
import { Performance } from "@gejia-element-plus/icons-vue/icons/Performance";
export * from "@gejia-element-plus/icons-vue/icons/Performance";
import { Performancemanagement } from "@gejia-element-plus/icons-vue/icons/Performancemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Performancemanagement";
import { Picture } from "@gejia-element-plus/icons-vue/icons/Picture";
export * from "@gejia-element-plus/icons-vue/icons/Picture";
import { Plateofgoods } from "@gejia-element-plus/icons-vue/icons/Plateofgoods";
export * from "@gejia-element-plus/icons-vue/icons/Plateofgoods";
import { Platform } from "@gejia-element-plus/icons-vue/icons/Platform";
export * from "@gejia-element-plus/icons-vue/icons/Platform";
import { Positioning } from "@gejia-element-plus/icons-vue/icons/Positioning";
export * from "@gejia-element-plus/icons-vue/icons/Positioning";
import { Postauthorization } from "@gejia-element-plus/icons-vue/icons/Postauthorization";
export * from "@gejia-element-plus/icons-vue/icons/Postauthorization";
import { Price } from "@gejia-element-plus/icons-vue/icons/Price";
export * from "@gejia-element-plus/icons-vue/icons/Price";
import { Pricing } from "@gejia-element-plus/icons-vue/icons/Pricing";
export * from "@gejia-element-plus/icons-vue/icons/Pricing";
import { Pricingmanagement } from "@gejia-element-plus/icons-vue/icons/Pricingmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Pricingmanagement";
import { Procurementplan } from "@gejia-element-plus/icons-vue/icons/Procurementplan";
export * from "@gejia-element-plus/icons-vue/icons/Procurementplan";
import { Productcopying } from "@gejia-element-plus/icons-vue/icons/Productcopying";
export * from "@gejia-element-plus/icons-vue/icons/Productcopying";
import { Productcopymanagement } from "@gejia-element-plus/icons-vue/icons/Productcopymanagement";
export * from "@gejia-element-plus/icons-vue/icons/Productcopymanagement";
import { Productreleasemanagement } from "@gejia-element-plus/icons-vue/icons/Productreleasemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Productreleasemanagement";
import { Productstratification } from "@gejia-element-plus/icons-vue/icons/Productstratification";
export * from "@gejia-element-plus/icons-vue/icons/Productstratification";
import { Purchase } from "@gejia-element-plus/icons-vue/icons/Purchase";
export * from "@gejia-element-plus/icons-vue/icons/Purchase";
import { PurchaseOrderManagement } from "@gejia-element-plus/icons-vue/icons/PurchaseOrderManagement";
export * from "@gejia-element-plus/icons-vue/icons/PurchaseOrderManagement";
import { Purchasebyappointment } from "@gejia-element-plus/icons-vue/icons/Purchasebyappointment";
export * from "@gejia-element-plus/icons-vue/icons/Purchasebyappointment";
import { Purchasemanagement } from "@gejia-element-plus/icons-vue/icons/Purchasemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Purchasemanagement";
import { Purchaseorder } from "@gejia-element-plus/icons-vue/icons/Purchaseorder";
export * from "@gejia-element-plus/icons-vue/icons/Purchaseorder";
import { Purchaseprice } from "@gejia-element-plus/icons-vue/icons/Purchaseprice";
export * from "@gejia-element-plus/icons-vue/icons/Purchaseprice";
import { Purchasepricemanagement } from "@gejia-element-plus/icons-vue/icons/Purchasepricemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Purchasepricemanagement";
import { Purchasingmanagement } from "@gejia-element-plus/icons-vue/icons/Purchasingmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Purchasingmanagement";
import { Purchasingstaff } from "@gejia-element-plus/icons-vue/icons/Purchasingstaff";
export * from "@gejia-element-plus/icons-vue/icons/Purchasingstaff";
import { Putitaway } from "@gejia-element-plus/icons-vue/icons/Putitaway";
export * from "@gejia-element-plus/icons-vue/icons/Putitaway";
import { Qualitycontrol } from "@gejia-element-plus/icons-vue/icons/Qualitycontrol";
export * from "@gejia-element-plus/icons-vue/icons/Qualitycontrol";
import { Qualitycontrolmanagement } from "@gejia-element-plus/icons-vue/icons/Qualitycontrolmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Qualitycontrolmanagement";
import { RealTimeKanban } from "@gejia-element-plus/icons-vue/icons/RealTimeKanban";
export * from "@gejia-element-plus/icons-vue/icons/RealTimeKanban";
import { RealTimeprediction } from "@gejia-element-plus/icons-vue/icons/RealTimeprediction";
export * from "@gejia-element-plus/icons-vue/icons/RealTimeprediction";
import { RebateRuleManagement } from "@gejia-element-plus/icons-vue/icons/RebateRuleManagement";
export * from "@gejia-element-plus/icons-vue/icons/RebateRuleManagement";
import { Rebaterules } from "@gejia-element-plus/icons-vue/icons/Rebaterules";
export * from "@gejia-element-plus/icons-vue/icons/Rebaterules";
import { Refused } from "@gejia-element-plus/icons-vue/icons/Refused";
export * from "@gejia-element-plus/icons-vue/icons/Refused";
import { Replenishment } from "@gejia-element-plus/icons-vue/icons/Replenishment";
export * from "@gejia-element-plus/icons-vue/icons/Replenishment";
import { Replenishmentmanagement } from "@gejia-element-plus/icons-vue/icons/Replenishmentmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Replenishmentmanagement";
import { Replenishmentplan } from "@gejia-element-plus/icons-vue/icons/Replenishmentplan";
export * from "@gejia-element-plus/icons-vue/icons/Replenishmentplan";
import { Reportmanagement } from "@gejia-element-plus/icons-vue/icons/Reportmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Reportmanagement";
import { Requisitioningmanagement } from "@gejia-element-plus/icons-vue/icons/Requisitioningmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Requisitioningmanagement";
import { Resaleprogram } from "@gejia-element-plus/icons-vue/icons/Resaleprogram";
export * from "@gejia-element-plus/icons-vue/icons/Resaleprogram";
import { Resourceordering } from "@gejia-element-plus/icons-vue/icons/Resourceordering";
export * from "@gejia-element-plus/icons-vue/icons/Resourceordering";
import { Resources } from "@gejia-element-plus/icons-vue/icons/Resources";
export * from "@gejia-element-plus/icons-vue/icons/Resources";
import { Resourcesubscriptionmanagement } from "@gejia-element-plus/icons-vue/icons/Resourcesubscriptionmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Resourcesubscriptionmanagement";
import { Retirementplan } from "@gejia-element-plus/icons-vue/icons/Retirementplan";
export * from "@gejia-element-plus/icons-vue/icons/Retirementplan";
import { ReturnOrderManagement } from "@gejia-element-plus/icons-vue/icons/ReturnOrderManagement";
export * from "@gejia-element-plus/icons-vue/icons/ReturnOrderManagement";
import { Returnorder } from "@gejia-element-plus/icons-vue/icons/Returnorder";
export * from "@gejia-element-plus/icons-vue/icons/Returnorder";
import { Returnwarehousereceipt } from "@gejia-element-plus/icons-vue/icons/Returnwarehousereceipt";
export * from "@gejia-element-plus/icons-vue/icons/Returnwarehousereceipt";
import { Reverse } from "@gejia-element-plus/icons-vue/icons/Reverse";
export * from "@gejia-element-plus/icons-vue/icons/Reverse";
import { Rightsandinterests } from "@gejia-element-plus/icons-vue/icons/Rightsandinterests";
export * from "@gejia-element-plus/icons-vue/icons/Rightsandinterests";
import { Rightsmanagement } from "@gejia-element-plus/icons-vue/icons/Rightsmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Rightsmanagement";
import { Riskcontrol } from "@gejia-element-plus/icons-vue/icons/Riskcontrol";
export * from "@gejia-element-plus/icons-vue/icons/Riskcontrol";
import { Rookieoperatingindicators } from "@gejia-element-plus/icons-vue/icons/Rookieoperatingindicators";
export * from "@gejia-element-plus/icons-vue/icons/Rookieoperatingindicators";
import { Routingwarehouse } from "@gejia-element-plus/icons-vue/icons/Routingwarehouse";
export * from "@gejia-element-plus/icons-vue/icons/Routingwarehouse";
import { Routingwarehousemanagement } from "@gejia-element-plus/icons-vue/icons/Routingwarehousemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Routingwarehousemanagement";
import { Safety } from "@gejia-element-plus/icons-vue/icons/Safety";
export * from "@gejia-element-plus/icons-vue/icons/Safety";
import { SalePlan } from "@gejia-element-plus/icons-vue/icons/SalePlan";
export * from "@gejia-element-plus/icons-vue/icons/SalePlan";
import { Sales } from "@gejia-element-plus/icons-vue/icons/Sales";
export * from "@gejia-element-plus/icons-vue/icons/Sales";
import { Salesandinventoryreport } from "@gejia-element-plus/icons-vue/icons/Salesandinventoryreport";
export * from "@gejia-element-plus/icons-vue/icons/Salesandinventoryreport";
import { Salesandinventoryreportmanagement } from "@gejia-element-plus/icons-vue/icons/Salesandinventoryreportmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Salesandinventoryreportmanagement";
import { Salesforecast } from "@gejia-element-plus/icons-vue/icons/Salesforecast";
export * from "@gejia-element-plus/icons-vue/icons/Salesforecast";
import { Salesmanagement } from "@gejia-element-plus/icons-vue/icons/Salesmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Salesmanagement";
import { Salesplan } from "@gejia-element-plus/icons-vue/icons/Salesplan";
export * from "@gejia-element-plus/icons-vue/icons/Salesplan";
import { SalesrealTimeKanban } from "@gejia-element-plus/icons-vue/icons/SalesrealTimeKanban";
export * from "@gejia-element-plus/icons-vue/icons/SalesrealTimeKanban";
import { Scheduling } from "@gejia-element-plus/icons-vue/icons/Scheduling";
export * from "@gejia-element-plus/icons-vue/icons/Scheduling";
import { Schedulingmanagement } from "@gejia-element-plus/icons-vue/icons/Schedulingmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Schedulingmanagement";
import { ScrapOrderManagement } from "@gejia-element-plus/icons-vue/icons/ScrapOrderManagement";
export * from "@gejia-element-plus/icons-vue/icons/ScrapOrderManagement";
import { Scrapdoc } from "@gejia-element-plus/icons-vue/icons/Scrapdoc";
export * from "@gejia-element-plus/icons-vue/icons/Scrapdoc";
import { Search } from "@gejia-element-plus/icons-vue/icons/Search";
export * from "@gejia-element-plus/icons-vue/icons/Search";
import { Searchbox } from "@gejia-element-plus/icons-vue/icons/Searchbox";
export * from "@gejia-element-plus/icons-vue/icons/Searchbox";
import { Selection } from "@gejia-element-plus/icons-vue/icons/Selection";
export * from "@gejia-element-plus/icons-vue/icons/Selection";
import { Selectionsystem } from "@gejia-element-plus/icons-vue/icons/Selectionsystem";
export * from "@gejia-element-plus/icons-vue/icons/Selectionsystem";
import { Service } from "@gejia-element-plus/icons-vue/icons/Service";
export * from "@gejia-element-plus/icons-vue/icons/Service";
import { SettingsManage } from "@gejia-element-plus/icons-vue/icons/SettingsManage";
export * from "@gejia-element-plus/icons-vue/icons/SettingsManage";
import { Settlement } from "@gejia-element-plus/icons-vue/icons/Settlement";
export * from "@gejia-element-plus/icons-vue/icons/Settlement";
import { Settlementdoc } from "@gejia-element-plus/icons-vue/icons/Settlementdoc";
export * from "@gejia-element-plus/icons-vue/icons/Settlementdoc";
import { Settlementdocmanagement } from "@gejia-element-plus/icons-vue/icons/Settlementdocmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Settlementdocmanagement";
import { Settlementefficiencyanalysis } from "@gejia-element-plus/icons-vue/icons/Settlementefficiencyanalysis";
export * from "@gejia-element-plus/icons-vue/icons/Settlementefficiencyanalysis";
import { Settlementmanagement } from "@gejia-element-plus/icons-vue/icons/Settlementmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Settlementmanagement";
import { Settlementtoolbox } from "@gejia-element-plus/icons-vue/icons/Settlementtoolbox";
export * from "@gejia-element-plus/icons-vue/icons/Settlementtoolbox";
import { Settlementwithsuppliers } from "@gejia-element-plus/icons-vue/icons/Settlementwithsuppliers";
export * from "@gejia-element-plus/icons-vue/icons/Settlementwithsuppliers";
import { Shopping } from "@gejia-element-plus/icons-vue/icons/Shopping";
export * from "@gejia-element-plus/icons-vue/icons/Shopping";
import { Shortcutbar } from "@gejia-element-plus/icons-vue/icons/Shortcutbar";
export * from "@gejia-element-plus/icons-vue/icons/Shortcutbar";
import { Singlewarehouseissuednationwide } from "@gejia-element-plus/icons-vue/icons/Singlewarehouseissuednationwide";
export * from "@gejia-element-plus/icons-vue/icons/Singlewarehouseissuednationwide";
import { SmartMarketingManagement } from "@gejia-element-plus/icons-vue/icons/SmartMarketingManagement";
export * from "@gejia-element-plus/icons-vue/icons/SmartMarketingManagement";
import { Smartmarketing } from "@gejia-element-plus/icons-vue/icons/Smartmarketing";
export * from "@gejia-element-plus/icons-vue/icons/Smartmarketing";
import { Smartsales } from "@gejia-element-plus/icons-vue/icons/Smartsales";
export * from "@gejia-element-plus/icons-vue/icons/Smartsales";
import { Smartsalesmanagement } from "@gejia-element-plus/icons-vue/icons/Smartsalesmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Smartsalesmanagement";
import { StickerPlan } from "@gejia-element-plus/icons-vue/icons/StickerPlan";
export * from "@gejia-element-plus/icons-vue/icons/StickerPlan";
import { StockInManage } from "@gejia-element-plus/icons-vue/icons/StockInManage";
export * from "@gejia-element-plus/icons-vue/icons/StockInManage";
import { Stocking } from "@gejia-element-plus/icons-vue/icons/Stocking";
export * from "@gejia-element-plus/icons-vue/icons/Stocking";
import { Stockmanagement } from "@gejia-element-plus/icons-vue/icons/Stockmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Stockmanagement";
import { Structure } from "@gejia-element-plus/icons-vue/icons/Structure";
export * from "@gejia-element-plus/icons-vue/icons/Structure";
import { SuperService } from "@gejia-element-plus/icons-vue/icons/SuperService";
export * from "@gejia-element-plus/icons-vue/icons/SuperService";
import { Supplier } from "@gejia-element-plus/icons-vue/icons/Supplier";
export * from "@gejia-element-plus/icons-vue/icons/Supplier";
import { Suppliermanagement } from "@gejia-element-plus/icons-vue/icons/Suppliermanagement";
export * from "@gejia-element-plus/icons-vue/icons/Suppliermanagement";
import { Synergy } from "@gejia-element-plus/icons-vue/icons/Synergy";
export * from "@gejia-element-plus/icons-vue/icons/Synergy";
import { Target } from "@gejia-element-plus/icons-vue/icons/Target";
export * from "@gejia-element-plus/icons-vue/icons/Target";
import { Targetmanagement } from "@gejia-element-plus/icons-vue/icons/Targetmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Targetmanagement";
import { Targetmonitoring } from "@gejia-element-plus/icons-vue/icons/Targetmonitoring";
export * from "@gejia-element-plus/icons-vue/icons/Targetmonitoring";
import { Theme } from "@gejia-element-plus/icons-vue/icons/Theme";
export * from "@gejia-element-plus/icons-vue/icons/Theme";
import { Timedate } from "@gejia-element-plus/icons-vue/icons/Timedate";
export * from "@gejia-element-plus/icons-vue/icons/Timedate";
import { Tools } from "@gejia-element-plus/icons-vue/icons/Tools";
export * from "@gejia-element-plus/icons-vue/icons/Tools";
import { Topickupthegoods } from "@gejia-element-plus/icons-vue/icons/Topickupthegoods";
export * from "@gejia-element-plus/icons-vue/icons/Topickupthegoods";
import { Touchenglist } from "@gejia-element-plus/icons-vue/icons/Touchenglist";
export * from "@gejia-element-plus/icons-vue/icons/Touchenglist";
import { TransferDocManagement } from "@gejia-element-plus/icons-vue/icons/TransferDocManagement";
export * from "@gejia-element-plus/icons-vue/icons/TransferDocManagement";
import { Transferdoc } from "@gejia-element-plus/icons-vue/icons/Transferdoc";
export * from "@gejia-element-plus/icons-vue/icons/Transferdoc";
import { Transferexecution } from "@gejia-element-plus/icons-vue/icons/Transferexecution";
export * from "@gejia-element-plus/icons-vue/icons/Transferexecution";
import { Unlock } from "@gejia-element-plus/icons-vue/icons/Unlock";
export * from "@gejia-element-plus/icons-vue/icons/Unlock";
import { Upload } from "@gejia-element-plus/icons-vue/icons/Upload";
export * from "@gejia-element-plus/icons-vue/icons/Upload";
import { User } from "@gejia-element-plus/icons-vue/icons/User";
export * from "@gejia-element-plus/icons-vue/icons/User";
import { Usermanagement } from "@gejia-element-plus/icons-vue/icons/Usermanagement";
export * from "@gejia-element-plus/icons-vue/icons/Usermanagement";
import { ValueAddedservice } from "@gejia-element-plus/icons-vue/icons/ValueAddedservice";
export * from "@gejia-element-plus/icons-vue/icons/ValueAddedservice";
import { Valueassessment } from "@gejia-element-plus/icons-vue/icons/Valueassessment";
export * from "@gejia-element-plus/icons-vue/icons/Valueassessment";
import { Venue } from "@gejia-element-plus/icons-vue/icons/Venue";
export * from "@gejia-element-plus/icons-vue/icons/Venue";
import { Venuemanagement } from "@gejia-element-plus/icons-vue/icons/Venuemanagement";
export * from "@gejia-element-plus/icons-vue/icons/Venuemanagement";
import { Wallpaper } from "@gejia-element-plus/icons-vue/icons/Wallpaper";
export * from "@gejia-element-plus/icons-vue/icons/Wallpaper";
import { Warehouse } from "@gejia-element-plus/icons-vue/icons/Warehouse";
export * from "@gejia-element-plus/icons-vue/icons/Warehouse";
import { WarehouseReturnManagement } from "@gejia-element-plus/icons-vue/icons/WarehouseReturnManagement";
export * from "@gejia-element-plus/icons-vue/icons/WarehouseReturnManagement";
import { Warehousehealthindexmanagement } from "@gejia-element-plus/icons-vue/icons/Warehousehealthindexmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Warehousehealthindexmanagement";
import { Warehousing } from "@gejia-element-plus/icons-vue/icons/Warehousing";
export * from "@gejia-element-plus/icons-vue/icons/Warehousing";
import { Warehousingmanagement } from "@gejia-element-plus/icons-vue/icons/Warehousingmanagement";
export * from "@gejia-element-plus/icons-vue/icons/Warehousingmanagement";
import { Website } from "@gejia-element-plus/icons-vue/icons/Website";
export * from "@gejia-element-plus/icons-vue/icons/Website";
import { Wisdom } from "@gejia-element-plus/icons-vue/icons/Wisdom";
export * from "@gejia-element-plus/icons-vue/icons/Wisdom";
import { Withdrawposition } from "@gejia-element-plus/icons-vue/icons/Withdrawposition";
export * from "@gejia-element-plus/icons-vue/icons/Withdrawposition";

import type { DefineComponent } from "vue";

const GejiaElementPlusIconsVue = [
	AItools,
	Ability,
	About,
	Accuracyanalysis,
	Acquisitionprogrammeadministration,
	Activereview,
	Activity,
	Activitycollaboration,
	Activitymanagement,
	Activitymonitoring,
	Add,
	Allocated,
	Allocationvariance,
	Announcement,
	Announcementmanagement,
	Appointment,
	Appointmentpurchasemanagement,
	Assembly,
	Audioandvideo,
	Backup,
	Backupandrecovery,
	Brandauthorization,
	Brandauthorizationmanagement,
	Businessanalysis,
	Calendar,
	Catalogue,
	Categorymanagement,
	Categoryplanning,
	Central,
	ChannelReturns,
	Channelinventory,
	Channelinventorymanagement,
	Channelmanagement,
	Channels,
	Channelsales,
	ChinaMerchants,
	Clearance,
	Clearancemanagement,
	Clearanceplan,
	Close,
	Collaborativeplanning,
	Commodityfiling,
	Commodityfilingmanagement,
	Commoditymanagement,
	Complete,
	Completionstatement,
	Configuration,
	Configurationmanagement,
	Contract,
	Contractmanagement,
	Costquery,
	Coupons,
	Crowd,
	Crowdmanagement,
	Customicon,
	Dark,
	Data,
	Dataanalysis,
	Datamanagement,
	Datariskcontrolandearlywarningplatform,
	Design,
	Dialogue,
	Diskormanagement,
	Distribution,
	Distributionofgoodsthroughchannels,
	Distributor,
	Distributorsettlement,
	Docmanagement,
	Doubt,
	DropDown,
	Earnfun,
	Equityissuancetool,
	Exitmanagement,
	Exitplan,
	Explosiveproducts,
	Financialanalysis,
	FirstScheduleManagement,
	Flow,
	Forward,
	FullScreen,
	FullScreenExit,
	Fulllinkavailableforsale,
	GMVtarget,
	GoalSetting,
	Goods,
	Goodsallocation,
	Goodsallocationmanagement,
	Goodsallocationmonitoring,
	Goodsselection,
	GrossprofitofbackEndcommodities,
	GrossprofitoffrontEndproducts,
	Health,
	Homepage,
	Icon,
	InTransitreport,
	InTransitreportmanagement,
	Inandout,
	Index,
	InputSupplement,
	Inquiriesmanagement,
	Inquiry,
	Intelligentselection,
	Inventory,
	Inventoryassurancemanagement,
	Inventoryguarantee,
	Inventoryhealth,
	Inventoryhealthindex,
	Inventoryhealthmanagement,
	Inventorymanagement,
	Inventorysystem,
	Investmentpromotionmanagement,
	Jobauthorizationmanagement,
	Jumpdirectly,
	Less,
	Liangjipin,
	Lifecycle,
	Lifecyclemanagement,
	Light,
	Link,
	List,
	Lock,
	Logistics,
	ManagementofthecargoinOut,
	Managementplan,
	Marketingtrafficconfiguration,
	MaterialCenter,
	Materiallibrary,
	Menu,
	Merchandise,
	Merchandiseplanning,
	Merchantdata,
	Merchantmanagement,
	Merchants,
	Message,
	Minigames,
	Minus,
	Monitorandcontrol,
	Monthlyclosing,
	Monthlyclosingmanagement,
	MultiDimensionalKanban,
	Newproductlaunch,
	NotData,
	Operation,
	Operationhub,
	Operationmanagement,
	Operationtools,
	Optimizationplan,
	Orderfulfillmentsignage,
	Page403,
	Page404,
	Panoramaoftheevent,
	Performance,
	Performancemanagement,
	Picture,
	Plateofgoods,
	Platform,
	Positioning,
	Postauthorization,
	Price,
	Pricing,
	Pricingmanagement,
	Procurementplan,
	Productcopying,
	Productcopymanagement,
	Productreleasemanagement,
	Productstratification,
	Purchase,
	PurchaseOrderManagement,
	Purchasebyappointment,
	Purchasemanagement,
	Purchaseorder,
	Purchaseprice,
	Purchasepricemanagement,
	Purchasingmanagement,
	Purchasingstaff,
	Putitaway,
	Qualitycontrol,
	Qualitycontrolmanagement,
	RealTimeKanban,
	RealTimeprediction,
	RebateRuleManagement,
	Rebaterules,
	Refused,
	Replenishment,
	Replenishmentmanagement,
	Replenishmentplan,
	Reportmanagement,
	Requisitioningmanagement,
	Resaleprogram,
	Resourceordering,
	Resources,
	Resourcesubscriptionmanagement,
	Retirementplan,
	ReturnOrderManagement,
	Returnorder,
	Returnwarehousereceipt,
	Reverse,
	Rightsandinterests,
	Rightsmanagement,
	Riskcontrol,
	Rookieoperatingindicators,
	Routingwarehouse,
	Routingwarehousemanagement,
	Safety,
	SalePlan,
	Sales,
	Salesandinventoryreport,
	Salesandinventoryreportmanagement,
	Salesforecast,
	Salesmanagement,
	Salesplan,
	SalesrealTimeKanban,
	Scheduling,
	Schedulingmanagement,
	ScrapOrderManagement,
	Scrapdoc,
	Search,
	Searchbox,
	Selection,
	Selectionsystem,
	Service,
	SettingsManage,
	Settlement,
	Settlementdoc,
	Settlementdocmanagement,
	Settlementefficiencyanalysis,
	Settlementmanagement,
	Settlementtoolbox,
	Settlementwithsuppliers,
	Shopping,
	Shortcutbar,
	Singlewarehouseissuednationwide,
	SmartMarketingManagement,
	Smartmarketing,
	Smartsales,
	Smartsalesmanagement,
	StickerPlan,
	StockInManage,
	Stocking,
	Stockmanagement,
	Structure,
	SuperService,
	Supplier,
	Suppliermanagement,
	Synergy,
	Target,
	Targetmanagement,
	Targetmonitoring,
	Theme,
	Timedate,
	Tools,
	Topickupthegoods,
	Touchenglist,
	TransferDocManagement,
	Transferdoc,
	Transferexecution,
	Unlock,
	Upload,
	User,
	Usermanagement,
	ValueAddedservice,
	Valueassessment,
	Venue,
	Venuemanagement,
	Wallpaper,
	Warehouse,
	WarehouseReturnManagement,
	Warehousehealthindexmanagement,
	Warehousing,
	Warehousingmanagement,
	Website,
	Wisdom,
	Withdrawposition,
] as unknown as DefineComponent[];

export { GejiaElementPlusIconsVue };

export default GejiaElementPlusIconsVue;
