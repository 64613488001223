import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * User 图标组件
 */
export const User = defineComponent({
	name: "User",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-User icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M517.632 552.15c-108.715 0-197.163-85.547-197.163-190.72 0-38.315 11.691-75.307 33.878-106.923 36.693-52.48 97.792-83.84 163.285-83.84 65.408 0 126.464 31.274 163.2 83.712a190.848 190.848 0 0 1 19.413 35.072 21.333 21.333 0 1 1-39.338 16.64 147.285 147.285 0 0 0-15.019-27.222c-28.757-41.045-76.715-65.536-128.256-65.536-51.627 0-99.584 24.534-128.427 65.622a143.445 143.445 0 0 0-26.069 82.432c0 81.664 69.29 148.096 154.453 148.096 63.403 0 119.723-36.438 143.36-92.8a21.333 21.333 0 0 1 39.339 16.512c-30.379 72.277-102.016 118.954-182.656 118.954M303.83 627.456c-49.92 0-90.454 41.088-90.454 91.605-.043 50.518 40.49 91.606 90.41 91.606h416.342c50.005 0 90.539-41.088 90.539-91.606 0-50.517-40.534-91.605-90.454-91.605H303.787zm416.383 225.877H303.83c-73.386 0-133.12-60.245-133.12-134.272-.042-74.026 59.691-134.272 133.078-134.272h416.341c73.472 0 133.205 60.246 133.205 134.272 0 74.027-59.733 134.272-133.12 134.272z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default User;
