import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Targetmanagement 图标组件
 */
export const Targetmanagement = defineComponent({
	name: "Targetmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Targetmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M876.672 531.328h-74.453a287.275 287.275 0 0 0-266.88-264.96V192a21.333 21.333 0 0 0-42.667 0v74.539a287.403 287.403 0 0 0-262.827 264.789h-80.512a21.333 21.333 0 1 0 0 42.667h80.427a285.184 285.184 0 0 0 262.912 264.661v78.677a21.333 21.333 0 0 0 42.667 0V723.925a21.333 21.333 0 1 0-42.667 0v71.979a242.859 242.859 0 0 1-220.245-221.867h70.314a21.333 21.333 0 0 0 0-42.666H272.47a244.907 244.907 0 0 1 220.203-222.166v76.203a21.333 21.333 0 0 0 42.667 0v-76.373a244.779 244.779 0 0 1 224.213 222.293h-76.33a21.333 21.333 0 0 0 0 42.667h193.45a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M513.877 593.792a39.168 39.168 0 0 1 0-78.25 39.168 39.168 0 0 1 0 78.25m0-120.917a81.92 81.92 0 0 0-81.792 81.792 81.92 81.92 0 0 0 81.792 81.792 81.92 81.92 0 0 0 81.792-81.792 81.92 81.92 0 0 0-81.792-81.792M707.67 682.667a64 64 0 1 0 .043 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M707.67 853.333a106.795 106.795 0 0 1-106.667-106.666c0-58.838 47.872-106.667 106.666-106.667s106.667 47.83 106.667 106.667-47.872 106.666-106.667 106.666m0-256c-82.346 0-149.333 66.987-149.333 149.334S625.323 896 707.669 896s149.334-66.987 149.334-149.333-66.987-149.334-149.334-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Targetmanagement;
