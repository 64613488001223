import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * FirstScheduleManagement 图标组件
 */
export const FirstScheduleManagement = defineComponent({
	name: "FirstScheduleManagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-FirstScheduleManagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M492.416 853.333H296.32c-22.23 0-40.32-19.285-40.32-43.008V299.008C256 275.285 274.09 256 296.32 256h107.179c4.693 0 8.832-1.835 12.33-4.395a20.992 20.992 0 0 0 11.051-14.122 85.845 85.845 0 0 1 83.328-66.816c40.448 0 75.605 28.714 83.627 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256h108.97c22.23 0 40.363 19.285 40.363 43.008v278.784a21.333 21.333 0 0 0 42.667 0V299.008c0-47.232-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.208 128a128.683 128.683 0 0 0-120.277 85.333H296.32c-45.739 0-82.987 38.443-82.987 85.675v511.317c0 47.232 37.248 85.675 82.987 85.675h196.096a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M533.333 689.195V510.72A85.12 85.12 0 0 0 512 343.083a85.163 85.163 0 1 0-21.333 167.68v178.432a41.984 41.984 0 0 0-21.334 36.138 42.667 42.667 0 1 0 85.334 0 42.112 42.112 0 0 0-21.334-36.138M704 682.667a64 64 0 1 0 0 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M704 853.333a106.795 106.795 0 0 1-106.667-106.666C597.333 687.872 645.163 640 704 640s106.667 47.872 106.667 106.667S762.837 853.333 704 853.333m0-256c-82.347 0-149.333 66.987-149.333 149.334S621.653 896 704 896s149.333-66.987 149.333-149.333S786.347 597.333 704 597.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default FirstScheduleManagement;
