import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Rookieoperatingindicators 图标组件
 */
export const Rookieoperatingindicators = defineComponent({
	name: "Rookieoperatingindicators",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Rookieoperatingindicators icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M833.792 449.75a21.333 21.333 0 0 0 21.333-21.334v-63.957c0-58.838-47.829-106.667-106.666-106.667H533.333v-23.125a21.333 21.333 0 0 0-42.666 0v23.125H277.333a106.752 106.752 0 0 0-106.666 106.667v256c0 58.837 47.829 106.666 106.666 106.666h213.334v49.792l-138.582 79.19a21.419 21.419 0 0 0-7.978 29.141 21.461 21.461 0 0 0 29.141 7.979L512 813.909l138.752 79.318a21.419 21.419 0 0 0 29.141-7.979 21.419 21.419 0 0 0-7.978-29.141l-138.582-79.19v-49.792H748.46c58.837 0 106.666-47.829 106.666-106.666v-64a21.333 21.333 0 0 0-42.666 0v64c0 35.285-28.715 64-64 64H277.333c-35.285 0-64-28.715-64-64v-256c0-35.286 28.715-64 64-64H748.46c35.285 0 64 28.714 64 64v63.957a21.333 21.333 0 0 0 21.333 21.333" fill="#3C405D"/><path d="M342.784 383.787a37.547 37.547 0 0 1 35.2 24.405h17.92A54.699 54.699 0 0 0 288 421.333v8.192a54.741 54.741 0 0 0 107.947 12.928h-17.92a37.547 37.547 0 0 1-72.832-13.098v-8.022a37.547 37.547 0 0 1 37.546-37.546m381.952 172.202v8.022a37.547 37.547 0 1 1-75.178 0v-8.022a37.59 37.59 0 0 1 75.136 0m-92.331 0v8.192a54.741 54.741 0 0 0 109.483-.17v-8.022a54.699 54.699 0 1 0-109.483 0M551.893 484.096h17.152V366.592h-17.152zm44.886 134.656h18.389l-45.227-117.504h-19.029l-45.227 117.504h72.704l-6.613-17.152h-41.003l29.739-77.227zM460.8 584.448h17.152V466.944H460.8zm-7.51 34.304h32.214l-6.57-17.152H459.86zm27.435-186.112h-41.088l25.43-66.048h-18.432l-32 83.2h72.704zm43.392 17.195l-32-83.2h-18.432l32.043 83.2zM288 618.752h17.152v-90.667l75.179 90.71h17.152V501.248H380.33v90.667l-75.094-90.624H288z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Rookieoperatingindicators;
