import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Salesmanagement 图标组件
 */
export const Salesmanagement = defineComponent({
	name: "Salesmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Salesmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M517.93 776.96a184.448 184.448 0 0 1-31.786-25.515l-272.81-272.768V213.333h275.54l288 288a21.333 21.333 0 1 0 30.166-30.165L514.944 179.029a21.035 21.035 0 0 0-8.917-5.034 20.736 20.736 0 0 0-10.71-3.328h-303.36A21.333 21.333 0 0 0 170.624 192v292.267c0 1.194.555 2.218.725 3.328a21.29 21.29 0 0 0 6.144 15.573L455.98 781.611c11.818 11.861 25.002 22.4 39.04 31.36a21.248 21.248 0 0 0 29.44-6.571 21.333 21.333 0 0 0-6.528-29.44" fill="#3C405D"/><path d="M405.333 366.208a42.667 42.667 0 1 1-85.333 0 42.667 42.667 0 0 1 85.333 0m-128 0c0 47.061 38.272 85.333 85.334 85.333S448 413.27 448 366.208s-38.272-85.333-85.333-85.333-85.334 38.272-85.334 85.333M705.75 640a64 64 0 1 0 .043 128.043 64 64 0 0 0 0-128.043" fill="#3C405D"/><path d="M705.75 810.667A106.752 106.752 0 0 1 599.082 704c0-58.837 47.829-106.667 106.666-106.667S812.416 645.163 812.416 704s-47.83 106.667-106.667 106.667m0-256c-82.346 0-149.333 66.986-149.333 149.333s66.987 149.333 149.333 149.333S855.083 786.347 855.083 704s-66.987-149.333-149.334-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Salesmanagement;
