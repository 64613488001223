import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Activity 图标组件
 */
export const Activity = defineComponent({
	name: "Activity",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Activity icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M853.719 623.833c.384-1.451.768-2.816.853-4.267 0-.427.256-.853.256-1.28 0-.896-.427-1.707-.512-2.603-.213-1.45-.427-2.858-.853-4.266a22.101 22.101 0 0 0-1.963-3.883c-.47-.81-.64-1.707-1.28-2.432L706.563 422.405l58.708-180.734c.342-1.11.171-2.22.384-3.328.171-1.067.64-2.048.64-3.2 0-.342-.17-.598-.17-.94-.086-1.535-.512-2.986-.896-4.479-.342-1.195-.512-2.475-1.067-3.541-.554-1.28-1.45-2.304-2.261-3.414-.853-1.109-1.493-2.218-2.475-3.2-.938-.853-2.048-1.365-3.157-2.048-1.28-.853-2.475-1.706-3.925-2.218-.3-.128-.512-.427-.854-.47-1.024-.34-2.133-.256-3.157-.34-1.152-.257-2.176-.684-3.413-.684H218.247l-5.334-26.069a21.333 21.333 0 1 0-41.813 8.49l129.791 641.83a21.418 21.418 0 0 0 25.173 16.64 21.333 21.333 0 0 0 16.64-25.088L226.865 256.433h488.658L662.36 420.101c-.213.597-.085 1.28-.213 1.92a18.347 18.347 0 0 0-.512 4.31 17.963 17.963 0 0 0 1.365 7.85 22.101 22.101 0 0 0 1.963 3.84c.384.555.427 1.28.853 1.835l123.69 157.14H405.338a21.333 21.333 0 1 0 0 42.666h428.114c4.608 0 8.534-1.792 12.032-4.224.341-.256.81-.128 1.152-.384.341-.213.427-.597.768-.853a20.693 20.693 0 0 0 2.944-3.5c.768-1.023 1.579-1.962 2.133-3.114.555-1.194.854-2.432 1.195-3.754" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Activity;
