import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Merchandiseplanning 图标组件
 */
export const Merchandiseplanning = defineComponent({
	name: "Merchandiseplanning",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Merchandiseplanning icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.832 170.667H263.168a92.63 92.63 0 0 0-92.501 92.458v497.622c0 50.986 41.514 92.501 92.586 92.501h268.8a21.333 21.333 0 1 0 0-42.667h-268.8a49.92 49.92 0 0 1-49.92-49.834V263.168a49.92 49.92 0 0 1 49.92-49.835h497.494a49.92 49.92 0 0 1 49.92 49.92V574.72a21.333 21.333 0 0 0 42.666 0V263.253a92.587 92.587 0 0 0-92.501-92.586" fill="#3C405D"/><path d="M383.573 299.093a21.333 21.333 0 0 0-21.333 21.334v87.466c0 81.707 66.987 148.139 149.333 148.139S660.907 489.6 660.907 407.936v-87.51a21.333 21.333 0 0 0-42.667 0v87.467c0 58.112-47.872 105.387-106.667 105.387s-106.666-47.275-106.666-105.387v-87.466a21.333 21.333 0 0 0-21.334-21.334m447.104 339.584H612.011a21.333 21.333 0 0 0 0 42.667h218.666a21.333 21.333 0 0 0 0-42.667m0 85.334H612.011a21.333 21.333 0 0 0 0 42.666h218.666a21.333 21.333 0 0 0 0-42.666m0 87.082H612.011a21.333 21.333 0 0 0 0 42.667h218.666a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Merchandiseplanning;
