import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Requisitioningmanagement 图标组件
 */
export const Requisitioningmanagement = defineComponent({
	name: "Requisitioningmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Requisitioningmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M489.728 839.424A310.357 310.357 0 0 1 215.253 615.68a20.95 20.95 0 0 0-20.565-15.104c-.939-.128-1.75-.597-2.688-.597a21.333 21.333 0 0 0-21.333 21.333v240.299a21.333 21.333 0 0 0 42.666 0v-147.2c58.24 94.72 159.062 159.146 273.238 167.637a21.333 21.333 0 0 0 3.157-42.581M808.448 438.23c2.005 6.4 6.827 10.922 12.63 13.226 1.066.512 2.218.683 3.327 1.024 1.024.213 2.006.512 3.03.597.896.128 1.706.512 2.645.512a21.333 21.333 0 0 0 21.333-21.333V192.043a21.333 21.333 0 1 0-42.666 0V340.82A349.952 349.952 0 0 0 512.98 178.56 350.677 350.677 0 0 0 176.64 425.899a21.333 21.333 0 0 0 40.747 12.672A308.181 308.181 0 0 1 512.98 221.227a308.139 308.139 0 0 1 295.467 217.002M705.024 656.981a64 64 0 1 0 .043 128.043 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M705.024 827.648a106.752 106.752 0 0 1-106.667-106.667c0-58.837 47.83-106.666 106.667-106.666 58.88 0 106.667 47.829 106.667 106.666 0 58.88-47.787 106.667-106.667 106.667m0-256c-82.347 0-149.333 66.987-149.333 149.333s66.986 149.334 149.333 149.334 149.333-66.987 149.333-149.334-66.986-149.333-149.333-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Requisitioningmanagement;
