import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Salesplan 图标组件
 */
export const Salesplan = defineComponent({
	name: "Salesplan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Salesplan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 170.667A21.333 21.333 0 0 0 810.667 192v552.875a21.333 21.333 0 0 0 42.666 0V192A21.333 21.333 0 0 0 832 170.667M619.819 766.208a21.333 21.333 0 0 0 21.333-21.333V320a21.333 21.333 0 0 0-42.667 0v424.875a21.333 21.333 0 0 0 21.334 21.333m-214.187-1.792a21.333 21.333 0 0 0 21.333-21.333V444.416a21.333 21.333 0 0 0-42.666 0v298.667a21.333 21.333 0 0 0 21.333 21.333M192 766.251a21.333 21.333 0 0 0 21.333-21.334V574.208a21.333 21.333 0 0 0-42.666 0v170.71A21.333 21.333 0 0 0 192 766.25m640 42.624H192a21.333 21.333 0 0 0 0 42.666h640a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Salesplan;
