import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * ScrapOrderManagement 图标组件
 */
export const ScrapOrderManagement = defineComponent({
	name: "ScrapOrderManagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-ScrapOrderManagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M494.208 853.333H296.363c-22.23 0-40.363-19.285-40.363-43.008V299.008C256 275.285 274.133 256 296.363 256H403.54A20.736 20.736 0 0 0 416 251.52a20.992 20.992 0 0 0 10.923-14.037 85.76 85.76 0 0 1 83.285-66.816c40.448 0 75.605 28.714 83.627 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256h108.97c22.23 0 40.363 19.285 40.363 43.008v236.117a21.333 21.333 0 0 0 42.667 0V299.008c0-47.232-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.208 128a128.512 128.512 0 0 0-120.235 85.333h-93.61c-45.782 0-83.03 38.443-83.03 85.675v511.317c0 47.232 37.248 85.675 83.03 85.675h197.845a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M705.792 682.667a64 64 0 1 0 0 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M705.792 853.333a106.795 106.795 0 0 1-106.667-106.666c0-58.795 47.83-106.667 106.667-106.667s106.667 47.872 106.667 106.667-47.83 106.666-106.667 106.666M515.542 704a148.053 148.053 0 0 1-89.003-30.123l208.213-208.213a148.053 148.053 0 0 1 30.123 89.003c0 18.005-3.371 35.498-9.558 52.053a149.248 149.248 0 0 0-89.088 88.277A148.907 148.907 0 0 1 515.541 704m0-298.667c33.494 0 64.086 11.478 89.046 30.166L396.373 643.712a147.968 147.968 0 0 1-30.165-89.045c0-82.347 66.987-149.334 149.333-149.334m190.251 192c-1.237 0-2.39.342-3.627.384a193.707 193.707 0 0 0 5.376-43.05 190.976 190.976 0 0 0-53.845-132.736c-.725-1.067-1.195-2.262-2.133-3.243-.982-.939-2.176-1.408-3.243-2.133a191.019 191.019 0 0 0-132.779-53.888 192.213 192.213 0 0 0-192 192c0 105.856 86.144 192 192 192 14.08 0 27.862-2.006 41.43-4.992-.086 1.706-.512 3.285-.512 4.992 0 82.346 66.986 149.333 149.333 149.333s149.333-66.987 149.333-149.333-66.986-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default ScrapOrderManagement;
