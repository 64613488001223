import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Clearancemanagement 图标组件
 */
export const Clearancemanagement = defineComponent({
	name: "Clearancemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Clearancemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M448.012 385.621a21.333 21.333 0 0 0 0 42.667h87.083a21.333 21.333 0 0 0 0-42.667h-87.04zm254.208 256a64 64 0 1 0 .043 128 64 64 0 0 0 0-128" fill="#3D405D"/><path d="M702.22 812.288a106.795 106.795 0 0 1-106.666-106.667c0-58.794 47.829-106.666 106.666-106.666 58.88 0 106.667 47.872 106.667 106.666S761.1 812.288 702.22 812.288zM424.887 662.955a21.333 21.333 0 0 1-21.333-21.334V492.288h172.458v134.357a147.84 147.84 0 0 0-16.17 36.267H424.887zM702.22 556.288c-30.933 0-59.69 9.472-83.541 25.6v-89.6h19.541a21.333 21.333 0 0 0 0-42.667H341.346a21.333 21.333 0 0 0 0 42.667h19.541v149.333c0 35.286 28.715 64 64 64h128c0 82.347 66.987 149.334 149.333 149.334s149.334-66.987 149.334-149.334-66.987-149.333-149.334-149.333z" fill="#3D405D"/><path d="M492.428 811.307H273.72a57.472 57.472 0 0 1-57.557-57.174V434.261L490.679 176.64l326.827 306.688a21.29 21.29 0 0 0 30.08-.981 21.29 21.29 0 0 0-.939-30.166L507.148 133.59a20.992 20.992 0 0 0-16.426-5.461 20.95 20.95 0 0 0-16.555 5.461L134.754 452.181a21.29 21.29 0 0 0 14.592 36.907 21.29 21.29 0 0 0 14.592-5.76l9.557-8.96v279.723a100.181 100.181 0 0 0 100.267 99.882h218.624a21.333 21.333 0 0 0 0-42.666" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Clearancemanagement;
