import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Settlementtoolbox 图标组件
 */
export const Settlementtoolbox = defineComponent({
	name: "Settlementtoolbox",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Settlementtoolbox icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M377.685 265.259c0-28.587 23.296-51.926 51.926-51.926h166.4c28.672 0 51.968 23.296 51.968 51.926v29.696H377.685v-29.696zm312.747 569.216h-462.89a56.96 56.96 0 0 1-56.875-56.918V587.008h321.237v24.32a21.29 21.29 0 1 0 42.667 0v-24.32h85.973a21.333 21.333 0 1 0 0-42.667h-85.973v-38.997a21.333 21.333 0 0 0-42.667 0v38.997h-321.28V394.54c0-31.36 25.557-56.875 56.917-56.875h568.875c31.403 0 56.917 25.515 56.917 56.875v111.104a21.333 21.333 0 0 0 42.667 0V394.539a99.67 99.67 0 0 0-99.584-99.542H690.603V265.26a94.72 94.72 0 0 0-94.55-94.592h-166.4a94.677 94.677 0 0 0-94.634 94.592v29.696h-107.52A99.67 99.67 0 0 0 128 394.539V777.6a99.67 99.67 0 0 0 99.541 99.584h462.934a21.333 21.333 0 0 0 0-42.667z" fill="#3C405D"/><path d="M875.52 753.877h-83.03V723.2h83.03a21.333 21.333 0 1 0 0-42.667h-83.115v-8.362l72.192-68.139a21.333 21.333 0 1 0-29.184-30.976l-65.962 62.208-65.92-62.208a21.248 21.248 0 1 0-29.312 30.976l75.52 71.296v5.205h-87.51a21.333 21.333 0 0 0 0 42.667h87.51v30.72h-87.51a21.333 21.333 0 0 0 0 42.667h87.51v60.885a21.333 21.333 0 1 0 42.666 0v-60.928h83.158a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Settlementtoolbox;
