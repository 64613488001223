import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Structure 图标组件
 */
export const Structure = defineComponent({
	name: "Structure",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Structure icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 853.333H192a21.333 21.333 0 0 1 0-42.666h640a21.333 21.333 0 0 1 0 42.666M649.13 640H192a21.333 21.333 0 0 1 0-42.667h457.13a21.333 21.333 0 0 1 0 42.667M470.101 426.667H192A21.333 21.333 0 0 1 192 384h278.101a21.333 21.333 0 0 1 0 42.667M279.637 213.333H192a21.333 21.333 0 0 1 0-42.666h87.637a21.333 21.333 0 0 1 0 42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Structure;
