import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Productcopying 图标组件
 */
export const Productcopying = defineComponent({
	name: "Productcopying",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Productcopying icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M243.499 330.283v405.162a41.259 41.259 0 0 1-30.166-39.424V255.147c0-23.04 18.688-41.814 41.728-41.814h440.96c19.798 0 35.627 14.166 39.894 32.598H327.936a84.565 84.565 0 0 0-84.437 84.394M832 473.557a21.333 21.333 0 0 0 21.333-21.333V330.368a84.267 84.267 0 0 0-73.685-83.37 84.267 84.267 0 0 0-83.627-76.289h-440.96c-46.506-.042-84.394 37.846-84.394 84.395v440.917c0 42.923 32.213 78.038 73.642 83.371a84.267 84.267 0 0 0 83.627 76.288h440.917a84.48 84.48 0 0 0 84.48-84.48V580.181a21.333 21.333 0 0 0-42.666 0V771.2a41.813 41.813 0 0 1-41.814 41.728H327.936a41.728 41.728 0 0 1-41.77-41.728V330.283a41.813 41.813 0 0 1 41.77-41.728h440.917a41.813 41.813 0 0 1 41.814 41.728v121.984A21.333 21.333 0 0 0 832 473.6" fill="#3C405D"/><path d="M434.603 359.68a21.333 21.333 0 0 0-21.334 21.333v77.568c0 73.686 60.459 133.632 134.742 133.632 74.325 0 134.784-59.946 134.784-133.632v-77.568a21.333 21.333 0 1 0-42.667 0v77.526c0 50.176-41.301 90.965-92.16 90.965-50.773 0-92.032-40.79-92.032-90.965v-77.526a21.333 21.333 0 0 0-21.333-21.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Productcopying;
