import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Commoditymanagement 图标组件
 */
export const Commoditymanagement = defineComponent({
	name: "Commoditymanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Commoditymanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M534.656 810.667H263.168a49.92 49.92 0 0 1-49.835-49.835V263.211a49.92 49.92 0 0 1 49.835-49.878h497.621a49.92 49.92 0 0 1 49.92 49.878v273.237a21.29 21.29 0 1 0 42.667 0V263.211a92.63 92.63 0 0 0-92.63-92.544H263.169a92.587 92.587 0 0 0-92.501 92.544v497.621a92.587 92.587 0 0 0 92.501 92.501h271.488a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/><path d="M383.573 299.093a21.333 21.333 0 0 0-21.333 21.334v87.466c0 81.664 66.987 148.139 149.333 148.139s149.334-66.475 149.334-148.096v-87.51a21.333 21.333 0 0 0-42.667 0v87.467c0 58.112-47.872 105.387-106.667 105.387-58.88 0-106.666-47.275-106.666-105.387v-87.466a21.333 21.333 0 0 0-21.334-21.334M706.86 639.787a64 64 0 1 0 .042 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M706.859 810.453a106.795 106.795 0 0 1-106.667-106.666c0-58.795 47.83-106.667 106.667-106.667 58.88 0 106.666 47.872 106.666 106.667S765.74 810.453 706.86 810.453m0-256c-82.347 0-149.334 66.987-149.334 149.334S624.512 853.12 706.86 853.12s149.333-66.987 149.333-149.333-66.987-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Commoditymanagement;
