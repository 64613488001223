import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * GrossprofitoffrontEndproducts 图标组件
 */
export const GrossprofitoffrontEndproducts = defineComponent({
	name: "GrossprofitoffrontEndproducts",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-GrossprofitoffrontEndproducts icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M656 810.667H348.416a49.92 49.92 0 0 1-49.835-49.878V263.211a49.92 49.92 0 0 1 49.835-49.878h411.05a49.92 49.92 0 0 1 49.878 49.878v185.258a21.333 21.333 0 1 0 42.667 0V263.211a92.63 92.63 0 0 0-92.544-92.544H348.416a92.63 92.63 0 0 0-92.501 92.544v497.578c0 51.03 41.514 92.544 92.501 92.544H656a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/><path d="M426.667 299.093a21.333 21.333 0 0 0-21.334 21.334v87.466c0 81.707 66.987 148.139 149.334 148.139S704 489.6 704 407.936v-87.51a21.333 21.333 0 0 0-42.667 0v87.467c0 58.112-47.829 105.387-106.666 105.387S448 466.005 448 407.893v-87.466a21.333 21.333 0 0 0-21.333-21.334M831.445 739.968h-70.784v-22.059h70.827a21.333 21.333 0 1 0 0-42.666h-70.827v-3.755l62.891-59.392a21.333 21.333 0 0 0-29.312-31.019l-56.405 53.291-56.448-53.333a21.333 21.333 0 1 0-29.227 31.018l65.835 62.208v.982h-74.752a21.333 21.333 0 0 0 0 42.666h74.752v22.059h-74.752a21.333 21.333 0 0 0 0 42.667h74.752v51.2a21.29 21.29 0 1 0 42.666 0v-51.2h70.827a21.333 21.333 0 0 0 0-42.667M192 259.115a21.333 21.333 0 0 0-21.333 21.333v467.541a21.333 21.333 0 0 0 42.666 0V280.448A21.333 21.333 0 0 0 192 259.115" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default GrossprofitoffrontEndproducts;
