import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Explosiveproducts 图标组件
 */
export const Explosiveproducts = defineComponent({
	name: "Explosiveproducts",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Explosiveproducts icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.794 170.797H263.249a92.615 92.615 0 0 0-92.53 92.488V760.83c0 51.021 41.508 92.53 92.53 92.53h269.654a21.33 21.33 0 1 0 0-42.66H263.25a49.912 49.912 0 0 1-49.87-49.87V263.285a49.912 49.912 0 0 1 49.87-49.827h497.545a49.912 49.912 0 0 1 49.827 49.827v313.21a21.33 21.33 0 1 0 42.66 0v-313.21a92.615 92.615 0 0 0-92.487-92.488" fill="#3C405D"/><path d="M383.593 299.204a21.33 21.33 0 0 0-21.33 21.33v87.454c0 81.651 66.976 148.073 149.31 148.073s149.31-66.422 149.31-148.03v-87.496a21.33 21.33 0 0 0-42.66 0v87.453c0 58.103-47.779 105.37-106.65 105.37-58.828 0-106.65-47.267-106.65-105.37v-87.453a21.33 21.33 0 0 0-21.33-21.33M766.638 802.082c-22.012-20.306-27.9-48.633-28.198-48.633a19.41 19.41 0 0 0-.512-4.394c-2.986-13.565-14.078-23.633-30.843-25.297a31.995 31.995 0 0 0-29.436 20.178 127.895 127.895 0 0 0-9.385 53.581c-22.183-14.803-36.261-38.65-36.261-64.928 0-22.482 10.366-43.94 29.862-60.151 2.816-2.346 5.674-4.735 8.788-7.124 14.504-10.836 35.237-26.364 55.202-58.146 6.4 5.93 11.305 12.627 13.95 19.538 5.034 13.225 3.413 28.753-5.588 47.737-4.394 11.817-1.45 25.17 10.153 36.346 9.812 6.997 20.818 9.386 35.578 1.835 7.551-4.693 14.633-9.855 21.16-15.571a73.866 73.866 0 0 1 9.086 35.536c0 28.582-16.722 54.818-43.556 69.493M801.62 642.96a21.629 21.629 0 0 0-15.443 8.105l-1.28 1.664a88.989 88.989 0 0 0-5.205-41.252c-11.518-29.863-37.114-46.628-51.32-53.923a21.202 21.202 0 0 0-28.838 9.385c-18.77 37.499-38.565 52.344-54.605 64.29-3.754 2.9-7.252 5.844-11.689 9.64a118.98 118.98 0 0 0-43.855 91.72c0 58.999 44.75 109.85 106.395 120.856a21.927 21.927 0 0 0 3.754.341h.427a21.5 21.5 0 0 0 21.714-21.33 21.458 21.458 0 0 0-5.29-14.12 65.142 65.142 0 0 1-5.12-19.496c8.618 15.912 21.586 32.933 40.528 45.774a21.33 21.33 0 0 0 19.794 2.133c49.358-19.495 81.267-64.289 81.267-114.158a117.315 117.315 0 0 0-34.853-83.358 21.842 21.842 0 0 0-16.339-6.229" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Explosiveproducts;
