import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Withdrawposition 图标组件
 */
export const Withdrawposition = defineComponent({
	name: "Withdrawposition",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Withdrawposition icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M556.467 641.621H384.01a21.333 21.333 0 0 0 0 42.667h172.458c58.88 0 106.667-47.872 106.667-106.667s-47.787-106.666-106.667-106.666H392.841l52.48-52.48a21.419 21.419 0 0 0-30.208-30.166l-87.98 88.022c-.127.128-.17.341-.34.512a21.077 21.077 0 0 0-6.784 15.445 21.333 21.333 0 0 0 21.333 21.333h215.125c35.286 0 64 28.715 64 64s-28.714 64-64 64" fill="#3D405D"/><path d="M846.6 452.181L507.188 133.59a21.205 21.205 0 0 0-16.512-5.504 21.248 21.248 0 0 0-16.512 5.504L134.75 452.181a21.29 21.29 0 1 0 29.184 31.104l9.6-8.96v279.766a100.139 100.139 0 0 0 100.224 99.882h433.835c55.296 0 100.266-44.8 100.266-99.882v-71.552a21.333 21.333 0 0 0-42.666 0v71.552c0 31.53-25.856 57.216-57.6 57.216H273.758a57.472 57.472 0 0 1-57.6-57.216V434.26l274.517-257.664 274.518 257.664V554.54a21.333 21.333 0 1 0 42.666 0v-80.214l9.558 8.96a21.12 21.12 0 0 0 30.122-.938 21.29 21.29 0 0 0-.938-30.166" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Withdrawposition;
