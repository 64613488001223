import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Rightsandinterests 图标组件
 */
export const Rightsandinterests = defineComponent({
	name: "Rightsandinterests",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Rightsandinterests icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M513.792 231.168l135.083 210.347-135.083 95.573-135.083-95.573 135.083-210.347zm323.413 87.04c-1.066-.17-2.133.128-3.157.085a21.205 21.205 0 0 0-16.128 3.542l-134.187 95.018-151.978-236.629-.171-.213c-.896-1.28-2.219-2.304-3.413-3.414-.854-.853-1.579-1.92-2.603-2.56l-.213-.256c-.982-.64-2.134-.768-3.072-1.194-1.451-.64-2.859-1.408-4.438-1.707-1.28-.256-2.56-.085-3.882-.128-1.451 0-2.859-.17-4.31.128-1.493.299-2.858 1.067-4.266 1.707-1.067.426-2.176.554-3.158 1.194l-.213.214c-1.067.682-1.835 1.792-2.773 2.688-1.11 1.109-2.39 2.048-3.243 3.328l-.17.213-151.98 236.63-134.186-95.02a21.163 21.163 0 0 0-16.213-3.413c-1.707-.17-3.243-.512-4.907-.213a21.333 21.333 0 0 0-17.579 24.533l60.459 366.208c.128.939.64 1.75.939 2.646.341 1.109.64 2.176 1.152 3.242a20.992 20.992 0 0 0 2.56 3.84c.682.854 1.237 1.707 2.005 2.347a19.968 19.968 0 0 0 4.181 2.816c.811.47 1.494 1.024 2.347 1.365a21.077 21.077 0 0 0 7.808 1.579h520.917a21.077 21.077 0 0 0 7.851-1.579c.853-.341 1.536-.853 2.347-1.365a19.968 19.968 0 0 0 4.181-2.816c.768-.683 1.28-1.536 1.963-2.347a20.992 20.992 0 0 0 2.56-3.84c.554-1.066.853-2.133 1.194-3.242.299-.854.811-1.707.939-2.646l14.677-88.874a21.333 21.333 0 0 0-42.069-6.955l-11.733 70.997H270.507l-49.92-302.208 280.832 198.742c.384.298.853.17 1.28.426 3.413 2.134 7.125 3.499 11.05 3.499a21.248 21.248 0 0 0 11.051-3.499c.384-.256.853-.128 1.28-.426l278.784-197.334-16.768 101.76a21.333 21.333 0 1 0 42.07 6.955l24.618-149.29a21.333 21.333 0 0 0-17.579-24.534zm-46.08 492.16H236.46a21.333 21.333 0 0 0 0 42.667h554.666a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Rightsandinterests;
