import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Merchantdata 图标组件
 */
export const Merchantdata = defineComponent({
	name: "Merchantdata",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Merchantdata icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M518.4 554.667c90.155 0 170.368-52.267 204.373-133.078a21.333 21.333 0 0 0-39.338-16.512C656.128 470.06 591.36 512 518.4 512c-98.09 0-177.92-76.544-177.92-170.667 0-34.09 10.41-66.944 30.08-95.061 33.152-47.317 88.448-75.605 147.84-75.605s114.56 28.245 147.712 75.562c6.912 9.856 12.8 20.395 17.323 31.275a21.504 21.504 0 0 0 27.946 11.392 21.419 21.419 0 0 0 11.392-27.99 214.23 214.23 0 0 0-21.76-39.21C659.84 163.029 591.573 128 518.4 128c-73.259 0-141.525 35.03-182.741 93.781a207.445 207.445 0 0 0-37.846 119.552c0 117.632 98.987 213.334 220.587 213.334M823.723 820.821a104.235 104.235 0 0 1-75.776 32.512H276.053c-58.026 0-105.386-47.786-105.386-106.666 0-58.838 47.317-106.667 105.386-106.667h386.134a21.333 21.333 0 0 0 0-42.667H276.053C194.475 597.333 128 664.32 128 746.667S194.432 896 276.053 896h471.894c40.533 0 78.378-16.213 106.624-45.653a21.376 21.376 0 0 0-.683-30.208 21.419 21.419 0 0 0-30.165.682" fill="#3D405D"/><path d="M851.2 725.333H745.813V640h2.091c50.901 0 93.525 36.693 103.296 85.333m-103.296-128H724.48a21.333 21.333 0 0 0-21.333 21.334c0 1.877.597 3.584 1.066 5.333-.469 1.75-1.066 3.456-1.066 5.333v115.542c0 8.832 5.376 16.426 13.013 19.669A20.736 20.736 0 0 0 727.125 768h140.416c1.28 0 2.347-.512 3.584-.725 1.195.213 2.304.725 3.542.725A21.333 21.333 0 0 0 896 746.667c0-82.347-66.432-149.334-148.096-149.334M511.147 834.944l42.666-52.693-41.386-120.918-43.947 120.918z" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Merchantdata;
