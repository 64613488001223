import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Exitplan 图标组件
 */
export const Exitplan = defineComponent({
	name: "Exitplan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Exitplan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M533.336 811.221H308.611c-28.97 0-52.608-23.466-52.608-52.352V459.648l256-240.299 256 240.299v116.139a21.333 21.333 0 1 0 42.667 0v-76.118l6.741 6.358a21.077 21.077 0 0 0 14.592 5.76 20.95 20.95 0 0 0 15.53-6.742 21.248 21.248 0 0 0-.938-30.122L528.387 176.256a20.907 20.907 0 0 0-16.384-5.504 20.907 20.907 0 0 0-16.384 5.504L177.411 474.923a21.248 21.248 0 0 0-.939 30.122 20.95 20.95 0 0 0 15.531 6.742 21.077 21.077 0 0 0 14.592-5.76l6.741-6.358v259.2a95.275 95.275 0 0 0 95.275 95.019h224.725a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M832.003 680.661a21.333 21.333 0 0 0 0-42.666H678.019a106.752 106.752 0 0 0-98.475-147.542H415.918l52.522-52.48a21.419 21.419 0 0 0-30.208-30.208l-87.936 88.022c-.17.17-.213.426-.384.554a21.333 21.333 0 0 0 14.592 36.779h215.083c35.285 0 64 28.715 64 64s-28.715 64-64 64H407.214a21.333 21.333 0 0 0 0 42.667h172.373c24.832 0 47.403-8.875 65.536-23.126h186.88zM832.003 723.328H613.336a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667M832.003 810.453H613.336a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Exitplan;
