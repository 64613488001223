import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Venue 图标组件
 */
export const Venue = defineComponent({
	name: "Venue",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Venue icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M253.312 512l.81-4.821L512 345.216l257.877 161.963.811 4.821H253.312zm596.01 209.75a21.376 21.376 0 0 0-42.069 7.167l3.414 18.262c0 34.986-28.715 63.488-64 63.488h-128v-46.208c0-58.838-47.83-106.667-106.667-106.667a106.752 106.752 0 0 0-106.667 106.667v46.208h-128c-35.285 0-64-28.502-64.298-59.947L246.1 554.667H777.9l7.765 46.208a21.333 21.333 0 0 0 42.112-7.083l-17.408-103.125c0-.214-.17-.342-.213-.555-.299-1.664-1.067-3.115-1.792-4.65-.47-1.025-.726-2.091-1.323-2.987-.853-1.238-1.963-2.134-3.03-3.2-.938-.896-1.706-1.92-2.773-2.646-.213-.128-.341-.384-.554-.512l-267.35-167.936V256h87.04a21.333 21.333 0 1 0 0-42.667h-87.04V192a21.333 21.333 0 1 0-42.666 0v116.181L223.36 476.117c-.256.171-.427.47-.683.64-.725.512-1.28 1.238-1.962 1.878-1.366 1.237-2.774 2.389-3.755 3.882-.555.811-.768 1.792-1.195 2.688-.768 1.579-1.578 3.158-1.92 4.907l-.213.555-42.965 256.512a106.496 106.496 0 0 0 106.666 106.154h144a20.907 20.907 0 0 0 13.526-5.205A21.29 21.29 0 0 0 448 828.458v-64c0-35.285 28.715-64 64-64s64 28.715 64 64v64c0 3.883 1.28 7.34 3.157 10.497a21.163 21.163 0 0 0 19.926 14.378h147.584c58.837 0 106.666-47.616 106.368-109.738l-3.712-21.846z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Venue;
