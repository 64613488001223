import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Merchantmanagement 图标组件
 */
export const Merchantmanagement = defineComponent({
	name: "Merchantmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Merchantmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M517.632 509.483c80.64 0 152.32-46.678 182.741-118.955a21.333 21.333 0 0 0-39.338-16.512c-23.638 56.363-79.958 92.8-143.36 92.8-77.568 0-141.312-55.253-152.192-126.72h183.424a21.29 21.29 0 1 0 0-42.667H364.843c3.285-21.845 11.52-42.666 24.49-61.141 28.8-41.088 76.8-65.621 128.299-65.621 51.499 0 99.499 24.49 128.299 65.536 5.973 8.576 11.05 17.706 15.018 27.178a21.333 21.333 0 0 0 39.339-16.597 189.44 189.44 0 0 0-19.413-35.072C644.096 159.275 583.083 128 517.632 128c-65.45 0-126.507 31.317-163.243 83.797a185.173 185.173 0 0 0-33.877 106.923c0 105.216 88.405 190.763 197.12 190.763M537.856 767.957H303.957c-49.92 0-90.538-41.088-90.538-91.562 0-50.475 40.618-91.606 90.538-91.606h233.899a21.333 21.333 0 0 0 0-42.666H303.957c-73.472 0-133.29 60.202-133.29 134.314.085 73.942 59.818 134.187 133.333 134.187h233.899a21.333 21.333 0 0 0 0-42.667M708.523 615.125a64 64 0 1 0 .042 128 64 64 0 0 0-.042-128" fill="#3C405D"/><path d="M708.523 785.75a106.795 106.795 0 0 1-106.667-106.667c0-58.752 47.787-106.667 106.667-106.667 58.837 0 106.666 47.915 106.666 106.667 0 58.794-47.829 106.666-106.666 106.666m0-256c-82.347 0-149.334 66.987-149.334 149.334s66.987 149.333 149.334 149.333 149.333-66.987 149.333-149.333-66.987-149.334-149.333-149.334M473.429 696.747l38.827 36.138 38.059-37.674-38.059-75.264z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Merchantmanagement;
