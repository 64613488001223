import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * MaterialCenter 图标组件
 */
export const MaterialCenter = defineComponent({
	name: "MaterialCenter",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-MaterialCenter icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M222.464 658.347l137.813-134.614 157.739 196.694a21.333 21.333 0 1 0 33.28-26.624l-32.939-41.088 100.779-84.48 183.467 218.154a49.195 49.195 0 0 1-41.814 24.278H263.253a49.92 49.92 0 0 1-49.92-49.92V663.38a20.736 20.736 0 0 0 9.131-4.992M832 469.76a21.333 21.333 0 0 0 21.333-21.333V263.21a92.63 92.63 0 0 0-92.544-92.587H263.253a92.63 92.63 0 0 0-92.586 92.63v497.493a92.587 92.587 0 0 0 92.586 92.586h497.494a92.587 92.587 0 0 0 92.586-92.586v-184.32a21.333 21.333 0 0 0-42.666 0V729.6L638.08 524.459c-.853-1.024-2.048-1.579-2.987-2.39-1.237-.981-2.346-2.176-3.669-2.858-.128-.086-.299-.086-.427-.128A20.907 20.907 0 0 0 608 521.899l-116.352 97.536-112.81-140.8c-.257-.256-.598-.342-.811-.64-.256-.256-.342-.64-.598-.896-.81-.854-1.834-1.195-2.688-1.835-1.152-.853-2.133-1.75-3.413-2.304-1.28-.64-2.56-.896-3.968-1.28-1.28-.299-2.517-.683-3.84-.768-1.45-.085-2.773.17-4.181.341-1.28.171-2.56.256-3.84.683-1.408.427-2.646 1.28-3.926 2.048-.853.512-1.92.725-2.73 1.408-.299.213-.384.555-.64.768-.299.256-.683.341-.939.597L213.334 607.66V263.21a49.92 49.92 0 0 1 49.92-49.92h497.493c27.477 0 49.92 22.442 49.92 49.92v185.216A21.333 21.333 0 0 0 832 469.76" fill="#3C405D"/><path d="M704.427 342.656a22.23 22.23 0 1 1 .042-44.459 22.23 22.23 0 0 1 0 44.459m0-87.125c-35.84 0-64.896 29.141-64.896 64.896 0 35.84 29.099 64.896 64.854 64.896 35.797 0 64.938-29.099 64.938-64.854 0-35.797-29.141-64.938-64.896-64.938" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default MaterialCenter;
