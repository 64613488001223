import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Transferdoc 图标组件
 */
export const Transferdoc = defineComponent({
	name: "Transferdoc",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Transferdoc icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M789.333 512a21.333 21.333 0 0 0 21.334-21.333V299.008c0-47.232-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.208 128a128.512 128.512 0 0 0-120.235 85.333h-93.61c-45.782 0-83.03 38.443-83.03 85.675v511.317c0 47.232 37.248 85.675 83.03 85.675h431.274c45.782 0 83.03-38.443 83.03-85.675V618.667a21.333 21.333 0 0 0-42.667 0v191.658c0 23.723-18.133 43.008-40.363 43.008H296.363c-22.23 0-40.363-19.285-40.363-43.008V299.008C256 275.285 274.133 256 296.363 256H403.54c4.779 0 8.96-1.877 12.544-4.523a21.12 21.12 0 0 0 10.838-13.994 85.76 85.76 0 0 1 83.285-66.816c40.448 0 75.605 28.714 83.627 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256h108.97c22.23 0 40.363 19.285 40.363 43.008v191.659A21.333 21.333 0 0 0 789.333 512" fill="#3C405D"/><path d="M360.96 530.944a21.333 21.333 0 0 0 26.71-14.037 132.267 132.267 0 0 1 127.018-93.355c58.453 0 109.483 37.461 126.89 93.184a21.12 21.12 0 0 0 5.846 8.875l.299.256c1.706 1.621 3.669 2.986 5.845 3.925 1.024.427 2.133.683 3.285.981 1.28.342 2.56.726 3.968.811.598 0 1.11.341 1.707.341 2.048 0 3.925-.64 5.803-1.152a21.077 21.077 0 0 0 15.53-20.181V398.507a21.333 21.333 0 1 0-42.666 0v36.181a174.677 174.677 0 0 0-126.507-53.803 174.805 174.805 0 0 0-167.723 123.307 21.333 21.333 0 0 0 14.038 26.752m-17.366 179.883a21.333 21.333 0 0 0 42.667 0v-34.902a176.085 176.085 0 0 0 128.384 56.235c79.147 0 148.907-53.333 169.515-129.707a21.333 21.333 0 1 0-41.131-11.093 133.205 133.205 0 0 1-128.427 98.133 133.547 133.547 0 0 1-127.872-96.469 21.035 21.035 0 0 0-13.482-13.91h-.043a19.797 19.797 0 0 0-6.315-1.28c-.682-.085-1.28-.426-1.962-.426a21.333 21.333 0 0 0-21.334 21.333v112.086z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Transferdoc;
