import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Productstratification 图标组件
 */
export const Productstratification = defineComponent({
	name: "Productstratification",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Productstratification icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 469.803a21.333 21.333 0 0 1-21.333-21.334V263.211a49.92 49.92 0 0 0-49.835-49.878H682.24v63.531a21.333 21.333 0 0 1-21.333 21.333H362.24a21.29 21.29 0 0 1-21.333-21.333v-63.53H263.21a49.92 49.92 0 0 0-49.878 49.877V640h597.334v-63.53a21.333 21.333 0 1 1 42.666 0v184.32a92.63 92.63 0 0 1-92.501 92.543H263.211a92.63 92.63 0 0 1-92.544-92.544V263.211c0-51.03 41.514-92.544 92.544-92.544h497.621c50.987 0 92.501 41.514 92.501 92.544v185.258A21.333 21.333 0 0 1 832 469.803zM383.573 255.53h256v-42.198h-256v42.198zm-170.24 469.802h597.334v-42.666H213.333v42.666zm547.499 85.334c24.917 0 44.757-18.731 48.384-42.667H214.784c3.627 23.936 23.467 42.667 48.427 42.667h497.621z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Productstratification;
