import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Monitorandcontrol 图标组件
 */
export const Monitorandcontrol = defineComponent({
	name: "Monitorandcontrol",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Monitorandcontrol icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M554.667 405.333c0 35.286-28.715 64-64 64s-64-28.714-64-64 28.714-64 64-64 64 28.715 64 64m-170.667 0C384 464.171 431.872 512 490.667 512s106.666-47.83 106.666-106.667-47.872-106.666-106.666-106.666A106.795 106.795 0 0 0 384 405.333" fill="#3C405D"/><path d="M717.952 389.248c1.664 6.4 2.987 12.97 4.139 19.584a21.333 21.333 0 1 0 42.069-7.083 277.163 277.163 0 0 0-273.493-231.082A277.675 277.675 0 0 0 213.333 448c0 146.005 113.494 265.77 256.854 276.31v86.357H345.856a21.333 21.333 0 1 0 0 42.666h298.667a21.333 21.333 0 0 0 0-42.666h-131.67v-87.083c108.16-8.79 202.752-79.616 239.318-183.125a21.333 21.333 0 1 0-40.192-14.251 235.221 235.221 0 0 1-221.312 156.459A234.965 234.965 0 0 1 256 448c0-129.365 105.301-234.667 234.667-234.667a234.496 234.496 0 0 1 227.285 175.915" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Monitorandcontrol;
