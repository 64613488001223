import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Refused 图标组件
 */
export const Refused = defineComponent({
	name: "Refused",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Refused icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M490.667 213.333c80.128 0 153.984 29.654 210.645 79.147l-451.2 451.2a318.464 318.464 0 0 1-79.445-210.347c0-176.469 143.53-320 320-320m338.56 368.47a21.461 21.461 0 0 0-25.174 16.554C773.59 746.112 641.792 853.333 490.667 853.333a318.379 318.379 0 0 1-210.39-79.488L731.52 322.603a318.464 318.464 0 0 1 74.155 154.026 21.333 21.333 0 0 0 41.984-7.466 360.96 360.96 0 0 0-98.816-190.678c-.512-.64-.768-1.408-1.323-2.005-.47-.47-1.11-.64-1.579-1.067a362.41 362.41 0 0 0-255.274-104.746C290.688 170.667 128 333.355 128 533.333 128 733.312 290.688 896 490.667 896c171.306 0 320.64-121.557 355.114-288.981a21.29 21.29 0 0 0-16.554-25.216" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Refused;
