import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Warehouse 图标组件
 */
export const Warehouse = defineComponent({
	name: "Warehouse",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Warehouse icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M886.349 492.812L549.46 176.66a22.271 22.271 0 0 0-17.407-5.888 22.57 22.57 0 0 0-17.365 5.845L177.8 492.812a22.613 22.613 0 0 0 30.932 32.938l7.126-6.656V793.52c0 55.465 45.268 100.606 100.904 100.606h430.667A100.862 100.862 0 0 0 848.333 793.52v-58.367a22.613 22.613 0 0 0-45.182 0v58.367c0 30.591-25.045 55.465-55.722 55.465H316.762c-30.72 0-55.721-24.874-55.721-55.465V476.685l271.055-254.373L803.15 476.685V599.69a22.57 22.57 0 1 0 45.182 0v-80.638l7.083 6.698a22.57 22.57 0 1 0 30.933-32.938" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Warehouse;
