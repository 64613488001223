import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Topickupthegoods 图标组件
 */
export const Topickupthegoods = defineComponent({
	name: "Topickupthegoods",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Topickupthegoods icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M772.523 432a21.333 21.333 0 0 1-21.334-21.333V213.845c0-16.981-5.418-30.592-14.89-37.376-9.43-6.698-20.523-5.034-28.288-2.474L640 197.035v82.005c0 .256-.128.427-.128.725-.085 1.622-.555 3.115-.981 4.694-.299 1.152-.427 2.346-.896 3.413-.598 1.28-1.536 2.39-2.39 3.541-.768 1.024-1.408 2.134-2.304 2.987-.981.939-2.176 1.536-3.285 2.261-1.237.811-2.39 1.664-3.797 2.219-.256.085-.427.299-.683.384l-167.125 56.917a20.437 20.437 0 0 1-17.878-2.389 20.779 20.779 0 0 1-8.832-10.453c-.085-.214-.298-.299-.384-.512-.384-1.195-.256-2.39-.469-3.584-.17-1.11-.64-2.134-.64-3.286v-68.01l-28.459 9.642c-30.848 10.411-56.874 50.774-56.874 88.107v452.523c0 16.938 5.418 30.592 14.89 37.376 9.387 6.741 20.608 5.077 28.331 2.432l306.219-103.595c30.848-10.41 56.874-50.773 56.874-88.107V536.832a21.333 21.333 0 0 1 42.667 0v129.493c0 55.766-37.675 112.214-85.845 128.512L401.749 898.475a84.053 84.053 0 0 1-26.666 4.565c-14.635 0-28.374-4.31-40.15-12.8-20.821-14.933-32.725-41.13-32.725-72.021v-20.054a106.581 106.581 0 0 1-88.875-104.832V234.667C213.333 175.787 261.163 128 320 128h400c2.816 0 5.419.64 7.893 1.579 11.99 1.152 23.339 5.12 33.28 12.202 20.779 14.934 32.683 41.174 32.683 72.064v196.822A21.287 21.287 0 0 1 772.523 432zM388.096 237.227l196.65-66.56H320c-35.285 0-64 28.714-64 64v458.666c0 29.099 19.584 53.419 46.208 61.184V365.74c0-55.766 37.717-112.214 85.888-128.512zm209.237 26.581v-52.352l-124.458 42.112v52.608l124.458-42.368z" fill="#3C405D"/><path d="M405.333 559.104c0 16.213 10.326 29.312 23.126 29.312 12.757 0 23.04-13.099 23.04-29.312s-10.283-29.312-23.04-29.312c-12.8 0-23.126 13.099-23.126 29.312" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Topickupthegoods;
