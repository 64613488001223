import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Intelligentselection 图标组件
 */
export const Intelligentselection = defineComponent({
	name: "Intelligentselection",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Intelligentselection icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M886.87 134.699a21.248 21.248 0 0 0-30.123-.854L706.859 275.243a339.627 339.627 0 0 0-199.126-64.598c-71.68 0-140.202 22.614-196.992 63.019L163.968 135.125a21.248 21.248 0 1 0-29.27 30.976l142.763 134.571a338.432 338.432 0 0 0-109.909 250.155c0 187.648 152.619 340.352 340.181 340.352a339.925 339.925 0 0 0 329.899-256.427 20.181 20.181 0 0 0-39.083-9.899A299.648 299.648 0 0 1 507.733 850.86a300.245 300.245 0 0 1-299.946-299.947 299.776 299.776 0 0 1 299.946-299.99 299.947 299.947 0 0 1 293.76 238.507 19.925 19.925 0 0 0 23.808 15.659 20.053 20.053 0 0 0 15.616-23.765 336.981 336.981 0 0 0-50.986-120.79 341.248 341.248 0 0 0-49.963-57.984l146.005-137.685a21.248 21.248 0 0 0 .854-30.165" fill="#3C405D"/><path d="M633.6 433.067v232.277a11.947 11.947 0 0 1-11.947 11.904H389.42a11.904 11.904 0 0 1-11.862-11.947V433.11c0-6.528 5.334-11.861 11.862-11.861h232.234c6.571 0 11.947 5.333 11.947 11.861zm-244.224-54.443a54.613 54.613 0 0 0-54.443 54.443V665.3a54.613 54.613 0 0 0 54.528 54.571h232.235a54.613 54.613 0 0 0 54.613-54.613V433.067a54.613 54.613 0 0 0-54.613-54.528H389.419z" fill="#3C405D"/><path d="M569.173 463.915h-128a21.333 21.333 0 0 0 0 42.666h128a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Intelligentselection;
