import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Forward 图标组件
 */
export const Forward = defineComponent({
	name: "Forward",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Forward icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M871.723 572.544a20.053 20.053 0 0 0-23.382 16.299 320.597 320.597 0 0 1-316.202 264.49c-177.11 0-321.195-144.085-321.195-321.194a321.11 321.11 0 0 1 587.52-179.584c10.41 15.402 19.584 31.872 27.179 48.938 4.522 10.155 16.384 14.763 26.581 10.155a19.797 19.797 0 0 0 10.07-10.24 19.84 19.84 0 0 0 2.9-9.813V272.81a20.181 20.181 0 0 0-40.32 0v48.17a360.79 360.79 0 0 0-592.554 9.174 359.68 359.68 0 0 0-61.653 201.984c0 199.338 162.176 361.472 361.472 361.472A360.875 360.875 0 0 0 888.02 595.925a20.181 20.181 0 0 0-16.298-23.381" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Forward;
