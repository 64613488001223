import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Central 图标组件
 */
export const Central = defineComponent({
	name: "Central",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Central icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M796.459 453.333c-24.534 0-45.483 15.062-54.272 36.438h-79.062a21.333 21.333 0 0 0-21.333 21.333l.085.47-.085.426c0 70.57-57.43 128-128 128s-128-57.43-128-128 57.43-128 128-128c29.397 0 58.027 10.197 80.81 28.757a21.419 21.419 0 0 0 30.038-3.029 21.419 21.419 0 0 0-3.072-30.08 171.136 171.136 0 0 0-107.776-38.315c-40.704 0-77.653 14.934-107.008 38.827l-78.763-78.763c8.32-13.184 13.312-28.672 13.312-45.397A85.333 85.333 0 1 0 256 341.333c14.89 0 28.587-4.138 40.79-10.837l80.34 80.341A168.96 168.96 0 0 0 343.126 512c0 40.277 14.592 76.843 37.974 106.027l-78.55 78.592A84.907 84.907 0 0 0 256 682.667 85.333 85.333 0 1 0 341.333 768c0-14.379-3.882-27.733-10.24-39.637l80.555-80.512a168.96 168.96 0 0 0 102.144 34.816c87.125 0 158.293-65.792 168.619-150.23h59.264A58.539 58.539 0 0 0 855.125 512c0-32.384-26.282-58.667-58.666-58.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Central;
