import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * TransferDocManagement 图标组件
 */
export const TransferDocManagement = defineComponent({
	name: "TransferDocManagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-TransferDocManagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M492.459 853.333H296.363c-22.23 0-40.363-19.285-40.363-43.008V299.008C256 275.285 274.133 256 296.363 256H403.54c4.779 0 8.96-1.877 12.502-4.523a21.077 21.077 0 0 0 10.88-13.994 85.76 85.76 0 0 1 83.285-66.816c40.448 0 75.605 28.714 83.627 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256h108.97c22.23 0 40.363 19.285 40.363 43.008v237.867a21.333 21.333 0 1 0 42.667 0V299.008c0-47.232-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.208 128a128.512 128.512 0 0 0-120.235 85.333h-93.61c-45.782 0-83.03 38.443-83.03 85.675v511.317c0 47.232 37.248 85.675 83.03 85.675h196.096a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M641.195 398.507v36.181a174.933 174.933 0 0 0-126.55-53.845 174.805 174.805 0 0 0-167.68 123.392 21.333 21.333 0 1 0 40.747 12.629 132.352 132.352 0 0 1 126.933-93.355c58.496 0 109.526 37.462 126.934 93.227a20.907 20.907 0 0 0 5.802 8.832c.086.128.214.17.342.256 1.749 1.621 3.626 2.987 5.802 3.968 1.067.47 2.176.64 3.328.981 1.323.299 2.56.726 3.968.768.598.086 1.11.342 1.707.342 2.048 0 3.925-.598 5.76-1.152h.043a21.035 21.035 0 0 0 15.53-20.182V398.507a21.333 21.333 0 0 0-42.666 0m64.597 284.16a64 64 0 1 0 0 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M705.792 853.333a106.795 106.795 0 0 1-106.667-106.666c0-58.795 47.83-106.667 106.667-106.667s106.667 47.872 106.667 106.667-47.83 106.666-106.667 106.666m0-256c-56.49 0-105.216 31.915-130.56 78.336-1.024.342-2.09.342-3.115.811a132.01 132.01 0 0 1-57.429 13.013 133.547 133.547 0 0 1-127.957-96.512 20.95 20.95 0 0 0-19.798-15.189c-.682-.043-1.28-.384-1.962-.384a21.333 21.333 0 0 0-21.334 21.333v112.086a21.333 21.333 0 1 0 42.667 0v-34.902a176 176 0 0 0 128.384 56.235c15.019 0 29.61-2.347 43.861-6.059-.981 6.784-2.09 13.526-2.09 20.566 0 82.346 66.986 149.333 149.333 149.333s149.333-66.987 149.333-149.333-66.986-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default TransferDocManagement;
