import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inventoryhealth 图标组件
 */
export const Inventoryhealth = defineComponent({
	name: "Inventoryhealth",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inventoryhealth icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M596.318 482.133c8.363 8.363 12.97 19.499 12.97 31.275s-4.607 22.912-12.97 31.275L491.102 649.94l-104.79-105.258a43.99 43.99 0 0 1-12.97-31.275c0-11.776 4.608-22.912 12.97-31.275 8.363-8.362 19.5-12.97 31.318-12.97 11.776 0 22.912 4.608 31.275 12.97l27.306 27.35a21.163 21.163 0 0 0 30.208 0l27.307-27.307a45.227 45.227 0 0 1 62.592 0m-92.715-30.208l-12.288 12.288-12.245-12.288c-32.853-32.853-90.07-32.853-122.923 0a86.315 86.315 0 0 0-25.472 61.44c0 23.168 9.046 45.013 25.472 61.44l119.766 120.32c3.968 4.01 9.386 6.272 15.06 6.315a21.461 21.461 0 0 0 15.105-6.23l120.405-120.405c16.427-16.427 25.472-38.272 25.472-61.44 0-23.21-9.045-45.013-25.472-61.44-32.853-32.939-90.07-32.853-122.88 0" fill="#3D405D"/><path d="M846.6 452.181L507.188 133.59a21.205 21.205 0 0 0-16.512-5.504 21.163 21.163 0 0 0-16.512 5.504L134.75 452.181a21.29 21.29 0 1 0 29.184 31.104l9.6-8.96v279.766a100.139 100.139 0 0 0 100.224 99.882h433.835c55.296 0 100.266-44.8 100.266-99.882v-71.552a21.333 21.333 0 0 0-42.666 0v71.552c0 31.53-25.856 57.216-57.6 57.216H273.758a57.472 57.472 0 0 1-57.6-57.216V434.26l274.517-257.664 274.518 257.664V554.54a21.333 21.333 0 1 0 42.666 0v-80.214l9.558 8.96a21.12 21.12 0 0 0 30.122-.938 21.29 21.29 0 0 0-.938-30.166" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inventoryhealth;
