import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Settlementefficiencyanalysis 图标组件
 */
export const Settlementefficiencyanalysis = defineComponent({
	name: "Settlementefficiencyanalysis",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Settlementefficiencyanalysis icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M405.333 853.333h-108.97c-22.23 0-40.363-19.285-40.363-43.008V299.008C256 275.285 274.133 256 296.363 256H403.54c4.779 0 8.96-1.877 12.502-4.48a21.205 21.205 0 0 0 10.88-14.08 85.76 85.76 0 0 1 83.285-66.773c40.448 0 75.605 28.714 83.627 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256h108.97c22.23 0 40.363 19.285 40.363 43.008v278.741a21.333 21.333 0 0 0 42.667 0V299.008c0-47.275-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.208 128a128.512 128.512 0 0 0-120.235 85.333h-93.61c-45.782 0-83.03 38.4-83.03 85.675v511.317c0 47.275 37.248 85.675 83.03 85.675h108.97a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M659.541 544.853a21.333 21.333 0 0 0 0-42.666H534.656v-23.894l103.765-97.92a21.333 21.333 0 1 0-29.269-30.976l-98.133 92.587-98.176-92.587a21.333 21.333 0 1 0-29.27 31.019L491.99 482.688v19.541H360.875a21.333 21.333 0 0 0 0 42.667h131.114v59.99H360.875a21.333 21.333 0 0 0 0 42.666h131.114v93.781a21.333 21.333 0 1 0 42.667 0v-93.781h124.885a21.333 21.333 0 1 0 0-42.667H534.656v-60.032h124.885zm194.603 160.683a20.736 20.736 0 0 0-5.675-8.235c-1.621-1.408-3.541-2.218-5.461-2.986a20.65 20.65 0 0 0-10.283-2.987H767.66a21.29 21.29 0 1 0 0 42.667h21.632l-70.699 82.986-100.821-80.469a21.333 21.333 0 0 0-29.014 2.219L480.3 856.619a21.333 21.333 0 0 0 31.445 28.928l94.976-103.254 101.419 80.939a21.333 21.333 0 0 0 29.568-2.816l76.501-89.941v10.069a21.333 21.333 0 0 0 42.667 0v-65.11a20.608 20.608 0 0 0-2.731-9.898" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Settlementefficiencyanalysis;
