import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * RealTimeprediction 图标组件
 */
export const RealTimeprediction = defineComponent({
	name: "RealTimeprediction",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-RealTimeprediction icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M535.723 530.987h234.666a20.181 20.181 0 0 0 0-40.32H555.904V299.264a20.181 20.181 0 0 0-40.363 0v209.75c0 .34.171.597.214.895-.043.342-.214.598-.214.896 0 11.136 9.046 20.182 20.182 20.182M865.024 694.4c-1.536-1.28-3.328-2.005-5.077-2.816a20.864 20.864 0 0 0-10.624-3.243h-65.11a21.333 21.333 0 0 0 0 42.667h21.632l-70.656 83.03-100.821-80.47a21.333 21.333 0 0 0-29.013 2.219L496.896 853.675a21.419 21.419 0 0 0 15.701 35.84c5.76 0 11.52-2.347 15.702-6.912l95.018-103.254 101.419 80.939a21.248 21.248 0 0 0 29.525-2.859l76.544-89.898V777.6a21.333 21.333 0 1 0 42.667 0v-65.067a20.565 20.565 0 0 0-2.816-10.026 20.65 20.65 0 0 0-5.632-8.107" fill="#3C405D"/><path d="M532.181 170.667c-199.381 0-361.514 162.176-361.514 361.514a361.259 361.259 0 0 0 246.613 342.87 20.181 20.181 0 0 0 12.8-38.272A320.768 320.768 0 0 1 211.03 532.18c0-177.066 144.085-321.194 321.151-321.194S853.333 355.072 853.333 532.18a324.608 324.608 0 0 1-5.973 61.952 20.139 20.139 0 0 0 16 23.638 20.395 20.395 0 0 0 23.595-16 370.176 370.176 0 0 0 6.741-69.59c0-199.338-162.176-361.514-361.515-361.514" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default RealTimeprediction;
