import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Crowdmanagement 图标组件
 */
export const Crowdmanagement = defineComponent({
	name: "Crowdmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Crowdmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M347.392 343.595c0-26.027 7.979-51.03 22.955-72.534a137.77 137.77 0 0 1 191.658-32.554c-2.602-.086-5.162-.47-7.765-.47a180.139 180.139 0 0 0-147.925 75.947 167.979 167.979 0 0 0-30.72 96.981c0 4.694 1.066 9.216 1.45 13.867a126.72 126.72 0 0 1-29.653-81.237m372.395 132.48a21.333 21.333 0 0 0-39.339-16.512c-20.907 49.578-70.443 81.664-126.208 81.664-74.965 0-135.979-58.454-135.979-130.262 0-26.026 7.979-51.029 22.955-72.533a137.685 137.685 0 0 1 113.024-57.685c45.355 0 87.595 21.589 112.939 57.728a129.103 129.103 0 0 1 13.226 23.893 21.333 21.333 0 1 0 39.254-16.64 173.184 173.184 0 0 0-17.579-31.744 176.597 176.597 0 0 0-67.413-57.173 20.523 20.523 0 0 0-3.456-10.24 180.267 180.267 0 0 0-147.84-75.904c-59.307 0-114.646 28.416-147.926 75.946a167.979 167.979 0 0 0-30.72 96.982c0 64.341 36.438 122.666 95.147 152.746 10.07 17.067 22.912 32.128 38.144 44.886h-145.92c-66.987 0-121.43 54.826-121.43 122.368a122.667 122.667 0 0 0 80.598 115.2 121.6 121.6 0 0 0 111.701 74.538h155.222a21.333 21.333 0 1 0 0-42.666H362.965c-43.392 0-78.72-35.755-78.72-79.702 0-43.946 35.328-79.786 78.72-79.786h155.222a21.333 21.333 0 1 0 0-42.667H362.965c-64.853 0-117.504 51.712-120.789 116.224a79.957 79.957 0 0 1-28.843-61.141 79.36 79.36 0 0 1 78.763-79.702h256.427c.64 0 1.194-.341 1.877-.426 1.28.085 2.517.426 3.84.426 72.96 0 137.984-42.325 165.547-107.818m-7.851 190.293a59.947 59.947 0 1 0 0 119.893 59.947 59.947 0 0 0 0-119.893" fill="#3C405D"/><path d="M711.893 832.981a106.795 106.795 0 0 1-106.666-106.666c0-58.795 47.829-106.667 106.666-106.667 58.88 0 106.667 47.872 106.667 106.667S770.773 832.98 711.893 832.98m0-256c-82.346 0-149.333 66.987-149.333 149.334s66.987 149.333 149.333 149.333 149.334-66.987 149.334-149.333S794.24 576.98 711.893 576.98" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Crowdmanagement;
