import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Close 图标组件
 */
export const Close = defineComponent({
	name: "Close",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Close icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M830.123 573.653a18.944 18.944 0 0 0-22.059 15.36 322.901 322.901 0 0 1-318.55 266.71c-178.346 0-323.413-145.152-323.413-323.499a323.328 323.328 0 0 1 323.456-323.499 323.456 323.456 0 0 1 318.379 266.112 19.03 19.03 0 0 0 37.504-6.698 357.547 357.547 0 0 0-56.15-138.112 361.387 361.387 0 0 0-299.775-159.36A361.43 361.43 0 0 0 128 532.224c0 199.339 162.176 361.557 361.515 361.557a360.832 360.832 0 0 0 356.01-298.069 18.987 18.987 0 0 0-15.402-22.059" fill="#3C405D"/><path d="M655.787 363.776a20.181 20.181 0 0 0-28.544 0L487.296 503.723 347.349 363.776a20.181 20.181 0 0 0-28.458 28.459L458.837 532.18 318.891 672.128a20.181 20.181 0 0 0 28.458 28.501l139.947-139.946 139.947 139.946a20.053 20.053 0 0 0 28.501 0 20.181 20.181 0 0 0 0-28.458L515.797 532.224l139.947-139.947a20.181 20.181 0 0 0 0-28.501" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Close;
