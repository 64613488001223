import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Monthlyclosing 图标组件
 */
export const Monthlyclosing = defineComponent({
	name: "Monthlyclosing",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Monthlyclosing icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M618.667 598.23a21.333 21.333 0 0 0 0-42.667h-83.115V547.2l72.15-68.053a21.333 21.333 0 0 0-29.227-31.062l-65.92 62.166-65.92-62.166a21.333 21.333 0 0 0-29.27 31.019l75.52 71.253v5.248h-87.552a21.333 21.333 0 0 0 0 42.667h87.552v30.592h-87.552a21.333 21.333 0 0 0 0 42.667h87.552v60.885a21.333 21.333 0 1 0 42.667 0v-60.843h83.115a21.333 21.333 0 1 0 0-42.666h-83.115v-30.635h83.115z" fill="#3C405D"/><path d="M213.333 341.333v-19.541c0-35.328 28.715-64 64-64h62.251v23.083a21.333 21.333 0 1 0 42.667 0v-23.083h259.541v23.083a21.333 21.333 0 1 0 42.667 0v-23.083h65.792c35.285 0 64 28.672 64 64v19.541H213.333zm622.251 128A21.333 21.333 0 0 0 856.917 448V321.792c0-58.837-47.829-106.667-106.666-106.667h-65.792V192a21.333 21.333 0 0 0-42.667 0v23.125H382.251V192a21.333 21.333 0 0 0-42.667 0v23.125h-62.25a106.752 106.752 0 0 0-106.667 106.667v424.875c0 58.794 47.829 106.666 106.666 106.666h472.918c58.837 0 106.666-47.872 106.666-106.666V576a21.333 21.333 0 0 0-42.666 0v170.667c0 35.285-28.715 64-64 64H277.333c-35.285 0-64-28.715-64-64V384h600.918v64a21.333 21.333 0 0 0 21.333 21.333z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Monthlyclosing;
