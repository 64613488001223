import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Configuration 图标组件
 */
export const Configuration = defineComponent({
	name: "Configuration",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Configuration icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M234.667 320h128v42.667a21.333 21.333 0 0 0 42.666 0V320h467.542a21.333 21.333 0 0 0 0-42.667H405.333v-42.666a21.333 21.333 0 0 0-42.666 0v42.666h-128a21.333 21.333 0 0 0 0 42.667M872.875 488.917H746.667V444.46a21.333 21.333 0 0 0-42.667 0v44.458H234.667a21.333 21.333 0 0 0 0 42.667H704v40.832a21.333 21.333 0 1 0 42.667 0v-40.832h126.208a21.333 21.333 0 0 0 0-42.667m0 213.334H405.333v-42.667a21.333 21.333 0 0 0-42.666 0v42.667h-128a21.333 21.333 0 0 0 0 42.666h128v42.667a21.333 21.333 0 0 0 42.666 0v-42.667h467.542a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Configuration;
