import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Service 图标组件
 */
export const Service = defineComponent({
	name: "Service",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Service icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M744.671 375.595a21.333 21.333 0 0 0-30.165 0L515.892 574.25l-197.76-198.656a94.592 94.592 0 0 1-27.86-67.243 95.232 95.232 0 0 1 162.431-67.243l48.555 48.555a21.333 21.333 0 0 0 30.165 0l48.64-48.555a95.147 95.147 0 0 1 152.32 24.576 21.333 21.333 0 1 0 38.144-19.157 137.984 137.984 0 0 0-220.715-35.584l-33.536 33.493-33.493-33.493a137.984 137.984 0 0 0-194.859 0 136.875 136.875 0 0 0-40.362 97.408c0 36.779 14.336 71.381 40.362 97.408L500.66 619.52a21.174 21.174 0 0 0 15.062 6.272h.042c5.675 0 11.094-2.219 15.062-6.187L744.628 405.76a21.333 21.333 0 0 0 0-30.165M840.628 435.072a21.675 21.675 0 0 0-26.112 15.104 311.21 311.21 0 0 1-199.04 213.504c-.298.128-.426.341-.768.47a21.035 21.035 0 0 0-15.36 20.138V830.08a21.333 21.333 0 1 0 42.667 0V698.795a353.45 353.45 0 0 0 213.76-237.568 21.419 21.419 0 0 0-15.147-26.155M421.258 670.165a20.907 20.907 0 0 0-8.747-6.186 311.04 311.04 0 0 1-199.85-213.931 21.675 21.675 0 0 0-26.198-15.061 21.333 21.333 0 0 0-15.061 26.154 353.195 353.195 0 0 0 214.613 237.952V830.08a21.333 21.333 0 0 0 42.667 0v-144a21.035 21.035 0 0 0-7.424-15.915" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Service;
