import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Businessanalysis 图标组件
 */
export const Businessanalysis = defineComponent({
	name: "Businessanalysis",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Businessanalysis icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M640.939 417.963V403.54a21.333 21.333 0 0 0-42.667 0v14.422c0 47.061-38.272 85.333-85.333 85.333s-85.334-38.272-85.334-85.333v-7.296c0-1.622-.554-3.03-.853-4.523v-2.603a21.333 21.333 0 0 0-42.667 0v14.422c0 47.061-38.357 85.333-85.333 85.333-47.061 0-85.333-38.272-85.333-85.333V256h598.186v161.963c0 47.061-38.272 85.333-85.333 85.333s-85.333-38.272-85.333-85.333m192-204.63c-.299 0-.598.171-.854.171-.341 0-.597-.17-.896-.17H192.896a21.248 21.248 0 0 0-19.797 13.653 20.821 20.821 0 0 0-2.432 9.472v181.504c0 35.626 14.677 67.84 38.229 91.05v280.32c0 58.838 47.83 106.667 106.667 106.667h90.666a21.333 21.333 0 0 0 0-42.667h-90.581c-35.285 0-64-28.714-64-64V536.747c14.592 5.802 30.421 9.216 47.104 9.216a127.83 127.83 0 0 0 107.093-58.326 127.83 127.83 0 0 0 107.051 58.326 127.872 127.872 0 0 0 106.667-57.515 127.872 127.872 0 0 0 106.666 57.515c16.342 0 31.83-3.371 46.208-8.96v101.205a21.333 21.333 0 0 0 42.667 0v-128l-.085-.384a127.403 127.403 0 0 0 39.253-91.861V234.667a21.333 21.333 0 0 0-21.333-21.334" fill="#3C405D"/><path d="M845.824 701.141c-1.664-1.408-3.541-2.176-5.419-2.986a20.821 20.821 0 0 0-10.24-3.03h-65.194a21.333 21.333 0 0 0 0 42.667h21.589l-70.57 83.03-100.822-80.47a21.333 21.333 0 0 0-29.013 2.219L477.653 860.416a21.419 21.419 0 0 0 15.702 35.84c5.76 0 11.52-2.304 15.701-6.87l95.061-103.295 101.419 80.981c9.003 7.168 22.059 5.973 29.525-2.859l76.544-89.984v10.112a21.333 21.333 0 1 0 42.667 0v-65.024a20.95 20.95 0 0 0-2.773-9.984 21.035 21.035 0 0 0-5.675-8.192" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Businessanalysis;
