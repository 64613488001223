import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * WarehouseReturnManagement 图标组件
 */
export const WarehouseReturnManagement = defineComponent({
	name: "WarehouseReturnManagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-WarehouseReturnManagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M488.917 853.333H296.363c-22.23 0-40.363-19.285-40.363-43.008V299.008C256 275.285 274.133 256 296.363 256H403.54c4.779 0 8.96-1.877 12.502-4.523a21.077 21.077 0 0 0 10.88-13.994 85.76 85.76 0 0 1 83.328-66.816c40.405 0 75.562 28.714 83.584 68.266A21.333 21.333 0 0 0 614.699 256c.896 0 1.792-.17 2.688-.256.426.043.81.256 1.28.256H727.68c22.23 0 40.32 19.285 40.32 43.008v236.117a21.333 21.333 0 0 0 42.667 0V299.008c0-47.232-37.248-85.675-82.987-85.675h-97.067A128.299 128.299 0 0 0 510.251 128c-54.315 0-102.4 35.157-120.278 85.333h-93.61c-45.782 0-83.03 38.443-83.03 85.675v511.317c0 47.232 37.248 85.675 83.03 85.675h192.554a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M341.333 554.667A21.333 21.333 0 0 0 362.667 576h295.125a21.333 21.333 0 0 0 21.333-21.333 20.907 20.907 0 0 0-7.381-15.83c-.256-.341-.384-.725-.64-1.024l-88.021-88.021A21.333 21.333 0 0 0 552.917 480l53.334 53.333H362.667a21.333 21.333 0 0 0-21.334 21.334m360.918 128a64 64 0 1 0 0 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M702.25 853.333a106.795 106.795 0 0 1-106.666-106.666c0-58.795 47.83-106.667 106.667-106.667s106.666 47.872 106.666 106.667-47.829 106.666-106.666 106.666m0-256c-82.347 0-149.334 66.987-149.334 149.334S619.904 896 702.251 896s149.333-66.987 149.333-149.333-66.987-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default WarehouseReturnManagement;
