import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * StickerPlan 图标组件
 */
export const StickerPlan = defineComponent({
	name: "StickerPlan",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-StickerPlan icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M831.703 637.91H613.036a21.333 21.333 0 0 0 0 42.666h218.667a21.333 21.333 0 0 0 0-42.667m0 85.334H613.036a21.333 21.333 0 0 0 0 42.666h218.667a21.333 21.333 0 0 0 0-42.666m0 87.125H613.036a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M211.116 455.808L459.82 205.867a354.176 354.176 0 0 1-248.704 249.941M510.85 128c-7.254 0-14.422.17-21.547.555l-.384.085a20.352 20.352 0 0 0-14.933 5.845l-332.8 334.507a19.755 19.755 0 0 0-11.691 15.275c-.256 1.024-.725 1.962-.81 3.029a392.107 392.107 0 0 0-.64 23.51c-.043 211.114 171.733 382.847 382.762 382.847a413.867 413.867 0 0 0 25.301-.853 20.139 20.139 0 0 0 18.859-21.333c-.64-11.094-9.941-18.432-21.419-18.902a334.507 334.507 0 0 1-22.741.768c-188.843 0-342.485-153.6-342.485-342.528 0-1.92.17-3.84.213-5.802a393.813 393.813 0 0 0 339.541-336.598l2.774-.085c188.842 0 342.485 153.6 342.485 342.485 0 20.054-1.707 40.107-5.205 59.606a20.139 20.139 0 1 0 39.68 6.997 380.843 380.843 0 0 0 5.845-66.56C893.655 299.733 721.879 128 510.807 128" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default StickerPlan;
