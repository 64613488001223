import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inventorysystem 图标组件
 */
export const Inventorysystem = defineComponent({
	name: "Inventorysystem",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inventorysystem icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.832 170.667c50.987 0 92.501 41.514 92.501 92.544v230.57a21.333 21.333 0 1 1-42.666 0v-230.57a49.92 49.92 0 0 0-49.835-49.878H682.24v63.531a21.287 21.287 0 0 1-21.333 21.333H362.24a21.333 21.333 0 0 1-21.333-21.333v-63.53H263.21a49.92 49.92 0 0 0-49.878 49.877v497.578a49.92 49.92 0 0 0 49.878 49.878h248.362a21.333 21.333 0 1 1 0 42.666H263.211a92.63 92.63 0 0 1-92.544-92.544V263.211c0-51.03 41.514-92.544 92.544-92.544h497.621zM383.573 255.53h256v-42.198h-256v42.198z" fill="#3D405D"/><path d="M363.99 525.781H493.78a21.333 21.333 0 0 0 0-42.666H363.99a21.333 21.333 0 0 0 0 42.666m0-99.541h40.96a21.333 21.333 0 0 0 0-42.667h-40.96a21.333 21.333 0 0 0 0 42.667M810.24 746.453a12.63 12.63 0 0 1-12.63 12.587H609.494a12.587 12.587 0 0 1-12.586-12.587v-136.96c0-6.997 5.632-12.629 12.586-12.629h188.16c6.912 0 12.587 5.632 12.587 12.587v137.002zm-12.63-192.256H609.494c-20.565 0-37.802 11.776-47.317 28.459H363.989a21.333 21.333 0 0 0 0 42.667h190.294v56.874H363.947a21.333 21.333 0 0 0 0 42.667H554.24v21.59c0 30.463 24.747 55.253 55.21 55.253h188.16c30.465 0 55.254-24.747 55.254-55.254v-136.96c0-30.506-24.747-55.296-55.296-55.296zm18.305 256.043h-224a21.333 21.333 0 0 0 0 42.667h224a21.333 21.333 0 0 0 0-42.667" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inventorysystem;
