import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Tools 图标组件
 */
export const Tools = defineComponent({
	name: "Tools",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Tools icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M642.901 473.728a21.333 21.333 0 0 0 30.166 30.165L819.2 357.717a105.813 105.813 0 0 0 31.275-75.392c0-28.458-11.136-55.253-31.232-75.392l-5.035-5.077a106.795 106.795 0 0 0-150.87 0L469.164 396.032l-51.968-51.968a21.333 21.333 0 0 0-30.166 30.165l66.944 66.944c0 .043 0 .086.086.128l.128.086 48.81 48.81L250.112 743.04a21.973 21.973 0 0 0-5.59-4.864 20.821 20.821 0 0 0-25.855 4.267l-.299.213c-.17.17-.256.427-.427.64-.853.981-1.877 1.75-2.56 2.944L173.1 820.907c-.427.81-.47 1.664-.854 2.517-.512 1.365-1.066 2.688-1.322 4.139-.256 1.408-.256 2.688-.256 4.096 0 1.322 0 2.602.341 3.925.299 1.493.853 2.816 1.45 4.181.342.854.385 1.707.854 2.518.17.298.555.384.725.682a20.267 20.267 0 0 0 7.168 6.998c2.56 1.45 5.547 1.962 8.491 2.261.64.043 1.28.512 1.963.512.341 0 .64-.256.981-.256a21.29 21.29 0 0 0 9.77-2.73h.129l73.386-43.563a21.333 21.333 0 0 0 7.51-29.227c-.854-1.45-2.134-2.432-3.286-3.627l253.014-252.97 112 112a21.248 21.248 0 0 0 30.165 0 21.333 21.333 0 0 0 0-30.166l-176-176 194.133-194.176A64.213 64.213 0 0 1 784 231.98l5.035 5.077c12.074 12.075 18.773 28.16 18.773 45.27 0 17.109-6.656 33.151-18.773 45.226L642.9 473.728z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Tools;
