import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Health 图标组件
 */
export const Health = defineComponent({
	name: "Health",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Health icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M799.83 309.334A180.608 180.608 0 0 0 671.146 256c-48.598 0-94.294 18.944-128.683 53.333l-51.968 51.968-51.883-51.968A180.608 180.608 0 0 0 309.888 256c-48.597 0-94.293 18.944-128.682 53.333a182.187 182.187 0 0 0 0 257.365l293.46 294.912a21.205 21.205 0 0 0 30.123.085l140.544-139.008a21.333 21.333 0 0 0-29.994-30.293L489.94 816.47l-278.57-279.894a139.52 139.52 0 0 1 0-197.077c52.65-52.65 144.341-52.65 197.034 0l67.03 67.115a21.333 21.333 0 0 0 30.165 0l67.03-67.03c52.693-52.693 144.383-52.693 197.034 0 26.368 26.326 40.832 61.355 40.832 98.475 0 37.205-14.464 72.192-40.832 98.56l-33.238 33.237a21.333 21.333 0 1 0 30.166 30.166l33.28-33.28a180.693 180.693 0 0 0 53.333-128.683c0-48.597-18.944-94.293-53.333-128.682" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Health;
