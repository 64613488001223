import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Transferexecution 图标组件
 */
export const Transferexecution = defineComponent({
	name: "Transferexecution",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Transferexecution icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M837.973 596.523a21.333 21.333 0 0 0-26.112 15.018 310.016 310.016 0 0 1-298.837 228.267c-137.643.043-260.053-92.203-297.728-224.299a20.907 20.907 0 0 0-20.565-15.232c-.896-.128-1.75-.554-2.731-.554a21.333 21.333 0 0 0-21.333 21.333v240.128a21.333 21.333 0 0 0 42.666 0V714.837c63.062 101.931 175.872 167.68 299.691 167.68a352.725 352.725 0 0 0 340.01-259.84 21.333 21.333 0 0 0-15.018-26.154M190.72 452.437a21.333 21.333 0 0 0 26.71-14.037 308.01 308.01 0 0 1 295.594-217.173A308.224 308.224 0 0 1 808.534 438.1c2.005 6.4 6.826 10.923 12.586 13.227 1.024.427 2.133.64 3.243.939 1.109.256 2.133.597 3.285.682.853.086 1.579.427 2.432.427a21.26 21.26 0 0 0 21.333-21.333V192.085a21.29 21.29 0 1 0-42.666 0v148.566a349.867 349.867 0 0 0-295.723-162.048 350.507 350.507 0 0 0-336.299 247.21 21.333 21.333 0 0 0 14.038 26.71" fill="#3C405D"/><path d="M441.003 694.613a21.248 21.248 0 0 0 30.165 0L619.221 546.56c.427-.512.64-1.152 1.067-1.664.512-.427 1.152-.597 1.621-1.067a21.29 21.29 0 0 0 0-30.165L471.168 362.923a21.333 21.333 0 0 0-30.165 30.165l135.68 135.68-135.68 135.68a21.333 21.333 0 0 0 0 30.123" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Transferexecution;
