import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Merchandise 图标组件
 */
export const Merchandise = defineComponent({
	name: "Merchandise",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Merchandise icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.79 853.333H263.167a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621c51.03 0 92.587 41.514 92.587 92.501v185.259a21.333 21.333 0 1 1-42.667 0V263.168a49.92 49.92 0 0 0-49.962-49.835H263.168a49.92 49.92 0 0 0-49.835 49.835v497.621a49.92 49.92 0 0 0 49.835 49.878h497.621a49.92 49.92 0 0 0 49.92-49.878V576.427a21.333 21.333 0 0 1 42.667 0v184.362a92.63 92.63 0 0 1-92.63 92.544" fill="#3C405D"/><path d="M511.573 555.99c-82.346 0-149.333-66.433-149.333-148.054v-87.51a21.333 21.333 0 0 1 42.667 0v87.467c0 58.112 47.829 105.387 106.666 105.387 58.752 0 106.667-47.275 106.667-105.387v-87.466a21.333 21.333 0 0 1 42.667 0v87.466c0 81.622-66.987 148.054-149.334 148.054" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Merchandise;
