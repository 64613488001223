import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Purchaseprice 图标组件
 */
export const Purchaseprice = defineComponent({
	name: "Purchaseprice",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Purchaseprice icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.333 832a32 32 0 1 0 0 64 32 32 0 0 0 0-64m277.334 0a32 32 0 1 0 0 64 32 32 0 0 0 0-64M827.776 557.696a21.205 21.205 0 0 0-27.307 12.8 169.515 169.515 0 0 1-158.634 112.213H290.56l-26.283-341.333h377.6a169.259 169.259 0 0 1 159.275 113.835 21.333 21.333 0 0 0 40.277-14.038 211.968 211.968 0 0 0-199.594-142.506H260.992l-5.077-65.664c-.086-1.28-.683-2.347-.982-3.542-.298-1.237-.512-2.56-1.024-3.712a20.907 20.907 0 0 0-2.773-4.096c-.64-.81-1.11-1.706-1.877-2.346a19.2 19.2 0 0 0-4.096-2.774c-.939-.597-1.792-1.28-2.816-1.664-1.366-.512-2.859-.64-4.352-.853-1.152-.256-2.134-.683-3.328-.683H192A21.29 21.29 0 1 0 192 256h22.912l41.13 534.955c.129 1.365.683 2.56 1.025 3.84.298 1.109.469 2.304.938 3.328.683 1.493 1.707 2.688 2.646 3.968.682.853 1.152 1.792 1.92 2.56 1.28 1.194 2.773 2.005 4.266 2.858.854.47 1.494 1.152 2.39 1.536a21.248 21.248 0 0 0 8.106 1.622h469.334a21.333 21.333 0 0 0 0-42.667H297.088l-3.285-42.667h348.032a212.267 212.267 0 0 0 198.741-140.33 21.333 21.333 0 0 0-12.8-27.307" fill="#3C405D"/><path d="M620.416 517.333a21.333 21.333 0 0 0 0-42.666h-62.55l-.085-.171 56.832-53.632a21.333 21.333 0 0 0-29.226-31.061l-49.835 47.018-49.792-47.018a21.333 21.333 0 0 0-29.27 31.061l56.96 53.803h-63.7a21.333 21.333 0 0 0 0 42.666h65.791v16H449.75a21.333 21.333 0 0 0 0 42.667h65.792v44.459a21.333 21.333 0 1 0 42.667 0V576h62.208a21.333 21.333 0 0 0 0-42.667h-62.165v-16h62.165z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Purchaseprice;
