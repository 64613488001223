import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Commodityfilingmanagement 图标组件
 */
export const Commodityfilingmanagement = defineComponent({
	name: "Commodityfilingmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Commodityfilingmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M835.03 233.77c-1.451-.64-2.731-1.45-4.353-1.791l-314.666-61.014a19.072 19.072 0 0 0-6.23.086 19.712 19.712 0 0 0-7.253-.043l-309.333 60.97c-1.579.342-2.859 1.153-4.267 1.793a21.035 21.035 0 0 0-18.261 20.736v286.165c0 1.45.554 2.73.853 4.096 8.619 175.573 173.739 287.744 337.365 308.31a19.541 19.541 0 0 0 2.731.17l.427-.043h.341a19.541 19.541 0 0 0 2.73-.128 421.613 421.613 0 0 0 29.44-4.693 21.333 21.333 0 1 0-8.191-41.856 399.649 399.649 0 0 1-24.32 3.84c-148.864-19.499-298.667-121.515-298.667-279.893V271.488l295.979-58.325 301.312 58.368v262.4a21.333 21.333 0 1 0 42.666 0V254.464a21.077 21.077 0 0 0-18.304-20.693" fill="#3C405D"/><path d="M382.25 299.221a21.333 21.333 0 0 0-21.333 21.334v87.466c0 81.664 66.987 148.096 149.334 148.096s149.333-66.432 149.333-148.053v-87.467a21.333 21.333 0 0 0-42.667 0v87.467c0 58.112-47.829 105.387-106.666 105.387s-106.667-47.275-106.667-105.387v-87.467a21.333 21.333 0 0 0-21.333-21.333M704 640.768a64 64 0 1 0 .043 128.085 64 64 0 0 0 0-128.085" fill="#3C405D"/><path d="M704 811.477a106.795 106.795 0 0 1-106.667-106.666c0-58.795 47.83-106.667 106.667-106.667s106.667 47.872 106.667 106.667S762.837 811.477 704 811.477m0-256c-82.347 0-149.333 66.987-149.333 149.334S621.653 854.144 704 854.144s149.333-66.987 149.333-149.333S786.347 555.477 704 555.477" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Commodityfilingmanagement;
