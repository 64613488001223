import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Distribution 图标组件
 */
export const Distribution = defineComponent({
	name: "Distribution",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Distribution icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M906.818 820.523L783.682 607.275a21.163 21.163 0 0 0-29.099-7.808 21.248 21.248 0 0 0-7.808 29.141l104.619 181.248H186.306l332.544-576 154.026 266.667c.427.896 1.28 1.408 1.878 2.176 1.024 1.28 1.92 2.602 3.242 3.669 1.195.981 2.56 1.579 4.011 2.261.981.47 1.707 1.238 2.816 1.579.213.043.384 0 .597.085.555.128 1.11.128 1.707.256 1.408.256 2.773.64 4.181.64.342 0 .598-.298.939-.298a20.95 20.95 0 0 0 9.728-2.56c.427-.256.64-.64 1.024-.896.64-.427 1.067-.982 1.664-1.494a20.267 20.267 0 0 0 6.357-8.746c.128-.342.512-.47.64-.854l37.974-118.485a21.333 21.333 0 1 0-40.662-13.013l-23.296 72.661-148.352-256.981c-7.594-13.184-29.354-13.184-36.906 0l-369.536 640a21.419 21.419 0 0 0 18.474 32h738.987a21.333 21.333 0 0 0 18.475-32" fill="#3C405D"/><path d="M612.844 621.867a21.333 21.333 0 0 0 0-42.667H542.06v-3.84l62.848-59.307a21.333 21.333 0 1 0-29.226-31.018l-56.448 53.248-56.491-53.248a21.248 21.248 0 1 0-29.227 31.018l65.878 62.123v1.024h-74.752a21.333 21.333 0 0 0 0 42.667h74.752v22.058h-74.752a21.333 21.333 0 1 0 0 42.667h74.752v51.2a21.333 21.333 0 1 0 42.666 0v-51.2h70.827a21.333 21.333 0 0 0 0-42.667H542.06v-22.058h70.827z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Distribution;
