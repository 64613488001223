import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Jumpdirectly 图标组件
 */
export const Jumpdirectly = defineComponent({
	name: "Jumpdirectly",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Jumpdirectly icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M786.3 88.5H621.6c-21.5 0-38.8 17.4-38.8 38.8s17.4 38.8 38.8 38.8h164.7c85.6 0 155.3 69.6 155.3 155.2v388.2c0 85.6-69.7 155.2-155.3 155.2H618.9c-21.5 0-38.8 17.4-38.8 38.8s17.4 38.8 38.8 38.8h167.5c128.4 0 232.9-104.5 232.9-232.8V321.4c-.1-128.4-104.6-232.9-233-232.9zm-356 776.3H236.4c-85.6 0-155.2-69.6-155.2-155.2V321.4c0-85.6 69.7-155.2 155.2-155.2h191.1c21.5 0 38.8-17.4 38.8-38.8S449 88.5 427.5 88.5H236.4C108 88.5 3.6 193 3.6 321.4v388.2C3.6 838 108 942.4 236.4 942.4h193.8c21.5 0 38.8-17.4 38.8-38.8s-17.3-38.8-38.7-38.8z"/><path d="M581 678.8c7.6 7.6 17.5 11.4 27.4 11.4s19.9-3.8 27.4-11.4L771.6 543c15.2-15.2 15.2-39.7 0-54.9L635.9 352.2c-15.2-15.2-39.7-15.2-54.9 0s-15.2 39.7 0 54.9l69.6 69.6H259.1c-21.5 0-38.8 17.4-38.8 38.8 0 21.5 17.4 38.8 38.8 38.8h391.5L581 623.9c-15.2 15.1-15.2 39.7 0 54.9z"/></svg>
			</ElIcon>
		);
	},
});

export default Jumpdirectly;
