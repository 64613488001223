import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Search 图标组件
 */
export const Search = defineComponent({
	name: "Search",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Search icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M888.682 857.57L713.59 682.479a21.332 21.332 0 0 0-30.164 0c-.853.853-1.28 1.92-1.92 2.858-100.984 98.382-259.65 112.162-377.7 32.34-137.632-93.007-174.578-279.618-82.383-416.055a295.445 295.445 0 0 1 135.884-109.56 301.716 301.716 0 0 1 280.81 30.632 301.716 301.716 0 0 1 133.239 248.942 294.72 294.72 0 0 1-22.356 114.082 21.332 21.332 0 0 0 39.421 16.297 336.572 336.572 0 0 0 25.598-130.55 344.337 344.337 0 0 0-152.01-284.139 344.294 344.294 0 0 0-320.488-34.813 337.682 337.682 0 0 0-155.466 125.26C80.678 433.708 122.83 646.94 279.916 753.044a344.636 344.636 0 0 0 193.18 59.046 341.18 341.18 0 0 0 225.178-84.601L858.52 887.733a21.246 21.246 0 0 0 30.163 0 21.332 21.332 0 0 0 0-30.163" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Search;
