import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Stockmanagement 图标组件
 */
export const Stockmanagement = defineComponent({
	name: "Stockmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Stockmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.79 170.667c51.029 0 92.543 41.514 92.543 92.501v227.03a21.333 21.333 0 1 1-42.666 0v-227.03a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h228.821a21.333 21.333 0 1 1 0 42.666H263.168a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621zM383.53 255.53h256v-42.198h-256v42.198z" fill="#3C405D"/><path d="M363.99 525.739h129.749a21.333 21.333 0 0 0 0-42.667H364.032a21.333 21.333 0 0 0 0 42.667m-.043-99.499h40.875a21.333 21.333 0 0 0 0-42.667h-40.875a21.333 21.333 0 0 0 0 42.667m339.584 213.333a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M703.573 810.24a106.752 106.752 0 0 1-106.666-106.667c0-58.88 47.786-106.666 106.666-106.666 58.795 0 106.667 47.786 106.667 106.666 0 58.795-47.872 106.667-106.667 106.667m0-256c-41.472 0-78.933 16.939-106.026 44.288a21.035 21.035 0 0 0-20.224-15.872H363.989a21.333 21.333 0 0 0 0 42.667h212.907a147.755 147.755 0 0 0-20.48 56.874h-192.47a21.333 21.333 0 0 0 0 42.667h192.427c10.411 72.192 72.107 128 147.115 128 82.347 0 149.333-66.987 149.333-149.333s-66.986-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Stockmanagement;
