import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Smartmarketing 图标组件
 */
export const Smartmarketing = defineComponent({
	name: "Smartmarketing",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Smartmarketing icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M886.912 134.656a21.419 21.419 0 0 0-30.165-.853L706.859 275.285a339.413 339.413 0 0 0-396.032-1.706L164.01 135.083a21.333 21.333 0 1 0-29.312 31.018L277.419 300.8a344.49 344.49 0 0 0-51.84 59.947 338.688 338.688 0 0 0-58.027 190.122c0 187.606 152.619 340.267 340.267 340.267a339.755 339.755 0 0 0 329.813-256.341 20.139 20.139 0 1 0-39.04-9.984 299.563 299.563 0 0 1-290.773 226.005c-165.419 0-299.99-134.528-299.99-299.947a298.24 298.24 0 0 1 51.2-167.594 299.733 299.733 0 0 1 248.704-132.31A299.819 299.819 0 0 1 801.451 489.6a20.139 20.139 0 1 0 39.466-8.192 336.853 336.853 0 0 0-51.072-120.747 340.779 340.779 0 0 0-49.92-57.984l146.048-137.813a21.333 21.333 0 0 0 .854-30.165" fill="#3C405D"/><path d="M368.043 531.541v146.347a20.139 20.139 0 1 0 40.277 0V531.541a20.139 20.139 0 1 0-40.277 0m118.314-46.122v192.426a20.139 20.139 0 1 0 40.278 0V485.42a20.139 20.139 0 1 0-40.278 0m122.283-50.134v242.603a20.139 20.139 0 1 0 40.277 0V435.285a20.139 20.139 0 1 0-40.277 0" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Smartmarketing;
