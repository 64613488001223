import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Index 图标组件
 */
export const Index = defineComponent({
	name: "Index",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Index icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M192 809.472a21.333 21.333 0 0 1-19.03-30.976l183.553-363.52a21.333 21.333 0 1 1 38.101 19.285l-183.595 363.52A21.333 21.333 0 0 1 192 809.472" fill="#3C405D"/><path d="M551.68 794.453a21.248 21.248 0 0 1-18.987-11.69L356.565 434.26a21.333 21.333 0 1 1 38.059-19.285l176.128 348.501a21.333 21.333 0 0 1-19.03 31.019" fill="#3C405D"/><path d="M551.68 794.453a21.29 21.29 0 0 1-18.176-32.426l269.781-442.155a21.333 21.333 0 1 1 36.438 22.187L569.94 784.213a21.29 21.29 0 0 1-18.218 10.24" fill="#3C405D"/><path d="M641.707 394.752a21.29 21.29 0 0 1-6.144-41.813l179.626-53.376a21.29 21.29 0 1 1 12.16 40.874l-179.584 53.376a21.76 21.76 0 0 1-6.101.854" fill="#3C405D"/><path d="M874.667 520.917a21.29 21.29 0 0 1-20.438-15.232l-53.376-179.584a21.333 21.333 0 0 1 40.96-12.202l53.334 179.626a21.333 21.333 0 0 1-20.438 27.392" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Index;
