import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Purchasepricemanagement 图标组件
 */
export const Purchasepricemanagement = defineComponent({
	name: "Purchasepricemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Purchasepricemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.333 832a32 32 0 1 0 0 64 32 32 0 0 0 0-64m184.918-256h62.208a21.333 21.333 0 0 0 0-42.667H558.25v-16h62.208a21.333 21.333 0 0 0 0-42.666h-62.55l-.042-.214 56.789-53.589a21.333 21.333 0 0 0-29.27-31.061l-49.834 47.018-49.792-47.018a21.333 21.333 0 0 0-29.27 31.061l57.046 53.803h-63.744a21.333 21.333 0 0 0 0 42.666h65.792v16h-65.792a21.333 21.333 0 0 0 0 42.667h65.792v44.459a21.333 21.333 0 0 0 42.667 0V576zm147.541 106.667a64 64 0 1 0 .043 128.042 64 64 0 0 0-.043-128.042" fill="#3C405D"/><path d="M705.792 853.333a106.795 106.795 0 0 1-106.667-106.666c0-58.795 47.83-106.667 106.667-106.667s106.667 47.872 106.667 106.667-47.83 106.666-106.667 106.666m0-256c-82.347 0-149.333 66.987-149.333 149.334S623.445 896 705.792 896s149.333-66.987 149.333-149.333-66.986-149.334-149.333-149.334" fill="#3C405D"/><path d="M492.459 726.23a21.333 21.333 0 0 0 0-42.667H290.56l-26.368-343.126H640a171.733 171.733 0 0 1 167.339 209.536 21.333 21.333 0 1 0 41.642 9.344A213.077 213.077 0 0 0 854.23 512 214.443 214.443 0 0 0 640 297.77H260.907l-4.992-64.767c-.086-.896-.555-1.622-.768-2.518a21.077 21.077 0 0 0-1.579-5.205c-.256-.597-.683-1.024-1.024-1.579a21.077 21.077 0 0 0-4.224-5.077c-.427-.384-.896-.597-1.323-.896a22.827 22.827 0 0 0-5.248-2.987c-1.408-.512-2.816-.64-4.266-.853-.939-.128-1.792-.555-2.816-.555H192A21.333 21.333 0 0 0 192 256h22.912l41.173 534.997c.086 1.28.64 2.347.982 3.584.298 1.195.469 2.475.981 3.584.64 1.494 1.664 2.688 2.645 3.968.64.811 1.11 1.792 1.878 2.518 1.322 1.237 2.901 2.09 4.437 2.986.768.427 1.408 1.067 2.219 1.408a21.248 21.248 0 0 0 8.064 1.622h215.168a21.333 21.333 0 0 0 0-42.667H297.088l-3.243-41.77H492.46z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Purchasepricemanagement;
