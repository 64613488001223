import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Allocated 图标组件
 */
export const Allocated = defineComponent({
	name: "Allocated",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Allocated icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M838.016 596.48a21.333 21.333 0 0 1 15.019 26.155 352.725 352.725 0 0 1-340.011 259.84c-123.819 0-236.63-65.792-299.69-167.638v146.304a21.333 21.333 0 1 1-42.667 0V621.013A21.333 21.333 0 0 1 192 599.68c.981 0 1.835.427 2.73.555a20.907 20.907 0 0 1 20.566 15.189c37.675 132.096 160.085 224.384 297.728 224.384 139.52 0 262.4-93.867 298.837-228.31a21.333 21.333 0 0 1 26.155-15.018zM190.763 452.437a21.333 21.333 0 0 1-14.038-26.709 350.507 350.507 0 0 1 336.299-247.21 349.867 349.867 0 0 1 295.723 162.09V192a21.333 21.333 0 1 1 42.666 0v240.043a21.287 21.287 0 0 1-21.333 21.333c-.853 0-1.579-.384-2.432-.47-1.152-.085-2.133-.426-3.285-.682-1.11-.299-2.219-.47-3.243-.939a21.077 21.077 0 0 1-12.587-13.226 308.224 308.224 0 0 0-295.509-216.875A308.01 308.01 0 0 0 217.472 438.4a21.333 21.333 0 0 1-26.71 14.037z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Allocated;
