import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Contractmanagement 图标组件
 */
export const Contractmanagement = defineComponent({
	name: "Contractmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Contractmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M492.459 853.333H319.445c-34.986 0-63.445-28.416-63.445-63.36V234.027c0-34.944 28.459-63.36 63.445-63.36h385.11c34.986 0 63.445 28.416 63.445 63.36v259.285a21.333 21.333 0 1 0 42.667 0V234.027A106.24 106.24 0 0 0 704.555 128h-385.11a106.24 106.24 0 0 0-106.112 106.027v555.946A106.24 106.24 0 0 0 319.445 896H492.46a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M704 681.813a64 64 0 1 0 .043 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M704 852.48a106.795 106.795 0 0 1-106.667-106.667c0-26.88 10.326-51.2 26.795-69.973a21.333 21.333 0 0 0 4.779-5.675A106.24 106.24 0 0 1 704 639.147c58.795 0 106.667 47.786 106.667 106.666 0 58.838-47.872 106.667-106.667 106.667M502.955 589.696l-58.795 30.848 11.221-65.408a21.333 21.333 0 0 0-6.144-18.859l-47.53-46.378 65.706-9.515a21.419 21.419 0 0 0 16.086-11.69l29.397-59.563 29.355 59.562a21.419 21.419 0 0 0 16.085 11.691l65.707 9.515-47.574 46.378a21.419 21.419 0 0 0-6.101 18.859l11.221 65.45-58.794-30.847a21.461 21.461 0 0 0-19.84 0M704 596.48c-29.184 0-56.235 8.704-79.275 23.253l-10.453-60.757 70.528-68.693a21.333 21.333 0 0 0-11.861-36.438l-97.366-14.08-43.562-88.32c-7.168-14.506-31.104-14.506-38.272 0l-43.563 88.32-97.365 14.08a21.333 21.333 0 0 0-11.862 36.438l70.486 68.693-16.64 96.981a21.333 21.333 0 0 0 30.933 22.486l87.168-45.739 64.896 34.048a148.139 148.139 0 0 0-23.125 79.061c0 82.347 66.986 149.334 149.333 149.334s149.333-66.987 149.333-149.334S786.347 596.48 704 596.48" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Contractmanagement;
