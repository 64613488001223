import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Configurationmanagement 图标组件
 */
export const Configurationmanagement = defineComponent({
	name: "Configurationmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Configurationmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M192 320h128v42.667a21.333 21.333 0 0 0 42.667 0V320h467.541a21.333 21.333 0 1 0 0-42.667H362.667v-42.666a21.333 21.333 0 0 0-42.667 0v42.666H192A21.333 21.333 0 0 0 192 320m0 211.541h638.208a21.333 21.333 0 1 0 0-42.666H704v-44.459a21.333 21.333 0 0 0-42.667 0v44.459H192a21.333 21.333 0 0 0 0 42.666M492.459 702.208H362.667v-42.667a21.333 21.333 0 1 0-42.667 0v42.667H192a21.333 21.333 0 0 0 0 42.667h128v42.666a21.333 21.333 0 0 0 42.667 0v-42.666h129.792a21.333 21.333 0 0 0 0-42.667M704 638.208a64 64 0 1 0 .043 128.043A64 64 0 0 0 704 638.208" fill="#3C405D"/><path d="M704 808.875a106.795 106.795 0 0 1-106.667-106.667c0-58.837 47.872-106.667 106.667-106.667s106.667 47.83 106.667 106.667S762.795 808.875 704 808.875m0-256c-82.347 0-149.333 66.986-149.333 149.333S621.653 851.541 704 851.541s149.333-66.986 149.333-149.333S786.347 552.875 704 552.875" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Configurationmanagement;
