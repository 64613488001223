import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Settlement 图标组件
 */
export const Settlement = defineComponent({
	name: "Settlement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Settlement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M638.379 543.787a21.333 21.333 0 0 0 0-42.667H513.493v-23.893l103.766-97.92a21.333 21.333 0 1 0-29.312-31.062l-98.091 92.587-98.133-92.587a21.333 21.333 0 0 0-29.27 31.062L470.827 481.62v19.499H339.712a21.333 21.333 0 0 0 0 42.667h131.115v59.989H339.712a21.333 21.333 0 0 0 0 42.667h131.115v93.781a21.333 21.333 0 0 0 42.666 0V646.4H638.38a21.333 21.333 0 1 0 0-42.667H513.493v-59.989H638.38z" fill="#3C405D"/><path d="M829.056 572.544a20.053 20.053 0 0 0-23.381 16.299 320.597 320.597 0 0 1-316.16 264.49c-177.152 0-321.238-144.085-321.238-321.194a321.11 321.11 0 0 1 587.52-179.584c10.411 15.36 19.627 31.872 27.179 48.938 4.523 10.155 16.384 14.763 26.581 10.155a19.797 19.797 0 0 0 10.07-10.24 19.84 19.84 0 0 0 2.901-9.813V272.81a20.181 20.181 0 0 0-40.32 0v48.17a360.79 360.79 0 0 0-592.555 9.174A359.68 359.68 0 0 0 128 532.139c0 199.338 162.176 361.472 361.472 361.472a360.875 360.875 0 0 0 355.883-297.686 20.181 20.181 0 0 0-16.299-23.381" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Settlement;
