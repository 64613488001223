import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Appointmentpurchasemanagement 图标组件
 */
export const Appointmentpurchasemanagement = defineComponent({
	name: "Appointmentpurchasemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Appointmentpurchasemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M330.667 832a32 32 0 1 0 0 64 32 32 0 0 0 0-64M448 469.333h213.333a21.333 21.333 0 0 0 0-42.666H448a21.333 21.333 0 0 0 0 42.666m-85.333-42.666H320a21.333 21.333 0 0 0 0 42.666h42.667a21.333 21.333 0 0 0 0-42.666m320 149.333a21.333 21.333 0 0 0-21.334-21.333H448a21.333 21.333 0 0 0 0 42.666h213.333A21.333 21.333 0 0 0 682.667 576m-320-21.333H320a21.333 21.333 0 0 0 0 42.666h42.667a21.333 21.333 0 0 0 0-42.666m296.874 128a64 64 0 1 0 .043 128.042 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M659.541 853.333a106.795 106.795 0 0 1-106.666-106.666C552.875 687.872 600.704 640 659.54 640s106.667 47.872 106.667 106.667-47.83 106.666-106.667 106.666m0-256c-82.346 0-149.333 66.987-149.333 149.334S577.195 896 659.541 896s149.334-66.987 149.334-149.333-66.987-149.334-149.334-149.334" fill="#3C405D"/><path d="M597.333 297.77H218.24l-4.992-64.767c-.085-.896-.555-1.622-.768-2.475a19.968 19.968 0 0 0-1.579-5.29c-.256-.555-.682-.982-1.024-1.537a21.76 21.76 0 0 0-4.181-5.077c-.47-.384-.981-.597-1.45-.981a20.139 20.139 0 0 0-5.12-2.859c-1.409-.555-2.86-.683-4.31-.896-.981-.128-1.835-.555-2.816-.555h-42.667a21.333 21.333 0 0 0 0 42.667h22.912l41.174 534.997c.085 1.28.64 2.347.981 3.584.299 1.195.47 2.475.981 3.584.64 1.494 1.664 2.688 2.646 3.968.64.811 1.109 1.792 1.877 2.518 1.323 1.237 2.901 2.09 4.437 2.986.768.427 1.408 1.067 2.219 1.408a21.248 21.248 0 0 0 8.064 1.622h215.168a21.333 21.333 0 0 0 0-42.667h-195.37l-3.243-41.77h198.613a21.333 21.333 0 0 0 0-42.667H247.893l-26.368-343.126h375.808a171.776 171.776 0 0 1 167.339 209.536 21.333 21.333 0 1 0 41.643 9.344A213.077 213.077 0 0 0 811.563 512a214.443 214.443 0 0 0-214.23-214.23" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Appointmentpurchasemanagement;
