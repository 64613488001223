import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Timedate 图标组件
 */
export const Timedate = defineComponent({
	name: "Timedate",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Timedate icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M512.7 62.2C263.6 62.2 61 264.8 61 513.9s202.6 451.7 451.7 451.7S964.4 763 964.4 513.9c-.1-249.1-202.7-451.7-451.7-451.7zm0 837.8c-212.9 0-386.1-173.2-386.1-386.2 0-212.9 173.2-386.1 386.1-386.1S898.8 301 898.8 513.9c0 212.9-173.2 386.1-386.1 386.1z" fill="#231815"/><path d="M754.2 578.1l-223.6-83.3v-210c0-18.1-14.7-32.8-32.8-32.8S465 266.7 465 284.8v222.7c-.7 19.4 10.9 37 29.1 43.7l237.2 88.4c3.8 1.4 7.7 2 11.5 2 13.3 0 25.8-8.1 30.7-21.3 6.3-17-2.3-35.8-19.3-42.2z" fill="#231815"/></svg>
			</ElIcon>
		);
	},
});

export default Timedate;
