import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Distributorsettlement 图标组件
 */
export const Distributorsettlement = defineComponent({
	name: "Distributorsettlement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Distributorsettlement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M518.357 554.667c90.24 0 170.454-52.267 204.374-133.078a21.333 21.333 0 1 0-39.296-16.512C656.128 470.06 591.36 512 518.315 512c-90.454 0-164.523-65.365-175.616-149.333H553.77a21.333 21.333 0 0 0 0-42.667H342.059a165.675 165.675 0 0 1 28.501-73.728c33.195-47.36 88.448-75.605 147.797-75.605 59.307 0 114.56 28.245 147.712 75.562 6.912 9.814 12.758 20.352 17.323 31.275a21.376 21.376 0 0 0 39.339-16.597 212.352 212.352 0 0 0-21.76-39.211C659.883 163.029 591.616 128 518.4 128c-73.259 0-141.568 35.072-182.741 93.781a207.445 207.445 0 0 0-37.888 119.552c0 117.632 98.944 213.334 220.586 213.334m142.08 298.666H276.096c-58.155 0-105.43-47.829-105.43-106.666S217.942 640 276.097 640h343.467a21.333 21.333 0 0 0 0-42.667H276.096C194.432 597.333 128 664.32 128 746.667S194.432 896 276.096 896h384.341a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M873.77 767.573h-83.114v-30.72h83.115a21.333 21.333 0 1 0 0-42.666h-83.115v-8.363l72.192-68.139a21.333 21.333 0 1 0-29.27-30.976l-65.92 62.208-65.92-62.208a21.333 21.333 0 1 0-29.269 30.976l75.52 71.296v5.206h-87.552a21.333 21.333 0 0 0 0 42.666h87.552v30.72h-87.552a21.333 21.333 0 0 0 0 42.667h87.552v60.885a21.333 21.333 0 0 0 42.667 0V810.24h83.115a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Distributorsettlement;
