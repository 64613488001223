import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Resaleprogram 图标组件
 */
export const Resaleprogram = defineComponent({
	name: "Resaleprogram",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Resaleprogram icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M639.683 659.637a21.33 21.33 0 0 0 21.33 21.33h218.635a21.33 21.33 0 0 0 0-42.66H661.013a21.33 21.33 0 0 0-21.33 21.33M879.648 723.627H661.013a21.33 21.33 0 0 0 0 42.66h218.635a21.33 21.33 0 0 0 0-42.66M879.648 810.698H661.013a21.33 21.33 0 0 0 0 42.66h218.635a21.33 21.33 0 0 0 0-42.66" fill="#3C405D"/><path d="M579.276 810.698H186.372l332.496-576.045 203.619 350.37a21.202 21.202 0 1 0 39.162-6.057l37.627-117.274a21.245 21.245 0 0 0-13.78-26.79 21.202 21.202 0 0 0-26.79 13.821l-23.464 73.206-197.944-340.517a21.33 21.33 0 0 0-36.944.043l-369.44 639.908a21.33 21.33 0 0 0 18.472 31.995h429.847a21.33 21.33 0 0 0 0-42.66" fill="#3C405D"/><path d="M612.807 622.735a21.33 21.33 0 0 0 0-42.66h-70.774v-3.754l62.882-59.384a21.373 21.373 0 0 0-29.308-31.014l-56.397 53.283-56.483-53.326a21.245 21.245 0 1 0-29.222 31.057l65.867 62.157v.98h-74.74a21.33 21.33 0 0 0 0 42.661h74.74v22.056h-74.74a21.33 21.33 0 1 0 0 42.66h74.74v51.193a21.33 21.33 0 1 0 42.661 0V687.45h70.816a21.33 21.33 0 0 0 0-42.66h-70.816v-22.056h70.816z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Resaleprogram;
