import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Commodityfiling 图标组件
 */
export const Commodityfiling = defineComponent({
	name: "Commodityfiling",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Commodityfiling icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 469.888a21.333 21.333 0 0 0 21.333-21.333V254.464a21.077 21.077 0 0 0-18.304-20.736c-1.408-.64-2.73-1.45-4.309-1.792l-314.667-60.97a19.328 19.328 0 0 0-6.272.042 19.712 19.712 0 0 0-7.253 0l-309.333 60.928c-1.579.341-2.859 1.195-4.267 1.792a21.035 21.035 0 0 0-18.261 20.736v286.165c0 1.451.554 2.731.81 4.096C180.14 720.3 345.26 832.47 508.885 853.035a18.944 18.944 0 0 0 2.688.17h.854a18.944 18.944 0 0 0 2.688-.17C655.872 835.37 820.437 742.443 848.98 580.224a21.333 21.333 0 1 0-41.984-7.381c-24.704 140.074-169.77 221.056-294.954 237.525-148.822-19.499-298.71-121.515-298.71-279.893V271.488l296.064-58.368 301.27 58.368v177.067A21.333 21.333 0 0 0 832 469.888" fill="#3C405D"/><path d="M382.208 299.221a21.333 21.333 0 0 0-21.333 21.334v87.466c0 81.664 66.986 148.096 149.333 148.096s149.333-66.432 149.333-148.053v-87.467a21.333 21.333 0 0 0-42.666 0v87.467c0 58.112-47.83 105.387-106.667 105.387s-106.667-47.275-106.667-105.387v-87.467a21.333 21.333 0 0 0-21.333-21.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Commodityfiling;
