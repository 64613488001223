import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Distributor 图标组件
 */
export const Distributor = defineComponent({
	name: "Distributor",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Distributor icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M517.632 552.15c80.64 0 152.277-46.678 182.613-118.955a21.333 21.333 0 0 0-39.338-16.512c-23.595 56.362-79.915 92.8-143.275 92.8-77.568 0-141.355-55.254-152.235-126.72h183.467a21.29 21.29 0 1 0 0-42.667H364.757c3.286-21.845 11.52-42.667 24.491-61.141 28.8-41.088 76.8-65.622 128.384-65.622 51.541 0 99.499 24.491 128.256 65.536 5.973 8.576 11.05 17.707 15.019 27.179a21.333 21.333 0 0 0 39.338-16.597 189.44 189.44 0 0 0-19.413-35.072c-36.736-52.438-97.792-83.712-163.2-83.712-65.493 0-126.55 31.317-163.285 83.84a185.259 185.259 0 0 0-33.92 106.88c0 105.216 88.448 190.762 197.12 190.762M720.17 810.667H303.788c-49.92 0-90.454-41.088-90.454-91.606 0-50.517 40.534-91.605 90.454-91.605H720.17c49.92 0 90.453 41.088 90.453 91.605.043 50.518-40.49 91.606-90.41 91.606m0-225.878H303.786c-73.387 0-133.12 60.246-133.12 134.272 0 74.027 59.733 134.272 133.12 134.272H720.17c73.386 0 133.12-60.245 133.12-134.272.042-74.026-59.691-134.272-133.078-134.272" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Distributor;
