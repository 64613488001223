import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Rebaterules 图标组件
 */
export const Rebaterules = defineComponent({
	name: "Rebaterules",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Rebaterules icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M382.738 603.733a21.333 21.333 0 0 0 0 42.667h131.115v93.824a21.333 21.333 0 0 0 42.667 0V646.4h124.885a21.333 21.333 0 0 0 0-42.667H556.477v-59.989h124.885a21.333 21.333 0 0 0 0-42.667H556.477v-23.936l103.765-97.877a21.333 21.333 0 0 0-29.269-31.061l-98.133 92.586-98.134-92.586a21.333 21.333 0 1 0-29.312 31.061L513.81 481.536v19.541H382.696a21.333 21.333 0 1 0 0 42.667H513.81v59.99H382.696z" fill="#3C405D"/><path d="M827.026 330.112a360.875 360.875 0 0 0-592.554-9.216v-48.085a20.139 20.139 0 0 0-40.32 0v118.784c0 3.626 1.194 6.826 2.858 9.77a20.053 20.053 0 0 0 10.027 10.326c10.24 4.437 22.059 0 26.624-10.24a321.11 321.11 0 0 1 293.547-190.507A321.11 321.11 0 0 1 848.36 532.096c0 177.152-144.086 321.195-321.152 321.195a320.597 320.597 0 0 1-316.203-264.448 20.139 20.139 0 1 0-39.68 7.082 360.875 360.875 0 0 0 355.84 297.686c199.381 0 361.515-162.134 361.515-361.515 0-72.32-21.334-142.165-61.654-201.941" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Rebaterules;
