import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Diskormanagement 图标组件
 */
export const Diskormanagement = defineComponent({
	name: "Diskormanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Diskormanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.79 170.667c51.029 0 92.543 41.514 92.543 92.501v228.821a21.333 21.333 0 1 1-42.666 0V263.168a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h228.821a21.333 21.333 0 1 1 0 42.666H263.168a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621zM383.53 255.53h256v-42.198h-256v42.198z" fill="#3C405D"/><path d="M703.573 639.573a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M703.573 810.24a106.752 106.752 0 0 1-106.666-106.667c0-58.88 47.786-106.666 106.666-106.666 58.795 0 106.667 47.786 106.667 106.666 0 58.795-47.872 106.667-106.667 106.667m-320-278.272a107.69 107.69 0 0 1 107.52-107.52 107.69 107.69 0 0 1 107.563 107.52 107.69 107.69 0 0 1-107.52 107.563 107.69 107.69 0 0 1-107.605-107.52m320 22.186c-24.662 0-47.616 6.571-68.139 17.238 3.499-12.672 5.973-25.728 5.973-39.467a150.443 150.443 0 0 0-150.272-150.187 150.4 150.4 0 0 0-150.186 150.187 150.357 150.357 0 0 0 150.186 150.23c24.918 0 48.128-6.7 68.864-17.494a147.755 147.755 0 0 0-5.76 38.827c0 82.346 66.987 149.333 149.334 149.333s149.333-66.987 149.333-149.333-66.987-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Diskormanagement;
