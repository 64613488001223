import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * InTransitreportmanagement 图标组件
 */
export const InTransitreportmanagement = defineComponent({
	name: "InTransitreportmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-InTransitreportmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M491.99 854.23H296.362c-22.187 0-40.363-19.286-40.363-43.009v-505.77l134.827-134.827H727.68c22.23 0 40.32 19.328 40.32 43.05V538.24a21.333 21.333 0 1 0 42.667 0V213.675c0-47.232-37.248-85.675-83.03-85.675H382.293a21.248 21.248 0 0 0-15.232 6.357c-.298.256-.682.342-.938.64l-146.56 146.56a21.12 21.12 0 0 0-6.059 16.086c0 .341-.17.64-.17 1.024V811.22c0 47.232 37.247 85.675 83.029 85.675h195.626a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M532.907 512H413.312l56.021-56.021a21.333 21.333 0 0 0-30.165-30.166l-88.021 87.979a21.333 21.333 0 0 0-4.608 23.21 20.992 20.992 0 0 0 20.565 17.665h165.803a21.333 21.333 0 0 0 0-42.667m83.541 0a21.333 21.333 0 0 0 0 42.667h44.459a21.333 21.333 0 0 0 0-42.667h-44.459zm87.125 171.99a64 64 0 1 0 .043 128.042 64 64 0 0 0-.043-128" fill="#3C405D"/><path d="M703.573 854.656a106.795 106.795 0 0 1-106.666-106.667c0-58.794 47.829-106.666 106.666-106.666 58.88 0 106.667 47.872 106.667 106.666s-47.787 106.667-106.667 106.667m0-256c-82.346 0-149.333 66.987-149.333 149.333s66.987 149.334 149.333 149.334 149.334-66.987 149.334-149.334-66.987-149.333-149.334-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default InTransitreportmanagement;
