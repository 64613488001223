import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Wisdom 图标组件
 */
export const Wisdom = defineComponent({
	name: "Wisdom",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Wisdom icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M886.867 177.329a21.376 21.376 0 0 0-30.123-.853L707.711 317.104a340.733 340.733 0 0 0-199.978-65.024 340.904 340.904 0 0 0-197.843 63.36L163.97 177.713a21.333 21.333 0 0 0-29.269 31.018l141.866 133.887a339.453 339.453 0 0 0-110.25 250.921c0 188.244 153.172 341.416 341.459 341.416A340.904 340.904 0 0 0 838.739 677.72a21.333 21.333 0 1 0-41.386-10.539A298.323 298.323 0 0 1 507.776 892.29c-164.735 0-298.792-133.972-298.792-298.75a298.664 298.664 0 0 1 298.75-298.792 298.792 298.792 0 0 1 292.563 237.651 21.248 21.248 0 1 0 41.727-8.66 338.43 338.43 0 0 0-51.2-121.173 342.397 342.397 0 0 0-50.004-58.027l145.065-137.044a21.333 21.333 0 0 0 .939-30.165" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Wisdom;
