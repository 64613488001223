import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Purchaseorder 图标组件
 */
export const Purchaseorder = defineComponent({
	name: "Purchaseorder",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Purchaseorder icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.333 832a32 32 0 1 0 0 64 32 32 0 0 0 0-64m277.334 0a32 32 0 1 0 0 64 32 32 0 0 0 0-64M828.203 555.861a21.333 21.333 0 0 0-27.179 13.099 169.13 169.13 0 0 1-159.147 113.707H290.56l-26.283-341.334H641.92a169.13 169.13 0 0 1 159.232 113.92 21.333 21.333 0 0 0 40.277-14.08 211.883 211.883 0 0 0-199.552-142.506H260.95l-5.034-65.664c-.086-.896-.598-1.707-.768-2.56-.384-1.878-.726-3.584-1.494-5.163-.384-.683-.853-1.28-1.28-1.92a20.139 20.139 0 0 0-3.84-4.65c-.64-.513-1.365-.811-2.048-1.323a19.413 19.413 0 0 0-4.565-2.56c-1.493-.64-2.987-.768-4.523-.982-.938-.085-1.706-.512-2.73-.512H192A21.29 21.29 0 1 0 192 256h22.912l41.173 534.955c.086 1.28.64 2.389.982 3.584.298 1.28.426 2.474.938 3.626.683 1.451 1.707 2.688 2.688 3.926.64.853 1.11 1.792 1.878 2.56 1.322 1.28 2.858 2.133 4.437 2.986.768.427 1.408 1.024 2.219 1.366a20.48 20.48 0 0 0 8.106 1.706h469.334a21.333 21.333 0 0 0 0-42.666H297.045l-3.285-42.667h348.16A211.925 211.925 0 0 0 841.301 583.04a21.333 21.333 0 0 0-13.098-27.179" fill="#3C405D"/><path d="M641.792 405.333H423.125a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667m0 85.334H423.125a21.333 21.333 0 0 0 0 42.666h218.667a21.333 21.333 0 0 0 0-42.666m0 87.082H423.125a21.333 21.333 0 0 0 0 42.667h218.667a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Purchaseorder;
