import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inventoryhealthmanagement 图标组件
 */
export const Inventoryhealthmanagement = defineComponent({
	name: "Inventoryhealthmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inventoryhealthmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M705.804 641.621a64 64 0 1 0 .043 128.043 64 64 0 0 0 0-128.043" fill="#3D405D"/><path d="M705.804 812.288a106.795 106.795 0 0 1-106.666-106.667c0-58.794 47.829-106.666 106.666-106.666 58.88 0 106.667 47.872 106.667 106.666s-47.787 106.667-106.667 106.667zM492.898 649.984l-104.79-105.259a43.904 43.904 0 0 1-12.97-31.274c0-11.819 4.565-22.912 12.97-31.275a44.032 44.032 0 0 1 62.592 0l27.307 27.35a21.333 21.333 0 0 0 30.165 0l27.392-27.35a45.227 45.227 0 0 1 62.55 0c8.362 8.363 12.928 19.456 12.928 31.275 0 11.776-4.566 22.869-12.928 31.274L492.898 649.984zm212.906-93.696c-33.706 0-64.512 11.648-89.557 30.592l12.032-11.99c16.384-16.426 25.43-38.271 25.43-61.44 0-23.21-9.046-45.055-25.43-61.44-32.853-32.938-90.112-32.853-122.88 0l-12.288 12.246-12.245-12.245c-32.854-32.896-90.112-32.896-122.88 0a86.23 86.23 0 0 0-25.515 61.44c0 23.168 9.045 45.013 25.472 61.44l119.723 120.32a21.333 21.333 0 0 0 15.104 6.272h.042a21.12 21.12 0 0 0 15.019-6.23l79.275-79.232a147.968 147.968 0 0 0-30.635 89.6c0 82.347 66.987 149.334 149.333 149.334s149.334-66.987 149.334-149.334-66.987-149.333-149.334-149.333z" fill="#3D405D"/><path d="M492.471 811.307h-218.71a57.472 57.472 0 0 1-57.557-57.174V434.176L490.68 176.64 817.42 483.328a21.29 21.29 0 0 0 30.123-.981 21.29 21.29 0 0 0-.939-30.166L507.191 133.59a21.205 21.205 0 0 0-16.512-5.504 21.163 21.163 0 0 0-16.47 5.547l-339.455 318.55a21.29 21.29 0 0 0 14.592 36.906 21.29 21.29 0 0 0 14.592-5.76l9.6-9.045V754.09a100.181 100.181 0 0 0 100.266 99.882h218.667a21.333 21.333 0 0 0 0-42.666" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inventoryhealthmanagement;
