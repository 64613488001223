import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * RebateRuleManagement 图标组件
 */
export const RebateRuleManagement = defineComponent({
	name: "RebateRuleManagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-RebateRuleManagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M826.71 330.112a360.875 360.875 0 0 0-592.555-9.216v-48.085a20.139 20.139 0 0 0-40.32 0v118.784c0 3.84 1.28 7.168 3.2 10.24a19.797 19.797 0 0 0 9.77 9.813c10.155 4.523 22.059 0 26.624-10.197a321.11 321.11 0 0 1 293.462-190.507 321.11 321.11 0 0 1 320.426 342.955 20.224 20.224 0 0 0 40.278 2.688 359.893 359.893 0 0 0-60.886-226.432M545.408 852.779A320.64 320.64 0 0 1 210.645 588.8a20.139 20.139 0 1 0-39.637 7.083 360.832 360.832 0 0 0 376.747 297.13 20.181 20.181 0 0 0 18.944-21.248 19.37 19.37 0 0 0-21.334-18.986m177.195-192.64a64 64 0 1 0 0 128.042 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M722.56 830.805A106.795 106.795 0 0 1 615.893 724.14c0-58.795 47.83-106.667 106.667-106.667 58.88 0 106.667 47.872 106.667 106.667S781.44 830.805 722.56 830.805m0-256c-32.81 0-62.89 10.966-87.552 29.014h-78.805v-60.075h124.885a21.29 21.29 0 1 0 0-42.667H556.203v-23.893l103.722-97.92a21.333 21.333 0 0 0-29.226-31.019l-98.134 92.587-98.133-92.587a21.333 21.333 0 1 0-29.27 31.019l108.374 102.315v19.498H382.421a21.333 21.333 0 0 0 0 42.667h131.115v60.032H382.421a21.333 21.333 0 1 0 0 42.667h131.115v93.738a21.29 21.29 0 1 0 42.667 0v-93.738h39.466a147.499 147.499 0 0 0-22.442 77.696c0 82.346 66.986 149.333 149.333 149.333s149.333-66.987 149.333-149.333-66.986-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default RebateRuleManagement;
