import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Materiallibrary 图标组件
 */
export const Materiallibrary = defineComponent({
	name: "Materiallibrary",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Materiallibrary icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 513.45a21.333 21.333 0 0 1-21.333-21.333V327.851a41.813 41.813 0 0 0-41.814-41.728H327.936a41.813 41.813 0 0 0-41.77 41.77v299.435l114.602-111.915c.299-.256.64-.341.939-.597.256-.213.426-.597.64-.768.853-.683 1.877-.896 2.773-1.45 1.28-.769 2.475-1.537 3.883-1.963 1.322-.427 2.602-.555 3.968-.768 1.365-.171 2.645-.342 4.01-.299 1.408.043 2.688.47 4.054.853 1.28.342 2.56.555 3.754 1.152 1.28.598 2.347 1.536 3.456 2.39.896.597 1.92.981 2.731 1.749.256.341.341.725.597.981.214.214.555.342.768.598l98.39 122.837 101.205-84.779v-.085a21.248 21.248 0 0 1 30.123 2.688L810.667 734.72V605.568a21.333 21.333 0 1 1 42.666 0v163.37a84.48 84.48 0 0 1-84.48 84.395H327.936a84.224 84.224 0 0 1-83.37-73.898 84.267 84.267 0 0 1-73.9-83.414v-440.96c0-46.506 37.846-84.394 84.48-84.394h440.918c42.923 0 78.08 32.426 83.37 73.898a84.224 84.224 0 0 1 73.9 83.328v164.182A21.333 21.333 0 0 1 832 513.408zm-588.544-185.6c0-46.506 37.888-84.394 84.48-84.394h407.467a41.301 41.301 0 0 0-39.382-30.123h-440.96a41.813 41.813 0 0 0-41.728 41.771v440.917c0 18.944 12.928 34.304 30.208 39.467V327.851zm50.048 351.958a21.163 21.163 0 0 1-7.381 4.65v84.438c0 23.04 18.773 41.728 41.813 41.728h440.917a40.96 40.96 0 0 0 34.006-18.901l-159.83-190.08-85.589 71.722 27.733 34.56a21.248 21.248 0 0 1-16.64 34.688 21.376 21.376 0 0 1-16.64-8.021L413.781 562.347 293.547 679.765z" fill="#3C405D"/><path d="M718.933 395.947a17.323 17.323 0 0 1-17.237-17.28 17.323 17.323 0 0 1 34.56 0 17.323 17.323 0 0 1-17.28 17.237m0-77.184c-33.024 0-59.947 26.88-59.947 59.947 0 33.024 26.88 59.904 59.947 59.904 33.024 0 59.947-26.88 59.947-59.904 0-33.067-26.923-59.947-59.947-59.947" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Materiallibrary;
