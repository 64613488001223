import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Earnfun 图标组件
 */
export const Earnfun = defineComponent({
	name: "Earnfun",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Earnfun icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M871.766 572.544a20.181 20.181 0 0 0-23.382 16.299 320.555 320.555 0 0 1-316.202 264.49c-177.067 0-321.152-144.085-321.152-321.152 0-64.341 18.901-126.421 54.784-179.456a321.195 321.195 0 0 1 582.57 122.795 20.267 20.267 0 0 0 23.382 16.213 20.096 20.096 0 0 0 16.298-23.296 361.515 361.515 0 0 0-655.658-138.325 359.68 359.68 0 0 0-61.739 202.07c0 199.338 162.133 361.471 361.515 361.471a360.832 360.832 0 0 0 355.84-297.728 20.139 20.139 0 0 0-16.256-23.381" fill="#3C405D"/><path d="M327.936 450.56a21.333 21.333 0 0 0 0 42.667h58.454v38.954a21.333 21.333 0 0 0 42.666 0v-38.954h55.296a21.333 21.333 0 0 0 0-42.667h-55.296v-11.136h55.296a21.333 21.333 0 1 0 0-42.667H431.19l49.066-46.25a21.333 21.333 0 1 0-29.226-30.976l-44.502 41.941-44.416-41.941a21.248 21.248 0 1 0-29.269 30.976l49.024 46.25h-53.93a21.333 21.333 0 0 0 0 42.667h58.453v11.093h-58.454zm-45.312 130.475A251.52 251.52 0 0 0 530.688 788.65a251.648 251.648 0 0 0 248.15-207.574 20.139 20.139 0 1 0-39.68-7.082 211.328 211.328 0 0 1-208.47 174.336 211.285 211.285 0 0 1-208.384-174.379 20.139 20.139 0 1 0-39.68 7.083m292.096-101.59a20.139 20.139 0 0 0 19.84-16.64 72.79 72.79 0 0 1 71.851-60.117c35.413 0 65.707 25.259 71.893 60.075a20.139 20.139 0 1 0 39.68-7.083 113.152 113.152 0 0 0-111.573-93.312c-55.04 0-101.888 39.296-111.53 93.355a20.139 20.139 0 0 0 19.882 23.722" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Earnfun;
