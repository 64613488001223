import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Exitmanagement 图标组件
 */
export const Exitmanagement = defineComponent({
	name: "Exitmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Exitmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M746.679 641.621a64 64 0 1 0 .043 128.043 64 64 0 0 0 0-128.043" fill="#3D405D"/><path d="M746.679 812.288a106.795 106.795 0 0 1-106.667-106.667c0-58.794 47.83-106.666 106.667-106.666 58.88 0 106.667 47.872 106.667 106.666s-47.787 106.667-106.667 106.667m0-256c-12.8 0-25.13 2.133-37.035 5.205-10.496-49.109-61.013-90.538-110.506-90.538H435.51l52.48-52.438a21.419 21.419 0 0 0-30.208-30.208l-87.979 88.022c-.128.128-.17.341-.341.512a21.077 21.077 0 0 0-6.784 15.445 21.333 21.333 0 0 0 21.333 21.333h215.126c29.696 0 62.549 27.136 68.821 56.875a20.864 20.864 0 0 0 2.987 7.125 149.632 149.632 0 0 0-58.624 64h-185.6a21.333 21.333 0 0 0 0 42.667h172.8c-1.024 7.04-2.134 14.037-2.134 21.333-.042 82.347 66.944 149.334 149.291 149.334s149.333-66.987 149.333-149.334-66.986-149.333-149.333-149.333" fill="#3D405D"/><path d="M535.138 811.307h-218.71c-31.744 0-57.6-25.686-57.6-57.174V434.261l274.518-257.664 326.741 306.774a21.29 21.29 0 0 0 30.123-1.024 21.29 21.29 0 0 0-.939-30.166L549.858 133.59a21.205 21.205 0 0 0-16.512-5.504 21.205 21.205 0 0 0-16.512 5.547L177.42 452.182a21.29 21.29 0 0 0 14.592 36.906 21.29 21.29 0 0 0 14.592-5.76l9.558-8.96v279.723a100.181 100.181 0 0 0 100.266 99.882h218.71a21.333 21.333 0 0 0 0-42.666" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Exitmanagement;
