import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Flow 图标组件
 */
export const Flow = defineComponent({
	name: "Flow",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Flow icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M738.816 508.843a21.333 21.333 0 1 0-39.51 16.085 208.213 208.213 0 0 1-192.64 286.592 208.213 208.213 0 0 1-208-208c0-38.997 11.222-76.245 31.574-109.056l176.427-264.277L616.832 395.22a21.419 21.419 0 0 0 29.61 5.974 21.419 21.419 0 0 0 5.889-29.654L527.872 185.173c-.683-1.066-1.707-1.706-2.56-2.56a20.437 20.437 0 0 0-6.955-8.362 21.333 21.333 0 0 0-29.61 5.973L305.323 454.997c-.555.598-1.28.939-1.792 1.622-2.816 3.882-4.864 8.149-7.424 12.16l-2.518 3.712c-.213.341-.213.725-.426 1.066a248.363 248.363 0 0 0-37.12 130.006 250.965 250.965 0 0 0 250.581 250.624 250.965 250.965 0 0 0 232.15-345.344" fill="#3C405D"/><path d="M626.944 632.192a123.605 123.605 0 0 1-82.133 85.333 21.333 21.333 0 1 0 12.629 40.79A166.485 166.485 0 0 0 668.16 643.2a21.333 21.333 0 0 0-15.104-26.112 21.59 21.59 0 0 0-26.112 15.104" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Flow;
