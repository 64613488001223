import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Inventoryassurancemanagement 图标组件
 */
export const Inventoryassurancemanagement = defineComponent({
	name: "Inventoryassurancemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Inventoryassurancemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M704.012 641.621a64 64 0 1 0 .043 128.043 64 64 0 0 0 0-128.043" fill="#3D405D"/><path d="M704.012 812.288a106.795 106.795 0 0 1-106.666-106.667c0-58.794 47.829-106.666 106.666-106.666 58.88 0 106.667 47.872 106.667 106.666s-47.787 106.667-106.667 106.667m-306.944-241.45v-93.526l90.326-20.267 92.16 20.31v103.808c-.214.81-.683 1.45-.811 2.304-6.357 50.218-50.73 79.445-90.41 86.186-45.825-7.68-91.265-43.477-91.265-98.816m306.944-14.549c-30.208 0-58.24 9.173-81.792 24.661V460.416a21.077 21.077 0 0 0-15.786-20.224c-1.024-.341-1.792-.981-2.859-1.237l-110.677-24.363c-.768-.17-1.451.043-2.219-.043-1.28-.256-2.73-.042-4.139-.042-.768.042-1.621-.128-2.389.042-.81.086-1.579-.128-2.39.043l-108.8 24.405c-1.066.214-1.834.854-2.773 1.238a20.992 20.992 0 0 0-15.786 20.181v114.475c0 1.493.554 2.773.853 4.181 4.352 75.861 67.157 124.501 129.877 133.461a18.39 18.39 0 0 0 3.03.214l.128-.043.128.043a19.456 19.456 0 0 0 3.114-.214 165.632 165.632 0 0 0 64.939-24.618c-.725 5.845-1.792 11.648-1.792 17.706 0 82.347 66.987 149.334 149.333 149.334s149.334-66.987 149.334-149.334-66.987-149.333-149.334-149.333" fill="#3D405D"/><path d="M490.679 811.307H273.762c-31.744 0-57.6-25.686-57.6-57.174V434.261l274.517-257.664L817.42 483.371a21.29 21.29 0 0 0 30.123-1.024 21.29 21.29 0 0 0-.939-30.166L507.191 133.59a20.907 20.907 0 0 0-16.512-5.461 20.821 20.821 0 0 0-16.512 5.461L134.754 452.181a21.29 21.29 0 0 0 14.592 36.907 21.29 21.29 0 0 0 14.592-5.76l9.557-8.96v279.723a100.181 100.181 0 0 0 100.267 99.882h216.917a21.333 21.333 0 0 0 0-42.666" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Inventoryassurancemanagement;
