import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Accuracyanalysis 图标组件
 */
export const Accuracyanalysis = defineComponent({
	name: "Accuracyanalysis",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Accuracyanalysis icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M735.659 170.667h-447.36a117.76 117.76 0 0 0-117.632 117.632V735.7a117.76 117.76 0 0 0 117.632 117.632h117.034a21.333 21.333 0 0 0 0-42.666H288.3a75.05 75.05 0 0 1-74.966-74.966V288.3c0-41.344 33.622-74.966 74.966-74.966h447.36c41.386 0 75.008 33.622 75.008 74.966v289.493a21.333 21.333 0 1 0 42.666 0V288.299A117.76 117.76 0 0 0 735.66 170.667" fill="#3C405D"/><path d="M516.267 271.616L382.805 400.469 332.8 348.63a21.333 21.333 0 0 0-30.72 29.654l64.853 67.157a21.205 21.205 0 0 0 30.166.512L545.92 302.336a21.333 21.333 0 1 0-29.653-30.677M704 385.792a21.333 21.333 0 0 0 0-42.667H577.75a21.333 21.333 0 0 0 0 42.667H704zm-382.25 337.75h170.666a21.333 21.333 0 0 0 0-42.667H321.749a21.333 21.333 0 0 0 0 42.666m-21.333-188.416a21.333 21.333 0 0 0 21.333 21.334H704a21.333 21.333 0 0 0 0-42.667H321.75a21.333 21.333 0 0 0-21.334 21.333m544.47 123.35c-1.707-1.408-3.542-2.176-5.42-2.987a20.821 20.821 0 0 0-10.282-3.03h-65.11a21.333 21.333 0 0 0 0 42.667h21.59l-70.613 83.03-100.822-80.47a21.333 21.333 0 0 0-29.013 2.219L476.715 817.792a21.419 21.419 0 0 0 15.701 35.797c5.76 0 11.52-2.304 15.701-6.869l95.062-103.296 101.418 80.981c9.046 7.168 22.102 5.974 29.526-2.858l76.544-89.942v10.112a21.333 21.333 0 1 0 42.666 0v-65.066a20.95 20.95 0 0 0-2.773-9.984 21.035 21.035 0 0 0-5.675-8.192" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Accuracyanalysis;
