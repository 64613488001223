import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Purchasebyappointment 图标组件
 */
export const Purchasebyappointment = defineComponent({
	name: "Purchasebyappointment",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Purchasebyappointment icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M373.333 789.333a32 32 0 1 0 0 64 32 32 0 0 0 0-64m277.334 0a32 32 0 1 0 0 64 32 32 0 0 0 0-64M828.203 513.195a21.333 21.333 0 0 0-27.179 13.098A169.13 169.13 0 0 1 641.877 640H290.56l-26.283-341.333H641.92a169.13 169.13 0 0 1 159.232 113.92 21.333 21.333 0 0 0 40.277-14.08A211.883 211.883 0 0 0 641.877 256H260.95l-5.034-65.664c-.086-.853-.555-1.664-.768-2.517-.384-1.792-.726-3.584-1.536-5.206-.342-.597-.768-1.066-1.11-1.621a20.565 20.565 0 0 0-4.138-5.035c-.427-.384-.982-.597-1.451-.981a21.717 21.717 0 0 0-5.12-2.901c-1.408-.555-2.859-.64-4.267-.854-1.066-.128-1.92-.554-2.858-.554H192a21.29 21.29 0 1 0 0 42.666h22.912l41.173 534.955c.086 1.28.64 2.39.982 3.584.298 1.28.426 2.475.938 3.627.683 1.45 1.707 2.688 2.688 3.925.64.853 1.11 1.792 1.878 2.56 1.322 1.28 2.858 2.133 4.437 2.987.768.426 1.408 1.024 2.219 1.365a20.48 20.48 0 0 0 8.106 1.707h469.334a21.333 21.333 0 0 0 0-42.667H297.045l-3.285-42.667h348.16a211.925 211.925 0 0 0 199.381-142.336 21.333 21.333 0 0 0-13.098-27.178" fill="#3C405D"/><path d="M704 384H490.667a21.333 21.333 0 0 0 0 42.667H704A21.333 21.333 0 0 0 704 384m-298.667 0h-42.666a21.333 21.333 0 0 0 0 42.667h42.666a21.333 21.333 0 0 0 0-42.667M704 512H490.667a21.333 21.333 0 0 0 0 42.667H704A21.333 21.333 0 0 0 704 512m-298.667 0h-42.666a21.333 21.333 0 0 0 0 42.667h42.666a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Purchasebyappointment;
