import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Channelinventory 图标组件
 */
export const Channelinventory = defineComponent({
	name: "Channelinventory",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Channelinventory icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M846.6 452.181L507.188 133.59a20.907 20.907 0 0 0-16.512-5.461 21.035 21.035 0 0 0-16.512 5.461L134.75 452.181a21.29 21.29 0 1 0 29.184 31.104l9.643-9.002V754.09a100.139 100.139 0 0 0 100.18 99.882h433.878a100.181 100.181 0 0 0 100.224-99.882v-71.595a21.333 21.333 0 0 0-42.666 0v71.595c0 31.53-25.856 57.216-57.6 57.216H273.8a57.43 57.43 0 0 1-57.515-57.216V434.219l274.39-257.579L765.15 434.261v120.235a21.29 21.29 0 1 0 42.667 0v-80.17l9.557 8.96a21.12 21.12 0 0 0 30.123-.94 21.29 21.29 0 0 0-.94-30.165" fill="#3C405D"/><path d="M386.1 575.83h213.034c35.285 0 64-28.715 64-64s-28.715-64-64-64h-256a21.333 21.333 0 0 0 0 42.666h256a21.333 21.333 0 0 1 0 42.667H386.099c-35.285 0-64 28.714-64 64s28.715 64 64 64h252.16a21.333 21.333 0 1 0 0-42.667H386.1a21.333 21.333 0 0 1 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Channelinventory;
