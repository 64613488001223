import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Warehousing 图标组件
 */
export const Warehousing = defineComponent({
	name: "Warehousing",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Warehousing icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M846.636 452.181L507.18 133.59a21.205 21.205 0 0 0-16.555-5.504 21.333 21.333 0 0 0-16.426 5.504L134.743 452.181a21.333 21.333 0 0 0 29.226 31.104l9.6-8.96v279.766a100.139 100.139 0 0 0 100.182 99.882h433.92a100.181 100.181 0 0 0 100.18-99.882v-69.803a21.333 21.333 0 0 0-42.666 0v69.803c0 31.53-25.813 57.216-57.557 57.216H273.75a57.43 57.43 0 0 1-57.515-57.216V434.26L490.71 176.64l274.474 257.621v124.502a21.333 21.333 0 1 0 42.667 0v-84.438l9.557 8.96a21.333 21.333 0 1 0 29.227-31.104" fill="#3C405D"/><path d="M448.044 446.976a21.333 21.333 0 0 0-30.165 0l-88.022 88.064a21.333 21.333 0 0 0-4.608 23.168 21.035 21.035 0 0 0 20.566 17.664H640.94a21.333 21.333 0 1 0 0-42.667H392.023l56.02-56.021a21.333 21.333 0 0 0 0-30.165" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Warehousing;
