import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Panoramaoftheevent 图标组件
 */
export const Panoramaoftheevent = defineComponent({
	name: "Panoramaoftheevent",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Panoramaoftheevent icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M738.859 453.461a21.29 21.29 0 1 0-12.288 40.832c89.173 26.88 144.512 68.395 144.512 108.374 0 42.282-60.075 85.162-156.8 111.872a21.333 21.333 0 0 0 11.349 41.173c119.552-33.024 188.16-88.832 188.16-153.045 0-61.355-63.787-115.755-174.933-149.206" fill="#3C405D"/><path d="M655.915 332.33L616.32 454.145c-.213.683-.085 1.323-.213 2.005a20.821 20.821 0 0 0-.214 8.32 20.139 20.139 0 0 0 3.072 7.68c.342.555.427 1.28.854 1.792l90.752 115.286H338.219l-52.182-256.854h369.835zm-48.811 408.15l-58.667-58.667a21.333 21.333 0 0 0-30.165 30.166l27.605 27.648c-15.317.768-30.549 1.706-46.293 1.706-47.659 0-91.904-3.626-132.395-9.728l-20.224-99.712h407.552a20.907 20.907 0 0 0 11.947-4.181c.427-.256.853-.17 1.195-.427.298-.256.426-.64.768-.853a20.139 20.139 0 0 0 2.901-3.413c.853-1.11 1.621-2.091 2.219-3.243.512-1.152.768-2.432 1.152-3.712a18.432 18.432 0 0 0 .853-4.267c0-.426.256-.81.256-1.237 0-.939-.427-1.707-.512-2.645a20.992 20.992 0 0 0-.853-4.267 18.432 18.432 0 0 0-1.963-3.84c-.512-.853-.64-1.707-1.237-2.475l-110.806-140.8 45.142-138.965c.298-1.11.17-2.133.341-3.285.213-1.11.64-2.134.64-3.286 0-.298-.17-.597-.17-.853-.043-1.579-.47-2.987-.854-4.48-.341-1.237-.512-2.475-1.067-3.627-.554-1.237-1.45-2.261-2.261-3.37-.853-1.11-1.536-2.262-2.517-3.2-.896-.854-2.006-1.408-3.115-2.091-1.28-.853-2.517-1.664-3.968-2.219-.299-.128-.47-.341-.81-.426-1.025-.384-2.134-.214-3.286-.427-1.11-.17-2.133-.64-3.285-.64H277.419l-3.371-16.597a21.333 21.333 0 0 0-41.813 8.533l89.728 441.899C202.58 697.813 128 648.448 128 602.667c0-33.067 37.248-67.158 102.23-93.568a21.333 21.333 0 0 0 11.775-27.819 21.248 21.248 0 0 0-27.776-11.69c-83.114 33.749-128.896 80.98-128.896 133.162 0 101.632 181.888 181.333 414.166 181.333 13.226 0 25.898-.938 38.826-1.45l-20.138 20.096a21.333 21.333 0 0 0 30.165 30.165l58.71-58.667a20.992 20.992 0 0 0 5.887-16.853 21.077 21.077 0 0 0-5.93-16.853z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Panoramaoftheevent;
