import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Customicon 图标组件
 */
export const Customicon = defineComponent({
	name: "Customicon",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Customicon icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M258.9 71.6c-104.5 0-189.1 84.7-189.1 189.1s84.7 189.1 189.1 189.1H448V260.7c0-104.4-84.6-189.1-189.1-189.1zM385 386.8H258.9c-69.5 0-126.1-56.6-126.1-126.1s56.6-126.1 126.1-126.1S385 191.2 385 260.7v126.1zm567.4-126.1c0-104.5-84.7-189.1-189.1-189.1s-189.1 84.7-189.1 189.1v189.1h189.1c104.4.1 189.1-84.6 189.1-189.1zm-315.2 0c0-69.5 56.6-126.1 126.1-126.1s126.1 56.6 126.1 126.1-56.6 126.1-126.1 126.1H637.2V260.7zM69.8 765.1c0 104.5 84.7 189.1 189.1 189.1S448 869.6 448 765.1V576H258.9c-104.5 0-189.1 84.6-189.1 189.1zm315.2 0c0 69.5-56.6 126.1-126.1 126.1s-126.1-56.6-126.1-126.1S189.4 639 258.9 639H385v126.1zM763.3 576H574.1v189.1c0 104.5 84.7 189.1 189.1 189.1s189.1-84.7 189.1-189.1S867.7 576 763.3 576zm0 315.2c-69.5 0-126.1-56.6-126.1-126.1V639h126.1c69.5 0 126.1 56.6 126.1 126.1s-56.6 126.1-126.1 126.1z"/></svg>
			</ElIcon>
		);
	},
});

export default Customicon;
