import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Appointment 图标组件
 */
export const Appointment = defineComponent({
	name: "Appointment",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Appointment icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M515.115 298.197v230.87a21.333 21.333 0 0 0 21.333 21.333c.427 0 .725-.213 1.11-.213.426 0 .682.213 1.109.213H767.36a21.333 21.333 0 0 0 0-42.667H557.781V298.197a21.333 21.333 0 0 0-42.666 0" fill="#3C405D"/><path d="M875.307 532.693a21.461 21.461 0 0 0-22.614 20.011c-10.325 168.192-150.613 299.947-319.36 299.947-176.469 0-320-143.531-320-320 0-168.704 131.755-308.992 299.99-319.36a21.333 21.333 0 0 0-2.603-42.582C320 182.443 170.667 341.461 170.667 532.651c0 199.978 162.688 362.666 362.666 362.666 191.232 0 350.208-149.376 361.942-340.053a21.333 21.333 0 0 0-19.968-22.57M813.056 377.088a21.333 21.333 0 1 0 37.29-20.608 351.573 351.573 0 0 0-24.49-38.23 21.29 21.29 0 1 0-34.347 25.26 325.815 325.815 0 0 1 21.547 33.62m29.824 74.027c1.835 7.083 3.413 14.251 4.821 21.462a21.333 21.333 0 0 0 41.942-7.936 359.467 359.467 0 0 0-5.547-24.363 21.29 21.29 0 1 0-41.259 10.837M704.3 262.06c11.306 7.125 22.144 15.018 32.426 23.466a21.29 21.29 0 0 0 29.995-2.986 21.333 21.333 0 0 0-2.901-30.038 389.547 389.547 0 0 0-36.694-26.538 21.333 21.333 0 1 0-22.826 36.096m-110.934-43.776c13.227 2.517 26.155 5.845 38.742 9.813a21.376 21.376 0 0 0 13.098-40.576 359.51 359.51 0 0 0-43.904-11.136 21.29 21.29 0 1 0-7.936 41.899" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Appointment;
