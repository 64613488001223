import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * DropDown 图标组件
 */
export const DropDown = defineComponent({
	name: "DropDown",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-DropDown icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M676.395 432.896a21.333 21.333 0 0 0-30.166 0L511.061 568.021 377.728 434.645a21.333 21.333 0 0 0-30.165 30.166l148.394 148.48a21.419 21.419 0 0 0 30.208 0l150.23-150.187a21.333 21.333 0 0 0 0-30.208" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default DropDown;
