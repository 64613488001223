import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Doubt 图标组件
 */
export const Doubt = defineComponent({
	name: "Doubt",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Doubt icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M830.08 573.867a18.944 18.944 0 0 0-22.059 15.445A322.901 322.901 0 0 1 489.557 855.68c-178.389 0-323.498-145.067-323.498-323.499A323.413 323.413 0 0 1 757.76 351.275a319.83 319.83 0 0 1 50.261 123.818 19.072 19.072 0 0 0 37.462-6.656 356.95 356.95 0 0 0-56.15-138.453 361.301 361.301 0 0 0-299.776-159.317A361.43 361.43 0 0 0 128 532.18c0 199.382 162.219 361.558 361.557 361.558a360.875 360.875 0 0 0 355.926-297.771 19.03 19.03 0 0 0-15.36-22.059" fill="#3C405D"/><path d="M509.696 572.16c69.12-9.941 122.581-68.907 122.581-140.672 0-78.72-64-142.72-142.72-142.72a142.848 142.848 0 0 0-142.72 142.72 20.139 20.139 0 0 0 40.278 0c0-56.49 45.994-102.4 102.4-102.4 56.49 0 102.485 45.91 102.485 102.4 0 56.448-45.995 102.4-102.443 102.4-11.093 0-20.138 8.96-20.138 20.181 0 .854.384 1.622.512 2.475-.128.853-.512 1.621-.512 2.56v100.693a20.139 20.139 0 0 0 40.277 0V572.16zm-20.139 129.621a20.139 20.139 0 0 0-20.138 20.182v31.914a20.139 20.139 0 0 0 40.277 0v-31.914a20.139 20.139 0 0 0-20.139-20.182" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Doubt;
