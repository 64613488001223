import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Qualitycontrol 图标组件
 */
export const Qualitycontrol = defineComponent({
	name: "Qualitycontrol",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Qualitycontrol icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M405.333 213.888h128a21.333 21.333 0 0 0 0-42.667h-128a21.333 21.333 0 0 0 0 42.667m-128-42.667H192a21.333 21.333 0 0 0 0 42.667h85.333a21.333 21.333 0 0 0 0-42.667m128 362.667H832a21.333 21.333 0 0 0 0-42.667H405.333a21.333 21.333 0 0 0 0 42.667m-128-42.667H192a21.333 21.333 0 0 0 0 42.667h85.333a21.333 21.333 0 0 0 0-42.667m554.667 320H405.333a21.333 21.333 0 0 0 0 42.667H832a21.333 21.333 0 0 0 0-42.667m-554.667 0H192a21.333 21.333 0 0 0 0 42.667h85.333a21.333 21.333 0 0 0 0-42.667m447.702-399.488C689.792 405.547 640 376.832 640 319.573V232.15l84.224-18.901 85.803 18.944v96.341l.085.427c-.17.853-.683 1.493-.768 2.347-5.973 46.805-47.317 74.026-84.31 80.384m-3.2 42.922a24.747 24.747 0 0 0 3.073.214l.128-.086.085.086a25.344 25.344 0 0 0 3.072-.214c53.163-7.68 114.517-47.786 123.477-117.845.086-.768-.17-1.493-.17-2.219.554-1.962 1.194-3.882 1.194-5.973v-113.28a21.077 21.077 0 0 0-15.616-20.181c-.981-.427-1.792-1.024-2.858-1.28L729.6 170.795c-.768-.128-1.493.085-2.261 0-1.152-.214-2.39 0-3.584-.043-.939 0-1.878-.128-2.774 0-.81.128-1.578-.128-2.389 0l-102.827 23.04c-1.066.299-1.877.981-2.858 1.365a20.992 20.992 0 0 0-15.574 20.139v108.117c0 1.408.555 2.646.768 3.968 4.267 72.278 64.043 118.614 123.734 127.232" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Qualitycontrol;
