import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Brandauthorizationmanagement 图标组件
 */
export const Brandauthorizationmanagement = defineComponent({
	name: "Brandauthorizationmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Brandauthorizationmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M836.053 199.68c-1.536-.768-2.986-1.707-4.736-2.09l-314.666-69.291c-2.048-.427-4.096-.299-6.102-.128h-.256c-.725.042-1.493-.128-2.218 0-2.006-.128-3.968-.299-6.016.128l-309.334 69.29c-1.749.427-3.2 1.323-4.736 2.134a21.077 21.077 0 0 0-17.28 20.48v325.333c0 1.408.555 2.645.811 3.968 8.32 198.101 173.483 324.907 337.067 348.288a24.747 24.747 0 0 0 3.029.213l.427-.085.426.085a25.984 25.984 0 0 0 3.072-.213c6.656-.939 13.398-2.133 20.139-3.413a21.333 21.333 0 0 0-8.192-41.899 339.755 339.755 0 0 1-15.36 2.603c-148.864-22.315-298.752-139.264-298.752-321.024V236.715l296.064-66.347 301.227 66.347v244.864a21.333 21.333 0 1 0 42.666 0V220.203a20.992 20.992 0 0 0-17.28-20.48" fill="#3D405D"/><path d="M704.043 630.912a64 64 0 1 0 .042 128 64 64 0 0 0 0-128" fill="#3D405D"/><path d="M704.043 801.579a106.795 106.795 0 0 1-106.667-106.667c0-58.88 47.872-106.667 106.667-106.667s106.666 47.787 106.666 106.667c0 58.837-47.872 106.667-106.666 106.667zm-278.23-310.4V374.784h94.72c52.096 0 78.123 19.413 78.123 58.197 1.11 38.827-24.917 58.198-78.123 58.198h-94.72zm278.23 54.4c-38.784 0-73.814 15.232-100.395 39.552l-44.885-55.766c63.146-7.765 94.762-40.405 94.762-98.048-4.437-63.146-44.885-95.829-121.344-98.133H374.272v340.821h51.541V532.693h78.123l68.736 92.758a147.2 147.2 0 0 0-17.963 69.461c0 82.347 66.987 149.333 149.334 149.333s149.333-66.986 149.333-149.333-66.987-149.333-149.333-149.333z" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Brandauthorizationmanagement;
