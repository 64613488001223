import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Completionstatement 图标组件
 */
export const Completionstatement = defineComponent({
	name: "Completionstatement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Completionstatement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M488.875 854.187H296.363c-22.23 0-40.363-19.243-40.363-43.008V305.493l134.784-134.826h336.853c22.23 0 40.363 19.328 40.363 43.008v317.013a21.333 21.333 0 0 0 42.667 0V213.675c0-47.275-37.248-85.675-83.03-85.675H382.208a21.077 21.077 0 0 0-15.19 6.4c-.298.299-.64.341-.895.64l-146.56 146.475a21.163 21.163 0 0 0-6.016 16.128c-.043.341-.214.682-.214 1.024v512.512c0 47.232 37.248 85.674 83.03 85.674h192.512a21.333 21.333 0 0 0 0-42.666" fill="#3C405D"/><path d="M702.208 681.77a64 64 0 1 0 .043 128.043 64 64 0 0 0 0-128.042" fill="#3C405D"/><path d="M702.208 852.437a106.795 106.795 0 0 1-106.667-106.666c0-58.795 47.83-106.667 106.667-106.667s106.667 47.872 106.667 106.667-47.83 106.666-106.667 106.666m0-256c-14.08 0-27.435 2.603-40.363 6.23-.768-.086-1.493-.47-2.304-.47H534.656v-59.989h124.885a21.333 21.333 0 0 0 0-42.667H534.656v-23.893l103.765-97.92a21.333 21.333 0 0 0-29.269-31.061l-98.133 92.672-98.176-92.672a21.376 21.376 0 0 0-29.27 31.061L491.99 480.043v19.498H360.875a21.333 21.333 0 0 0 0 42.667h131.114v59.99H360.875a21.333 21.333 0 0 0 0 42.666h131.114v93.781a21.333 21.333 0 1 0 42.667 0v-93.781h58.197a148.181 148.181 0 0 0-39.978 100.907c0 82.346 66.986 149.333 149.333 149.333s149.333-66.987 149.333-149.333-66.986-149.334-149.333-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Completionstatement;
