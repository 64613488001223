import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Jobauthorizationmanagement 图标组件
 */
export const Jobauthorizationmanagement = defineComponent({
	name: "Jobauthorizationmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Jobauthorizationmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M530.816 851.797c-6.272 1.28-12.544 2.347-18.773 3.286-148.864-22.315-298.667-139.264-298.667-321.067V236.715l295.979-66.347 301.312 66.39v244.778a21.29 21.29 0 1 0 42.666 0V220.203a21.035 21.035 0 0 0-17.365-20.523c-1.536-.768-2.987-1.707-4.693-2.09l-314.71-69.334c-2.048-.427-3.968-.213-5.973-.085-.555-.086-1.195.085-1.792 0h-.768c-2.005-.128-3.968-.342-6.059.085L192.64 197.589c-1.707.384-3.157 1.323-4.693 2.091a21.035 21.035 0 0 0-17.28 20.523v325.333c0 1.408.512 2.645.768 3.968 8.362 198.101 173.44 324.907 337.109 348.288a23.552 23.552 0 0 0 3.03.213l.426-.085.427.085a25.472 25.472 0 0 0 3.072-.213c7.893-1.152 15.829-2.517 23.808-4.181a21.333 21.333 0 1 0-8.491-41.814" fill="#3C405D"/><path d="M523.093 428.075l60.331-60.246a45.397 45.397 0 0 1 64.555 0c8.618 8.619 13.354 20.096 13.354 32.256 0 12.203-4.736 23.638-13.354 32.299l-60.288 60.288a46.848 46.848 0 0 1-64.555 0 45.781 45.781 0 0 1 0-64.597m32.299 120.618c23.594 0 45.738-9.216 62.421-25.856l60.288-60.288C694.784 445.91 704 423.637 704 400.085c0-23.594-9.216-45.781-25.856-62.421-33.323-33.365-91.605-33.28-124.885 0l-60.288 60.245a87.765 87.765 0 0 0-13.056 107.734L329.813 655.829a21.29 21.29 0 0 0 15.062 36.352 21.248 21.248 0 0 0 15.104-6.229l28.074-28.075 26.155 26.155a21.333 21.333 0 1 0 30.165-30.165l-26.154-26.155 91.861-91.861c13.61 8.192 29.013 12.842 45.355 12.842m150.357 82.176a64 64 0 1 0 0 128.043 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M705.792 801.536a106.752 106.752 0 0 1-106.667-106.667c0-58.837 47.83-106.666 106.667-106.666s106.667 47.829 106.667 106.666c0 58.88-47.83 106.667-106.667 106.667m0-256c-82.347 0-149.333 66.987-149.333 149.333s66.986 149.334 149.333 149.334 149.333-66.987 149.333-149.334-66.986-149.333-149.333-149.333" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Jobauthorizationmanagement;
