import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Costquery 图标组件
 */
export const Costquery = defineComponent({
	name: "Costquery",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Costquery icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M469.504 724.181a21.333 21.333 0 0 0 21.333-21.333v-93.739h124.886a21.333 21.333 0 0 0 0-42.666H490.837V506.41h124.886a21.333 21.333 0 1 0 0-42.667H490.837v-23.936l103.68-97.877a21.248 21.248 0 0 0 .854-30.123 21.248 21.248 0 0 0-30.08-.896l-98.134 92.587-98.133-92.587a21.29 21.29 0 1 0-29.27 31.019l108.374 102.272v19.541h-131.03a21.333 21.333 0 1 0 0 42.667h131.115v60.032h-131.2a21.29 21.29 0 1 0 0 42.666h131.115v93.739a21.333 21.333 0 0 0 21.333 21.333" fill="#3C405D"/><path d="M745.259 170.667H276.352a105.856 105.856 0 0 0-105.685 105.77v468.864a105.813 105.813 0 0 0 105.728 105.686h298.453a20.181 20.181 0 0 0 0-40.32H276.395a65.408 65.408 0 0 1-65.366-65.366V276.395c0-36.054 29.312-65.408 65.366-65.408h468.864c36.096 0 65.408 29.354 65.408 65.45v295.254a20.181 20.181 0 0 0 40.362 0V276.437a105.899 105.899 0 0 0-105.813-105.813" fill="#3C405D"/><path d="M650.07 719.872a64.64 64.64 0 1 1 64.597 64.597 64.64 64.64 0 0 1-64.598-64.597zm155.605 56.021a106.24 106.24 0 0 0 16.213-56.021 107.392 107.392 0 0 0-107.221-107.264 107.392 107.392 0 0 0-107.264 107.264 107.392 107.392 0 0 0 107.264 107.264 106.24 106.24 0 0 0 61.866-19.968l36.736 40.277a21.163 21.163 0 0 0 30.123 1.366 21.29 21.29 0 0 0 1.365-30.123l-39.082-42.795z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Costquery;
