import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Unlock 图标组件
 */
export const Unlock = defineComponent({
	name: "Unlock",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Unlock icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M810.667 557.312v-58.155c0-54.272-48-98.432-106.966-98.432H357.888v-92.544c0-75.818 69.632-137.514 155.136-137.514 85.547 0 155.179 61.696 155.179 137.514v18.006a21.333 21.333 0 1 0 42.666 0V308.18c0-99.37-88.746-180.181-197.845-180.181-109.056 0-197.803 80.81-197.803 180.181v93.014c-56.576 2.474-101.888 45.269-101.888 97.962V754.86c0 54.314 47.958 98.474 106.923 98.474h383.445c58.966 0 106.966-44.16 106.966-98.474v-69.547a21.333 21.333 0 0 0-42.667 0v69.547c0 30.805-28.843 55.808-64.299 55.808H320.256c-35.413 0-64.256-25.003-64.256-55.808V499.157c0-30.762 28.843-55.765 64.256-55.765h383.445c35.456 0 64.299 25.003 64.299 55.765v58.155a21.333 21.333 0 0 0 42.667 0z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Unlock;
