import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Lifecycle 图标组件
 */
export const Lifecycle = defineComponent({
	name: "Lifecycle",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Lifecycle icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M871.765 572.544a20.31 20.31 0 0 0-23.381 16.299 320.597 320.597 0 0 1-316.203 264.49c-162.901 0-297.514-122.026-318.122-279.381h177.834c9.302 0 17.494-5.973 20.31-14.805l30.122-94.294 48.726 221.227a21.333 21.333 0 0 0 20.181 16.768h.64a21.376 21.376 0 0 0 20.48-15.488l45.91-160.427 18.773 35.67a21.248 21.248 0 0 0 18.858 11.349h85.334a21.333 21.333 0 1 0 0-42.667h-72.448l-37.547-71.296a20.907 20.907 0 0 0-21.077-11.221 21.248 21.248 0 0 0-18.347 15.317L514.56 594.517l-47.616-216.32a21.333 21.333 0 0 0-19.84-16.64c-9.984-1.28-18.432 5.547-21.333 14.763l-49.494 154.965H211.115A321.067 321.067 0 0 1 532.18 210.987 321.195 321.195 0 0 1 825.6 401.493c4.523 10.198 16.47 14.635 26.624 10.198a19.797 19.797 0 0 0 9.77-9.856 19.627 19.627 0 0 0 3.2-10.24V272.896a20.181 20.181 0 0 0-40.362 0v47.957a361.003 361.003 0 0 0-292.65-150.186A361.387 361.387 0 0 0 170.666 532.18c0 199.339 162.133 361.472 361.514 361.472a360.917 360.917 0 0 0 355.84-297.728 20.139 20.139 0 0 0-16.256-23.381" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Lifecycle;
