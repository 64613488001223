import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Smartsalesmanagement 图标组件
 */
export const Smartsalesmanagement = defineComponent({
	name: "Smartsalesmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Smartsalesmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M886.827 134.699a21.333 21.333 0 0 0-30.123-.896l-151.21 142.72a338.901 338.901 0 0 0-125.44-57.046 339.67 339.67 0 0 0-269.355 54.059L163.925 135.083a21.376 21.376 0 0 0-29.184 31.061L277.12 300.459c-1.621 1.536-3.456 2.73-5.12 4.266a338.56 338.56 0 0 0-97.92 173.014c-40.704 183.168 75.093 365.226 258.261 406.016a342.187 342.187 0 0 0 69.248 8.106h.299a20.181 20.181 0 0 0 .256-40.234 301.653 301.653 0 0 1-61.056-7.254c-161.493-35.84-263.637-196.394-227.755-357.845a299.947 299.947 0 0 1 357.888-227.67 299.861 299.861 0 0 1 232.107 251.734 20.352 20.352 0 0 0 22.699 17.152 20.053 20.053 0 0 0 17.194-22.699 338.219 338.219 0 0 0-20.394-77.909 339.541 339.541 0 0 0-84.267-123.179l147.328-139.093a21.333 21.333 0 0 0 .853-30.123" fill="#3C405D"/><path d="M701.568 636.63a64 64 0 1 0 0 128.042 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M701.568 807.296a106.795 106.795 0 0 1-106.667-106.667c0-58.794 47.83-106.666 106.667-106.666 58.88 0 106.667 47.872 106.667 106.666s-47.787 106.667-106.667 106.667m0-256c-40.533 0-77.227 16.384-104.235 42.71h-60.757v-39.851h96.17a21.333 21.333 0 1 0 0-42.667h-96.17v-13.227l82.09-77.44a21.333 21.333 0 1 0-29.269-31.018l-75.946 71.722-76.032-71.68a21.333 21.333 0 0 0-29.27 30.976l85.803 80.982v9.685h-101.12a21.333 21.333 0 1 0 0 42.667h101.12v39.85h-101.12a21.333 21.333 0 1 0 0 42.667h101.12v71.168a21.333 21.333 0 1 0 42.667 0v-71.168h30.634a147.2 147.2 0 0 0-14.933 64c0 82.347 66.987 149.333 149.333 149.333s149.334-66.986 149.334-149.333S784 551.339 701.653 551.339" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Smartsalesmanagement;
