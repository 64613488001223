import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * StockInManage 图标组件
 */
export const StockInManage = defineComponent({
	name: "StockInManage",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-StockInManage icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M702.22 684.288a64 64 0 1 0 .043 128 64 64 0 0 0 0-128" fill="#3D405D"/><path d="M702.22 854.955a106.795 106.795 0 0 1-106.666-106.667c0-58.795 47.829-106.667 106.666-106.667 58.88 0 106.667 47.872 106.667 106.667S761.1 854.955 702.22 854.955m0-256c-30.506 0-58.88 9.301-82.56 25.045.256-1.28.768-2.39.768-3.712a21.333 21.333 0 0 0-21.333-21.333H388.407l56.021-56.022a21.333 21.333 0 0 0-30.165-30.165l-88.021 87.979a21.461 21.461 0 0 0-4.608 23.21 21.035 21.035 0 0 0 20.565 17.664h255.83a148.693 148.693 0 0 0-45.142 106.667c0 82.347 66.987 149.333 149.333 149.333s149.334-66.986 149.334-149.333-66.987-149.333-149.334-149.333" fill="#3D405D"/><path d="M488.887 853.973H273.719a57.472 57.472 0 0 1-57.557-57.173V476.928l274.517-257.621L817.42 525.995a21.29 21.29 0 0 0 30.123-.982 21.29 21.29 0 0 0-.939-30.165L507.148 176.256a20.992 20.992 0 0 0-16.426-5.461 20.95 20.95 0 0 0-16.555 5.461L134.754 494.848a21.29 21.29 0 0 0 14.592 36.907 21.29 21.29 0 0 0 14.592-5.76l9.557-8.96v279.722a100.181 100.181 0 0 0 100.267 99.883h215.125a21.333 21.333 0 0 0 0-42.667" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default StockInManage;
