import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Website 图标组件
 */
export const Website = defineComponent({
	name: "Website",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Website icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M511.1 8.5C232.5 8.5 6.7 234.4 6.7 512.9s225.8 504.4 504.4 504.4 504.4-225.8 504.4-504.4S789.6 8.5 511.1 8.5zm441.3 504.4H763.3c0-173.8-43.9-327-110.8-417.7 174.1 59.1 299.9 223.9 299.9 417.7zM511.1 954.2c-81.7 0-171.9-158.1-186.8-378.3h373.6C683 796.2 592.7 954.2 511.1 954.2zM321.9 512.9c0-252.6 99.9-441.3 189.1-441.3s189.1 188.7 189.1 441.3H321.9zm47.8-417.7c-66.9 90.7-110.8 243.9-110.8 417.7H69.8c0-193.8 125.8-358.6 299.9-417.7zM74.8 576h186.3c9.2 147.4 50 275.1 108.6 354.6C214.8 878 98.7 741.9 74.8 576zm577.7 354.6c58.6-79.5 99.4-207.3 108.6-354.6h186.3c-23.9 165.9-140.1 302-294.9 354.6z"/></svg>
			</ElIcon>
		);
	},
});

export default Website;
