import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Settlementwithsuppliers 图标组件
 */
export const Settlementwithsuppliers = defineComponent({
	name: "Settlementwithsuppliers",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Settlementwithsuppliers icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M518.357 554.667c90.24 0 170.454-52.267 204.374-133.078a21.504 21.504 0 0 0 1.578-7.552A21.29 21.29 0 0 0 704.853 384H491.52a21.333 21.333 0 0 0 0 42.667h180.779C640.853 479.147 582.827 512 518.272 512c-98.048 0-177.92-76.544-177.92-170.667 0-34.048 10.453-66.901 30.208-95.061 33.195-47.36 88.448-75.605 147.797-75.605 59.307 0 114.56 28.245 147.712 75.562a164.884 164.884 0 0 1 17.323 31.232 21.333 21.333 0 0 0 39.339-16.512 209.152 209.152 0 0 0-21.76-39.253C659.883 163.029 591.616 128 518.4 128c-73.259 0-141.568 35.072-182.741 93.781a207.659 207.659 0 0 0-37.888 119.552c0 117.632 98.944 213.334 220.586 213.334M662.23 853.333H276.096c-58.155 0-105.43-47.829-105.43-106.666S217.942 640 276.097 640h341.675a21.333 21.333 0 0 0 0-42.667H276.096C194.432 597.333 128 664.32 128 746.667S194.432 896 276.096 896h386.133a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M870.23 767.573h-83.115v-30.72h83.114a21.333 21.333 0 1 0 0-42.666h-83.114v-8.363l72.192-68.139a21.333 21.333 0 1 0-29.27-30.976l-65.92 62.208-65.92-62.208a21.333 21.333 0 1 0-29.269 30.976l75.52 71.296v5.206h-87.552a21.333 21.333 0 0 0 0 42.666h87.552v30.72h-87.552a21.333 21.333 0 0 0 0 42.667h87.552v60.885a21.333 21.333 0 1 0 42.667 0V810.24h83.114a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Settlementwithsuppliers;
