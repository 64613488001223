import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Postauthorization 图标组件
 */
export const Postauthorization = defineComponent({
	name: "Postauthorization",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Postauthorization icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 470.912a21.333 21.333 0 0 0 21.333-21.333V220.16a21.035 21.035 0 0 0-17.322-20.48c-1.579-.81-2.987-1.707-4.779-2.133l-314.667-69.248c-2.048-.427-4.01-.256-5.973-.128h-.043c-.896.085-1.834-.128-2.688.042-1.92-.128-3.84-.341-5.845.086l-309.333 69.29c-1.792.427-3.2 1.323-4.736 2.134a21.035 21.035 0 0 0-17.28 20.48v325.333c0 1.408.554 2.645.81 3.968 8.32 198.101 173.483 324.907 337.11 348.288a24.107 24.107 0 0 0 3.029.213l.384-.085.427.085a24.107 24.107 0 0 0 3.029-.213c144.47-20.693 310.912-128.853 335.019-317.568a21.333 21.333 0 0 0-42.368-5.376c-21.078 165.461-167.723 260.907-296.064 280.235-148.864-22.358-298.71-139.264-298.71-321.11V236.715l296.064-66.39 301.27 66.39v212.864A21.333 21.333 0 0 0 832 470.912" fill="#3C405D"/><path d="M523.093 428.117l60.331-60.245a45.397 45.397 0 0 1 64.555 0c8.618 8.619 13.354 20.053 13.354 32.256s-4.736 23.637-13.354 32.299l-60.246 60.288a46.805 46.805 0 0 1-64.597 0 45.824 45.824 0 0 1 0-64.598m32.299 120.619a87.893 87.893 0 0 0 62.464-25.856l60.245-60.288C694.784 445.909 704 423.68 704 400.128c0-23.595-9.216-45.781-25.856-62.421-33.323-33.323-91.563-33.323-124.885 0l-60.288 60.245a88.405 88.405 0 0 0-13.014 107.776L329.813 655.787a21.29 21.29 0 0 0 15.062 36.394 21.248 21.248 0 0 0 15.104-6.229l28.032-28.075 26.154 26.155a21.333 21.333 0 1 0 30.166-30.165l-26.112-26.155 91.861-91.861a87.467 87.467 0 0 0 45.312 12.8" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Postauthorization;
