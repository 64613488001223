import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Targetmonitoring 图标组件
 */
export const Targetmonitoring = defineComponent({
	name: "Targetmonitoring",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Targetmonitoring icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M552.96 512a39.168 39.168 0 1 1-78.25-.043 39.168 39.168 0 0 1 78.293.043m-120.918 0a81.92 81.92 0 0 0 81.792 81.792A81.92 81.92 0 0 0 595.627 512a81.92 81.92 0 0 0-81.792-81.792A81.92 81.92 0 0 0 432.085 512m361.088 298.837H673.707a21.333 21.333 0 0 0 0 42.667h119.466a21.333 21.333 0 0 0 0-42.667m-61.525-183.466a34.133 34.133 0 1 0 0 68.266 34.133 34.133 0 0 0 0-68.266" fill="#3C405D"/><path d="M731.648 759.637c-44.715 0-81.067-36.394-81.067-81.066s36.352-81.067 81.067-81.067a81.067 81.067 0 1 1 0 162.133m0-204.8c-68.267 0-123.733 55.467-123.733 123.734 0 68.224 55.466 123.733 123.733 123.733a123.819 123.819 0 0 0 119.893-154.667 123.733 123.733 0 0 0-119.893-92.8" fill="#3C405D"/><path d="M876.672 488.661h-74.453a287.275 287.275 0 0 0-266.88-264.96v-74.368a21.333 21.333 0 0 0-42.667 0v74.539a287.403 287.403 0 0 0-262.87 264.79h-80.469a21.333 21.333 0 0 0 0 42.666h80.427a285.184 285.184 0 0 0 262.912 264.661v78.678a21.333 21.333 0 0 0 42.667 0v-76.203c13.098-.853 26.112-2.39 38.954-5.035a21.333 21.333 0 1 0-8.661-41.77 241.536 241.536 0 0 1-30.293 4.138V681.26a21.333 21.333 0 1 0-42.667 0v71.978a242.859 242.859 0 0 1-220.245-221.866h70.314a21.333 21.333 0 1 0 0-42.667H272.47a244.907 244.907 0 0 1 220.16-222.165v76.202a21.333 21.333 0 0 0 42.667 0v-76.373a244.779 244.779 0 0 1 224.256 222.293h-76.288a21.333 21.333 0 1 0 0 42.667h193.408a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Targetmonitoring;
