import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Performancemanagement 图标组件
 */
export const Performancemanagement = defineComponent({
	name: "Performancemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Performancemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M494.208 853.333H319.445c-34.986 0-63.445-28.416-63.445-63.36V234.027c0-34.944 28.459-63.36 63.445-63.36h385.11c34.986 0 63.445 28.416 63.445 63.36v257.536a21.333 21.333 0 1 0 42.667 0V234.027A106.24 106.24 0 0 0 704.555 128h-385.11a106.24 106.24 0 0 0-106.112 106.027v555.946A106.24 106.24 0 0 0 319.445 896h174.763a21.333 21.333 0 0 0 0-42.667" fill="#3D405D"/><path d="M585.813 312.021a34.645 34.645 0 1 0 69.248 0 34.645 34.645 0 0 0-69.29 0m120.021 369.792a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3D405D"/><path d="M705.792 852.48a106.752 106.752 0 0 1-106.667-106.667c0-58.88 47.83-106.666 106.667-106.666s106.667 47.786 106.667 106.666c0 58.838-47.83 106.667-106.667 106.667zM451.371 547.413c33.28-86.442 50.432-130.73 51.541-132.992l9.984-29.866h1.664c0 2.218 2.73 9.984 8.32 23.253 1.067 3.285 1.664 5.547 1.664 6.613 1.067 2.262 18.261 46.55 51.499 132.992H451.37zm254.421 49.067c-19.243 0-37.461 3.925-54.357 10.581L544.469 339.627h-61.482l-136.32 340.778H403.2l34.901-91.392h151.254l17.493 45.824a148.395 148.395 0 0 0-50.39 110.934c0 82.346 66.987 149.333 149.334 149.333s149.333-66.987 149.333-149.333-66.986-149.334-149.333-149.334z" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Performancemanagement;
