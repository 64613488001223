import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Activereview 图标组件
 */
export const Activereview = defineComponent({
	name: "Activereview",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Activereview icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M554.751 597.035h-216.32l-68.95-340.566h488.79l-53.163 163.67c-.17.64-.085 1.28-.213 1.962a18.517 18.517 0 0 0-.427 4.31 20.907 20.907 0 0 0 .214 4.01 23.637 23.637 0 0 0 3.072 7.723c.426.555.47 1.28.896 1.75l85.333 108.458a21.419 21.419 0 0 0 29.995 3.541 21.376 21.376 0 0 0 3.541-29.952l-78.293-99.498 58.71-180.736c.34-1.11.17-2.134.34-3.286.214-1.109.683-2.133.683-3.285 0-.341-.17-.597-.17-.939-.086-1.493-.513-2.901-.854-4.352-.341-1.237-.555-2.56-1.11-3.712-.554-1.194-1.407-2.218-2.218-3.285-.81-1.152-1.536-2.304-2.517-3.243-.939-.853-2.048-1.408-3.115-2.133-1.28-.81-2.517-1.664-3.968-2.219-.299-.085-.512-.341-.81-.426-1.11-.384-2.22-.214-3.371-.384-1.067-.171-2.048-.64-3.2-.64h-526.72l-5.248-25.942a21.29 21.29 0 1 0-41.814 8.406l129.75 641.792a21.333 21.333 0 1 0 41.813-8.406l-38.4-189.866h207.787a21.333 21.333 0 0 0 0-42.667" fill="#3C405D"/><path d="M871.21 619.69a148.779 148.779 0 0 0-221.782-28.757v-2.688a20.181 20.181 0 0 0-40.362 0v61.696c0 4.011 1.493 7.552 3.541 10.71a19.456 19.456 0 0 0 8.79 8.277 20.096 20.096 0 0 0 26.623-10.112 108.97 108.97 0 0 1 189.782-16.555 108.928 108.928 0 0 1-90.283 169.643 108.63 108.63 0 0 1-107.179-89.6 20.181 20.181 0 0 0-39.68 7.083 148.907 148.907 0 0 0 146.86 122.88A149.333 149.333 0 0 0 896.681 703.06c0-29.866-8.832-58.752-25.472-83.328" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Activereview;
