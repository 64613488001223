import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * InTransitreport 图标组件
 */
export const InTransitreport = defineComponent({
	name: "InTransitreport",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-InTransitreport icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M789.333 512.896a21.333 21.333 0 0 0 21.334-21.333V213.675c0-47.232-37.248-85.675-82.987-85.675H382.25a21.333 21.333 0 0 0-15.189 6.357c-.256.256-.64.342-.938.64l-146.56 146.56a21.077 21.077 0 0 0-6.016 16.086c-.043.341-.214.64-.214 1.024V811.22c0 47.232 37.248 85.675 83.03 85.675H727.68c45.739 0 82.987-38.443 82.987-85.675V619.563a21.333 21.333 0 0 0-42.667 0V811.22c0 23.723-18.09 43.008-40.32 43.008H296.363c-22.23 0-40.363-19.285-40.363-43.008v-505.77l134.784-134.784H727.68c22.23 0 40.32 19.285 40.32 43.008v277.888a21.333 21.333 0 0 0 21.333 21.333" fill="#3C405D"/><path d="M469.333 425.813a21.333 21.333 0 0 0-30.165 0l-88.021 88.022a21.333 21.333 0 0 0-4.608 23.168 21.035 21.035 0 0 0 20.565 17.664h165.803a21.333 21.333 0 1 0 0-42.667H413.312l56.021-56.021a21.333 21.333 0 0 0 0-30.166M616.448 512a21.333 21.333 0 0 0 0 42.667h44.459a21.333 21.333 0 0 0 0-42.667h-44.459z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default InTransitreport;
