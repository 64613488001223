import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Returnwarehousereceipt 图标组件
 */
export const Returnwarehousereceipt = defineComponent({
	name: "Returnwarehousereceipt",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Returnwarehousereceipt icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M789.333 512a21.333 21.333 0 0 0 21.334-21.333V299.008c0-47.232-37.248-85.675-83.03-85.675h-97.024A128.299 128.299 0 0 0 510.208 128a128.64 128.64 0 0 0-120.277 85.333h-93.568c-45.782 0-83.03 38.443-83.03 85.675v511.317c0 47.232 37.248 85.675 83.03 85.675h431.274c45.782 0 83.03-38.443 83.03-85.675V618.667a21.333 21.333 0 0 0-42.667 0v191.658c0 23.723-18.09 43.008-40.363 43.008H296.363c-22.272 0-40.363-19.285-40.363-43.008V299.008C256 275.285 274.09 256 296.363 256H403.54a20.821 20.821 0 0 0 12.843-4.736 20.907 20.907 0 0 0 10.496-13.781 85.845 85.845 0 0 1 83.328-66.816c40.448 0 75.605 28.714 83.627 68.266C595.883 249.003 604.8 256 614.74 256c.854 0 1.75-.17 2.646-.256.426.043.81.256 1.28.256h108.97C749.91 256 768 275.285 768 299.008v191.659A21.333 21.333 0 0 0 789.333 512" fill="#3C405D"/><path d="M341.333 554.667A21.333 21.333 0 0 0 362.667 576h295.125a21.333 21.333 0 0 0 21.333-21.333 21.205 21.205 0 0 0-7.338-15.83c-.299-.298-.384-.725-.683-1.024l-88.021-88.021A21.333 21.333 0 0 0 552.917 480l53.334 53.333H362.667a21.333 21.333 0 0 0-21.334 21.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Returnwarehousereceipt;
