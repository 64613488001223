import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Backup 图标组件
 */
export const Backup = defineComponent({
	name: "Backup",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Backup icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M636.3 590.4L529.6 483.8c-9.1-9.1-23.7-9.1-32.8 0L390.1 590.4c-9.1 9.1-9.1 23.7 0 32.8 9.1 9.1 23.7 9.1 32.8 0l67.1-67.1v234.8c0 12.8 10.4 23.2 23.2 23.2 12.8 0 23.2-10.4 23.2-23.2V556.2l67.1 67.1c4.5 4.5 10.5 6.8 16.4 6.8s11.9-2.3 16.4-6.8c9.1-9.1 9.1-23.8 0-32.9z"/><path d="M998.7 526.5c-96.4-153.1-171.1-186.7-250.4-180.6-45.3-109.1-152-181.2-271.2-181.2-135 0-252.4 92.5-284.9 222-121.4 26-204.4 142.9-187 268.2 16.2 117.3 122.1 205.6 246.2 205.6h149.1c12.8 0 23.2-10.4 23.2-23.2s-10.4-23.2-23.2-23.2H251.4c-101.1 0-187.2-71.2-200.2-165.6C36.6 543 110 444.8 214.9 429.8c9.8-1.4 17.6-8.9 19.5-18.6C256.8 295.2 358.9 211 477.1 211c105.6 0 199.6 67.2 233.9 167.3 3.6 10.5 14.5 16.9 25.2 15.4 57.8-8.2 122.6-2.6 223.3 157.4 12.4 19.7 18.5 42.3 17.3 63.7-6 105.1-89.7 190.3-194.7 198.3-1.6.1-3.1.4-4.5.9H658.8c-12.8 0-23.2 10.4-23.2 23.2s10.4 23.2 23.2 23.2h125.1c2.8 0 5.5-.5 8-1.5C917 846.3 1016 743.6 1023.1 617.5c1.8-30.9-6.9-63.3-24.4-91z"/></svg>
			</ElIcon>
		);
	},
});

export default Backup;
