import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Replenishmentmanagement 图标组件
 */
export const Replenishmentmanagement = defineComponent({
	name: "Replenishmentmanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Replenishmentmanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.832 170.667c50.987 0 92.501 41.514 92.501 92.501v225.237a21.333 21.333 0 1 1-42.666 0V263.168a49.92 49.92 0 0 0-49.835-49.835H682.24v63.531a21.287 21.287 0 0 1-21.333 21.333H362.24a21.333 21.333 0 0 1-21.333-21.333v-63.53H263.21a49.92 49.92 0 0 0-49.878 49.834v497.621a49.92 49.92 0 0 0 49.878 49.878H490.24a21.333 21.333 0 1 1 0 42.666H263.21a92.63 92.63 0 0 1-92.543-92.544V263.168c0-50.987 41.514-92.501 92.544-92.501h497.621zM383.573 255.53h256v-42.198h-256v42.198z" fill="#3C405D"/><path d="M703.573 639.573a64 64 0 1 0 .043 128 64 64 0 0 0-.043-128" fill="#3C405D"/><path d="M703.573 810.24a106.752 106.752 0 0 1-106.666-106.667c0-58.88 47.829-106.666 106.666-106.666 58.88 0 106.667 47.786 106.667 106.666 0 58.795-47.787 106.667-106.667 106.667m0-256c-9.344 0-18.346 1.067-27.221 2.73.128-.98.555-1.791.555-2.773a21.333 21.333 0 0 0-21.334-21.333h-248.96l56.064-56.021a21.419 21.419 0 0 0-30.208-30.208l-87.978 88.021a21.333 21.333 0 0 0-4.651 23.168 21.077 21.077 0 0 0 20.608 17.707h267.435a148.821 148.821 0 0 0-73.643 128c0 82.346 66.987 149.333 149.333 149.333s149.334-66.987 149.334-149.333-66.987-149.334-149.334-149.334" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Replenishmentmanagement;
