import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Purchasemanagement 图标组件
 */
export const Purchasemanagement = defineComponent({
	name: "Purchasemanagement",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Purchasemanagement icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M760.79 170.667c51.029 0 92.543 41.514 92.543 92.501v228.821a21.333 21.333 0 1 1-42.666 0V263.168a49.92 49.92 0 0 0-49.878-49.835h-78.592v63.531a21.287 21.287 0 0 1-21.333 21.333H362.197a21.333 21.333 0 0 1-21.333-21.333v-63.53h-77.696a49.92 49.92 0 0 0-49.835 49.834v497.621a49.92 49.92 0 0 0 49.835 49.878h227.03a21.333 21.333 0 1 1 0 42.666h-227.03a92.63 92.63 0 0 1-92.501-92.544V263.168c0-50.987 41.514-92.501 92.501-92.501h497.621zM383.53 255.53h256v-42.198h-256v42.198z" fill="#3C405D"/><path d="M470.613 425.344a21.333 21.333 0 0 0-30.165 0l-88.021 88.021a21.205 21.205 0 0 0-4.608 23.168 20.992 20.992 0 0 0 20.565 17.707h295.125a21.333 21.333 0 1 0 0-42.667H414.592l56.021-56.064a21.333 21.333 0 0 0 0-30.165m232.96 214.23a64 64 0 1 0 0 128 64 64 0 0 0 0-128" fill="#3C405D"/><path d="M703.573 810.24a106.752 106.752 0 0 1-106.666-106.667c0-58.88 47.786-106.666 106.666-106.666 58.795 0 106.667 47.786 106.667 106.666 0 58.795-47.872 106.667-106.667 106.667m0-256c-40.533 0-77.226 16.299-104.234 42.667h-235.35a21.333 21.333 0 0 0 0 42.666h205.227a147.797 147.797 0 0 0-15.019 64c0 82.347 66.987 149.334 149.334 149.334s149.333-66.987 149.333-149.334S785.877 554.24 703.531 554.24" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Purchasemanagement;
