import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * RealTimeKanban 图标组件
 */
export const RealTimeKanban = defineComponent({
	name: "RealTimeKanban",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-RealTimeKanban icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M832 508.416a21.333 21.333 0 0 0 21.333-21.333v-128a106.24 106.24 0 0 0-106.197-106.07H276.821a106.24 106.24 0 0 0-106.154 106.07v386.389a106.24 106.24 0 0 0 106.154 106.07h470.315a106.24 106.24 0 0 0 106.197-106.07V616.875a21.333 21.333 0 0 0-42.666 0v128.597a63.573 63.573 0 0 1-63.531 63.403H276.821a63.573 63.573 0 0 1-63.488-63.403v-386.39a63.573 63.573 0 0 1 63.488-63.402h470.315a63.573 63.573 0 0 1 63.53 63.403v128A21.333 21.333 0 0 0 832 508.416M318.208 213.333h389.333a21.333 21.333 0 0 0 0-42.666H318.208a21.333 21.333 0 0 0 0 42.666" fill="#3C405D"/><path d="M512 382.208a21.333 21.333 0 0 0-21.333 21.333v149.334A21.333 21.333 0 0 0 512 574.208c.299 0 .555-.17.896-.17.299 0 .555.17.853.17h192a21.333 21.333 0 0 0 0-42.667H533.333v-128A21.333 21.333 0 0 0 512 382.208" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default RealTimeKanban;
