import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Logistics 图标组件
 */
export const Logistics = defineComponent({
	name: "Logistics",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Logistics icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M730.667 682.667a42.71 42.71 0 0 1 0 85.333 42.71 42.71 0 0 1 0-85.333M318.208 768a42.71 42.71 0 0 1 0-85.333 42.667 42.667 0 0 1 0 85.333M170.667 629.333V509.44L227.115 384H384v244.95c0 24.277 8.405 46.42 22.059 64.383h-8.832A85.461 85.461 0 0 0 318.208 640c-35.712 0-66.261 22.144-78.976 53.333h-6.357c-.342 0-.64.171-.982.214-.426-.043-.768-.299-1.237-.342a64.128 64.128 0 0 1-59.99-63.872m704-202.666A21.333 21.333 0 0 0 896 405.333v-84.949a107.093 107.093 0 0 0-107.008-107.05H491.008A107.093 107.093 0 0 0 384 320.383v20.95H213.333c-.81 0-1.493.383-2.261.469a22.699 22.699 0 0 0-5.675 1.109c-1.237.512-2.261 1.408-3.37 2.133-1.195.726-2.432 1.323-3.456 2.304-1.238 1.238-2.134 2.774-3.03 4.267-.512.81-1.28 1.365-1.664 2.304l-.17.427-.043.042-63.787 141.739-.042.213c-.555 1.28-.64 2.731-.939 4.096-.299 1.451-.853 2.816-.853 4.224l-.043.214v124.458A106.837 106.837 0 0 0 229.333 735.83c.512 0 .896-.256 1.408-.256.726.086 1.366.427 2.134.427h1.109c5.29 41.941 40.832 74.667 84.224 74.667A85.163 85.163 0 0 0 402.475 736H646.4c5.333 41.941 40.832 74.667 84.267 74.667a85.163 85.163 0 0 0 84.608-78.294A106.581 106.581 0 0 0 896 628.95v-95.616a21.333 21.333 0 0 0-42.667 0v95.616c0 28.63-19.157 52.182-45.568 60.416A85.29 85.29 0 0 0 730.667 640c-35.712 0-66.304 22.144-78.976 53.333H491.008a64.427 64.427 0 0 1-64.341-64.384V320.384A64.427 64.427 0 0 1 491.008 256h297.984a64.427 64.427 0 0 1 64.341 64.384v84.95a21.333 21.333 0 0 0 21.334 21.333" fill="#3D405D"/><path d="M316.459 522.667a21.333 21.333 0 0 0 0-42.667h-80a21.333 21.333 0 0 0 0 42.667h80z" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Logistics;
