import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Price 图标组件
 */
export const Price = defineComponent({
	name: "Price",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Price icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M829.013 572.544a20.096 20.096 0 0 0-23.338 16.299 320.64 320.64 0 0 1-316.16 264.49c-177.152 0-321.238-144.085-321.238-321.194a321.067 321.067 0 0 1 321.195-321.195 321.28 321.28 0 0 1 316.203 264.533 20.181 20.181 0 0 0 39.68-7.082 361.387 361.387 0 0 0-355.883-297.728A361.387 361.387 0 0 0 128 532.139c0 199.338 162.176 361.472 361.472 361.472a360.79 360.79 0 0 0 355.84-297.728 20.096 20.096 0 0 0-16.299-23.339" fill="#3C405D"/><path d="M638.379 543.787a21.333 21.333 0 0 0 0-42.667H513.493v-23.893l103.766-97.92a21.333 21.333 0 1 0-29.312-31.062l-98.134 92.587-98.133-92.587a21.333 21.333 0 0 0-29.27 31.062L470.828 481.62v19.499H339.712a21.333 21.333 0 0 0 0 42.667h131.115v59.989H339.712a21.333 21.333 0 0 0 0 42.667h131.115v93.781a21.333 21.333 0 1 0 42.666 0V646.4H638.38a21.333 21.333 0 1 0 0-42.667H513.493v-59.989H638.38z" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Price;
