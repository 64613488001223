import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Activitymonitoring 图标组件
 */
export const Activitymonitoring = defineComponent({
	name: "Activitymonitoring",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Activitymonitoring icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M538.444 597.035H295.799l-68.907-340.566h488.747l-53.163 163.67c-.213.64-.085 1.28-.213 1.962a21.205 21.205 0 0 0 2.858 16.043c.427.555.47 1.28.896 1.75l85.334 108.458a21.333 21.333 0 1 0 33.493-26.41l-78.25-99.5 58.666-180.735c.427-1.11.213-2.134.427-3.286.128-1.109.64-2.133.64-3.285 0-.341-.171-.597-.214-.939 0-1.493-.426-2.901-.853-4.352-.341-1.237-.512-2.56-1.067-3.712-.554-1.194-1.45-2.218-2.218-3.285-.811-1.152-1.536-2.304-2.56-3.243-.854-.853-2.048-1.408-3.115-2.133-1.28-.81-2.475-1.664-3.925-2.219-.3-.085-.512-.341-.811-.426-1.11-.384-2.261-.214-3.37-.384-1.11-.171-2.091-.64-3.2-.64h-526.72l-5.249-25.942a21.333 21.333 0 1 0-41.813 8.406l129.75 641.792a21.29 21.29 0 1 0 41.813-8.406l-38.4-189.866h234.069a21.333 21.333 0 0 0 0-42.667M789.452 810.667H669.985a21.333 21.333 0 0 0 0 42.666h119.467a21.333 21.333 0 0 0 0-42.666M727.927 627.2a34.133 34.133 0 1 0 0 68.267 34.133 34.133 0 0 0 0-68.267" fill="#3C405D"/><path d="M727.927 759.467a81.152 81.152 0 0 1-81.067-80.982c0-44.714 36.395-81.109 81.067-81.109a80.981 80.981 0 0 1 81.066 81.067 81.152 81.152 0 0 1-81.066 81.066m119.85-112.042a123.733 123.733 0 1 0-239.573 61.909 123.733 123.733 0 0 0 239.573-61.91" fill="#3C405D"/></svg>
			</ElIcon>
		);
	},
});

export default Activitymonitoring;
