import { defineComponent } from "vue";
import { ElIcon } from "element-plus";

/**
 * Routingwarehouse 图标组件
 */
export const Routingwarehouse = defineComponent({
	name: "Routingwarehouse",
	components: {
		ElIcon,
	},
	setup(props, { attrs, slots, emit, expose }) {
		expose({});

		return {
			attrs,
			slots,
		};
	},
	render() {
		return (
			<ElIcon {...this.attrs} class="el-icon g-icon g-icon-Routingwarehouse icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M654.942 406.23a21.333 21.333 0 0 0-30.165 0l-86.016 86.015H341.34a21.333 21.333 0 0 0 0 42.667H544.01a20.779 20.779 0 0 0 9.728-2.56 21.077 21.077 0 0 0 10.666-5.419l90.539-90.496a21.333 21.333 0 0 0 0-30.165m-90.496 179.541a20.48 20.48 0 0 0-10.71-5.418 20.352 20.352 0 0 0-9.727-2.646H341.342a21.333 21.333 0 0 0 0 42.667H538.76l86.016 86.101a21.419 21.419 0 0 0 30.165 0 21.333 21.333 0 0 0 0-30.165l-90.496-90.539z" fill="#3D405D"/><path d="M846.6 452.181L507.188 133.59a21.12 21.12 0 0 0-16.512-5.504 21.333 21.333 0 0 0-16.554 5.504L134.75 452.181a21.29 21.29 0 1 0 29.184 31.104l9.6-8.96v279.766a100.139 100.139 0 0 0 100.224 99.882h433.835c55.296 0 100.266-44.8 100.266-99.882v-71.552a21.333 21.333 0 0 0-42.666 0v71.552c0 31.53-25.856 57.216-57.6 57.216H273.758a57.472 57.472 0 0 1-57.6-57.216V434.26l274.517-257.664 274.518 257.664V554.54a21.333 21.333 0 1 0 42.666 0v-80.214l9.558 8.96a21.12 21.12 0 0 0 30.122-.938 21.29 21.29 0 0 0-.938-30.166" fill="#3D405D"/></svg>
			</ElIcon>
		);
	},
});

export default Routingwarehouse;
